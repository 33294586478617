.announcementIcon {
  //margin-right: 5px;
  height: 27px;
  width: 27px;
  //margin-bottom: 15px;
  margin-bottom: 6px;
  cursor: pointer;
  margin-top: 6px;
}

.badge {

}

@primary-color: #36c889;