.product-teaser {
    position: relative;
    overflow: hidden;
}

.event-teaser {
    position: relative;
    display: flex;
    margin-bottom: 30px;
    padding: 30px 25px;
    background-color: #f6f6f6;
    border-radius: 10px;
}

.event-teaser .event-image .field-field-image {
    border-radius: 70%;
    position: relative;
    overflow: hidden;
}

.event-image-webinar {
    width: 100px;
    height: 100px;
    vertical-align: middle;
}
.text-webinar{
    text-align: center;
    margin-top: 10px;
}

.event-teaser .event-content-wrap {
    position: relative;
    width: 75%;
    margin-left: 50px;
}

.event-teaser .event-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding-right: 100px;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

a, a:hover, a:focus, a:visited {
    text-decoration: none;
    outline: none;
}

a {
    color: #333333;
}

.event-teaser .event-price {
    position: absolute;
    right: 0;
    top: 0;
    color: #0f52ba;
    font-size: 24px;
    font-weight: 500;
}

.event-teaser .event-meta {
    display: flex;
}

.event-teaser .event-date-wrap {
    width: 30%;
    flex: 1;
}

.event-teaser .event-time {
    width: 25%;
    flex: 1;
}

.event-teaser .event-venue-wrap {
    flex: 2;
}

.event-teaser .event-date span {
    display: block;
    line-height: 1.2;
}

.event-teaser .event-date-day {
    font-size: 25px;
    font-weight: 300;
    float: left;
    line-height: 1;
    margin-right: 7px;
    color: #0f52ba;
}

.event-teaser .event-date-month {
    text-transform: uppercase;
}

.event-teaser .event-venue-wrap, .event-teaser .event-date-wrap, .event-teaser .event-time {
    width: 100%;
}


.event-teaser .event-time, .event-teaser .event-venue-wrap {
    padding-top: 5px;
}

.event-teaser .event-venue, .event-teaser .event-location {
    display: inline-block;
}

.event-teaser .event-button {
    position: relative;
}

.button {
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.1;
    margin: 10px 0;
    outline: 0 none;
    padding: 10px 20px;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    position: relative;
    white-space: nowrap;
    transition: 0.3s;
    background-color: #0f52ba;
    overflow: hidden;
    margin-top: 53px;
    margin-left: 20px;
}
.info_box  p {
    font-size: 18px !important;
    line-height: 20px;
    padding-top: 16px;
    font-weight: 300 !important;
}


/* student events styles */
.gap1-62 {
    padding: 16px !important;
}
.text-webinar1 {
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
}

.event-teaser .event-date-day1 {
    font-size: 12px;
    font-weight: 400;
    float: left;
    line-height: 1;
    margin-right: 7px;
    color: #0f52ba;
}
.event-cityname {
    font-size: 11px;
    font-weight: normal;
}


.event-teaser .event-date1 span {
    display: block;
    line-height: 1.2;
    /* background: #e8f4f8; */
    border-radius: 5px;
    height: 120px;
    width: 160px;
    text-align: center;
    padding: 40px 20px;
    color: black;
    font-weight: 500;
    font-size: 15px;
}


.event-date-year1 {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 1px;
}

.event-date-time1 {
    font-weight: 700;
    font-size: 15px;
    margin-top: 4px;
}
.event-teaser1 {
    padding: 8px 8px 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 5px;
    background: white;
    max-width: 100%;
}

.event-teaser .event-title1 {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.2;
    padding-bottom: 3px;
    margin-bottom: 1px;
    padding-right: 100px;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.event-teaser .event-title1.fontLg {
    font-size: 18px;
}

.button1 {
    padding: 6px 14px;
    font-size: 11px;
    margin: 0px !important;
}
.event-teaser .event-content-wrap1 {
    position: relative;
    width: 100%;
    margin-left: 1px;
}
.applyButton{
    font-size: 12px;
    margin-top: 30px;
    color: white;
    background: #0f52ba;
    /* margin: 20px; */
    min-height: 31px;
}
.disableApply {
    font-size: 12px;
    margin-top: 30px;
    color: #0f52ba;
    /* border: 1px solid #0f52ba; */
    padding: 5px;
    /*border-radius: 5px;*/
    background-color: #f0faff;
}

.event-teaser .event-venue-wrap1 {
    flex: 2 1;
    margin-left: 10px;
    margin-right: 20px;
}
.event-teaser .event-content-wrap1 {
    position: relative;
    width: 100%;
    margin-left: 1px;
}

.nonActive-event {
    color: red;
    font-size: 11px;
}

.active-event {
    color: #00b600;
    font-size: 11px;

}

.loadMore {
    color: white;
    background: #0f52ba;
    font-weight: 500;
    border: none;
}
