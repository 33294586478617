.custom-exchange {
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    background-color: #f9f9f9;
    overflow: hidden;
}

.custom-exchange:hover {
    transform: translateY(-10px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.custom-exchange-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.custom-exchange-body {
    padding: 13px;
    text-align: center;
    min-height: 190px;
}

.custom-exchange-img {
    max-width: 50%;
    border-radius: 50%;
    border: 2px solid #e0e0e0;
    margin-bottom: 15px;
}

.custom-exchange-btn {
    background-color: #0f52ba;
    border: none;
    border-radius: 25px;
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

.custom-exchange-btn:hover {
    background-color: #0f52ba;
}

.custom-exchange-link {
    color: #0f52ba;
    font-size: 12px;
    text-decoration: none;
}

.custom-exchange-link:hover {
    text-decoration: underline;
}


.order-summary-card {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
}

.doc-label {
    font-size: 12px;
    font-weight: bold;
}

.status-information-card {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.status-item {
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    text-align: center;
}


.header-quote .quotation-date p {
    font-weight: bold;
}

.description-section table tr td:first-child {
    font-weight: bold;
}

.description-section table {
    max-width: 65%;
    font-size: 13px;
}

.description-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-quote img {
    max-width: 22%;
}

.quotation-date {
    float: right;
}
