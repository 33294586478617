.ant-select.ant-fullcalendar-month-select {
    width: 15%;

}

.ant-select.ant-fullcalendar-year-select {
    width: 15%;
}

.ant-fullcalendar-header .ant-radio-group {
    display: none !important;
}

.ant-fullcalendar-column-header {
    padding: 10px !important;
    background-color: white;
}

.ant-descriptions {
    border: 2px solid #000;
    padding: 20px;
    border-radius: 8px;
}

.ant-descriptions-item-label {
    font-weight: bold;
    color: #333;
}

.ant-descriptions-item-content {
    color: #666;
}

.menu-label {
    padding: 6px !important;
    font-size: 13px !important;
}

.comment-text {
    flex: 1 1;
    background-color: #f0f0f0;
    border-radius: 35px;
    padding: 6px 20px 5px 20px;
    max-width: 85%;
}

.time-span {
    color: #0f52ba;
    font-size: 8px !important;
    font-style: italic;
}
.comment-text .mb5 {
    margin-bottom: 5px;
    font-size: 12px;
}
.comment-author .name {
    text-transform: capitalize;
    font-weight: 600;
}

.avatar_text1 {
    background-color: #0f52ba;
    margin: 0 12px;
    font-size: 17px !important;
}
.comment-section .sendBtn1 {
    border-radius: 15px !important;
    background-color: #0f52ba;
    border: none;
    color: white;
    padding: 14px !important;
}

.act-container {
    padding: 20px;
    border-bottom: 1px solid #ccc;
}

.act-card {
    background-color: #efefef;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.act-card-body {
    padding: 15px;
}
.act-list {
    list-style: none;
    padding: 26px;
}


.act-list-item {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 15px;
    display: flex;
}

.act-date {
    float: left;
    width: 100px;
    text-align: center;
    font-size: 14px;
    color: #333;
    border-right: 1px solid #ccc;
    padding-right: 15px;
    font-weight: bold;
}

.act-content {
    margin-left: 15px;
    flex: 1;
}

.act-label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 17px;
    color: #333;
}

.act-dot {
    color: blue;
    margin-right: 5px;
}

.act-info {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
}

.act-message {
    margin-left: 0;
    padding-left: 0;
    color: #000;
}

.act-message-item {
    font-size: 15px;
    line-height: 1.5;
}

.act-subject {
    margin-bottom: 10px;
    font-size: 15px;
    color: #000;
}



/* .react-calendar__tile--active {
    background: white !important;
    color: black !important;
} */

.event-card.booked {
    color: #0f52ba;
    border: 2px solid #0f52ba;
}

.event-card.available {
    color: #00cc00;
    border: 2px solid #00cc00;
}

.event-card.pending {
    color: red;
    border: 2px solid red;

}