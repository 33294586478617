@import "baseColors";

:global {
  .afs {
    align-items: flex-start !important;
  }

  .flex-d-columns {
    flex-direction: column;
  }

  .hr1 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

@primary-color: #36c889;