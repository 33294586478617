@import "baseColors";

:global {
  body {
    color: #212529 !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px;
  }

  .logo-link {
    display: none;
  }

  .ant-layout {
    background: white;
  }


  .ant-card-head,
  .ant-drawer-header {
    //background: @basic;
    .ant-drawer-title {
      font-size: 15px;
      font-weight: 600;
      text-transform: capitalize;
      //padding: 0 40px;
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  input[type='text'],
  input[type='password'],
  input[type='number'],
  .ant-select-selection {
    height: 33px;
  }

  .ant-input,
  .ant-input-number,
  .ant-select-selection--single {
    height: 33px;
  }

  .ant-input {
    &.ant-select-search__field {
      height: 43px !important;
      border: 1px solid #666 !important;
    }
  }

  .ant-select {
    width: 100%;
    font-size: 12px;
  }

  .mt0 {
    margin-top: 0 !important;
  }

  .mt1 {
    margin-top: 1px !important;
  }

  .ck.ck-reset.ck-editor.ck-rounded-corners {
    width: 100%;
  }

  .mt3 {
    margin-top: 3px !important;
  }

  .mt5 {
    margin-top: 7px !important;
  }

  .mt10 {
    margin-top: 10px !important;
  }

  .mt15 {
    margin-top: 15px !important;
  }

  .mt18 {
    margin-top: 18px !important;
  }

  .mt20 {
    margin-top: 20px !important;
  }

  .mt30 {
    margin-top: 30px !important;
  }

  .mt40 {
    margin-top: 40px !important;
  }

  .p10 {
    padding: 10px;
  }

  .p20 {
    padding: 20px;
  }

  .mb0 {
    margin-bottom: 0 !important;
  }

  .mb10 {
    margin-bottom: 10px !important;
  }

  .mb20 {
    margin-bottom: 20px !important;
  }

  .mb5 {
    margin-bottom: 5px;
  }

  .ml5 {
    margin-left: 5px !important;
  }

  .mr5 {
    margin-right: 5px !important;
  }

  .mr0 {
    margin-right: 0 !important;
  }

  .ml10 {
    margin-left: 10px;
  }

  .ml15 {
    margin-left: 15px;
  }

  .ml20 {
    margin-left: 20px;
  }

  .mr10 {
    margin-right: 10px;
  }

  .mr20 {
    margin-right: 20px;
  }

  .mb100 {
    margin-bottom: 100px;
  }

  .customForm {
    .ant-select {
      width: 100%;
    }

    .ant-form-item-label {
      line-height: 28.9999px;
    }

    .ant-form-item {
      margin-bottom: 2px;
    }
  }

  .ant-layout-sider {
    background: white;
  }

  .ant-form-item {
    margin-bottom: 2px;
  }

  .antd-pro-components-sider-menu-index-logo {
    text-align: center;
    height: 60px;
    background: white !important;
    padding: 5px 5px 5px 0;
    display: flex;

    img {
      height: 100%;
    }
  }

  .ant-layout-sider-collapsed {
    .antd-pro-components-sider-menu-index-logo {
      text-align: left;
      height: 60px;
      background: white;
      padding: 5px;
      display: flex;
    }
  }

  .ant-layout-sider-collapsed {
    width: 120px !important;
    min-width: 120px !important;
    max-width: 120px !important;

    .antd-pro-components-sider-menu-index-logo {
      a {
        text-align: center;
      }
    }
  }

  .ant-menu-sub {
    //background-color: #0872BC !important;
  }

  .ant-menu-submenu-popup {
    left: 120px !important;
  }

  .ant-menu-inline-collapsed {
    padding-top: 0 !important;

    /*.ant-menu-item-selected {
      background-color: #22ade2 !important;
    }*/

    /* li {
       padding: 0 10px !important;
       display: flex;
       width: 100% !important;
       justify-content: center;
       align-items: center;
       height: 60px !important;

       .ant-menu-submenu > .ant-menu-submenu-title {
         padding: 0 10px !important;
       }

       span, a {
         line-height: 15px;
         color: #fff;
         display: flex !important;
         flex-direction: column !important;
         width: 100% !important;
         justify-content: center;
         align-items: center;

         span {
           opacity: 1 !important;
         }
       }
     }*/
  }

  .englishTypeBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    div {
      width: 80px;
    }
  }

  /*.inputBox {
    margin-top: 10px;
    margin-bottom: 10px;

    .labelRow {
      line-height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0;

      .extra {
        a {
          margin-left: 10px;
        }
      }
    }

    .labelNew {
      //line-height: 25px;
      line-height: 34px;
      color: rgba(0, 0, 0, 0.65);
      float: left;
      margin: 0 15px 0;
    }

    .innerBox {
      //margin-top: 14px;

      &.logoCls {
        //margin-top: 10px;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }

    .uniTypeCheckbox {
      .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        label {
          width: 45%;
          margin-bottom: 5px;
        }
      }
    }
  }*/

  .inputBox {
    /*margin-top: 10px;
    margin-bottom: 10px;*/

    margin-bottom: 12px;

    .labelRow {
      display: inline-block;
      overflow: hidden;
      //line-height: 39.9999px;
      //line-height: 32.9999px;
      //line-height: 27px;
      white-space: nowrap;
      text-align: right;
      vertical-align: middle;
      padding: 0 10px;

      display: inline-block;
      overflow: hidden;
      line-height: 30px;
      white-space: nowrap;
      text-align: right;
      vertical-align: middle;

      .extra {
        a {
          color: #000000d9;
          margin-left: 10px;
          text-decoration: underline;
        }
      }
    }

    .labelNew {
      //line-height: 25px;
      float: left;
      //padding-left: 15px;
      color: #666;
      margin-bottom: 0;
    }

    .innerBox {
      //margin-top: 14px;
      position: relative;
      line-height: 40px;
      zoom: 1;
      //padding: 3px 0 2px;

      &.logoCls {
        //margin-top: 10px;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }

    .uniTypeCheckbox {
      .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        label {
          width: 45%;
          margin-bottom: 5px;
        }
      }
    }
  }

  .ant-select-selection--multiple {
    height: auto;
  }

  .ant-input-number {
    width: 100%;
  }

  .courseBox {
    .ant-card-body {
      padding-bottom: 0;
    }

    a {
      color: rgb(0, 100, 225);
    }

    line-height: 22px;

    .ant-card-bordered {
      border: none;
      border-bottom: 1px solid #e8e8e8;
    }

    .uniInfo {
      display: flex;
      align-items: center;
      font-weight: normal;
      padding: 8px 24px;

      img {
        height: 48px !important;
        width: 48px !important;
        margin-right: 8px;
        border-radius: 50%;
        border: 1px solid #ddd;
      }

      .uniName {
        font-size: 20px;

        .uniAdd {
          font-size: 15px;
        }
      }
    }

    .courseRow {
      //border-bottom: 1px solid #dddddd54;
      line-height: 22px;
      margin-bottom: 17px;
      margin-top: 9px;
      padding: 12px 24px;

      &:last-child {
        border: none;
      }

      .courseName {
        font-size: 13px;
        margin-right: 20px;
      }

      .courseInfo {
        font-weight: normal;
        font-size: 15px;
        margin-top: 8px;

        .ant-col {
          line-height: 1;

          span {
            font-size: 12px;
          }

          small {
            font-size: 12px;
          }

          button {
            color: rgb(47, 138, 201);
          }
        }
      }
    }

    .loadAllBtn {
      display: flex;
      justify-content: space-between;
      padding: 10px 100px;
      position: absolute;
      z-index: 999;
      top: 100px;
      width: 100%;
      background: #1890ff54;
      font-size: 17px;
      //color: white;
      font-weight: 500;
      align-items: center;
      border-left: 10px solid @primary;
    }
  }

  .universityBox {
    a {
      color: rgb(0, 100, 225);
    }

    .ant-col {
      margin-bottom: 10px;
    }

    .uniInfo {
      display: flex;
      align-items: center;
      font-weight: normal;
      padding: 8px 5px;
      height: 50px;

      img {
        height: 40px;
        width: 40px;
        margin-right: 8px;
        border-radius: 50%;
        border: 1px solid #ddd;
      }

      .uniName {
        font-size: 15px;

        .uniAdd {
          font-size: 13px;
        }
      }
    }
  }

  .linkBtn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    text-align: center;
    touch-action: manipulation;
    height: 32px;
    padding: 3px 40px;
    font-size: 13px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    //border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);

    i {
      margin-right: 5px;
    }

    &.small {
      height: 28px;
      padding: 2px 10px;
    }
  }

  .alignCenter {
    text-align: center;
    cursor: pointer;
  }

  .alignRight {
    text-align: right;
  }

  .alignLeft {
    text-align: left !important;
  }

  .loadMoreBox {
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    margin-bottom: 15px;
  }

  .totalCourseCount {
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    position: relative;
  }

  .vertical-form {
    .header {
      font-size: 25px !important;
      color: #606a84;
      margin-top: 10px;
    }

    .ant-card {
      margin-bottom: 20px;
    }

    .ant-row {
      margin-bottom: 0;

      .ant-form-item-label {
        text-align: left;
        line-height: 30px;
      }
    }

    .ant-calendar-picker {
      width: 100%;
    }
  }

  .sticky {
    /*position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    left: 0;*/

    position: fixed;
    top: 0;
    width: calc(100% - 168px);
    z-index: 10;
    opacity: 0.9;
  }

  .outerLoader {
    height: 100%;
    width: 100%;
    background: #ffffffc7;
    z-index: 9999;
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: @primary;
    font-size: 25px;

    .ant-spin-dot {
      font-size: 40px;
    }

    .mainLoader {
      margin-top: 20px;
      font-size: 50px;

      i {
        margin-right: 20px;
      }
    }
  }

  .imgSm {
    height: 50px;
    width: 50px;
  }

  input[type='file']::-webkit-file-upload-button {
    color: rgba(0, 0, 0, 0.85);
    display: inline-block;
    font-weight: normal;
    border-radius: 3px;
    white-space: nowrap;
    cursor: pointer;
    height: 100%;
    border: none;
    border-right: 1px solid #ddd;
    width: 150px;
    margin-right: 10px;
  }

  .ant-form input[type='file']:focus {
    outline: 0 auto -webkit-focus-ring-color !important;
    border-color: white;
  }

  .rowFlex {
    display: flex;

    > div {
      width: 100%;
    }

    button {
      margin-left: 10px;
    }

    .d1 {
      width: 100%;

      &.mr10 {
        margin-right: 10px;
      }
    }

    .d2 {
      width: 100%;
      margin-left: 10px;
    }
  }

  .linkBtn {
    line-height: 1.499;
    position: relative;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    touch-action: manipulation;
    height: 40px;
    padding: 0 10px;
    font-size: 18px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #666;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 5px;
    background-color: white;
    margin-top: 1px;

    &.sm {
      height: 25px;
      padding: 0 10px;
      font-size: 13px;
    }
  }

  ul {
    &.list {
      padding-left: 0;

      li {
        justify-content: space-between;
        border: 1px solid #ddd;
        align-items: center;
        padding: 5px 10px;
        border-bottom: none;

        &:last-child {
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }

  .padd-5-bb-1 {
    border-bottom: 1px solid #d3d3d34a;
    padding: 7px 5px;
    font-size: 13px;

    color: #2a2a2a;

    .ant-row {
      .ant-col:first-child {
        //font-weight: bold;
        color: #333 !important;
      }
    }
  }

  .ant-success {
    color: @success;
  }

  .ant-info {
    color: @info;
  }

  .ant-warning {
    color: @warning;
  }

  .card.unizportal button.btn[type='submit'] {
    width: 150px;
    background: #1556bc;
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    margin: 25px 0 0;
    border-radius: 50px;
    font-weight: 600;
    height: 42px !important;
  }

  .ant-btn-danger {
    color: #fff;
    background-color: @danger;
    border-color: #d43f3a;
  }

  .ant-btn-basic {
    color: #333;
    background-color: @basic;
    border-color: #f7ebeb;
  }

  .successTag {
    background: #0872bc;
    border: 1px solid #0872bc;
    padding: 1px 20px;
    border-radius: 20px;
    font-size: 13px;
    color: white;
  }

  .statusDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .statusBox {
      height: 30px;
      background: #fff;
      border-radius: 0;
      text-align: center;
      border: 1px solid #ddd;
      margin: 3px 0;
      color: #333;
      padding: 3px;
      font-size: 12px;
      width: 350px;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .icon {
        position: absolute;
        right: 10px;
        color: @warning;
        top: 25%;
      }
    }
  }

  .bgTheme {
    background-color: @success !important;
    border: 1px solid @success !important;
    color: white !important;

    .icon {
      color: @success !important;
    }
  }

  table {
    tbody {
      tr:nth-child(odd) {
        background: white;
      }

      tr:nth-child(even) {
        background: #f9f9f9;
      }
    }
  }

  .applicationProfile {
    .imgDiv {
      display: inline-flex;
    }

    overflow: hidden;
    height: calc(100vh - 200);

    aside {
      display: none;
    }

    .ant-layout-content {
      padding: 0;
      margin: 0;
    }

    .antd-pro-components-global-header-index-trigger {
      //display: none;
    }

    .antd-pro-components-global-header-index-header {
      //background: @primary;
      //color: white;
    }

    .ant-drawer-header {
      //background-color: @primary;
      background-color: #fafafa;
      border-radius: 0;
      padding: 10px 20px;

      .ant-drawer-title {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 15px;
      }

      .ant-drawer-close {
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .input-group-box {
      width: 40%;
    }

    .logo-link {
      display: block;
    }

    .displayName {
      display: none;
    }
  }

  .bgWhite {
    background: white !important;
  }

  .appProfile {
    overflow: hidden !important;
    background: #f5f8fa;
    //background: white;
    //padding-top: 10px;

    /* width */

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */

    ::-webkit-scrollbar-track {
      //background: #f1f1f1;
      //background: #1890ff47;
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      //background: #888;
      background: #a9a9a9;
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
      //background: #555;
      //background: @success;
    }

    .align-self-stretch {
      display: flex;
      align-self: stretch !important;
      word-break: break-word;
      overflow: visible;
      flex-grow: 1;
      width: 100%;

      .profile-3-column {
        overflow: scroll;
        height: calc(100vh - 1px);

        &.profile-3-col-sidebar {
          &.profile-3-col-sidebar--left {
            border-right: 1px solid #dfe3eb;
            padding-bottom: 52px;
            //flex-basis: 25%;
            flex-basis: 350px;
            max-width: 500px;
            min-width: 280px;
          }
        }

        &.profile-3-col-sidebar--right-wrapper {
          background-color: #fff;
          display: block;
          flex-basis: 25%;
          max-width: 500px;
          transition: flex 0.15s cubic-bezier(0.16, 0.84, 0.44, 1);
          will-change: flex;
          min-width: 280px;
        }

        padding-bottom: 45px;
      }

      .profile-6-column {
        &.noteBlock {
          flex-basis: 50%;
          //padding-top: 10px;
          height: 100vh;
          //border-left: 1px solid #ddd;
          //border-right: 1px solid #ddd;
          padding-left: 10px;
        }

        padding-bottom: 45px;
      }

      .profile-12-column {
        &.noteBlock {
          /* flex-basis: 100%;
           //padding-top: 10px;
           height: 100vh;
           //border-left: 1px solid #ddd;
           //border-right: 1px solid #ddd;
           padding-left: 10px;
           overflow: hidden;
           border-bottom: 3px solid #ededed;
           padding-top: 15px;*/

          flex-basis: 100%;
          height: 100vh;
          /* padding-left: 10px; */
          overflow: hidden;
          border-bottom: 3px solid #ededed;
          /* padding-top: 15px; */
          border-top: 1px solid #ededed;

          &.noteBlockAgent {
            height: auto !important;
            max-height: 100vh !important;

            .scrollView {
              height: auto !important;
              max-height: 100vh !important;
            }
          }
        }

        padding-bottom: 45px;
      }
    }

    .infoBox {
      line-height: 1.5;
      margin-bottom: 10px;

      label {
        font-size: 12px;
        color: @primary;
      }

      div {
        //color: @primary;
      }
    }

    .bgWhite {
      background: white !important;
      //padding-top: 10px;
      .ant-card-body {
        padding: 0;
      }
    }

    .actionBlock {
      display: flex;
      justify-content: space-between;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 999;
      background: white;
      margin-top: 10px;
    }

    .stuInfo {
      padding: 18px 45px;
    }

    .leftBlock {
      text-align: center;
    }

    .bgImg {
      height: 80px;
      width: 80px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .logo {
      height: 80px;
      width: 80px;
    }

    .font15 {
      font-size: 15px;
    }

    .appInfo {
      margin-top: 10px;
      font-size: 13px;
      line-height: 2.3em;

      .moveBtn {
        //position: absolute;
        //right: 0;
        //text-align: right;
        display: flex;
        justify-content: space-between;

        .ant-btn {
          margin-left: 10px;
        }
      }
    }

    .ant-collapse-header {
      background: white;
    }

    .ant-collapse-content {
      border: none;
    }

    .noteBlock {
      //padding: 10px;
      height: 100vh;
      border-left: 3px solid #ededed;
      border-right: 3px solid #ededed;
      overflow: scroll;
      background: white;

      &::-webkit-scrollbar {
        width: 3px;
      }

      /* &::-webkit-scrollbar-track {
         background: #f1f1f1;
       }

       &::-webkit-scrollbar-thumb {
         background: @primary;
       }

       &::-webkit-scrollbar-thumb:hover {
         background: @success;
       }*/

      .activityBlock {
        padding: 30px 10px 10px 50px;
      }

      .noteCardOuter {
        margin: 10px;

        .dateLabel {
          font-size: 20px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
        }

        .noteCard {
          background: white;
          line-height: 2.5;
          font-size: 15px;
          box-shadow: 2px 2px 10px #ddd;
          margin-bottom: 20px;

          .content {
            display: flex;
            padding: 5px 20px;

            i {
              margin-top: 10px;
              margin-right: 20px;
            }

            .noteContent {
              width: 100%;

              .title {
                display: flex;
                justify-content: space-between;
                font-size: 15px;

                .date {
                  font-size: 12px;
                  color: #9d8e8e;
                }
              }
            }
          }

          .contentComm {
            padding: 0;

            .noteContent {
              width: 100%;

              .title {
                background: #dfe6ed;
                color: black;
                padding: 0 10px;
                border: 1px solid #80808014;
                display: flex !important;
                justify-content: space-between !important;
                flex-direction: row !important;
                font-size: 12px;

                .leftTit {
                  display: flex;
                  padding: 5px 0;
                  align-items: flex-start;

                  i {
                    font-size: 12px;
                    //margin-top: 5px;
                    margin-right: 10px;
                  }

                  .subject {
                    //padding-right: 10px;
                    line-height: 1;
                  }
                }
              }

              .commContent {
                //display: flex;
                padding: 10px 20px 10px 50px;

                strong {
                  margin-right: 10px;
                }
              }

              .date {
                display: flex;
                font-size: 10px;
                line-height: 1;
                padding: 5px 0;
                opacity: 0.7;
              }
            }
          }

          img {
            height: 30px;
            margin-right: 10px;
          }

          .commentBlock {
            border-top: 1px solid #ddd;
            padding: 5px 20px;
            color: @primary;
            display: flex;
            justify-items: center;
            align-items: center;
            font-weight: 600;
            margin-top: 10px;

            a {
              color: @primary;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .customTag {
    .ant-tag {
      border: none;
      background: #ddffff !important;
      padding: 2px 8px;

      &.noneCls {
        border: none;
        background: none !important;
        font-size: 13px;
        color: #2a2a2a;
      }
    }
  }

  .documentTitle {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 15px;
    align-items: center;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    background: white;
    margin-top: 10px;

    span {
      display: flex;
      align-items: center;
    }
  }

  .oldDoc {
    background: #ddffff !important;
    //background: red !important;
    span {
      text-decoration: line-through !important;
    }
  }

  .documentBlock {
    .ant-collapse-header {
      font-weight: bold;
    }

    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }

    .documentDownBlock {
      margin-top: 10px;
      background: #dddddd4a;
      padding: 5px;
    }

    .documentText {
      font-size: 13px;
      //font-weight: bold;
      padding-right: 15px;
    }
  }

  .btnGroup {
    display: flex;
    justify-content: space-between;

    .eBtn {
      img {
        height: 40px;
        margin-bottom: 5px;
      }

      button {
        margin-bottom: 10px;
      }
    }
  }

  .dashboardCard {
    .ant-card-body {
      .ant-card-meta {
        border-bottom: 1px solid #e8e8e8 !important;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }

    .ant-col {
      .ant-card {
        text-align: center;
        text-transform: uppercase;
        //box-shadow: 0 0 20px #ddd;
        padding: 0px 12px 0px 0px;
        font-weight: 600;
        border: none;

        .title {
          height: 25px;
          font-size: 12px;
        }

        .count {
          margin-top: 10px;

          p {
            font-size: 28px;
            width: 80px;
            margin: 0 auto;
            padding: 6px;
            border-radius: 20px;
            //background: @success;
            //background: #28a745;
            color: @primary;
            cursor: pointer;
          }
        }
      }
    }

    .universityCountBox {
      padding: 10px;
      height: 400px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: @primary;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: @success;
      }
    }
  }

  .ant-menu-submenu-title {
    font-size: 13px;

    span {
      font-size: 13px !important;
    }
  }

  .drawerFooter {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 15px;
    background: #fff;
    //text-align: right;
    text-align: left;

    &.alignRight {
      text-align: right;
    }

    .ml-auto {
      i {
        margin-left: 5px !important;
      }
    }

    .ant-btn {
      margin-left: 10px;
      display: flex;
      justify-content: center;
    }
  }

  .ql-container {
    height: 400px;
  }

  .paddingHz {
    padding: 10px 0;
  }

  .attachment-box {
    background-color: white;

    a {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .noteTitle {
    display: flex;
    justify-content: space-between;
  }

  .commentBox {
    margin-top: 10px;
    line-height: 1.5;
    font-size: 13px;
    background-color: white;
    padding: 10px;

    .avatar {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      font-size: 20px;

      i {
        margin: 0 !important;
      }
    }

    .userInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: @primary;

      .date {
        i {
          margin: 0 !important;
        }

        margin-left: 10px;
        font-size: 12px;
        color: #808080;
      }
    }

    .commentText {
      margin-top: 5px;
      font-size: 12px;
      margin-left: 15px;
    }
  }

  .jcsb {
    display: flex;
    justify-content: space-between;
  }

  .jcc {
    display: flex;
    justify-content: center;
  }

  .jcic {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /*.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
    color: rgba(255, 255, 255, 0.9);

    &:hover {
      text-decoration: underline;
    }
  }*/

  .customTimeLine {
    margin-left: 15%;

    .ant-timeline-item {
      min-height: 50px;
    }

    .ant-timeline-item-head-custom {
      width: 180px;

      .date {
        position: absolute;
        top: 4px;
        text-align: right;
        color: @success;
        font-weight: 500;

        span {
          font-size: 10px;
          color: #9d8e8e;
        }
      }
    }
  }

  /* .ant-menu-inline-collapsed > .ant-menu-item .anticon + span {
     max-width: 100%;
   }*/

  .ant-timeline-item-head-primary {
    color: @primary;
  }

  .customPanel {
    border: 1px solid #e8e8e8;

    .ant-collapse-header {
      background: white;
      font-size: 15px;
      //border-bottom: 1px solid #e8e8e8;
    }
  }

  .pendencyList {
    border-bottom: 1px solid #dddddd54;
    padding: 7px 0;
  }

  .label {
    display: inline;
    padding: 5px;
    font-size: 12px;
    font-weight: 500 !important;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;

    &.labelNew {
      display: block;
      cursor: pointer;
    }

    &.round {
      border-radius: 50px;
      padding: 0.1em 0.6em 0.2em;
    }

    &.sm {
      padding: 0.1em 0.6em 0.2em;
      font-weight: normal !important;
    }

    &.xs {
      padding: 0.5px 8px;
      font-weight: normal !important;
      margin: 2px 0;
    }

    &.xs1 {
      padding: 0 6px;
      font-weight: normal !important;
      margin: 2px 0;
      font-size: 11px;
    }
  }

  .label-info {
    background-color: #5bc0de;
  }

  .label-default {
    background-color: @darkPrimary;
  }

  .label-primary {
    background-color: #337ab7;
  }

  .label-success {
    background-color: @success;
  }

  .label-success-green {
    background-color: @successGreen;
  }

  .label-danger {
    background-color: @danger;
  }

  .label-warning {
    background-color: @warning;
  }

  .label-black {
    background-color: black;
  }

  .label-tag {
    border: none;
    font-size: 12px !important;
    //background: #ddffff !important;
    color: black;
    text-transform: capitalize;
  }

  .label-sm {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 85%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
  }

  .label-xs {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0 !important;
  }

  .pointerCls-pdf {
    width: 24.6% !important;
    display: inline-block;
  }

  .pointerCls {
    cursor: pointer;
  }

  .linkPointer {
    cursor: pointer;
    color: @primary;
    font-size: 13px;
  }

  .antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
    text-align: left;
  }

  .dotsReplace {
    display: block;
    white-space: nowrap;
    //width: 200px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    height: 30px;
  }

  .ant-btn-circle {
    margin-right: 2px;
    margin-bottom: 5px;
    min-height: 35px !important;
    min-width: 35px !important;
    background: none;
    border: none;

    &.border {
      border: 1px solid #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
    }
  }

  .bs_btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: rgb(239, 239, 239);
    color: #333;
    padding: 0.2rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: 35px;
    min-width: 35px;

    &.bs-sm {
      padding: 5px 10px;
    }

    &.bs-xs {
      padding: 2px 5px;
      font-size: 11px;
      height: 25px;
    }

    &.bs-xxs {
      padding: 1px 5px;
      font-size: 10px;
      height: 20px;
    }

    &.bs-default {
      color: #333;
      background-color: #fff;
      border-color: #ccc;
    }

    &.bs-primary {
      color: #fff;
      background-color: #337ab7;
      border-color: #2e6da4;
    }

    &.bs-success {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
    }

    &.bs-info {
      color: #fff;
      background-color: #5bc0de;
      border-color: #46b8da;
    }

    &.bs-warning {
      color: #fff;
      background-color: #f0ad4e;
      border-color: #eea236;
    }

    &.bs-danger {
      color: #fff;
      background-color: #d9534f;
      border-color: #d43f3a;
    }

    &.bs-link {
      font-weight: 400;
      color: #337ab7;
      border-radius: 0;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .bs-link {
    font-weight: 400;
    color: #337ab7;
    border-radius: 0;
    font-size: 11px;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .tableAction {
    width: 125px;
    display: flex;
    flex-wrap: wrap;

    button,
    a {
      //margin-right: 5px;
      margin-right: 2px;
      margin-bottom: 5px;
      min-height: 35px !important;
      min-width: 35px !important;
      background: none;
      border: none;
    }
  }

  .appCommentDotsReplace {
    display: block;
    //white-space: nowrap;
    width: 150px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    max-height: 35px;
  }

  .appTextReplace {
    display: block;
    //white-space: nowrap;
    white-space: pre-wrap;
    width: 100%;
    overflow-y: hidden;
    text-overflow: ellipsis;
    max-height: 23px;
  }

  .newsContentDotsReplace {
    display: block;
    //white-space: nowrap;
    width: 350px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    max-height: 28px;
  }

  .replaceAgentDot {
    display: block;
    width: 100px;
    overflow-y: hidden;
    text-overflow: ellipsis;
    height: 17px;
    overflow-x: hidden;
  }

  .colorPrimary {
    color: @primary;
  }

  .colorPrimaryDark {
    color: @darkPrimary;
  }

  .colorSuccess {
    color: @successGreen;
  }

  .label1 {
    display: block !important;
    white-space: normal !important;
    font-size: 12px;
  }

  .linkAA {
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
  }

  .student-tab {
    border-right: none;
  }

  @media only screen and (max-width: 768px) {
    .applicationProfile {
      overflow: scroll;

      .appProfile {
        .align-self-stretch {
          flex-direction: column;
        }
      }

      .profile-3-column {
        &.profile-3-col-sidebar {
          &.profile-3-col-sidebar--left {
            flex-basis: 100% !important;
            max-width: 100% !important;
            min-width: 100% !important;
          }
        }

        &.profile-3-col-sidebar--right-wrapper {
          flex-basis: 100% !important;
          max-width: 100% !important;
          min-width: 100% !important;
        }
      }

      .profile-6-column {
        &.noteBlock {
          flex-basis: 100% !important;
          max-width: 100% !important;
          min-width: 100% !important;
        }
      }
    }
  }

  .tuitionBox {
    font-size: 15px;
    color: black;
  }

  .ant-calendar-picker,
  .ant-time-picker {
    width: 100%;
  }

  .ant-btn {
    line-height: 1.5;
    padding: 0.225rem 0.75rem !important;
    height: auto !important;

    &[type='submit'] {
      //width: 150px;
      background: #1556bc;
      font-size: 13px;
      text-transform: uppercase;
      color: #fff;
      margin: 25px 0 0;
      border-radius: 50px;
      font-weight: 600;
      //height: 42px !important;
      width: auto;
      height: auto !important;
    }
  }

  table {
    border: none;

    tbody {
      th {
        font-size: 13px;
        border-top: 2px solid #dee2e6 !important;
        background: #fafafa;
        border-bottom: 1px solid #dee2e6 !important;
      }

      tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
      }

      tr:nth-of-type(even) {
        background: #fafafa;
      }

      tr {
        td {
          padding: 8px 10px !important;
          font-size: 12px;
          font-weight: 500;
          //border: none !important;
          color: #212529 !important;
        }
      }
    }

    td {
      &.borderNone {
        border: none;
      }
    }
  }

  .ant-table-thead {
    background: #fafafa;

    tr {
      th {
        padding: 8px 10px !important;
        border: none !important;
        color: #212529 !important;
      }
    }
  }

  .ant-table-small {
    border: none;
  }

  .ant-table-content {
    border-right: none !important;
  }

  .antd-pro-components-global-header-index-header {
    padding: 15px 25px !important;
    height: auto;
  }

  .antd-pro-components-sider-menu-index-logo {
    text-align: center;
    height: 81px;
    background: white;
    padding: 0;
    border-bottom: 3px solid #ededed !important;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 65%;
      height: auto;
    }
  }

  .antd-pro-components-sider-menu-index-sider {
    box-shadow: none;
    border-right: 3px solid #ededed !important;
  }

  .ant-layout-header {
    height: auto;

    .antd-pro-components-global-header-index-header {
      padding: 13px 25px !important;
      height: 83px;
      box-shadow: none;
      border-bottom: 3px solid #ededed !important;
    }
  }

  .ant-layout-sider-children {
    //height: 100%;
  }

  .sidebar {
    //height: 90vh;
    height: auto;
    overflow-y: initial;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    overflow-x: hidden;
  }

  .sidebar::-webkit-scrollbar {
    //display: none;
  }

  .ant-layout.ant-layout-has-sider > .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    //height: 100vh;
    height: 100%;
  }

  .antd-pro-components-global-header-index-right {
    display: flex;
    flex-direction: row;
  }

  .antd-pro-components-global-header-index-action {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .antd-pro-components-global-header-index-name {
      line-height: 1;
      margin-top: 10px;
    }
  }

  .admin-box .content h5 {
    font-size: 15px;
    white-space: nowrap;
    font-weight: 600;
    color: #1a5abd;
    margin: 0;
  }

  form.input-group-box {
    width: 46%;
    float: left;
    margin-top: 10px;

  }

  .pd-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .appAction {
    float: right;
    margin-bottom: 10px;
  }

  .top_P10 {
    padding-top: 10px !important;
  }

  .sort-box-table {
    padding-right: 20px;
  }

  .btnBorder {
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-box-table-width {
    width: 34%;
    margin-right: auto;
  }

  .round-input {
    .ant-select-selection--single {
      font-size: 14px;
      border-radius: 50px;
      padding-left: 10px;
    }
  }

  .card-body table td span.badge {
    padding: 5px;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    background: #1053ba;
    border-radius: 4px;
    color: #fff;
  }

  .ant-form-item {
    label {
      color: rgba(0, 0, 0, 0.65);
      float: left;
      margin: 0 15px 0;
    }
  }

  .form-control {
    height: 42px !important;
    border-color: #666;
    color: #666;
    border-radius: 6px;
  }

  /*  .PhoneInputInput {
      border-width: 1px !important;
      height: 42px !important;
      border-color: #666;
      color: #666;
      border-radius: 6px;
    }*/

  .PhoneInputCountrySelect {
    padding: 10px !important;
  }

  .PhoneInput {
    border: 1px solid #666 !important;
    height: 42px !important;
    color: #666;
    border-radius: 6px;
    padding-right: 3px;

    .PhoneInputCountry {
      padding: 10px;
      border-right: 1px solid #666 !important;
    }

    .PhoneInputInput {
      height: 40px !important;
      color: #666;
      padding-left: 10px;
      border: none;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #999 !important;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999 !important;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #999 !important;
      }
    }
  }

  .ant-select-selection--single {
    height: 42px !important;
    border-color: #666;
    color: #666;
    border-radius: 6px;
  }

  .ant-select-selection--multiple {
    min-height: 42px !important;
    border-color: #666;
    color: #666;
    border-radius: 6px;

    .ant-select-selection__rendered {
      min-height: 40px !important;
    }

    .ant-select ul,
    .ant-select ol {
      margin: 0;
      padding: 0;
      list-style: none;
      padding-top: 5px;

      display: block;
      white-space: nowrap;
      //overflow-y: hidden;
      text-overflow: ellipsis;
      height: 32px;
    }
  }

  .ant-form-item-control {
    line-height: 30px;
  }

  .ant-select-selection__rendered {
    line-height: 40px !important;
  }

  .ant-select-arrow {
    user-select: none;
    background: #666666;
    padding: 5px;
    color: white;
    font-weight: 600;
    margin-top: -12px;
    border-radius: 5px;
  }

  .ant-select-focused {
    .ant-select-arrow {
      background: #1254bb !important;
    }
  }

  .ant-input-number {
    height: 42px;
  }

  .heading-form {
    &.stuHeader {
      span.img {
        img {
          height: 18px;
          margin-right: 3px;
          position: relative;
          top: -1px;
        }
      }
    }

    a.btn {
      box-shadow: 0 0 12px 0px #00000038 !important;
      border-radius: 0;
      margin: 0 40px;
      font-size: 13px;
      text-transform: capitalize;
      min-width: 120px;
    }
  }

  .pa-0 {
    padding: 0 !important;
  }

  .antd-pro-layouts-basic-layout-content {
    padding: 20px 15px;
    margin: 0 !important;
  }

  .ant-select-selection-selected-value {
    margin-right: 20px;
  }

  .ant-calendar-picker-input.ant-input,
  .ant-time-picker-input {
    height: 40px !important;
  }

  .ant-calendar {
    width: auto;
  }

  .stuIcon {
    font-size: 20px;
    color: @primary;
  }

  ul {
    &.pointerUl {
      li {
        cursor: pointer;
      }
    }
  }

  .ant-menu-dark {
    background: white;

    svg {
      display: none;
    }

    .ant-menu-item {
      font-size: 13px !important;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
        color: black !important;

        div > span {
          color: black !important;
        }
      }
    }

    .ant-menu-sub {
      background: white !important;
    }

    li {
      &:hover {
        background: rgba(0, 0, 0, 0.1);
        color: #343a40 !important;

        span,
        a {
          color: #343a40 !important;
        }
      }

      a {
        color: #343a40 !important;

        :hover {
          color: #343a40 !important;
        }
      }

      span {
        //color: #6c757d !important;
        color: #343a40 !important;

        :hover {
          color: #343a40 !important;
        }
      }
    }

    .ant-menu-inline.ant-menu-sub {
      box-shadow: none;
      //padding-left: 15px;

      .ant-menu-item {
        height: 40px;
        line-height: 40px;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
          color: #343a40 !important;

          a {
            &:hover {
              color: #343a40 !important;
            }
          }

          span {
            &:hover {
              color: #343a40 !important;
            }
          }
        }
      }
    }
  }

  li.ant-menu-item.ant-menu-item-selected {
    background: #fff !important;
    color: #343a40 !important;
    box-shadow: 0 0 12px 0px #00000038 !important;
    border-right: 5px solid #1254bb !important;

    a {
      color: #343a40 !important;

      span {
        color: #343a40 !important;
      }
    }
  }

  i.ant-menu-submenu-arrow {
    color: #343a40 !important;

    &::before {
      background: #343a40 !important;
    }

    &::after {
      background: #343a40 !important;
    }
  }

  .nav-item {
    color: #343a40 !important;

    a {
      color: #343a40 !important;
    }
  }

  .imgDiv {
    display: inherit;
    width: 75%;

    .logo-link {
      width: 350px;
      box-shadow: 3px 0 0 #ededed;
    }

    .logoImg {
      line-height: 0.8;
      margin-left: 0.8rem;
      margin-right: 0.5rem;
      margin-top: -3px;
      width: auto;
      box-shadow: none !important;
      opacity: 1 !important;
      border-radius: 0;
      max-height: 45px !important;
    }
  }

  .student-dashboard {
    width: calc(100% - 352px);
  }

  .profile-main {
    height: 100vh;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    padding-bottom: 50px;
  }

  .search-profile-main {
    height: 84vh;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .attachment-box {
    height: 100vh;
    overflow: scroll;
    //-ms-overflow-style: none; /* IE and Edge */
    //scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      //display: none;
    }

    /*  &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: @primary;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: @success;
      }
  */
    padding-bottom: 50px;
  }

  .scrollView {
    height: 100vh;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    padding-bottom: 50px;
  }

  .mobileMenu {
    display: none;
  }

  .infoBox {
    width: 350px;
  }

  .courseBox {
    min-height: 80px;
  }

  .courseFeeInfo {
    line-height: 1;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .selectStudentBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;

    .filter-box button.apply {
      font-size: 11px;
    }

    .selectStudentBtn {
      display: flex;
      align-items: center;
      background: @darkPrimary;
      color: #fff;
      text-transform: capitalize;
      font-weight: 500;
      border-radius: 50px;
      min-width: 100px;
      font-size: 12px;

      i {
        margin-right: 5px;
      }
    }
  }

  .detail-list {
    li {
      color: #212529;
    }
  }

  .ant-pagination-options-size-changer {
    .ant-select-selection--single {
      height: 33px !important;
      border: 1px solid #ddd;

      &::after {
        content: '' !important;
      }

      &::before {
        content: '' !important;
      }
    }
  }

  .ant-btn[type='submit'] {
    width: auto;
    height: auto !important;
  }

  .custom-sort-box-table {
    width: 34% !important;
  }

  .sort-box-table-right {
    text-align: right;
  }

  .btn {
    img {
      height: 17px;

      &.plus {
        height: 15px;
        margin-right: 5px;
      }
    }

    &.round {
      margin-right: auto;
      display: flex;
      border-radius: 50px;
      padding-left: 50px;
      position: relative;
      //background: #fff;
    }

    &.roundNew {
      margin-right: auto;
      border-radius: 50px;
      position: relative;
      margin-bottom: 5px;
      font-size: 15px;
      text-decoration: none !important;
      vertical-align: middle;

      img {
        height: 10px !important;
        margin-right: 5px;
        max-height: 13px;
      }
    }

    .anticon {
      margin-right: 5px;
    }

    &.ac {
      display: flex;
      align-items: center;
    }

    &.roundNew2 {
      // margin-right: auto;
      border-radius: 50px;
      position: relative;
      margin-bottom: 5px;
      font-size: 10px;
      float: right;
      margin-right: 10px;

      img {
        height: 10px !important;
        margin-right: 5px;
        max-height: 13px;
      }
    }

    &.round-bt {
      margin-right: 5px;
      border-radius: 50px;
      position: relative;
      margin-bottom: 5px;
      font-size: 15px;

      img {
        height: 10px !important;
        margin-right: 5px;
        max-height: 13px;
      }
    }

    &.lg {
      padding: 8px 30px !important;
    }

    &.md {
      padding: 5px 17px !important;
      font-size: 0.87rem;
    }

    &.sm {
      padding: 3px 15px !important;
      font-size: 0.8rem;
    }

    &.xs {
      padding: 2px 10px !important;
      font-size: 0.7rem;
    }

    &.xss {
      padding: 2px 5px !important;
      font-size: 0.65rem;
    }

    &.p0 {
      padding: 0 !important;
    }

  }

  .search-box-table {
    //width: 34%;
    &.round {
      margin-right: auto;
      display: flex;
      border-radius: 50px;
      padding-left: 50px;
      position: relative;
      background: #fff;

      &.m0 {
        margin: 0 !important;
      }

      img {
        position: absolute;
        left: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
      }

      input {
        border: none !important;
        border-radius: 50px !important;
        height: auto;
        padding: 8px 15px;
        padding-left: 0;
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        color: @fontColor;
        background-color: #fff;
        background-clip: padding-box;
        box-shadow: inset 0 0 0 transparent;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }

    &.ml0 {
      margin-left: 0 !important;
    }
  }

  td {
    span.badge {
      padding: 5px 7px !important;
      cursor: pointer;
    }
  }

  select {
    background: white;
  }

  .custom-sort-box-new {
    padding-right: 0 !important;
    margin-bottom: 10px;

    select {
      //background: white;
      margin-right: auto;
      display: flex;
      position: relative;
      background: #fff;
      border: none !important;
      border-radius: 50px !important;
      padding: 8px 15px;
      //display: block;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      color: @fontColor;
      //background-color: #fff;
      background-clip: padding-box;
      box-shadow: inset 0 0 0 transparent;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: 42px;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: @fontColor !important;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: @fontColor !important;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: @fontColor !important;
      }

      &:visited,
      &:active,
      &:focus {
        border: none !important;
        outline: none !important;
      }
    }
  }

  table td button.btn,
  table td a.btn {
    padding: 0 4px !important;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: @fontColor !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: @fontColor !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: @fontColor !important;
  }

  .courseList-box {
    margin-top: 43px;
  }

  .unizportal {
    .ant-col-8 {
      //margin-bottom: 10px;

      .inputBox {
        margin-top: 0;
        position: relative;
      }
    }
  }

  /* width */

  ::-webkit-scrollbar {
    width: 7px;
    height: 4px;
    border-radius: 10px !important;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px !important;
    box-shadow: inset 0 0 5px @grey;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: @grey;
    border-radius: 10px !important;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: @lightGray;
    border-radius: 10px !important;
  }

  .chooseCountryBox {
    position: absolute;
    z-index: 9;
    //margin-right: 46px;
    top: 35px;
    background: #fff;

    label {
      margin: 0 !important;
      border-bottom: 1px solid #ddd;
    }
  }

  .selectedCountryLabel {
    .icon {
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }

  .profile-activity {
    &.application-activity ul li .date {
      width: 15%;
    }
  }

  .ant-menu-dark .ant-menu-sub {
    background: #dddddd42 !important;
    //background: #f5fafe !important;
  }

  .font11 {
    font-size: 11px;
  }

  .font12 {
    font-size: 12px !important;
  }

  .font14 {
    font-size: 13px;
  }

  .font14_1 {
    font-size: 14px;
  }

  .font15 {
    font-size: 15px;
  }

  .actionBtnGroup {
    .eyeBtn {
    }

    .viewBtn {
      height: 20px;
    }

    .crossBtn {
      height: 10px;
    }
  }

  .antd-pro-components-sider-menu-index-sider {
    box-shadow: none;
    border-right: 3px solid #ededed !important;
  }

  .ant-select-selection__rendered {
    content: '' !important;
  }

  .ant-select-selection__clear {
    top: 42%;
    right: 15px;

    .ant-select-clear-icon {
      height: 20px;
      width: 20px;

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  .ant-select-enabled {
    &.ant-select-allow-clear {
      .ant-select-selection--single {
        &:hover {
          .ant-select-selection__clear {
            //background: red !important;
          }

          .ant-select-arrow {
            display: none;
          }
        }
      }
    }
  }

  .PrivacyPolicyPage {
    font-size: 12px;
    padding: 50px;

    h4 {
      text-align: left;
      font-size: 18px;
      text-decoration: underline;
    }

    p {
      text-align: left;
      line-height: 1.8;
      color: #6c4a4a;
      margin-bottom: 20px;
    }
  }

  .linkUl {
    float: right;
    color: #6c4a4a;

    a {
      text-decoration: underline !important;
    }
  }

  .ant-drawer-close {
    width: 30px;
    height: 30px;
    right: 20px !important;
    line-height: 2;
    top: 1px;
    //background: #fffafa;
    &:focus {
      outline: none;
    }
  }

  .roundBtn {
    line-height: 1.5;
    padding: 10px 15px !important;
    border-radius: 50px !important;
    height: 42px !important;
    margin-right: 5px;
    border-color: #efe8e8;

    &.icon {
      height: 40px !important;
      width: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .status {
    &.status1 {
      p {
        min-height: 22px;
        height: auto !important;
      }
    }
  }

  .ant-btn-success {
    width: 150px;
    background: #1556bc !important;
    font-size: 13px;
    text-transform: uppercase;
    color: #fff !important;
    margin: 25px 0 0;
    border-radius: 50px !important;
    font-weight: 600;
    height: 42px !important;
  }

  .ant-btn-info {
    color: #fff;
    background-color: @info;
    border-color: #46b8da;
  }

  .ant-btn-warning {
    color: #fff !important;
    background-color: @warning !important;
    border-color: #eea236 !important;
  }

  .ant-btn-cancel {
    background: #999;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: 50px;
    min-width: 100px;
    margin-right: 15px;
    height: 42px !important;
  }

  .ant-table-scroll {
    .ant-table-body {
      &::-webkit-scrollbar {
        height: 4px; /* height of horizontal scrollbar ← You're missing this */
        width: 4px; /* width of vertical scrollbar */
        border: 1px solid #d5d5d5;
      }
    }
  }

  .btn-success-green {
    border: 1px solid @successGreen !important;
    background-color: @successGreen !important;
    color: white !important;
  }

  .moreBtn {
    text-decoration: underline;
    color: @gray;
    margin-left: 20px;
    font-size: 10px;

    &:hover {
      color: @gray;
    }
  }

  .guestEmailBox {
    background: white !important;
    padding: 5px 5px;
    margin: 2px;
    display: flex;
    border: 1px solid #ddddff;
    border-radius: 5px;
    min-height: 40px;

    ul {
      padding-left: 0;
      margin-bottom: 0;
    }

    li.emailTag {
      position: relative;
      float: left;
      max-width: 99%;
      margin-right: 4px;
      padding: 2px 5px;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.65);
      background-color: #fafafa;
      border: 1px solid #e8e8e8;
      border-radius: 2px;
      margin-bottom: 2px;
    }

    .emailIcon {
      font-size: 12px;
      position: relative;
      top: -2px;
      margin-left: 5px;
    }
  }

  #loginForm.login-main {
    height: 100vh !important;
    display: flex !important;
    align-items: center !important;

    &.bgNone {
      //background: none!important;
    }
  }

  @media screen and (max-width: 560px) {
    .mobileMenu {
      display: block;
      position: absolute;
      top: 0;
    }

    .antd-pro-layouts-basic-layout-content {
      margin: 0;
    }

    .infoBox {
      width: 100%;
    }

    .applicationProfile {
      aside {
        display: block;
      }
    }

    .imgDiv {
      .logo-link {
        display: none;
      }

      .logoImg {
        line-height: 0.8;
        margin-left: 0.8rem;
        margin-right: 0.5rem;
        margin-top: -3px;
        width: auto;
        box-shadow: none !important;
        opacity: 1 !important;
        border-radius: 0;
        max-height: 45px !important;
      }
    }

    .student-dashboard {
      width: 100% !important;
    }

    .profile-main,
    .search-profile-main,
    .attachment-box,
    .scrollView {
      height: 100%;
    }
  }

  @media screen and (min-width: 769px) {
    .applicationProfile {
      .ant-layout-header .antd-pro-components-global-header-index-header {
        padding: 0 !important;
        height: 90px;
      }

      .imgDiv {
        height: 90px;
        //align-items: center;
        .logo-link {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      form.input-group-box {
        margin-top: 0;
        display: flex;
        align-items: center;
        padding-left: 70px;
      }
    }
  }

  .login-box .d-flex button.btn {
    height: 42px !important;
  }

  .card-pane-right {
    &.listing-section {
      ul {
        &.count {
          justify-content: start !important;
        }
      }
    }

    &.card-pane-new {
      .pointerCls {
        .linkText {
          font-size: 18px;
        }

        .statusLabel {
          color: black;
          font-size: 15px;
        }
      }
    }
  }

  .mark-btn {
    &.mark-btn30 {
      width: 35% !important;

      div {
        display: flex;

        a {
          margin: 0 5px;
        }
      }
    }

    &.mark-btn40 {
      width: 50% !important;
      position: absolute;
      right: 0;

      div {
        display: flex;

        a {
          margin: 0 5px;
        }
      }
    }

    &.mark-btn70 {
      width: 70% !important;
      position: absolute;
      right: 0;
      display: flex;
      justify-content: flex-end;

      div {
        display: flex;

        a {
          margin: 0 5px;
        }
      }
    }
  }

  .selectedUni {
    margin-right: 10px;
    background: #666666;
    padding: 2px 10px;
    border-radius: 5px;
    color: white !important;
    font-size: 12px;
  }

  .ant-table-filter-dropdown {
    .ant-dropdown-menu {
      max-height: 450px !important;
      overflow-x: hidden;
      border: 0;
      border-radius: 4px 4px 0 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .card {
    //min-height: 100vh;
  }

  .uniBox {
    .col-lg-4 {
      margin-bottom: 20px;

      .logo {
        img {
          height: 67px;
        }
      }
    }
  }

  .featureCourseDiv {
    .heading-form {
      position: fixed !important;
      top: 0;
      z-index: 9;
      background: white;
      width: 100%;
      padding: 10px 0;
    }

    .cardRelative {
      position: relative;
      top: 70px;
      background: white !important;
      padding-bottom: 0 !important;
    }

    .courseBox {
      //min-height: 650px;
      //padding: 20px;

      .col-lg-4 {
        background: #f1f1f1 !important;
        padding: 20px;
      }

      .newRow {
        margin-bottom: 20px;
      }

      .infoRow {
        padding-left: 20px;

        p {
          margin-bottom: 0;
        }
      }
    }

    .logo {
      padding: 10px;

      img {
        height: 60px;
      }
    }

    .nameBox {
      height: 70px;
      font-weight: bold;
      color: @darkPrimary;
    }

    .infoBox {
      //align-self: flex-end !important;
      //margin-bottom: 10px;
      /* width: 500px;
       display: flex;
       justify-content: space-between;
       align-items: center;*/

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 44%;

      .totalInfo {
        display: flex;
        padding-left: 27px;

        .totalCourseCount {
          margin-right: 50px;
          top: 4px;
        }
      }

      .featureCourseHeader {
        h5 {
          padding: 0;
        }

        padding: 0 25px;
      }
    }
  }

  .featureUniversityDiv {
    .heading-form {
      padding: 20px 0 0 !important;
    }

    .ml-auto {
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        margin-left: 5px !important;
      }
    }

    .cardRelative {
      position: relative;
      background: white !important;

      &.pb-0 {
        padding-bottom: 0 !important;
      }
    }

    .courseBox {
      .col-lg-4 {
        background: #f1f1f1 !important;
        padding: 20px;
      }

      .newRow {
        margin-bottom: 20px;
      }

      .infoRow {
        padding-left: 20px;

        p {
          margin-bottom: 0;
        }
      }
    }

    .logo {
      img {
        height: 80px;
      }
    }

    .nameBox {
      height: 80px;
      font-weight: bold;
      color: @primary;
    }

    .infoBox {
      align-self: flex-end !important;
      margin-bottom: 10px;
      width: 600px;
      display: flex;
      justify-content: space-between;
    }
  }

  .loadMoreDiv {
    text-align: center;
    text-transform: uppercase;

    a {
      background: @basic;
      color: #fff;
      font-size: 14px;
      text-transform: capitalize;
      min-width: 100px;
      margin-left: 15px;
      justify-content: center;
      align-items: center;
      padding: 5px 15px;
      border-radius: 5px;

      i {
        position: relative;
        bottom: 2px;
        margin-left: 5px !important;
      }
    }
  }

  .social {
    &.social1 {
      padding: 0 !important;

      h6 {
        color: white;
      }
    }
  }

  .navbar-dark {
    background-color: transparent;
    border-color: #4b545c;
  }

  .searchAppDiv {
    .loadMoreBox {
      text-align: right;
      text-transform: uppercase;
    }
  }

  .searchNewBtn {
    background: @darkPrimary !important;
    border-radius: 50px !important;
    color: #fff !important;
    font-size: 14px;
    text-transform: capitalize;
    min-width: 100px;
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex !important;

    &:hover {
      opacity: 0.7;
      color: white;
    }
  }

  .clearNewBtn {
    background: rgb(239, 239, 239) !important;
    border-radius: 50px !important;
    color: black !important;
    font-size: 14px;
    text-transform: capitalize;
    min-width: 100px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex !important;

    &:hover {
      opacity: 0.7;
      color: white;
    }
  }

  .btn {
    &.apply {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .invalidValueField {
    .form-control {
      border: 2px solid #ff0000;
    }
  }

  .requirementNote {
    font-size: 12px;
    color: black;
    padding-left: 10px;
  }

  .frontFooter {
    .social {
      padding: 0 !important;

      h6 {
        color: white !important;
      }
    }

    .white {
      color: white !important;
    }
  }

  .all-student-search {
    h5 {
      width: 20% !important;

      &.allAppCountry {
        width: 70% !important;
      }
    }

    .search-box-table {
      width: 30%;
      //margin-right: 5px;

      &.round {
        margin-left: 10px !important;
        position: relative;

        a {
          &.searchBtn1 {
            position: absolute;
            right: 0;
            top: 1px;
            background: #0872bc;
            color: white;
            padding: 7px 5px;
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
            font-size: 13px;
          }
        }
      }

      &.ml0 {
        margin-left: 0 !important;
        margin-bottom: 10px;
      }
    }

    .form-control {
      height: 35px !important;
    }
  }

  .search-box-table {
    &.round {
      margin-left: 10px !important;
      position: relative;

      a {
        &.searchBtn1 {
          position: absolute;
          right: 0;
          top: 0px;
          background: #0872bc;
          color: white;
          padding: 11.5px 8px;
          border-bottom-right-radius: 20px;
          border-top-right-radius: 20px;
          font-size: 13px;
        }
      }
    }

    &.ml0 {
      margin-left: 0 !important;
      margin-bottom: 10px;
    }
  }

  header.navbar-fix nav {
    &.newNav {
      a:first-child {
        color: black !important;
        background: none !important;
        border: none;
        margin-right: 15px;
        box-shadow: none;
        text-decoration: underline;
        font-size: 15px !important;
      }

      a:last-child {
        color: #fff !important;
        background: @darkPrimary;
        border: none;
        margin-right: 15px;
        box-shadow: 2px 2px 4px #00000057;
      }
    }
  }

  @media screen and (max-width: 560px) {
    .table-head .search-box-table {
      display: none !important;
    }

    .all-student-search {
      h5 {
        width: 60%;
      }
    }

    .containerStu {
      margin-top: 20% !important;
    }
  }

  .recharts-legend-wrapper {
    position: relative !important;
    bottom: 35px !important;
  }

  .amt {
    font-style: normal;
    color: black;
    font-size: 13px;
    font-weight: 600;
  }

  .ant-scroll-number {
    position: absolute;
    top: 11px;
    right: 6px;
  }

  .rowWrap {
    display: flex;
    flex-wrap: wrap;
  }

  .custom-tooltip {
    background: #ffffff6e;
    padding: 10px 10px 2px 10px;
    line-height: 1.3;
    font-size: 13px;

    .label {
      color: black;
      font-size: 13px;
      padding: 0;
    }

    .count {
    }
  }

  .recharts-legend-wrapper {
    margin-top: 20px;
  }

  .barChart {
    .recharts-legend-wrapper {
      display: none;
    }
  }

  .barLabel_ul {
    padding: 0px;
    margin: 0px;
    text-align: center;

    &.btn_option {
      display: flex;
      justify-content: space-between;
    }

    li {
      display: inline-flex;
      margin-right: 10px;
      align-items: center;
      font-size: 14px;

      .colorBox {
        height: 12px;
        width: 12px;
        margin-right: 4px;
      }

      &.lg {
        .colorBox {
          height: 25px;
          width: 40px;
          margin-right: 4px;
        }
      }
    }
  }

  .newsRowBox {
    display: flex;
    cursor: pointer;
    margin-right: 20px !important;

    i {
      margin-right: 5px;
      font-weight: bold;
      margin-top: 2px;
      font-size: 15px;
      color: @primary;
    }
  }

  .sideMenuSoical {
    li {
      margin-right: 10px !important;
    }

    img {
      height: 32px;
    }
  }

  .tableBox {
    margin-bottom: 20px;

    &.striped {
      .tableRow {
        &:nth-child(odd) {
          background-color: #f9f9f9;
        }
      }
    }

    .tableRow {
      padding: 4px 10px;
      display: flex;
      font-size: 12px;

      .title {
        font-weight: bold;
        width: 35%;
        height: 18px;
      }

      .title1 {
        font-weight: bold;
        width: 40%;
      }

      p {
        margin-bottom: 0;
      }

      &:first-child {
        border-top: 1px solid #ddd;
      }

      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
  }

  .circleBtn {
    background: @success;
    width: 35px !important;
    height: 35px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .justifyContent {
    display: flex !important;
    justify-content: space-between;
    align-items: center !important;
  }

  .meetingInfo {
    line-height: 1.8;
  }

  .login-form {
    label {
      display: none !important;
    }

    .form-control {
      border: none !important;
    }

    input[type='text'].form-control {
      background: #e6e6e6 !important;
    }

    input[type='file'].form-control {
      background: #e6e6e6 !important;
      padding: 0;
    }

    .documents {
      .inputBox {
        margin-bottom: 0;

        .labelNew {
          height: 20px;
        }

        input[type='file'].form-control {
          height: 35px !important;
        }
      }
    }

    input.ant-calendar-picker-input.ant-input {
      height: 55px !important;
    }

    .form-control {
      height: 55px !important;
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: #666;
      font-size: 14px;
    }

    .ant-select-selection {
      background: #e6e6e6;
      border: none;
      width: 100%;
      height: 55px !important;
      padding: 0 23px;
      outline: none;
      border-radius: 6px;
      padding-right: 75px;
      font-size: 14px;
    }

    .btnSub {
      background: @darkPrimary;
      color: #fff;
      height: 42px !important;
      min-width: 120px;
      border-radius: 50px;
    }
  }

  .btn-secondary.dropdown-toggle {
    background: @darkPrimary !important;
    border-radius: 50px;
    width: 150px;
    margin-right: 10px;
  }

  header {
    &.navbar-fix {
      nav {
        a {
          &:first-child {
            &.regLink {
              color: #666 !important;
            }
          }

          &.regLink {
            color: #666 !important;
            background: none !important;
            border: none !important;
            box-shadow: none !important;
            text-align: left;
            justify-content: flex-start;
          }
        }
      }
    }
  }

  /* Dropdown Button */

  .dropbtn {
    background: @darkPrimary !important;
    border-radius: 50px;
    width: 150px;
    margin-right: 10px;
    color: #fff;
    height: 37px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem !important;

    i {
      margin-left: 5px;
    }

    &.lite {
      background: transparent !important;
      border: 1px solid #c1c1c1;
      color: black;
    }

    &:focus {
      outline: none !important;
    }
  }

  /* The container <div> - needed to position the dropdown content */

  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 140px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: 10px;
    //margin-top: 5px;
    border-radius: 10px;

    a {
      &.regLink {
        margin-right: 0 !important;
        border-radius: 0;

        &:hover {
          background: #e5dddd !important;
        }
      }
    }
  }

  /* Links inside the dropdown */

  .dropdown-content a {
    color: black !important;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    background: none !important;
    box-shadow: none !important;
    border: none !important;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .studentRegForm {
    label {
      display: block !important;
      padding: 0 !important;
      margin: 0;
    }

    .ant-form-item-label {
      line-height: 28.9999px;
    }

    padding: 20px 20px;
    //box-shadow: 0 0 10px #ddd;
    margin-top: 50px;
    border-radius: 5px;
    background: #fffdfd;

    .labelNew {
      display: block !important;
      color: #666;
      margin: 0 0 10px 0 !important;
    }

    .btnSub {
      //margin: 0 !important;
    }

    input[type='text'],
    input[type='text'].form-control,
    input[type='password'],
    input[type='email'],
    input[type='number'],
    input[type='textarea'],
    .ant-select-selection {
      background: #ffffff !important;
      border: 1px solid #e5dddd !important;

      &.ant-select-focused {
        background: #ffffff !important;
      }
    }

    .ant-select-search__field {
      background: transparent !important;
      padding: 0 !important;
    }

    .ant-calendar-picker {
      padding: 0 !important;
    }

    input.ant-calendar-picker-input.ant-input {
      background: #ffffff !important;
      border: 1px solid #e5dddd !important;
    }

    input[type='file'] {
      background: #ffffff !important;
      border: 1px solid #e5dddd !important;

      &::-webkit-file-upload-button {
        width: 120px;
      }

      &.form-control {
        background: #ffffff !important;
        border: 1px solid #e5dddd !important;
      }
    }
  }

  .containerStu {
    max-width: 100%;
    padding: 0 calc((100% - 1140px) / 2);
    overflow-y: scroll;
    margin-top: 5%;
    z-index: 99999;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }

    .inner-container {
      height: 80vh;
    }
  }

  .login-form {
    label {
      &.authCheckbox {
        display: block !important;
      }
    }
  }

  .hyperLink {
    color: #00e !important;
  }

  .hyperLinkUnderLine {
    color: #00e !important;
    text-decoration: underline;
  }

  .disbaledField {
    display: flex;
    width: 100%;
    padding: 0 11px;
    text-align: left;
    border: 1px solid #666;
    border-radius: 5px;
    outline: 0;
    background: #ededed;
  }

  .sliderBox {
    margin: 0 15px;

    .sliderImg {
      height: 65px;
      width: 100%;
    }
  }

  .dotNone {
    background: none !important;
  }

  .owl-theme {
    .owl-dots {
      margin-top: 5px;

      .owl-dot {
        span {
          background-color: #666 !important;
          height: 7px !important;
          width: 7px !important;
        }

        &.active {
          span {
            background-color: @success !important;
          }
        }
      }
    }

    .owl-nav {
      display: none !important;
    }

    &.owl-new {
      .owl-nav {
        display: block !important;

        .owl-prev {
          height: 45px;
          width: 45px;
          border-radius: 50%;
          font-size: 35px;
          position: absolute;
          top: 31%;
          background: #f1f1f1;
          line-height: 0;
          font-weight: 500;
          left: -14px;
        }

        .owl-next {
          height: 45px;
          width: 45px;
          border-radius: 50%;
          font-size: 35px;
          position: absolute;
          top: 31%;
          background: #f1f1f1;
          line-height: 0;
          font-weight: 500;
          left: 97%;
        }
      }
    }
  }

  .main-footer {
    border-bottom: none !important;
  }

  .ant-time-picker,
  .ant-calendar-picker {
    padding: 0 !important;
    border-color: #666 !important;
    box-shadow: none !important;
  }

  .form-control {
    border-color: #666 !important;
  }

  /* .btn {
     width: 150px;
     background: #1556bc;
     font-size: 14px;
     text-transform: uppercase;
     color: #fff;
     margin: 25px 0 0;
     border-radius: 50px;
     font-weight: 600;
     height: 42px;
   }*/

  .ant-slider-mark {
    width: 82%;
    left: 9%;
  }

  // new
  .custom-slider .ant-slider-mark {
    width: 102%;
    left: 0%;
  }

  .statusRow {
    .statusColumn {
      margin-bottom: 10px;
      text-align: center;

      .ant-card-body {
        //padding: 5px 3px;
        text-align: center;
        height: 120px;
      }
    }

    .box {
      //height: 90px;
      text-align: center;

      &.active {
        color: white;
        background-color: @success;
      }

      img {
        height: 20px;
        margin-bottom: 5px;
      }
    }

    .status {
      font-size: 12px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .iconDiv {
      font-size: 28px;
      padding: 1px;
    }
  }

  .newsImg {
    margin-top: 20px;

    img {
      width: 80%;
      margin-left: 10%;
    }
  }

  .roundGoBackBtn {
    line-height: 1.5;
    padding: 10px 15px !important;
    border-radius: 50px !important;
    border-color: #efe8e8;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 36px !important;
    background-color: white;

    &.icon {
      width: 36px;
    }
  }

  .thinLabel {
    font-weight: normal !important;
  }

  .flairUniversityBlockOuter {
    //padding: 20px 40px;
    .flairUniversityBlock {
      //background-color: @success;
      .innerBox {
        padding: 15px 40px;
        border-radius: 5px;
        text-align: center;
        margin: 0 5px;
        //height: 220px !important;
        min-height: 250px !important;
      }

      .imgBlock {
        background-color: white;
        padding: 10px 20px;
        border-radius: 3px;
        height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .applyBtn {
        background-color: white;
        padding: 6px 15px;
        width: 100%;
        font-weight: bold;
        font-size: 15px;
        margin-top: 10px;
        border: none;
        border-radius: 3px;

        &.dark {
          background-color: @success;
          color: white;
        }
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 0px !important;

      li {
        border-bottom: none;
        background: none !important;

        button {
          background: @success !important;
          height: 5px !important;
        }
      }
    }

    .slick-arrow.slick-prev {
      font-size: 10px;
    }

    .ant-carousel .slick-prev::before {
      content: '<';
      z-index: 1000 !important;
      display: block;
      position: relative;
      bottom: 10px;
      right: -38px;
      /* width: 100px; */
      font-size: 25px;
      color: gray;
      background-color: white;
      border: 2px solid gray;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 50%;
      width: 22px;
      height: 22px;
    }

    .slick-arrow.slick-next {
      font-size: 10px;
    }

    .ant-carousel .slick-next::before {
      content: '>';
      display: block;
      position: relative;
      right: 0px;
      left: -39px;
      bottom: 10px;
      /* width: 100px; */
      font-size: 25px;
      color: gray;
      background-color: white;
      border: 2px solid gray;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 50%;
      width: 22px;
      height: 22px;
    }
  }

  .countList {
    list-style: decimal;
    padding-left: 12px;
  }

  .labelDoc {
    margin-top: -2px;
    margin-bottom: 0;
  }

  .addStuInfo {
    width: 100%;

    .ant-row {
      &.row1 {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .ant-drawer-body {
    overflow: hidden;
  }

  .ant-input-number-disabled {
    background-color: #e9ecef !important;
    opacity: 1;
  }

  .label_sm {
    padding-left: 10px;
    font-size: 13px;
    font-weight: normal !important;
    margin-bottom: 0 !important;
  }

  .antSelect {
    &.ant-select {
      .ant-select-selection-selected-value {
        margin-right: 10px !important;
      }

      .ant-select-selection {
        border: none !important;
        border-radius: 50px !important;
        font-size: 13px !important;

        .ant-select-selection__placeholder {
          display: block;
          color: #495057 !important;
          //font-size: 13px;
        }

        .ant-select-arrow {
          background: none !important;
          color: #495057 !important;
        }
      }
    }
  }

  .smSelect {
    .ant-select-selection {
      height: 25px !important;
      border-radius: 0;

      .ant-select-selection__rendered {
        line-height: 1 !important;
        margin-left: 5px !important;

        .ant-select-selection-selected-value {
          //overflow: inherit !important;
          margin-right: 5px !important;
        }
      }

      .ant-select-arrow {
        background: none !important;
        color: #495057 !important;
        border: none;
        position: absolute;
        right: 0;
        top: 8px;
      }
    }
  }

  .fcmtDiv {
    text-align: center;

    img {
      height: 40px;

      &.sm {
        height: 32px;
      }
    }
  }

  .infoUni {
    background-color: white;
    font-size: 15px;
    border-radius: 5px;
    line-height: 2;
    color: black;

    .title {
      width: 18% !important;
      font-size: 13px;
      font-weight: normal !important;
      border-right: 1px solid #ddd;
      margin-right: 10px;
    }

    span {
      color: @success;
      font-size: 13px;
    }
  }

  .darkBtn {
    background: transparent !important;
    border: 1px solid #c1c1c1 !important;
    display: inline-block;
    border-radius: 50px;
    min-width: 110px;
    color: #000 !important;
    height: 38px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none !important;

    span {
      color: #343a40 !important;
    }
  }

  .sprBox {
    margin-top: 7px;
    //border-top: 1px solid #ddd;
    padding-top: 7px;
  }

  .customAutoComplete {
    ul {
      width: 100%;
    }

    .ant-select-search__field {
      height: 43px !important;
      border-color: #666 !important;
    }
  }


  .pointerCls {
    height: 40px;
    padding-top: 3px;
  }

  .pCursor {
    cursor: pointer;
  }

  .CountCircle {
    font-size: 14px !important;
    height: 25px;
    width: 25px;
    border-radius: 5%;
    line-height: 2.5;
    background: white !important;
    //color: white !important;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 2px @primary !important;

    a {
      align-self: center;
      font-size: 12px !important;
      color: black !important;
    }
  }

  .pointerCls1 {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 6px @primary !important;
    padding-top: 0 !important;

    h5 {
      font-size: 14px !important;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      line-height: 2.5;
      background: #0872bc;
      color: white !important;
    }
  }

  input[type='text'],
  input[type='password'],
  input[type='search'],
  input[type='number'],
  input[type='email'],
  input[type='file'],
  input[type='date'],
  textarea,
  .ant-select-selection,
  .ant-calendar-picker-input {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #999 !important;
      font-size: 14px !important;
      opacity: 0.8 !important; /* Firefox */
    }
  }

  .ant-select-selection__placeholder {
    color: #999 !important;
    font-size: 13px !important;
    opacity: 0.8 !important; /* Firefox */
  }

  .ant-select-dropdown-menu-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    /* overflow: hidden; */
    color: rgba(0, 0, 0, 0.65);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid #edededbf;

    &.ant-select-dropdown-menu-item-disabled {
      opacity: 0.5;

      &:hover {
        opacity: 0.5 !important;
        color: rgba(0, 0, 0, 0.5) !important;
      }
    }
  }

  .mailerOption {
    input[type='radio'] {
      position: relative;
      top: 2px;
      margin-left: 10px;
    }

    label {
      font-weight: normal !important;
      margin-left: 5px !important;
      margin-bottom: 0 !important;
      margin-right: 5px;
    }

    i {
      font-style: inherit;
      margin-left: 3px;
    }
  }

  .countLink {
    //color:#0645AD;
    color: #1254bb;
    //text-decoration: underline;
  }

  .ck-editor__editable {
    min-height: 400px;
  }

  .quillEditor {
    background-color: white;

    .ql-editor {
      line-height: 2;
      font-size: 15px;
    }
  }

  .customExtra {
    position: absolute;
    right: 10px;
    top: 5px;
    color: #00e;
    z-index: 9;
  }

  .defaultEligBtn {
    display: flex;
    border: 1px solid #ddd !important;
    justify-content: center;
    align-items: center;
    padding: 1px 10px !important;
    background: white !important;
    margin-top: 8px;
    font-size: 13px !important;
    border-radius: 50px !important;

    i {
      margin-right: 5px !important;
    }
  }

  .eligiBtn {
    border: 1px solid #ddd !important;
    //padding: 1px 3px !important;
    background: white !important;
    height: 27px;
    margin-top: 8px;
    font-size: 11px !important;
    border-radius: 50px !important;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px !important;

    i {
      margin-right: 5px !important;
    }
  }

  .flexEndCustom {
    margin-top: 10px;
    justify-content: flex-end;
  }

  .wrapBox {
    display: flex;
    flex-wrap: wrap;

    .widthLabel {
      font-weight: bold;
      text-decoration: underline;
      color: black;

      &.w100 {
        width: 100%;
      }

      &.w200 {
        width: 200%;
      }
    }

    label {
      font-weight: 600 !important;
    }
  }

  .status {
    p {
      min-height: 22px !important;
      height: auto !important;
    }
  }

  .status {
    p {
      min-height: 22px !important;
      height: auto !important;
    }
  }

  .courseNameDotsReplace {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
    height: 40px;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .smallBtn {
    padding: 10px !important;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lgBtn {
    padding: 15px 25px !important;
    height: auto !important;
  }

  .image-upload {
    > input {
      display: none;
    }

    label {
      margin-right: 10px;
    }

    img {
      height: 30px;
      cursor: pointer;
    }
  }

  .pendencyTab {
    td {
      padding: 5px !important;
    }
  }

  .documentBox {
    padding: 0 5px;
    /* margin: 2px; */
    border-radius: 5px;
    min-height: auto !important;

    ul {
      padding-left: 0;
      margin-bottom: 0;
    }

    .emailTag {
      position: relative;
      float: left;
      margin-right: 4px;
      padding: 2px 5px;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.65);
      background-color: #fafafa;
      //border: 1px solid #e8e8e8;
      border-radius: 2px;
      //margin-bottom: 2px;
      white-space: nowrap;
      width: 180px;
      text-overflow: ellipsis;
      //height: 30px;
    }

    .emailIcon {
      font-size: 15px;
      position: relative;
      top: 3px;
      margin-left: 5px;
    }
  }

  .cardSm {
    padding: 10px;
    background: white;
    border: 1px solid #e8e8e8;
    border-radius: 5px;

    .pb0 {
      padding-bottom: 0;
    }

    .smImg {
      height: 10px;
      margin-left: 5px;
    }

    .smFont {
      margin-top: 5px;
      font-size: 11px;

      .dLab {
        color: @success;
        font-weight: 600 !important;
      }
    }

    .dLab {
      font-size: 11px;
      margin-block: 0;
      font-weight: 600 !important;
    }
  }

  .customTextArea {
    height: 100px !important;
  }

  .ratioBox {
    label {
      margin-right: 20px;
    }

    input {
      position: relative;
      top: 2px;
      left: 3px;
    }
  }

  .feedbackLabel {
    background: @success;
    padding: 10px 50px;
    color: white;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  .banner11 {
    padding-bottom: 40px !important;
  }

  .feedbackInfo {
    font-size: 25px;
    text-align: center;
    padding: 50px 0;
  }

  .antd-pro-components-header-dropdown-index-container {
    z-index: 99 !important;
  }

  .float-right {
    float: right !important;
  }

  .ant-avatar-sm {
    width: 40px;
    height: 40px;
    line-height: 24px;
    border-radius: 50%;
  }

  .avatarBig {
    .ant-avatar-sm {
      width: 50px;
      height: 50px;
      line-height: 24px;
      border-radius: 50%;
    }
  }

  .card-body.table-responsive {
    padding-top: 0px !important;
  }

  .ant-table-pagination.ant-pagination {
    margin-top: 0px !important;
    margin-bottom: 7px !important;
  }

  .slimBtn {
    .fee-box {
      .box {
        .btn-group {
          button {
            display: flex;
            width: 100%;
            border: 2px solid #ededed;
            //background: transparent;
            background: rgb(230, 231, 232);
            font-size: 13px;
            text-transform: capitalize;
            margin: 0 0 10px;
            height: 30px;
            align-items: center;
            padding: 0 10px;
          }
        }
      }
    }

    //border: 2px solid rgb(237, 237, 237);
    //background: rgb(230, 231, 232);
    //border-radius: 5px;
    //text-align: center;
    //height: 30px;
    //align-items: center;
    //justify-content: center;
  }

  section.gic-revenue {
    border-radius: 15px;
  }

  section.gic-revenue a.main-btn {
    background: #11148a;
    font-size: 15px;
    color: #fff;
    border-radius: 12px;
    padding: 12px 25px;
    text-transform: capitalize;
    height: auto;
  }

  section.gic-revenue .list .box {
    box-shadow: inset 0 0 0 2px #e6e6e6;
    border-radius: 18px;
    padding: 10px 20px;
    margin: 0 0 15px;
    display: flex;
    padding-left: 80px;
    position: relative;
    flex-direction: column;
    text-align: left;
  }

  section.gic-revenue .list .box span {
    min-width: 50px;
    height: 50px;
    display: inline-flex;
    background: #e6e6e6;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 50px;
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  section.gic-revenue .list .box h4 {
    font-size: 23px;
    font-weight: 600;
    color: #4d4d4d;
    line-height: normal;
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    margin: 0;
  }

  section.gic-revenue .list .box p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 14px;
    color: #666666;
  }

  section.gic-revenue .list .box span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  section.gic-revenue .list button.btn {
    background: #5bb85d;
    border-radius: 18px;
    width: 100%;
    color: #fff;
  }

  section.gic-revenue .list button.btn span {
    display: block;
    font-size: 18px;
    font-weight: 600;
  }

  .flexRow {
    display: flex;
    flex-wrap: wrap;

    span {
      padding: 3px 12px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .cross {
      margin-left: 10px;
      position: relative;
      top: -1px;
    }
  }

  .actBy {
    font-weight: normal;
    color: #745e5e;
    margin-left: 27px;
    padding: 0;
    height: 15px;
    margin-bottom: 5px;
    position: relative;
    top: -5px;
  }

  table tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  table tbody tr:nth-child(odd) {
    background: white;
  }


  .input-group-inner {
    &.field1 {
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;

      .form-control {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      border-right: 1px solid #00000017;
    }

    &.field2 {
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
      padding-left: 20px !important;
    }

    &.field3 {
      border-radius: 50px !important;
      border-right: none;

      .form-control {
        border-radius: 50px !important;

      }
    }

  }

  .icefBlock {
    display: flex;
    flex-direction: row;
    background: #ffffff7a;
    font-weight: 700;
    line-height: 36px;
    align-items: center;
    border-left: 3px solid #1890ff;
    padding: 10px 30px 10px 10px;
    box-shadow: 2px 5px 10px #e9c4c4;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: 20px;
    justify-content: space-between;
    width: 80%;

    h1 {
      font-size: 20px !important;
      padding-left: 30px;
      padding-right: 30px;
      margin: 0 !important;
    }

    img {
      max-width: 60px;
    }
  }

  .outMotto {
    font-size: 16px !important;
  }

  .card {
    &.unizportal {
      &.regStu {
        padding: 20px;
      }
    }
  }

  .student-application-detail {
    &.student-application-new {
      .inputBox {
        margin-bottom: 0 !important;
      }

      .card {
        .form-group {
          label {
            font-size: 14px;
            text-transform: capitalize;
            font-weight: 400;
            margin: 0 !important;
          }
        }
      }

      &.addCourseForm {
        .card {
          .form-group {
            label {
              font-size: 14px;
              text-transform: capitalize;
              font-weight: 400;
              margin: -10px 6px -5px !important;
            }
          }
        }
      }

      h5 {
        font-size: 1rem;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }

      .detail-list ul {
        padding-left: 0;

        li {
          padding: 5px 0 !important;

          strong {
            width: 30%;
          }
        }
      }

      .manageBox {
        background-color: white;

        .managerHeader {
          background-color: gray;
          color: white;
          padding-left: 10px;
          padding-right: 10px;
          margin-bottom: 20px;
          position: absolute;
          right: 10px;
          border-bottom-left-radius: 10px;
        }

        .managerBoxOuter {
          display: flex;
          flex-direction: row;
          align-items: center;

          .manageBoxInfo {
            text-align: left;
            font-size: 12px;
            color: #585858;
            padding: 20px 0 20px 20px;
          }
        }
      }

      .add-application-btn {
        background: #1556bc;
        font-size: 13px;
        text-transform: uppercase;
        color: #fff;
        margin: 0px 0 0 !important;
        border-radius: 50px;
        font-weight: 400;
        width: auto;
        height: auto !important;
      }
    }
  }

  .fee-box {
    .box {
      .btn-group {
        button {
          &.profileBtn {
            border: 2px solid #ededed;
            background: rgb(230, 231, 232) !important;
            border-radius: 5px;
            text-align: center;
            height: 30px;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  .login-box {
    .form-group {
      .countryCode {
        img {
          position: relative;
          right: 0;
          top: 0;
          bottom: 0;
          left: 0;
          margin-right: 5px;
        }
      }
    }
  }

  .referBtn {
    background-color: @darkPrimary;
    color: white;
    text-align: center;
    margin: 10px;
    border-radius: 3px;
    padding: 0 20px 20px 10px;

    .ant-badge {
      position: relative;
      top: -17px;

      img {
        margin-right: 10px !important;
        height: 20px;
      }
    }

    a:hover {
      color: white !important;
    }
  }

  .referCodeBox {
    border-color: @darkPrimary;
    border-style: dashed;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .referHeader {
    background-color: @darkPrimary;
    padding-bottom: 5px;
    padding-top: 10px;
    margin-top: 10px;
  }

  .marketingHeader {
    background-color: white;
    border: 1px solid #ddd;
    padding: 15px 20px;
    margin-top: 10px;
    color: black;
    font-size: 18px;

    .fa {
      margin-right: 5px;
    }
  }

  .referInfoBox {
    margin-top: 30px;
    text-align: center;
  }

  .conditionList {
    flex: 1;
    padding-left: 15px;
    padding-right: 30px;
  }

  .conditionListText {
    font-size: 12px;
  }

  .reportingBox {
    padding: 15px;
    background-color: white;
    margin: 5px;
    text-align: center;
  }

  .reportContainer {
    .reportBox {
      margin: 20px 0;
    }

    .reportingBox {
      padding: 0 !important;
      box-shadow: 1px 1px 10px #b3b3b36b !important;
      background-color: @primary;
      margin: 0;
      border-radius: 5px;
      position: relative;

      .arrowIcon {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: white;
      }

      .reportingInnerBox {
        border-radius: 5px;
        border-bottom-right-radius: 80px !important;
        background-color: white;
        padding: 20px;
        margin: 20px 0;
      }

      img {
        margin-bottom: 10px;
        height: 30px;
      }

      .anticon {
        margin-left: 10px;
      }

      .reportInfoBox {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
      }
    }

    .reportingBoxLiveReporting {
      padding: 0 !important;
      box-shadow: 1px 1px 10px #b3b3b36b !important;
      background-color: white;
      margin: 0;
      border-radius: 5px;
      position: relative;

      .arrowIcon {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: white;
      }

      .reportingInnerBox {
        border-radius: 5px;
        border-bottom-right-radius: 80px !important;
        background-color: white;
        padding: 20px;
        margin: 20px 0;
      }

      img {
        margin-bottom: 10px;
        height: 30px;
      }

      .anticon {
        margin-left: 10px;
      }

      .reportInfoBox {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
      }
    }

  }

  .linkText {
    color: #0066ff;
    cursor: pointer;
  }

  .customGrpBtn {
    .ant-input-group-addon {
      padding: 0 !important;

      a {
        padding: 5px 15px !important;
        border-radius: 3px;

        &:first-child {
          border-right: 1px solid #ddd !important;
        }
      }
    }

    .selected {
      background-color: @primary;
      color: white;
    }
  }

  .othAppContainer {
    margin-top: 10px;
    margin-bottom: 50px;
    //background-color: white;
    //padding:10px;

    .flexColumn {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .otherAppList {
        width: 32%;
      }
    }

    .otherAppList {
      display: flex;
      //padding: 10px;
      background-color: white;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 0 10px #ddd;
      border-radius: 5px;
      //margin-bottom: 10px;
      padding: 10px 10px 5px;
      flex-direction: column;
      margin-top: 10px;

      .leftBox {
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        img {
          width: 120px !important;
          height: auto !important;
          margin-bottom: 10px;
        }
      }

      .rightBox {
        //margin-top: 10px;
        text-align: left;
        width: 100%;

        .label1 {
          font-size: 10px !important;
        }

        a {
          p {
            color: @fontColor;
            font-size: 15px;
          }

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 5px;
          width: 100%;
        }
      }
    }
  }

  .photoShortName {
    max-height: 35px;
    margin-bottom: 5px;
    padding: 5px 10px 0;

    a {
      color: black !important;
      display: block;
      white-space: nowrap;
      width: 180px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .followUp {
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: #ddd !important;
  }

  .studentRegForm {
    .form-group {
      input {
        padding-right: 10px !important;
      }
    }
  }

  .forgetEmailText {
    font-size: 16px !important;
    margin-top: 10px;
    text-transform: capitalize;

    strong {
      color: @primary;
      font-weight: 500;
    }
  }

  .gcKey {
    height: 250px !important;
  }

  .oshcBox {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    padding: 3px 5px;
    border-radius: 5px;
    background: #1690ff;
    color: white;
    font-weight: bold;

    img {
      height: 18px;
      margin-right: 5px;
    }
  }

  .noteMain {
    overflow: hidden !important;
  }

  .student-tab {
    overflow: hidden !important;

    .loadMoreBoxDiv {
      text-align: center;
      background-color: white;
      padding: 10px;
      border-bottom: 1px solid #f1f1f1 !important;
    }

    .noteTitle {
      color: #111b21;
    }

    .noteRow {
      background-color: white !important;
      position: relative;

      .noteContainer {
        height: 80vh;
        overflow-y: auto;
      }

      .noteColumn {
        .card {
          background: white !important;
          border: 1px solid #1890ff57;
          line-height: 1.5;

          .dateBox {
            text-align: left;
            font-weight: bold;
            color: @success;
          }

          .userBox {
            text-align: right;
            //font-weight: bold;
            color: #667781;
            padding: 0;
            line-height: 1;
            font-size: 15px;
          }
        }

        &.noteRight {
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;

          .card {
            border: 1px solid #5cb85c57;

            .dateBox {
              text-align: right;
            }

            .userBox {
              text-align: right;
              //color:@successGreen;
            }
          }
        }
      }

      .noteBox {
        width: 75% !important;

        .card {
          background: #fff;
        }

        .inner.card {
          padding: 10px 15px !important;
        }
      }
    }
  }

  .textAreaCls {
    height: 200px;

    textarea.form-control {
      height: 200px !important;
    }
  }

  .loginCredentialsBox {
    border: 0.3px solid #e6e7e8;
    margin-top: 10px;
    padding: 10px;
    background-color: white;
    box-shadow: 1px 1px 10px #e6e7e8;

    .header {
      font-size: 14px;
      text-align: center;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  .localLoginCredentialsBox {
    //border: 0.3px solid #E6E7E8;
    margin-top: 10px;
    padding: 10px;
    background-color: white;
    //box-shadow: 1px 1px 10px #E6E7E8;
    box-shadow: 1px 1px 10px #0f52ba2e;
    border-radius: 10px;
    margin-bottom: 30px;

    .header {
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 5px;
      font-weight: bold;
      text-transform: uppercase;

      .loginBanner {
        margin-bottom: 10px;
      }

      .loginIcon {
        margin-bottom: 10px;
        height: 70px;
      }
    }

    .verticalPadding10 {
      padding-left: 10px;
      padding-right: 10px;

      .label1 {
        position: relative;
        padding: 4px;
        font-size: 13px;
        color: #555;
        display: flex !important;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
      }

      .label2 {
        position: relative;
        padding: 4px;
        font-size: 13px;
        color: #555;
        display: flex !important;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;

        .btn-info {
          background-color: @darkPrimary;
          border-color: @darkPrimary;
          //border-radius: 0;
        }
      }

      .rowC {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: 1px solid #f1f1f1;
        width: 100%;

        &:last-child {
          border-bottom: none;

          span {
            margin-top: 5px;
          }
        }

        img {
          height: 20px;
          margin-right: 8px;
        }

        span {
          border: none;
          padding: 0;
          flex: 1;
          text-transform: capitalize;
          display: flex;
        }
      }
    }
  }

  .commissionBox {
    .commissionParentBox {
      display: flex;
      flex-direction: column;

      &.row {
        flex-direction: row;

        .commissionUniInfo {
          flex: 1;
          margin: 0 10px 20px;
        }
      }

      .commissionUniInfo {
        border: none;
        box-shadow: 1px 1px 10px #ddd;
        border-radius: 5px;
        margin-bottom: 20px;

        .ant-card-body {
          padding: 10px;
        }

        img {
          width: max-content;
          height: 50px;
        }

        div[class*='ant-col'] {
          display: flex;
          justify-content: center;
          height: 70px;
          flex-direction: column;
        }

        .logoBox {
          border-right: 1px solid #ddd;
          padding-right: 30px;
          align-items: center;
        }

        .contantBox {
          padding-left: 50px !important;
        }

        .studentBox {
          display: flex;
          min-height: 60px;

          .studentProfile {
            width: 20%;
            border-right: 1px solid #ddd;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              height: 60px;
              max-width: 100%;
            }
          }

          .studentInfo {
            justify-content: center;
            display: flex;
            flex-direction: column;
            padding: 5px 5px 5px 40px;
          }

          &.flexStart {
            //margin-right: 30px;
            border-right: 1px solid #f1f1f1;
            padding-right: 30px;
            width: 30%;

            &:first-child {
              width: 25%;
            }

            &:last-child {
              border: none;
            }

            .studentInfo {
              justify-content: flex-start;
            }

            .uniLogo {
              margin-left: 20px;
              justify-content: center;
              align-items: center;
              display: flex;
              //width: 120px;
              margin-top: 10px;

              img {
                height: 50px;
                width: 50px;
                //width: 100%;
              }
            }
          }

          &.flexStartNew {
            //margin-right: 30px;
            border-right: 1px solid #f1f1f1;
            padding-right: 30px;
            width: 22.5%;

            &:first-child {
              width: 25%;
            }

            &:last-child {
              border: none;
            }

            .studentInfo {
              justify-content: flex-start;
            }

            .uniLogo {
              margin-left: 20px;
              justify-content: center;
              align-items: center;
              display: flex;
              //width: 120px;
              margin-top: 10px;

              img {
                height: 50px;
                width: 50px;
                //width: 100%;
              }
            }
          }

          &.inputFlex {
            width: 15%;
            padding-left: 20px;
          }
        }

        .uniName {
          font-size: 18px;
          color: @darkPrimary;
          //font-weight: 600;
        }

        .courseInfo {
          font-size: 15px;
          color: black;
          //font-weight: 600;
          &.sm {
            font-size: 14px;
            color: #555;
          }
        }

        .commissionInfo {
          font-size: 14px;
          color: @success;
          font-weight: 600;
        }
      }
    }

    .inputGroup {
      margin-top: 10px;

      .input-group-text {
        //background-color: white;
        background-color: #e9ecef;
        border-color: #666;
      }
    }

    textarea {
      &.form-control {
        height: 50px !important;
      }
    }
  }

  .comBtnGroup {
    display: flex;
    flex-wrap: wrap;

    .btn {
      margin-bottom: 3px;
      font-size: 13px !important;
      padding: 0 4px !important;
      width: 180px;

      img {
        margin-right: 5px;
        height: 13px;
      }
    }

    .btn-view {
      border: 1px solid #d9d9d9 !important;
      color: #212529 !important;
      background-color: white;
    }
  }

  .darkFont {
    color: black;
  }

  .dangerPos {
    position: absolute;
    left: 10px;
  }

  .commissionListBox {
    margin-top: 20px;
    position: relative;

    .addMoreCommissionBtn {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  .student-group {
    //position: absolute;
    //bottom: 0;
    //width: 250px;
    margin-top: 10px;
    margin-right: 10px;

    label {
      margin: 0 10px 4px 3px !important;
      color: black;
      font-weight: 500 !important;
    }

    .form-control {
      border-color: #e5dcdc !important;
      background-image: none !important;
      height: 35px !important;
      border-radius: 5px !important;
    }

    .input-group-text {
      border-color: #e5dcdc !important;
      height: 35px !important;
    }
  }

  .uniInfoLogo {
    //width: 15%;
    border-right: 1px solid #f1f1f1;
    padding-right: 20px;
    margin-right: 20px;

    img {
      height: 70px !important;
    }
  }

  .textCap {
    text-transform: capitalize;
  }

  .textUpp {
    text-transform: uppercase;
  }

  .customCheckbox {
    cursor: pointer;
    text-align: center;

    img {
      height: 25px;
    }
  }

  .universityParentBox {
    display: flex;
    flex-direction: column;
    height: 100%;

    .commissionUniInfo {
      border: none;
      box-shadow: 1px 1px 10px #ddd;
      border-radius: 5px;
      height: 100%;
      align-items: center;
      display: flex;
      justify-content: center;

      .ant-card-body {
        justify-content: center;
        flex: 1;
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      .uniLogo {
        border-right: none;

        img {
          height: 70px !important;
          width: 100%;
        }
      }

      .studentInfoBox {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
      }

      .ant-card-body {
        padding: 10px;
      }

      img {
        width: max-content;
        height: 50px;
      }

      .uniName {
        font-size: 18px;
        color: @darkPrimary;
      }
    }
  }

  .filterCard {
    .ant-select {
      .ant-select-selection--single {
        border: 1px solid #e9d5d5 !important;
      }
    }

    .search-box-table {
      &.round {
        border: 1px solid #e9d5d5 !important;
      }
    }

    .roundBtn {
      border: 1px solid #e9d5d5 !important;
    }
  }

  .statusDate {
    margin-top: 5px;
    font-size: 13px;
    color: @darkPrimary;
    font-weight: 500;
  }

  .btn-warning {
    background-color: @warning !important;
    color: white !important;
  }

  .btn-success {
    border-color: @success !important;
    background-color: @success !important;
    color: white !important;
  }

  .btn-view {
    border: 1px solid #d9d9d9 !important;
    color: #212529 !important;
    background-color: white !important;
    margin-top: 0 !important;

    &.sm {
      padding: 0.25rem 0.8rem;
      font-size: 0.8rem;
      line-height: 1.5;
    }
  }

  .label-info-new {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
    box-shadow: none;
  }

  .btn-paid {
    background-color: #337ab7 !important;
    border-color: #337ab7 !important;
    color: white !important;
  }

  .label-paid {
    background-color: #337ab7 !important;
    border-color: #337ab7 !important;
    color: white !important;
  }

  .view-btn-box {
    margin-top: 5px;

    /* .eyeBtn {
       height: 20px;
     }*/

    .btn-view {
      font-size: 12px !important;
      border: 1px solid #d9d9d9;
      height: 32px;
      padding: 2px 5px;
      border-radius: 5px;
      color: #212529 !important;
      margin-right: 5px;

      img {
        height: 17px;
      }
    }
  }

  .contract {
    text-transform: uppercase;
    margin-top: 5px;
    color: black;

    a {
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .customInput {
    margin-top: 8px;

    label {
      margin-bottom: auto;
    }
  }

  .table-head {
    .sort-box-table {
      &.sort-box-table30 {
        width: 30% !important;
      }
    }
  }

  .flexRowBox {
    display: flex;

    .flexBox1 {
      flex: 1;
      width: 30%;
      margin-right: 10px;
      background-color: #f1f1f1 !important;
      margin-bottom: 18px;
    }

    .flexBox2 {
      flex: 2;
    }
  }

  .imgFlex {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;

    .img-column {
      width: 100px;
      height: 100px;
      border: 1px solid #ddd;
      margin-right: 10px;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .commissionBox {
      .commissionParentBox {
        .commissionUniInfo {
          .studentBox {
            &.flexStart {
              width: 33%;

              &:first-child {
                width: 33%;
              }

              &:nth-child(3) {
                border: none;
              }

              &:last-child {
                border: none;
              }
            }

            &.inputFlex {
              width: 50%;
              padding-left: 20px;

              .student-group {
                margin-top: 0;
              }
            }
          }

          .uniName {
            font-size: 15px;
            color: @darkPrimary;
          }

          .courseInfo {
            font-size: 12px;
            color: black;

            &.sm {
              font-size: 12px;
              color: #555;
            }
          }

          .commissionInfo {
            font-size: 12px;
            color: @success;
            font-weight: 600;
          }
        }
      }
    }

    .universityParentBox {
      display: flex;
      flex-direction: column;
      height: 100%;

      .commissionUniInfo {
        border: none;
        box-shadow: 1px 1px 10px #ddd;
        border-radius: 5px;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;

        .ant-card-body {
          justify-content: center;
          flex: 1;
          align-items: center;
          display: flex;
          flex-direction: column;
        }

        .uniLogo {
          border-right: none;

          img {
            height: 70px !important;
            width: 100%;
          }
        }

        .uniName {
          font-size: 15px;
          color: @darkPrimary;
        }
      }
    }

    .customInput {
      margin-top: 8px;

      label {
        margin-bottom: 0;
      }
    }
  }

  .round-date-picker {
    .ant-calendar-picker-input {
      height: 42px !important;
      border-radius: 50px;
      border: none;
    }
  }

  .verifyOfferBox {
    position: relative;
    overflow: hidden;
    height: auto;
    padding-top: 7%;
    padding-bottom: 90px;
    min-height: 80vh;

    h3 {
      font-size: 32px;
      text-transform: capitalize;
      margin: 0 0 10px;
      color: @darkPrimary;
    }

    .store {
      padding-top: 7%;

      img {
        width: 100%;
      }
    }

    .iframeBox {
      margin: 5% 15% 100px;
      border: 1px solid #ddd;
      padding: 10px;
    }
  }

  .announcement_banner {
    background-color: white;
    max-height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: auto;
      height: 250px;
    }

    .applyBtn {
      position: absolute;
      bottom: 15px;
      width: 350px !important;
    }
  }

  .certificate_box {
    .btn-default {
      margin-bottom: 10px;
      width: 220px;
    }

    .ant-tag {
      margin-bottom: 5px;
      border-color: #007bff33;

      .btn-link {
        font-weight: 400;
        color: #007bff !important;
        text-decoration: none;
      }
    }
  }

  .duplicateRecordLink {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .broadcast_info {
    font-size: 25px;
    text-align: center;
    padding: 50px 0;

    img {
      height: 150px;
      margin-bottom: 20px;
    }

    min-height: 500px;
  }

  .addInfo {
    padding-left: 3px !important;

    .box {
      position: relative;
      margin-bottom: 5px;
      display: flex !important;

      img {
        height: 22px !important;
        width: auto !important;
      }

      span {
        margin-left: 27px;
      }
    }
  }

  @media (max-width: 600px) {
    .verifyOfferBox {
      position: relative;
      overflow: hidden;
      height: auto;
      padding-top: 100px;
      padding-bottom: 90px;
      min-height: 80vh;

      h3 {
        font-size: 32px;
        text-transform: capitalize;
        margin: 0 0 10px;
        color: @darkPrimary;
      }

      .store {
        padding-top: 7%;

        img {
          width: 100%;
        }
      }

      .iframeBox {
        margin: 5% 2% 100px;
        border: 1px solid #ddd;
        padding: 10px;
        overflow: auto;
      }
    }


  }

  .counsellerDashboard {
    width: auto;

    .main-logo {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .counseller-card {
      width: 100%;
      height: 50%;

      .countryInfo {
        text-align: center;

        .map {
          width: auto;
          height: 100px;
        }

        h5 {
          color: #000;
          font-size: 22px;
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }

      border: 1px solid lightgrey;

      .counselling-container {
        position: relative;
        min-height: 200px;
        border-bottom: 1px solid #ddd;

        .waitingBox {
          position: absolute;
          height: 100%;
          width: 100%;
          background: #00000033;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;

          .waitingLabel {
            font-size: 25px;
          }
        }

        .counsellerInfo-card {
          width: 100%;
          height: 50%;
          display: flex;
          justify-content: center;
          font-size: 15px;
          flex-direction: column;
          padding: 20px 20px;

          &:nth-child(0) {
            border-bottom: 1px solid lightgrey;
          }

          .counsellerInfo-card-title {
            width: 100%;
            padding: 10px;
            padding-bottom: 15px;
            display: flex;
            justify-content: left;
            align-items: center;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
            background-color: #f1f1f1;
            border-radius: 10px;

            img {
              border-radius: 50px;
              margin-right: 15px;
            }

            .box-title {
              font-size: 12px;
            }

            .tarundiv {
              display: flex;
              flex-direction: column;
            }
          }

          .waitingInfo {
            display: flex;
            margin-bottom: 20px;

            .counsellerInfo-num {
              background-color: #ddd;
              border-radius: 40px;
              padding: 0 10px;
              margin-left: 10px;
            }
          }

          .counsellerInfo {
            width: 100%;
            height: 50%;
            font-size: 15px;
            display: flex;
            justify-content: left;
            align-items: center;

            &.active {
              color: @success;
            }
          }
        }
      }
    }
  }

  .comment-boxx {
    border-bottom: lightgrey;
  }

  .student-informationn {
    display: flex;
    // border: 1px solid lightgray;
    padding: 9px 14px;
  }

  .studentprofile {
    display: flex;
    flex-direction: row;

    .image {
      width: 35%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        border-radius: 100%;
      }
    }

    .descrip {
      width: 65%;
      // background-color: red;
      .border {
        // background-color: red;
        width: 60%;
        border: 2px solid gray;
        padding: 10px;
      }
    }
  }

  .imagee {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 100%;
    }
  }

  .studentinformationboxx {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .nameandnumberboxx {
    height: 40%;
  }

  .localLoginCredentialsBoxx {
    margin-top: 10px;
    padding: 10px;
    background-color: white;
    //box-shadow: 1px 1px 10px #E6E7E8;
    box-shadow: 1px 1px 10px #0f52ba2e;
    border-radius: 10px;
    margin-bottom: 10px;
    // height: 100px;
  }

  .centerpage {
    display: flex;
    align-items: center;
    justify-content: center;

    .borderr {
      width: 50%;
    }
  }

  .dateandhistory {
    height: 50px;
  }

  .studentListContainer {
    margin-bottom: 5px;
    //background-color: white;
    //padding:10px;
    &.active {
      .otherAppList {
        background-color: #fff8e1;
      }
    }

    .otherAppList {
      display: flex;
      //flex-direction: column;
      padding: 10px 10px 5px;
      background-color: white;
      //justify-content: space-between;
      box-shadow: 0 0 10px #f1f1;
      border-radius: 5px;
      margin-bottom: 10px;

      .leftBox {
        text-align: left;
        display: flex;
        align-items: flex-start;
        padding: 10px;

        .logo1 {
          width: 200px;
          height: 200px;
        }

        img {
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }

        .studentDetails {
          line-height: 1.5;
        }

        .leftBox1 {
          background-color: gray !important;
          text-align: left;
          display: flex;
          align-items: flex-start;
          padding: 10px;

          img {
            width: 50px !important;
            height: 50px !important;
            margin-right: 20px;
          }

          .studentDetails {
            line-height: 1.5;
          }
        }
      }

      .rightBox {
        margin-top: 10px;
        text-align: left;

        a {
          p {
            color: @fontColor;
            font-size: 15px;
          }

          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 5px;
        }
      }
    }
  }

  .closeconvert {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
  }

  .flaguk {
    margin-left: 466px;
    width: 25px;
  }

  // .onoff{
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   margin-bottom: 20px;

  // }
  .nameanddate {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .backanddot {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .onoffdot {
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-bottom: 10px;
    // .busydot{
    //   display: flex;
    //   flex-direction: column;
    //   font-size: 10px;

    // }
    .innerdot {
      margin-right: 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: red;
    }

    // .innerdot1{
    //   background-color: green;
    //   width: 15px;
    //   height: 15px;
    //   border-radius: 50%;
    //   margin-right: 5px;
    //   font-size: 12px;
    // }
  }

  .picandmore {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
  }

  .caseclose {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;

    .caseclosebtn {
      width: 100px;
      background: rgb(8, 114, 188);
      font-weight: 500 !important;
      color: #fff;
      text-align: center;
      border-radius: 50px;
      border: none;
      outline: none;
    }
  }

  .myTextarea {
    padding: 10px;
  }

  .directUniCount {
    width: 16.6% !important;
    display: inline-block;
    padding: 0 20px 0 10px !important;
    margin: 0 !important;
    cursor: pointer;

    .uniCountBox {
      display: flex !important;
      justify-content: space-around;
      align-items: center;
      background: white;
      border-radius: 5px;
      box-shadow: 0 0 6px @primary !important;
      padding: 10px !important;

      img {
        height: 30px;
        width: auto;

      }

      h5 {
        font-size: 14px !important;
        height: 35px;
        width: auto;
        border-radius: 50%;
        line-height: 2.5;
        background: #0872bc;
        color: white !important;
        min-width: 35px;
        padding: 0 5px;
      }
    }
  }

  .canadaDirectUniCount.canadaDirectUniCount2 {
    width: 25% !important;

  }

  .canadaDirectUniCount2 {
    .innerBox {

      border: 1px solid #ccc;
    }
  }

  .card.card-pdf {
    background: white !important;
  }

  .canadaDirectUniCount {
    width: 19.6% !important;
    display: inline-block;
    padding: 0 20px 0 10px !important;
    margin: 0 !important;
    cursor: pointer;

    .innerBox {
      background: white;
      border-radius: 5px;
      box-shadow: 0 0 6px @primary !important;
      padding: 10px !important;

      .uniCountBox {
        display: flex !important;
        justify-content: space-around;
        align-items: center;

        img {
          height: 30px;
          width: auto;

          &.sm {
            height: 25px;
          }

          &.md {
            height: 35px !important;
          }
        }

        h5 {
          font-size: 14px;
          height: 35px;
          width: auto;
          border-radius: 50%;
          line-height: 2.5;
          background: #0872bc;
          color: white !important;
          min-width: 35px;
          padding: 0 5px;
        }
      }

      .btnBox {
        border-top: 1px solid #f1f1f1;
        margin-top: 15px;
        padding-top: 8px;
        display: flex;
        justify-content: space-around;

        a {
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            margin-right: 5px;
          }
        }
      }

      .dirUniAgent {
        padding: 10px 0;
        font-size: 12px;
        display: block;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: hidden;
        text-overflow: ellipsis;
        height: 27px;
      }
    }
  }

  .filter-row {
    &.ant-row {
      display: flex;
      flex-wrap: wrap;
      //.ant-col
    }
  }

  .commissionBlock {
    margin: 20px 0;

    .countryCommission {
      //background-color: white;
      padding: 20px;
      text-align: center;

      .countryName {
        font-size: 16px !important;
        height: 50px;
      }

      .countryFlag {
        margin: 25px 0;

        img {
          height: 30px;
        }
      }

      .currencyName {
        font-size: 16px !important;
      }

      .countryAmount {
        font-size: 25px;
        cursor: pointer;
      }

      .canada {
        color: #ad2125;
      }

      .uk {
        color: #ff0066;
      }

      .australia {
        color: #003399;
      }
    }
  }

  .card {
    &.bgWhite {
      background: white !important;
    }
  }

  .ant-col-md-0 {
    display: block;
  }

  .squareContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;

    .squareStyle {
      width: 45px;
      height: 35px;
      border: 1px solid #efefef;
      line-height: 2;
      border-radius: 3px;
      color: @darkPrimary;
      font-size: 15px;

      &:nth-child(1) {
        border-bottom: 2px solid @primary;
      }

      &:nth-child(2) {
        border-bottom: 2px solid @successGreen;
      }

      &:nth-child(3) {
        border-bottom: 2px solid @success;
      }

      &:nth-child(4) {
        border-bottom: 2px solid @info;
      }

      &:nth-child(5) {
        border-bottom: 2px solid @warning;
      }
    }
  }

  .ukSquareContainer {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 15px;
    grid-column-gap: 5px;

    .squareStyle {
      height: 35px;
      border: 1px solid #efefef;
      line-height: 2;
      border-radius: 3px;
      color: @darkPrimary;
      font-size: 15px;
      text-align: center;

      &:nth-child(1) {
        border-bottom: 2px solid @primary;
      }

      &:nth-child(2) {
        border-bottom: 2px solid @successGreen;
      }

      &:nth-child(3) {
        border-bottom: 2px solid @success;
      }

      &:nth-child(4) {
        border-bottom: 2px solid @info;
      }

      &:nth-child(5) {
        border-bottom: 2px solid @warning;
      }
    }
  }

  .dirUniLabelBox {
    display: flex;
    padding: 30px 2.4rem 0 !important;

    div {
      margin: 0 20px;
      padding: 0 10px;

      &:nth-child(1) {
        border-bottom: 2px solid @primary;
      }

      &:nth-child(2) {
        border-bottom: 2px solid @successGreen;
      }

      &:nth-child(3) {
        border-bottom: 2px solid @info;
      }
    }
  }

  .ledgerBox {
    padding: 20px 3%;

    .header {
      border-bottom: 1px solid #ddd;
      margin-bottom: 5px;

      .ant-col {
        color: black;
        font-weight: 500;
        padding-bottom: 10px;
      }
    }

    .ant-col {
      &:nth-child(3) {
        text-align: right;
      }

      &:last-child {
        text-align: right;
      }
    }

    .ant-row {
      padding: 10px 10px 0;
      //border-bottom: 1px solid #f1f1f1;
      &:nth-child(even) {
        background-color: #f7f7f7;
      }

      &:nth-child(odd) {
        background-color: #ff11ff08;
      }

      &.header {
        background-color: white;
      }
    }

    .debit {
      color: @danger;
      font-weight: 600;
    }

    .credit {
      color: @successGreen;
      font-weight: 600;
    }

    .dateBox {
      font-size: 15px;
      font-weight: 500;
      color: @success;
      padding-bottom: 10px;
    }
  }

  .filterBox {
    .ant-calendar-picker-input {
      border-radius: 50px;
      border: none;
    }

    .search-box-table {
      &.round {
        margin-left: 0 !important;
      }
    }
  }

  .agentName {
    display: flex;

    .agentOnline {
      height: 7px;
      width: 7px;
      background-color: @successGreen;
      border-radius: 50%;
      margin-left: 5px;
    }
  }

  .display-linebreak {
    white-space: pre-line;
  }

  .hrFooter {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .selectable-box {
    //display: flex;
    border: 1px solid #e5d9d9;
    margin: 5px;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .box-text {
    font-size: 14px;
  }

  .countCircle {
    font-size: 12px !important;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    line-height: 0.9;
    background: #0872bc;
    color: white !important;
    margin-left: 7px;
    margin-top: 2px;
    padding: 5px;
    display: inline-block;
  }

  .selectable-box.selected {
    background-color: #007bff;
    color: white;

    .countCircle {
      background: @successGreen;
    }
  }

  .intakeRow {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    margin-top: 10px;

    .singleIntakeBox {
      border: 1px solid #d9d9d9;
      padding: 0 5px;
      background: #fafafa;
      border-radius: 5px;
      margin-right: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);

      .singleIntakeBox-check {
        height: 12px;
        width: 12px;
        margin-right: 5px;
        bottom: 3px;
      }

      &.selected {
        background-color: @primary;

        i {
          color: white !important;
        }
      }

      i {
        font-style: normal;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }

  .trainingBox {
    text-align: center;
    margin-top: 50px;

    img {
      height: 150px;
    }

    .textBox {
      margin-top: 20px;

      h3 {
        font-weight: 600;
        text-transform: uppercase;
        font-family: ui-monospace;
        font-size: 30px;
        margin-bottom: 3%;
      }

      &.normal {
        h3 {
          text-transform: capitalize;
        }
      }

      .confirmLink {
        font-size: 17px;
        background-color: #0f52ba;
        color: white;
        padding: 6px 30px;
        text-transform: capitalize;
        border-radius: 5px;
      }
    }
  }

  .marketingUserBox {
    margin-bottom: 50px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 10px 10px #ddd;

    &.managerBoxCanada {
      width: 100%;
      margin-bottom: 0;

      .ant-avatar {
        img {
          left: 0 !important;
        }
      }

      .managerHead {
        margin-bottom: 5px;
        background-color: #ad2125;
      }
    }

    .managerHead {
      margin-right: auto;
      color: white;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
      width: 80%;
    }

    .managerBody {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-bottom: 10px;
      padding-left: 10px;

      &.ais {
        align-items: flex-start;
      }
    }
  }

  .whatsNewCard {
    .whatsNewBox {
      border-bottom: 1px solid #fde6e6;
      padding: 20px;

      .flex-row {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 20px;

        .icon {
          width: 50px;
          height: 50px;
          margin-right: 30px;
          margin-left: 20px;
        }

        .title {
          color: @darkPrimary;
          font-size: 18px;
          margin-bottom: 5px;
          text-transform: capitalize;
          font-weight: 600;
        }

        .dateRow {
          color: #555;
          font-size: 14px;

          i {
            margin-right: 5px;
            color: #888;
          }
        }
      }

      .content-box {
        margin-top: 20px;
      }

      .img-box {
        img {
          width: 80%;
        }
      }
    }
  }

  .studentInfoTableRow {
    padding: 4px 10px;
    display: flex;
    font-size: 12px;

    .title {
      font-weight: bold;
      width: 40%;
      padding: 5px 10px;
    }

    .details {
      padding: 5px 10px;
    }

    p {
      margin-bottom: 0;
    }

    &:first-child {
      border-top: 1px solid #ddd;
    }

    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;

    &:nth-child(odd) {
      background-color: white;
    }

    &:nth-child(even) {
      background-color: #effbf8;
    }
  }

  .textAreaHeight {
    min-height: 100px !important;
  }

  .agent_form {
    background-color: #f7f7ff;

    .ant-form-item {
      margin-bottom: 0.8rem;
    }

    .form-control,
    .ant-select-selection {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem !important;
      font-size: 1rem !important;
      font-weight: 400;
      line-height: 1.5;
      color: #212529 !important;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 50px !important;
      border-radius: 0;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      background-image: none !important;

      &::-webkit-input-placeholder {
        font-size: 1rem !important;
        font-weight: 400;
      }
    }

    .ant-select-selection__placeholder {
      font-size: 1rem !important;
      font-weight: 400;
      line-height: 1.5;
    }

    .ant-select-selection__rendered {
      margin-left: 0;
    }

    .ant-select-arrow {
      background-color: white;
      color: #777 !important;
    }

    .ant-select-focused {
      .ant-select-arrow {
        background-color: white !important;
        color: #777 !important;
      }
    }

    label {
      font-size: 18px !important;
      margin: 0 !important;
      margin-bottom: 8px !important;
    }

    .right_bg {
      height: 100%;
      background-position: 35%;
      background-repeat: no-repeat;
    }

    .btn_sub {
      padding: 8px 16px !important;
      font-size: 1.25rem;
      border-radius: 0 !important;
      background-color: @primary;
      border: none;
    }
  }

  .registrationClose {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .default_btn {
    border: 1px solid #ddd;
    padding: 3px 10px;
    color: #555;
    text-decoration: none;
    font-size: 0.8rem !important;
    border-radius: 3px;
    margin-right: 5px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }

    img {
      height: 15px !important;
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }

  .btn_group {
    display: flex;
    flex-wrap: wrap;

    .default_btn {
      margin-right: 5px;
    }

    .btn {
      margin-right: 5px;
    }
  }

  .select_btn_group {
    display: flex;
    flex-wrap: wrap;

    .default_btn {
      margin-right: 5px;
      background-color: white;
      line-height: 1.5;
      padding: 0.4rem 1.75rem !important;
      height: auto !important;
      border: none;
      margin-left: 5px;
      transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      font-size: 15px;

      &.selected {
        background-color: @successGreen;
        color: white;
      }

      .anticon {
        font-size: 16px;
        margin-right: 7px;
      }
    }

    .default_btn_lg {
      margin-right: 10px;
      background-color: white;
      line-height: 1.5;
      padding: 0.4rem 3rem !important;
      height: auto !important;
      border: none;
      margin-left: 5px;
      transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      font-size: 15px;
      border-radius: 3px;
      color: #555;
      display: flex;
      align-items: center;

      &.selected {
        //border:1px solid @successGreen;
        color: white;
      }

      .anticon {
        font-size: 16px;
        margin-right: 7px;
      }
    }
  }


  .btn_link {
    text-decoration: underline;
    //color: #337ab7;
  }

  .custom-checkbox {
    width: 100%;

    .ant-checkbox-group-item {
      width: 40% !important;
    }

    span {
      font-weight: normal;
    }
  }

  .marketing_country_box {
    border: 1px solid #f1f1f1;
    padding: 5px 20px;
    border-radius: 5px;
    margin-bottom: 10px;

    .checkbox_list {
    }

    .uni_row {
      border: 1px solid #f1f1f1;
      padding: 5px 0 0 0;
      margin-bottom: 5px;
      border-radius: 5px;

      .form-control {
        &.sm {
          height: 35px !important;
          border-color: #ddd !important;
          background-image: none !important;
          font-size: 14px !important;
        }
      }
    }
  }

  .filter_box {
    margin-top: 15px;
    background-color: white;
    border-radius: 5px;
    padding: 15px 10px 10px;

    &.bg {
      border: 1px solid #dee2e6 !important;
    }

    .labelNew {
      font-size: 14px;
      font-weight: normal !important;
    }

    input:not([class]) {
      width: 100%;
      border: 1px solid #ddd;
      padding: 0 5px 0 20px;
      border-radius: 50px;
      font-size: 14px;
      color: #555;
      height: 40px;
    }

    .ant-calendar-picker-input {
      height: 42px !important;
      border-radius: 50px;
      border: 1px solid #ddd;
    }

    .ant-select {
      &.ant-select-focused {
        .ant-select-arrow {
          background: transparent !important;
          color: #555;
        }
      }

      .ant-select-selection {
        border-radius: 50px;
        border-color: #ddd;

        .ant-select-arrow {
          background: transparent;
          color: #555;
        }

        &:hover {
          .ant-select-arrow {
            background-color: white;
            display: block;

            .anticon {
              color: #555;
            }
          }
        }
      }
    }

    .ant-select-selection__placeholder {
      font-size: 14px !important;
      opacity: 0.8 !important;
      color: #333 !important;
    }

    .search-box-table {
      &.round {
        border: 1px solid #ddd;
      }
    }

    .btn_group {
      display: flex;
      justify-content: flex-end;

      .default_btn {
        margin-right: 5px;
        padding: 5px 20px;
        border-radius: 50px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .mismatchNote {
    padding: 5px 10px;
    background-color: @danger;
    color: white;
    font-weight: 600;
    margin-top: 10px;
  }

  .expenseAmtBox {
    .successAmt {
      color: @successGreen;
    }

    .pendingAmt {
      color: @warning;
    }

    .totalAmt {
      color: @success;
    }
  }

  .joinDate {
    margin-top: 5px;
    color: @primary;
    //border:1px solid @primary;
    font-size: 11px;
    //padding:0 5px;
    border-radius: 3px;
    font-weight: 600;

    &::before {
      content: '' !important;
      border-left: 2px solid @primary;
      padding-left: 3px;
    }
  }

  .table-bordered {
    border: 1px solid #dee2e6 !important;

    th,
    td {
      border: 1px solid #dee2e6 !important;
    }

    thead th,
    thead td {
      border-bottom-width: 2px;
    }

    th,
    td,
    thead th,
    tbody + tbody {
      border: 0;
    }
  }

  .marketingDirectUniCount {
    width: 16.6% !important;
    display: inline-block;
    padding: 0 20px 0 10px !important;
    margin: 0 !important;

    .uni_box {
      background: white;
      border-radius: 5px;
      box-shadow: 0 0 6px @primary !important;
      padding: 10px !important;

      img {
        height: 40px;
        width: auto;
      }
    }

    .uniCountBox {
      display: flex !important;
      justify-content: space-around;
      flex-direction: row;
      margin-top: 15px;

      .countBox {
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 40%;
        cursor: pointer;

        .label {
          font-size: 12px !important;
          color: #555;
          padding: 0;
        }

        .count {
          padding: 0 5px;
          color: @primary;
        }
      }
    }
  }

  .dropbtn {
    border: none;
  }

  input[type='date'] {
    &.js_datepicker {
      background-color: white !important;
      border: 1px solid #e5dddd !important;
    }
  }

  .eye_btn {
    //border: 1px solid @darkPrimary;
    padding: 0 3px !important;
    border-radius: 3px;
    margin-left: 5px;
    font-size: 10px;
    color: @darkPrimary !important;

    img {
      height: 14px;
    }
  }

  .structureDotsReplace {
    display: block;
    //white-space: nowrap;
    overflow-y: hidden;
    text-overflow: ellipsis;
    max-height: 28px;
  }

  .event_gallery_list {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

    .single_img {
      border: 1px solid #ebcfcf;
      padding: 10px;
      border-radius: 5px;
      position: relative;

      &:hover {
        border: 2px solid @success;
      }

      .cross_btn {
        position: absolute;
        right: -8px;
        top: -8px;
        background: @darkPrimary;
        border-radius: 50px;
        width: 22px;
        height: 22px;
        text-align: center;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        cursor: pointer;
      }

      img {
        height: 100px;
      }
    }
  }

  .btn_options {
    display: flex;

    a {
      font-size: 14px;
      width: 100%;
      height: 40px !important;
      padding: 0.475rem 0.75rem;
    }

    .date_box {
      width: 220px;
      margin-right: 10px !important;

      &.selected {
        background-color: #f1f1f1 !important;

        .btn {
          background-color: #f1f1f1 !important;
        }
      }

      .form-control {
        border-color: #ddd !important;
        font-size: 15px;
      }
    }
  }

  .ant-calendar-month-calendar {
    width: 300px;
  }

  div#studentChart {
    padding-top: 30px !important;
  }

  .agent-rank-label {
    font-size: 10px !important;
    padding: 4px !important;
  }

  .priorityBox {
    margin-top: 10px;

    .priority {
      font-size: 9px;
      padding: 2px 6px;
      border-radius: 50px;
      color: white;
      background: @warning;
      font-weight: 600;
      position: relative;
      top: -3px;
      left: 0;
      margin-left: 5px;
      font-style: normal;
      -webkit-animation: glowS 0.5s ease-in-out infinite alternate;
      -moz-animation: glowS 0.5s ease-in-out infinite alternate;
      animation: glowS 0.5s ease-in-out infinite alternate;
      text-transform: uppercase;
    }

    @-webkit-keyframes glowS {
      from {
        box-shadow: 0 0 6px @warning;
      }
      to {
        box-shadow: 0 0 13px @warning;
      }
    }

    .superPriority {
      font-size: 9px;
      padding: 2px 6px;
      border-radius: 50px;
      color: white;
      background: red;
      font-weight: 600;
      position: relative;
      top: -3px;
      left: 0;
      margin-left: 5px;
      font-style: normal;
      -webkit-animation: glow 0.5s ease-in-out infinite alternate !important;
      -moz-animation: glow 0.5s ease-in-out infinite alternate !important;
      animation: glow 0.5s ease-in-out infinite alternate !important;
      text-transform: uppercase;
    }

    @-webkit-keyframes glow {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  .eventDirectCount {
    width: 20% !important;
    display: inline-block;
    padding: 0 20px 0 10px !important;
    margin: 0 !important;
    cursor: pointer;

    .innerBox {
      background: white;
      border-radius: 5px;
      box-shadow: 0 0 6px @primary !important;
      padding: 10px !important;

      .uniCountBox {
        display: flex !important;
        align-items: center;
        padding: 10px 5px;
      }

      .btnBox {
        border-top: 1px solid #f1f1f1;
        margin-top: 15px;
        padding-top: 8px;

        a {
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            margin-right: 5px;
          }
        }
      }

      .dirUniAgent {
        padding: 10px 0;
        font-size: 12px;
        display: block;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: hidden;
        text-overflow: ellipsis;
        height: 27px;
      }

      .squareContainer {
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
        margin-bottom: 10px;

        & > div {
          width: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;

          span {
            font-size: 12px;
            margin-bottom: 5px;
          }
        }

        .squareStyle {
          width: 60px;
          height: 35px;
          border: 1px solid #efefef;
          line-height: 2;
          border-radius: 3px;
          color: @darkPrimary;
          font-size: 15px;
          text-align: center;
          align-self: center;

          &.totalCount {
            border-bottom: 2px solid @successGreen;
          }

          &.approvedCount {
            border-bottom: 2px solid @primary;
          }

          &.pendingCount {
            border-bottom: 2px solid @success;
          }

          &.deferredCount {
            border-bottom: 2px solid @info;
          }

          &.deniedCount {
            border-bottom: 2px solid @danger;
          }
        }
      }
    }
  }

  .icefMeetingCount {
    .singleLi {
      display: inline-block;
      padding: 10px;

      .li_box {
        display: flex;
        align-items: center;
        border: 1px solid #f1f1f1;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 14px;

        .squareStyle {
          margin-left: 20px;
          border-bottom: 2px solid;
          min-width: 30px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }

  .main-btn {
    width: auto !important;
    min-width: 150px;
    background: #1556bc;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    margin: 25px 0 0;
    border-radius: 50px;
    font-weight: 600;
    height: 42px !important;
  }

  .white-btn {
    background: white;
    border: 1px solid #ddd;
    color: #555;

    .ant-calendar-picker-input {
      &.ant-input {
        height: 35px !important;
      }
    }
  }

  .small-select {
    .ant-select-selection {
      height: 30px !important;
      border-color: #ddd;
      border-radius: 3px;

      .ant-select-arrow {
        background-color: transparent !important;
        color: #555;
      }
    }
  }

  .md-select {
    .ant-select-selection {
      height: 33px !important;
      border-color: transparent;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      .ant-select-arrow {
        background-color: transparent !important;
        color: #555;
      }
    }
  }

  .room_slider {
    .mobile_view {
      display: none;
    }

    .web_view {
      display: block;
    }
  }

  @media (max-width: 768px) {
    .room_slider {
      .mobile_view {
        display: block;
      }

      .web_view {
        display: none;
      }
    }
  }

  .shadow-select {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: none;

    .ant-select-selection {
      height: 35px !important;
      border-radius: 7px;
      padding: 15px 10px;
      border: none;

      .ant-select-arrow {
        background-color: transparent !important;
        color: #555;
      }
    }
  }


  .comment-section {
    .sendBtn {
      border-radius: 15px !important;
      background-color: #87d068;
      border: none;
      color: white;
      padding: 14px !important;
    }

    .avatar_text {
      background-color: #87d068;
      margin: 0 12px;
      font-size: 20px !important;
    }
  }

  .top_city {
    height: 170px;
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 15px;
    }

    .city_name {
      position: absolute;
      bottom: 0;
      left: 7.5px;
      padding: 15px;
      z-index: 1;
      color: white;
      font-size: 16px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
      width: calc(100% - 15px);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      font-weight: 500;
    }

  }


  .shadow-select {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: none;

    .ant-select-selection {
      height: 35px !important;
      border-radius: 7px;
      padding: 15px 10px;
      border: none;

      .ant-select-arrow {
        background-color: transparent !important;
        color: #555;
      }
    }
  }


  .comment-section {
    .sendBtn {
      border-radius: 15px !important;
      background-color: #87d068;
      border: none;
      color: white;
      padding: 14px !important;
    }

    .avatar_text {
      background-color: #87d068;
      margin: 0 12px;
      font-size: 20px !important;
    }
  }

  .top_city {
    height: 170px;
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 15px;
    }

    .city_name {
      position: absolute;
      bottom: 0;
      left: 7.5px;
      padding: 15px;
      z-index: 1;
      color: white;
      font-size: 16px;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
      width: calc(100% - 15px);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      font-weight: 500;
    }

  }


  .icefManager {
    //background: #A1A8EA;
    background: #A1A8EA;
    height: 140px;
    border-radius: 10px;
    position: relative;
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar_box {
      position: absolute;
      bottom: 0;
      left: 5%;

      img {
        height: 170px;
      }
    }

    .manager_info_box {
      position: absolute;
      left: 25%;
      font-size: 13px;

      .head-title {
        font-size: 22px;
        //color: #0f52ba;
        color: white;
      }

      .manager_details {
        .inline_a {
          display: flex;
          align-items: center;

          &:first-child {
            font-style: italic;
          }
        }
      }
    }
  }

  .event-card {
    .card-container {
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      height: 400px !important;
      justify-content: flex-start;
    }

    .event-card-box {
      h2 {
        font-size: 22px;
        margin: 15px 0 0 0;
        text-shadow: 1px 1px 5px #000;
        color: #fff;
        font-weight: bold;
      }

      h3 {
        font-size: 16px;
        margin: 5px 0 0 0;
        text-shadow: 1px 1px 5px #000;
        color: #fff;
        font-weight: bold;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        position: absolute;
        bottom: 45px;
        left: 50%;
        right: 25%;
        width: 250px;
        margin-left: -145px;
        text-transform: uppercase;
        text-shadow: 1px 1px 5px #000;
        font-weight: bold;
      }

      .event-info {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        margin-bottom: 20px;
        font-size: 16px;

        .add-event-btn {
          margin-top: 1rem;
          border: 2px solid white;
          padding: 6px;
          position: absolute;
          right: 20px;
          bottom: 5px;
          cursor: pointer;
        }
      }

    }
  }

  .task_box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label-sm {
      padding: 0.5em 0.6em 0.3em;
    }
  }

  .completeTask {
    text-decoration: line-through;
  }

  .event_list_card {
    height: 82vh;
    overflow-y: auto;
    padding: 5px;

    .single_event_card {
      margin-bottom: 15px;
      padding: 20px;
      box-shadow: 0 0 10px #ddd;
      border-radius: 10px;


      .ant-collapse {
        border: 1px solid #f4efef !important;
        margin-top: 10px;

        .ant-collapse-header {
          padding: 8px 40px;
        }
      }
    }
  }

  .align_center {
    align-items: center;
    display: flex;
    color: @darkPrimary;
  }

  .aic {
    align-items: center !important;
    display: flex !important;

    label {
      margin-bottom: 0;
    }
  }

  .ant-select-disabled {
    .ant-select-selection {
      background-color: #ededed !important;
    }
  }

  .calendar-container {
    &.bgWhite {
      background: white !important;
      //padding-top: 10px;
      .ant-card-body {
        padding: 0;
      }
    }

    .table-event {
      td {
        width: calc(100% / 7);
      }
    }
  }

  .small-select {
    .ant-select-selection--single {
      height: 30px !important;
      border-color: #666;
      border-radius: 5px;

      .ant-select-arrow {
        padding: 3px;
        margin-top: -10px;
      }
    }
  }

  .custom_row {
    display: flex;
    margin-left: -9px;
    margin-right: -9px;

    .custom_col {
      flex: 1;
      padding-left: 9px;
      padding-right: 9px;
    }
  }

  .w-65 {
    width: 65% !important
  }

  .login--wCard {
    width: 65% !important
  }

  .commission-listing-section {
    padding: 30px 2rem !important;
  }

  .custom-calendar-1 {
    width: 100% !important;
    font-size: 26px;
    border: none !important;

    .rc-calendar-header {
      border-bottom: none;
    }

    .rc-calendar-body {
      padding: 0;

      .rc-calendar-cell {
        & > div {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 20px !important;
  }

  .ant-menu-submenu-title {
    //padding-left: 18px!important;
  }

  .autoCompleted {
    ul {
      width: 100%;
    }

    .ant-select-search__field__wrap {
      width: 100%;
    }
  }

  .help_btn {
    position: absolute !important;
    font-size: 14px;
    right: 10px;
    margin-top: 2px;
    border: 1px solid #d5c4c4;
    padding: 0px 20px;
    border-radius: 5px;
    color: #555;
    bottom: 10px;
    display: flex;
    align-items: center;

    i {
      margin-right: 5px;
    }

    &:hover {
      color: black;
    }
  }

  .req_btn {
    //position: absolute;
    font-size: 14px;
    right: 10px;
    border: 1px solid #d5c4c4;
    padding: 0px 5px;
    border-radius: 5px;
    color: #555;
    bottom: 40px;
    display: flex;
    align-items: center;

    i {
      margin-right: 5px;
    }

    &:hover {
      color: black;
    }
  }

  .custom-container {
    .custom_card {
      .ant-card-head {
        height: 30px;
        background: #f1f1f1;
        font-size: 15px;
        color: #555;

        span {
          font-size: 14px;
          /* margin-left: 5px; */
        }
      }

      .ant-card-body {
        padding: 14px 14px 14px 24px;
        color: black;
      }
    }
  }

  .table-sm {
    th, td {
      padding: 0.3rem !important;
      font-size: 12px !important;
    }
  }

  .table-striped {
    tr:nth-of-type(odd) {
      //background-color: #f2f2f2;
      background-color: #dff0d8;
    }
  }

  .flexWrap {
    display: flex;
    flex-wrap: wrap;
  }

  .commissionRow {
    display: flex;

    .commBox {
      flex: 1;
    }
  }

  .direct-uni-box {
    .count {
      display: flex !important;

    }


    .canadaDirectUniCount {
      width: 20% !important;
      display: inline-block;
      padding: 0 10px 0 10px !important;
      margin: 0 !important;
      cursor: pointer;

      &:last-child {
        padding-right: 0;
      }

      .innerBox {
        background: white;
        border-radius: 5px;
        box-shadow: 0 0 6px @primary !important;
        padding: 10px !important;

        .uniCountBox {
          display: flex !important;
          justify-content: space-around;
          align-items: center;

          .record-count {
            display: flex;
            justify-content: center;
          }

          img {
            height: 30px;
            width: auto;

            &.sm {
              height: 25px;
            }
          }

          h5 {
            font-size: 14px;
            height: 35px;
            width: auto;
            border-radius: 50%;
            line-height: 2.5;
            background: #0872bc;
            color: white !important;
            min-width: 35px;
            padding: 0 5px;
          }
        }

        .btnBox {
          border-top: 1px solid #f1f1f1;
          margin-top: 15px;
          padding-top: 8px;
          display: flex;
          justify-content: space-around;

          a {
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
              margin-right: 5px;
            }
          }
        }

        .dirUniAgent {
          padding: 0;
          font-size: 12px;
          display: block;
          white-space: nowrap;
          overflow-y: hidden;
          overflow-x: hidden;
          text-overflow: ellipsis;
          height: 1px;
          background: #f1f1f1;
          margin: 10px 0;
        }
      }
    }

    .squareContainer {
      margin-top: 15px;
      display: grid;
      grid-gap: 5px;
      grid-template-columns: repeat(4, 1fr);

      .squareStyle {
        width: auto;
        height: 32px;
        border: 1px solid #efefef;
        line-height: 2;
        border-radius: 3px;
        color: #0f52ba;
        font-size: 15px;
        margin-bottom: 0;
        text-align: center;

        &:nth-child(1) {
          border-bottom: 2px solid @primary;
        }

        &:nth-child(2) {
          border-bottom: 2px solid @successGreen;
        }

        &:nth-child(3) {
          border-bottom: 2px solid @success;
        }

        &:nth-child(4) {
          border-bottom: 2px solid @info;
        }

        &:nth-child(5) {
          border-bottom: 2px solid @warning;
        }

        &:nth-child(6) {
          border-bottom: 2px solid @primary;
        }

        &:nth-child(7) {
          border-bottom: 2px solid @successGreen;
        }

        &:nth-child(8) {
          border-bottom: 2px solid @successGreen;
        }

      }
    }
  }

  .commissionBlock {
    .countryCommission {
      width: 20%;
    }
  }

  @media only screen and (max-width: 1441px) {
    .canadaDirectUniCount {
      .innerBox {
        .uniCountBox {
          display: flex !important;
          justify-content: space-around;
          align-items: center;

          img {
            max-height: 20px;
            width: auto;
          }

          h5 {
            font-size: 9px !important;
            height: 25px;
            width: auto;
            min-width: 25px;
            line-height: 3;
          }
        }

        .btnBox {
          a {
            font-size: 11px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 560px) {
    section.banner1 {
      height: auto !important;

      .img {
        display: none;
      }
    }

    .login--wCard {
      width: 80% !important
    }

    .direct-uni-box {
      .canadaDirectUniCount {
        width: 100% !important;
        margin-bottom: 10px !important;
      }
    }

    .commissionBlock {
      .countryCommission {
        width: 50%;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .institute--event {
      .table-head h5 {
        width: 65% !important;
      }

      .event-teaser {
        .event-meta {
          flex-direction: column;
        }

        .event-title {
          padding-right: 0;
        }

        .event-date-day1 {
          width: 100%;
        }

        .event-venue-wrap1 {
          padding: 20px 0;
          margin-left: 0;
        }

        .reg_btn {
          margin-left: 0 !important;
        }

        .regTime {
          margin: 10px 0;
        }
      }
    }
  }

  .WcStyle {
    padding: 3px !important;
  }

  .penSpan {
    background: #fff;
    white-space: nowrap;
    text-transform: capitalize;
    padding: 3px 12px;
    border-radius: 50px;
    font-weight: 500 !important;
    font-size: 12px;
    border: 2px solid;
  }

  .gic-list {
    .active {
      color: #50C37E;
      border-color: #50C37E;
    }

    .pending {
      color: #bccf5b;
      border-color: #bccf5b;
    }

  }

  .rc-table table td span.inactive {
    color: #ff3f5b;
    border-color: #ff3f5b;
  }

  .rc-table table td:first-child img {
    width: 35px;
  }

  .rc-table table td a {
    font-weight: 500;
    color: #3E6AB4;
  }

  .rc-table table td:last-child {
    white-space: nowrap;
  }

  .rc-table table td:last-child button {
    padding: 0;
    width: 30px;
    height: 30px;
  }

  .rc-table table td:last-child button img {
    width: 100%;
  }

  .debit2 {
    color: #ff0000;
    font-weight: 600;
  }

  .credit2 {
    color: #008000;
    font-weight: 600;
  }

  .nav-sidebar {
    .nav-item {
      .nav-link1 {
        font-size: 13px !important;
      }
    }
  }

  .table-head {
    &.table-head-new {
      h6 {
        font-size: 15px !important;
        margin: 0 !important;
        width: auto !important;
        padding-right: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .travel-plan-table {
    .ant-table-bordered .ant-table-thead > tr > th, .ant-table-bordered .ant-table-tbody > tr > td {
      vertical-align: top;
    }
  }

  .gic-manager {
    .header-box {
      padding: 10px 20px 5px;
      border-bottom: 1px solid #f1f1f1;
      font-size: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      color: @darkPrimary;
    }
  }

  .round-selector {
    .ant-select-selection__rendered {
      ul {
        padding: 0 10px;

        li {
          border-radius: 50px;
        }
      }
    }
  }

  .feedback-button {
    &.selected {
      border: 2px solid @primary !important;
    }
  }

  .tag-gic {
    color: rgba(0, 0, 0, 0.65);
    border-radius: 10px;
    width: 200px;
    display: flex;
    align-items: center;
  }


  .travel-carousel {
    .slick-list {
      padding-left: 20px;
    }

    .slick-dots {
      position: absolute;
      bottom: 0px !important;

      li {
        border-bottom: none;
        background: none !important;

        button {
          background: @success !important;
          height: 5px !important;
        }
      }
    }

    .slick-arrow.slick-prev {
      font-size: 10px;
    }

    .ant-carousel .slick-prev::before {
      content: '<';
      z-index: 1000 !important;
      display: block;
      position: relative;
      bottom: 10px;
      right: 0px;
      /* width: 100px; */
      font-size: 25px;
      color: gray;
      background-color: white;
      border: 2px solid gray;
      justify-content: center;
      align-items: center;
      padding: 9px 0;

      border-radius: 50%;
      width: 22px;
      height: 22px;
    }

    .slick-arrow.slick-next {
      font-size: 10px;
    }

    .ant-carousel .slick-next::before {
      content: '>';
      display: block;
      position: relative;
      right: 0px;
      left: 0;
      bottom: 10px;
      /* width: 100px; */
      font-size: 25px;
      color: gray;
      background-color: white;
      border: 2px solid gray;
      justify-content: center;
      align-items: center;
      padding: 9px 0;
      border-radius: 50%;
      width: 22px;
      height: 22px;
    }

    .ant-carousel .slick-slider {

    }
  }

  .marketingUserBox2 {

    .managerHead2 {
      margin-right: auto;
      color: white;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      width: 100%;
      border-bottom: 1px solid #f1f1f1;

      .universityFlag {
        font-size: 11px;
        //text-transform: uppercase;
        font-weight: 600;

        img {
          height: 20px;
          width: auto;
          border-radius: 3px;
        }
      }
    }

    .travel-avator {
      border: 2px solid;
      padding-right: 0;
      margin-top: 5px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .font-weight-500 {
    font-weight: 500;
  }

  .font-weight-600 {
    font-weight: 600;
  }

  .text-black {
    color: rgb(33, 37, 41);
  }


  .ant-input-group-wrapper {
    padding: 0 !important;

    .ant-input {
      height: 40px;
    }
  }

  tr {
    &.warning_tr {
      td {
        background-color: #fcf8e3 !important;
      }
    }
  }

  .feedbackButton.selected {
    /* Selected button styles */
    background-color: #f0f0f0 !important;
    color: #333;
    border: 1px solid #ccc;
  }

  .successTag2 {
    background: #c2cdd5;
    border: 1px solid #0872bc;
    padding: 1px 20px;
    border-radius: 20px;
    font-size: 13px;
    color: white;
  }

  .managerHead2 {
    margin-right: auto;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 20px;
    width: 100%;
  }

  .universityWiseBlock {
    margin-top: 15px !important;
  }

  .flagBox {
    height: 35px;
  }

  .liveReportingOuter {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 30px;

    .liveReportingInnerBox {
      border: 1px solid #efefef;
      line-height: 2;
      border-radius: 3px;
      font-size: 15px;
      text-align: center;

      .reportingInnerBox {
        height: 100px;
      }
    }
  }
  .sopModalRow {
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }

  .successMessage {
    font-size: 18px;
    /*color: #1556bc;*/
    color:black;
    margin-bottom: 20px;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .viewButton, .closeButton {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .viewButton {
    background-color: #2196F3;
    color: white;
  }

  .viewButton:hover {
    background-color: #1976D2;
  }

  .closeButton {
    background-color: #f44336;
    color: white;
  }

  .closeButton:hover {
    background-color: #d32f2f;
  }


  .status-list-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;

    .status-item-grid {
      text-align: center;
      margin-bottom: 15px;
      cursor: pointer;

      .status-box {
        display: inline-block;


        .status-name {
          font-size: 15px;
          color: #666;
        }

        .status-count {
          color: @darkPrimary;
          font-size: 18px;
          font-weight: normal;
          cursor: pointer;
        }
      }

      /*    &::after {
            content: "|";
            display: inline-block;
          }*/
    }
  }

  .liveReportTarget {
    margin-top: 10px;
  }

  .target-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 13px;
    font-weight: 500;

    .value-box {
      display: flex;
      justify-content: center;
    }

    img {
      height: 20px;
      margin-right: 5px;
    }

    span {
      font-size: 17px;
    }
  }

  .custom-autocomplete {
    .ant-select-search__field__wrap {
      width: 100%;
    }
  }
}

/* Larger screens */
@media (min-width: 1400px) {
  .canadaDirectUniCount {
    width: 32% !important;
    padding: 0 15px !important;
  }

  .card-pane-right.listing-section {
    padding: 30px 3rem 30px 4rem;
    display: flex;
    align-items: center;
  }

  .download-pdf-flag {
    width: 25px !important;
    height: 21px !important;
  }

  .download-pdf-text {
    font-weight: bold !important;
    font-size: 19px !important;

  }

  .card-pane-right.listing-section ul li p {
    word-spacing: normal;
  }

  .card-pane-right.listing-section ul li {
    width: 23.6% !important;
    display: inline-block;
  }
}

@media print {
  body {
    font-family: Arial, sans-serif;
    font-size: 12px;
  }

  .card-body {
    padding: 8px;
  }

  .download-pdf-flag {
    height: 20px;
    width: 25px;
    margin-right: 15px;
  }

  h5 {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }

}


@primary-color: #36c889;