.bankBox {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  //background-color: white;
  border-radius: 5px;

  .imgBox1 {
    position: relative;

    img {
      width: 100%;
      height: 110px;
      cursor: pointer;
      padding: 20px 20px;
      background-color: white;
      border-radius: 10px;

      &.selected {
        border: 1px solid green;
      }
    }

    .checkMark {
      //background-color: #5cb85c;
      background-color: green;
      height: 25px;
      width: 30px;
      position: absolute;
      top: 0;
      right: 8px;
      border-radius: 3px;
      border-bottom-left-radius: 20px;
      border-top-right-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      .checkIcon {
        color: white;
        font-weight: bold;
      }
    }
  }

}

@primary-color: #36c889;