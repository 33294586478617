.ant-drawer-body {
    padding: 0px !important;
}

.ql-snow .ql-editor h5 {
    font-size: 1.4em;
}

.ant-menu-dark {
    background-color: #0872BC;
}

.ant-menu-dark, .ant-menu-dark {
    background: #0872BC;
}

.ant-menu-sub {
    background-color: #3EAEE2 !important;
}

.ant-layout-sider {
    /*background: #0872BC;*/
    background: white;
}

.ant-input, .ant-input-number, .ant-select-selection--single {
    height: 100%
}

.ant-select-selection--single .ant-select-selection__rendered, .ant-input-number-input-wrap {
    height: 100%;
    display: flex;
}

.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected {
    background-color: #3EAEE2;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background: #C6C9D5;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: transparent;

}

.ant-form-item {
    /* margin-bottom: 2px;    */
}

#root > div > div > section > section > main > div > div > div.antd-pro-components-page-header-wrapper-index-content > div > div > div > div > div > form > div.ant-row > div:nth-child(1) > div > div {
    margin-bottom: 2px;

}

#root > div > div > section > section > main > div > div > div.antd-pro-components-page-header-wrapper-index-content > div > div > div > div > div > form > div:nth-child(1) > div.ant-col.ant-col-11.ant-col-offset-2 > div > div {
    margin-bottom: 2px;
}

.ant-select-selection-selected-value, .ant-input-number-input {
    align-self: center;
}

.ant-btn-primary {
    background-color: #0872BC;
    border-color: #0872BC;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-global-header-index-header {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.antd-pro-components-global-header-index-logo {
  height: 64px;
  line-height: 64px;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 0 24px;
  cursor: pointer;
  font-size: 20px;
}
.antd-pro-components-global-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
}
.antd-pro-components-global-header-index-menu .anticon {
  margin-right: 8px;
}
.antd-pro-components-global-header-index-menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.antd-pro-components-global-header-index-trigger {
  font-size: 20px;
  height: 64px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: calc((64px - 20px) / 2) 24px;
}
.antd-pro-components-global-header-index-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right {
  float: right;
  height: 100%;
  overflow: hidden;
  margin-right: 10px;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action > i {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action.opened {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search {
  padding: 0 12px;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search:hover {
  background: transparent;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
  margin: calc((64px - 24px) / 2) 0;
  margin-right: 8px;
  color: #36c889;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: top;
}
.antd-pro-components-global-header-index-dark {
  height: 64px;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action > i {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action:hover,
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action.opened {
  background: #36c889;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action .ant-badge {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-iconBox {
  margin-right: 5px;
  height: 15px;
}
.antd-pro-components-global-header-index-anouncmentIcon {
  margin-right: 5px;
  height: 29px;
  width: 29px;
  margin-bottom: 15px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .antd-pro-components-global-header-index-header .ant-divider-vertical {
    vertical-align: unset;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-name {
    display: none;
  }
  .antd-pro-components-global-header-index-header i.antd-pro-components-global-header-index-trigger {
    padding: 22px 12px;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-logo {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right {
    position: absolute;
    right: 12px;
    top: 0;
    background: #fff;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
    margin-right: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-page-header-index-pageHeader {
  background: #fff;
  padding: 16px 32px 0 32px;
  border-bottom: 1px solid #e8e8e8;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-wide {
  max-width: 1200px;
  margin: auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-detail {
  display: flex;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-row {
  display: flex;
  width: 100%;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-breadcrumb {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-tabs {
  margin: 0 0 0 -8px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-tabs .ant-tabs-bar {
  border-bottom: none;
  margin-bottom: 1px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo {
  flex: 0 1 auto;
  margin-right: 16px;
  padding-top: 1px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo > img {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: block;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
  margin-left: 56px;
  min-width: 266px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group:not(:last-child),
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn:not(:last-child) {
  margin-right: 8px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group > .ant-btn {
  margin-right: 0;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-content {
  flex: auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
  flex: 0 1 auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
  width: 100%;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-content,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  text-align: right;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  margin-left: 88px;
  min-width: 242px;
}
@media screen and (max-width: 1200px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 44px;
  }
}
@media screen and (max-width: 992px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-row {
    display: block;
  }
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-detail {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group,
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn {
    display: block;
    margin-bottom: 8px;
  }
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group > .ant-btn {
    display: inline-block;
    margin-bottom: 0;
  }
}

.antd-pro-components-page-header-wrapper-grid-content-main {
  width: 100%;
  height: 100%;
  min-height: 100%;
  transition: 0.3s;
}
.antd-pro-components-page-header-wrapper-grid-content-main.antd-pro-components-page-header-wrapper-grid-content-wide {
  max-width: 1200px;
  margin: 0 auto;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-page-header-wrapper-index-content {
  margin: 24px 24px 0;
}
@media screen and (max-width: 576px) {
  .antd-pro-components-page-header-wrapper-index-content {
    margin: 24px 0 0;
  }
}

.antd-pro-containers-course-views-styles-inputNumber {
  margin-top: 10px;
}

.antd-pro-containers-dashboard-gic-components-styles-bankBox {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  border-radius: 5px;
}
.antd-pro-containers-dashboard-gic-components-styles-bankBox .antd-pro-containers-dashboard-gic-components-styles-imgBox1 {
  position: relative;
}
.antd-pro-containers-dashboard-gic-components-styles-bankBox .antd-pro-containers-dashboard-gic-components-styles-imgBox1 img {
  width: 100%;
  height: 110px;
  cursor: pointer;
  padding: 20px 20px;
  background-color: white;
  border-radius: 10px;
}
.antd-pro-containers-dashboard-gic-components-styles-bankBox .antd-pro-containers-dashboard-gic-components-styles-imgBox1 img.antd-pro-containers-dashboard-gic-components-styles-selected {
  border: 1px solid green;
}
.antd-pro-containers-dashboard-gic-components-styles-bankBox .antd-pro-containers-dashboard-gic-components-styles-imgBox1 .antd-pro-containers-dashboard-gic-components-styles-checkMark {
  background-color: green;
  height: 25px;
  width: 30px;
  position: absolute;
  top: 0;
  right: 8px;
  border-radius: 3px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.antd-pro-containers-dashboard-gic-components-styles-bankBox .antd-pro-containers-dashboard-gic-components-styles-imgBox1 .antd-pro-containers-dashboard-gic-components-styles-checkMark .antd-pro-containers-dashboard-gic-components-styles-checkIcon {
  color: white;
  font-weight: bold;
}

.antd-pro-containers-private-message-views-styles-userNameBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
}
.antd-pro-containers-private-message-views-styles-userNameBox div {
  margin-top: 5px;
}
.antd-pro-containers-private-message-views-styles-statusBox {
  display: flex;
  align-items: center;
}
.antd-pro-containers-private-message-views-styles-unReadIcon {
  height: 12px;
  width: 12px;
  background: #f0ad4e;
  border-radius: 50%;
}
.antd-pro-containers-private-message-views-styles-nameLabel {
  margin-left: 0;
}

.antd-pro-containers-student-views-styles-userNameBox div {
  margin-top: 5px;
}
.antd-pro-containers-student-views-styles-smallAvat {
  background: white;
  vertical-align: middle;
  margin-right: 7px;
  border-radius: 50%;
  color: black;
  height: 17px;
  width: 17px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-left: 5px;
}

.antd-pro-components-qualification-style-test {
  display: block ;
}
.antd-pro-components-qualification-style-qualItem {
  display: flex;
  margin-left: 20px;
}
input {
  width: 120px;
}
.antd-pro-components-qualification-style-inputNumber {
  margin-left: 5px;
}
.antd-pro-components-qualification-style-minWidth {
  min-width: 200px;
  margin-left: 5px;
}

.chatBox .ant-card-body {
  padding: 0;
}
.chatBox .emptyBox {
  height: 400px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chatBox .list {
  padding: 0;
  border-right: 1px solid #ddd;
  height: 100vh;
}
.chatBox .list ul {
  border-top: 1px solid #ddd;
}
.chatBox .list ul li {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}
.chatBox .chatScreen {
  display: flex;
  height: 72vh;
  padding-bottom: 10px;
}
.chatBox .chatScreen textarea {
  padding: 15px 20px 12px !important;
  border-radius: 50px !important;
  border: 1px solid #ddd !important;
  margin: 0 5px 0 10px !important;
  background-color: #f0f0f0;
  border-bottom: 2px solid #c4c4c4 !important;
  height: 45px !important;
  line-height: 1 !important;
  overflow: -moz-scrollbars-none !important;
  -ms-overflow-style: none !important;
  overflow: hidden !important;
}
.chatBox .chatScreen textarea::-webkit-scrollbar {
  width: 0 !important;
}
.chatBox .chatScreen textarea::placeholder {
  color: #b9bbbe;
}
.chatBox .chatScreen div {
  border-top: none !important;
}
.chat-float-btn {
  position: fixed;
  z-index: 99;
  bottom: 6%;
  right: 2%;
}
.chat-float-btn img {
  height: 56px;
}
.chat-float-btn .chatCount {
  position: absolute;
  right: -5px;
  background: #d9534f;
  padding: 0;
  border-radius: 50px;
  height: 22px;
  min-width: 35px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  font-size: 12px;
  border: 3px solid white;
}
.ant-list-items {
  margin-top: 10px;
}
.textDiv {
  display: flex;
}
.textDiv span {
  padding-left: 5px;
}
.chat-room-list ul li {
  padding: 10px 10px 10px 20px;
  border-bottom: 1px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-room-list ul li .infoDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.chat-room-list ul li .infoDiv .innerInfo {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 5px;
  margin-right: 10px;
}
.chat-room-list ul li .infoDiv i {
  color: #d7d7d7;
}
.chat-room-list ul li:hover {
  background: #ececec;
  border-radius: 10px;
  cursor: pointer;
}
.chat-room-list .ant-list-item-action li {
  border-bottom: none;
  margin-bottom: 8px;
}
.chat-room-list .ant-list-item-action li a {
  background: #5cb85c;
  border-radius: 50%;
  width: 25px !important;
  height: 25px;
  display: flex;
  justify-content: center;
  color: white;
  padding: 5px;
  align-items: center;
  font-size: 12px;
}
.chat-room-list .ant-list-item-action li a:hover {
  background: #5cb85c;
  color: white;
}
.avatar {
  height: 35px;
  width: 35px;
  margin-right: 10px;
  padding: 0 !important;
}
.goBackBtn {
  background: white;
  height: 35px;
  width: 35px;
  margin-right: 10px;
  padding: 0 !important;
  color: #0f52ba;
  line-height: 1.5;
}
.headerBox {
  display: flex;
  flex-direction: column;
}
.headerBox .headerField {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 12px;
}
.headerBox .titleField {
  text-transform: capitalize;
  font-size: 11px;
}
.unreadSpan {
  height: 25px;
}
.unreadSpan .unreadCount {
  background-color: #0f52ba;
  color: white;
  padding: 0;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  display: inline-block;
  text-align: center;
  font-size: 12px;
  line-height: 1.7;
}
.time {
  color: #0f52ba;
  font-size: 12px;
}
.chatDrawer .ant-drawer-content {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.chatDrawer .ant-drawer-header {
  background-color: #0f52ba;
}
.chatDrawer .ant-drawer-header .ant-drawer-title {
  color: white;
}
.chatDrawer .ant-drawer-header .ant-drawer-close {
  color: white;
  margin-top: 5px;
}
.sendBtnBox {
  padding: 0 10px 0 0;
  margin-bottom: 1px;
}
.sendBtnBox img {
  height: 42px;
  position: relative;
  top: 0;
}
.plusBtnBox {
  padding: 0 10px 0 0;
  cursor: pointer;
}
.plusBtnBox img {
  height: 48px;
  position: relative;
  top: 3px;
}
.chat-popup {
  display: block;
  position: fixed;
  bottom: 0;
  right: 15px;
  z-index: 99;
  height: 85vh;
  background: white;
  top: 12%;
  box-shadow: 2px 2px 10px #ddd;
  border-radius: 10px;
  width: 400px;
}
.chat-popup .input-group-box {
  float: none !important;
}
.chat-popup .input-group-box .form-control {
  height: 35px !important;
}
.chat-popup .conversationList {
  padding: 0;
  overflow-y: scroll !important;
  position: relative;
  height: 70vh;
}
.chat-popup .conversationList h3 {
  font-size: 17px;
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 20px;
}
.chat-popup .header {
  padding: 20px;
  background: #039;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.chat-popup .header .topHeader {
  display: flex;
  justify-content: space-between;
}
.chat-popup .header .menuBtn {
  width: 100px;
  text-align: right;
}
.chat-popup .header .menuBtn a {
  margin: 0 5px;
  font-size: 14px;
}
@media screen and (max-width: 560px) {
  .chat-popup {
    display: none;
  }
  .chat-float-btn {
    display: none;
  }
}

.antd-pro-containers-company-views-styles-userNameBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
}
.antd-pro-containers-company-views-styles-userNameBox div {
  margin-top: 5px;
}

.header1 {
  font-size: 20px;
  text-decoration: underline;
}
.mt20 {
  margin-top: 20px !important;
}
.aggreementCard {
  padding: 20px 80px 20px 50px !important;
  background-color: white;
  font-size: 15px;
  line-height: 1.7;
}
.aggreementCard a {
  text-decoration: underline !important;
}
.aggreementCard p {
  margin-bottom: 20px !important;
  text-align: justify;
}
.aggreementCard ol,
.aggreementCard ul {
  margin-left: 10px !important;
}
.aggreementCard ol li,
.aggreementCard ul li {
  padding-left: 10px !important;
}
.aggreementCard ol,
.aggreementCard ul {
  list-style: auto !important;
}
.aggreementCard ol > li {
  padding-top: 20px;
}
.aggreementCard ol > li::marker {
  font-weight: bold !important;
}
.aggreementCard ul {
  padding-left: 10px;
}
.aggreementCard ul.alpha {
  list-style: lower-alpha !important;
}
.aggreementCard ul.roman {
  list-style: lower-roman !important;
}
.aggreementCard ul.roman li::marker {
  content: "(" counter(list-item) ")";
  list-style-type: circle !important;
}
.themeColor {
  color: #0872BC;
  font-weight: bold !important;
}

.antd-pro-containers-users-views-styles-userNameBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 120px;
}
.antd-pro-containers-users-views-styles-userNameBox div {
  margin-top: 5px;
}
.antd-pro-containers-users-views-styles-statusBox {
  display: flex;
  align-items: center;
}
.antd-pro-containers-users-views-styles-unReadIcon {
  height: 12px;
  width: 12px;
  background: #f0ad4e;
  border-radius: 50%;
}
.antd-pro-containers-users-views-styles-nameLabel {
  margin-left: 0;
}

.antd-pro-containers-intakes-program-list-styles-filter_row {
  display: flex;
  justify-content: space-between;
}
.antd-pro-containers-intakes-program-list-styles-single_column {
  margin-right: 5px !important;
}
.antd-pro-containers-intakes-program-list-styles-table tr td {
  width: 15%;
}
.antd-pro-containers-intakes-program-list-styles-table tr td:nth-child(1) {
  width: 5% !important;
  text-align: center;
}
.antd-pro-containers-intakes-program-list-styles-table tr td:nth-child(2) {
  width: 30%;
}
.antd-pro-containers-intakes-program-list-styles-table tr th {
  font-weight: 600;
}
.antd-pro-containers-intakes-program-list-styles-table tr.antd-pro-containers-intakes-program-list-styles-bgRow {
  background-color: #d9eafd7a;
}
.antd-pro-containers-intakes-program-list-styles-table tr.antd-pro-containers-intakes-program-list-styles-bgRow td {
  padding-top: 10px !important;
}
.antd-pro-containers-intakes-program-list-styles-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
  height: 80vh;
  opacity: 0.7;
}
.antd-pro-containers-intakes-program-list-styles-hideHead thead {
  display: none !important;
}

 {
  /*.inputBox {
    margin-top: 10px;
    margin-bottom: 10px;

    .labelRow {
      line-height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0;

      .extra {
        a {
          margin-left: 10px;
        }
      }
    }

    .labelNew {
      //line-height: 25px;
      line-height: 34px;
      color: rgba(0, 0, 0, 0.65);
      float: left;
      margin: 0 15px 0;
    }

    .innerBox {
      //margin-top: 14px;

      &.logoCls {
        //margin-top: 10px;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }

    .uniTypeCheckbox {
      .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        label {
          width: 45%;
          margin-bottom: 5px;
        }
      }
    }
  }*/
  /*.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
    color: rgba(255, 255, 255, 0.9);

    &:hover {
      text-decoration: underline;
    }
  }*/
  /* .ant-menu-inline-collapsed > .ant-menu-item .anticon + span {
     max-width: 100%;
   }*/
  /*  .PhoneInputInput {
      border-width: 1px !important;
      height: 42px !important;
      border-color: #666;
      color: #666;
      border-radius: 6px;
    }*/
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  /* Dropdown Button */
  /* The container <div> - needed to position the dropdown content */
  /* Dropdown Content (Hidden by Default) */
  /* Links inside the dropdown */
  /* .btn {
     width: 150px;
     background: #1556bc;
     font-size: 14px;
     text-transform: uppercase;
     color: #fff;
     margin: 25px 0 0;
     border-radius: 50px;
     font-weight: 600;
     height: 42px;
   }*/
}
body {
  color: #212529 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
}
.logo-link {
  display: none;
}
.ant-layout {
  background: white;
}
.ant-card-head .ant-drawer-title,
.ant-drawer-header .ant-drawer-title {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin: 0;
}
input[type='text'],
input[type='password'],
input[type='number'],
.ant-select-selection {
  height: 33px;
}
.ant-input,
.ant-input-number,
.ant-select-selection--single {
  height: 33px;
}
.ant-input.ant-select-search__field {
  height: 43px !important;
  border: 1px solid #666 !important;
}
.ant-select {
  width: 100%;
  font-size: 12px;
}
.mt0 {
  margin-top: 0 !important;
}
.mt1 {
  margin-top: 1px !important;
}
.ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100%;
}
.mt3 {
  margin-top: 3px !important;
}
.mt5 {
  margin-top: 7px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt18 {
  margin-top: 18px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb5 {
  margin-bottom: 5px;
}
.ml5 {
  margin-left: 5px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr0 {
  margin-right: 0 !important;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mb100 {
  margin-bottom: 100px;
}
.customForm .ant-select {
  width: 100%;
}
.customForm .ant-form-item-label {
  line-height: 28.9999px;
}
.customForm .ant-form-item {
  margin-bottom: 2px;
}
.ant-layout-sider {
  background: white;
}
.ant-form-item {
  margin-bottom: 2px;
}
.antd-pro-components-sider-menu-index-logo {
  text-align: center;
  height: 60px;
  background: white !important;
  padding: 5px 5px 5px 0;
  display: flex;
}
.antd-pro-components-sider-menu-index-logo img {
  height: 100%;
}
.ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo {
  text-align: left;
  height: 60px;
  background: white;
  padding: 5px;
  display: flex;
}
.ant-layout-sider-collapsed {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}
.ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo a {
  text-align: center;
}
.ant-menu-submenu-popup {
  left: 120px !important;
}
.ant-menu-inline-collapsed {
  padding-top: 0 !important;
  /*.ant-menu-item-selected {
      background-color: #22ade2 !important;
    }*/
  /* li {
       padding: 0 10px !important;
       display: flex;
       width: 100% !important;
       justify-content: center;
       align-items: center;
       height: 60px !important;

       .ant-menu-submenu > .ant-menu-submenu-title {
         padding: 0 10px !important;
       }

       span, a {
         line-height: 15px;
         color: #fff;
         display: flex !important;
         flex-direction: column !important;
         width: 100% !important;
         justify-content: center;
         align-items: center;

         span {
           opacity: 1 !important;
         }
       }
     }*/
}
.englishTypeBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.englishTypeBox div {
  width: 80px;
}
.inputBox {
  /*margin-top: 10px;
    margin-bottom: 10px;*/
  margin-bottom: 12px;
}
.inputBox .labelRow {
  padding: 0 10px;
  display: inline-block;
  overflow: hidden;
  line-height: 30px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.inputBox .labelRow .extra a {
  color: #000000d9;
  margin-left: 10px;
  text-decoration: underline;
}
.inputBox .labelNew {
  float: left;
  color: #666;
  margin-bottom: 0;
}
.inputBox .innerBox {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
.inputBox .innerBox .ant-calendar-picker {
  width: 100%;
}
.inputBox .uniTypeCheckbox .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.inputBox .uniTypeCheckbox .ant-checkbox-group label {
  width: 45%;
  margin-bottom: 5px;
}
.ant-select-selection--multiple {
  height: auto;
}
.ant-input-number {
  width: 100%;
}
.courseBox {
  line-height: 22px;
}
.courseBox .ant-card-body {
  padding-bottom: 0;
}
.courseBox a {
  color: #0064e1;
}
.courseBox .ant-card-bordered {
  border: none;
  border-bottom: 1px solid #e8e8e8;
}
.courseBox .uniInfo {
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 8px 24px;
}
.courseBox .uniInfo img {
  height: 48px !important;
  width: 48px !important;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.courseBox .uniInfo .uniName {
  font-size: 20px;
}
.courseBox .uniInfo .uniName .uniAdd {
  font-size: 15px;
}
.courseBox .courseRow {
  line-height: 22px;
  margin-bottom: 17px;
  margin-top: 9px;
  padding: 12px 24px;
}
.courseBox .courseRow:last-child {
  border: none;
}
.courseBox .courseRow .courseName {
  font-size: 13px;
  margin-right: 20px;
}
.courseBox .courseRow .courseInfo {
  font-weight: normal;
  font-size: 15px;
  margin-top: 8px;
}
.courseBox .courseRow .courseInfo .ant-col {
  line-height: 1;
}
.courseBox .courseRow .courseInfo .ant-col span {
  font-size: 12px;
}
.courseBox .courseRow .courseInfo .ant-col small {
  font-size: 12px;
}
.courseBox .courseRow .courseInfo .ant-col button {
  color: #2f8ac9;
}
.courseBox .loadAllBtn {
  display: flex;
  justify-content: space-between;
  padding: 10px 100px;
  position: absolute;
  z-index: 999;
  top: 100px;
  width: 100%;
  background: #1890ff54;
  font-size: 17px;
  font-weight: 500;
  align-items: center;
  border-left: 10px solid #1890ff;
}
.universityBox a {
  color: #0064e1;
}
.universityBox .ant-col {
  margin-bottom: 10px;
}
.universityBox .uniInfo {
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 8px 5px;
  height: 50px;
}
.universityBox .uniInfo img {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.universityBox .uniInfo .uniName {
  font-size: 15px;
}
.universityBox .uniInfo .uniName .uniAdd {
  font-size: 13px;
}
.linkBtn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  height: 32px;
  padding: 3px 40px;
  font-size: 13px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}
.linkBtn i {
  margin-right: 5px;
}
.linkBtn.small {
  height: 28px;
  padding: 2px 10px;
}
.alignCenter {
  text-align: center;
  cursor: pointer;
}
.alignRight {
  text-align: right;
}
.alignLeft {
  text-align: left !important;
}
.loadMoreBox {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 15px;
}
.totalCourseCount {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
}
.vertical-form .header {
  font-size: 25px !important;
  color: #606a84;
  margin-top: 10px;
}
.vertical-form .ant-card {
  margin-bottom: 20px;
}
.vertical-form .ant-row {
  margin-bottom: 0;
}
.vertical-form .ant-row .ant-form-item-label {
  text-align: left;
  line-height: 30px;
}
.vertical-form .ant-calendar-picker {
  width: 100%;
}
.sticky {
  /*position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    left: 0;*/
  position: fixed;
  top: 0;
  width: calc(100% - 168px);
  z-index: 10;
  opacity: 0.9;
}
.outerLoader {
  height: 100%;
  width: 100%;
  background: #ffffffc7;
  z-index: 9999;
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1890ff;
  font-size: 25px;
}
.outerLoader .ant-spin-dot {
  font-size: 40px;
}
.outerLoader .mainLoader {
  margin-top: 20px;
  font-size: 50px;
}
.outerLoader .mainLoader i {
  margin-right: 20px;
}
.imgSm {
  height: 50px;
  width: 50px;
}
input[type='file']::-webkit-file-upload-button {
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  font-weight: normal;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  height: 100%;
  border: none;
  border-right: 1px solid #ddd;
  width: 150px;
  margin-right: 10px;
}
.ant-form input[type='file']:focus {
  outline: 0 auto -webkit-focus-ring-color !important;
  border-color: white;
}
.rowFlex {
  display: flex;
}
.rowFlex > div {
  width: 100%;
}
.rowFlex button {
  margin-left: 10px;
}
.rowFlex .d1 {
  width: 100%;
}
.rowFlex .d1.mr10 {
  margin-right: 10px;
}
.rowFlex .d2 {
  width: 100%;
  margin-left: 10px;
}
.linkBtn {
  line-height: 1.499;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  height: 40px;
  padding: 0 10px;
  font-size: 18px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #666;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 5px;
  background-color: white;
  margin-top: 1px;
}
.linkBtn.sm {
  height: 25px;
  padding: 0 10px;
  font-size: 13px;
}
ul.list {
  padding-left: 0;
}
ul.list li {
  justify-content: space-between;
  border: 1px solid #ddd;
  align-items: center;
  padding: 5px 10px;
  border-bottom: none;
}
ul.list li:last-child {
  border-bottom: 1px solid #ddd;
}
.padd-5-bb-1 {
  border-bottom: 1px solid #d3d3d34a;
  padding: 7px 5px;
  font-size: 13px;
  color: #2a2a2a;
}
.padd-5-bb-1 .ant-row .ant-col:first-child {
  color: #333 !important;
}
.ant-success {
  color: #0872bc;
}
.ant-info {
  color: #5bc0de;
}
.ant-warning {
  color: rgba(240, 173, 78, 0.87);
}
.card.unizportal button.btn[type='submit'] {
  width: 150px;
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  height: 42px !important;
}
.ant-btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.ant-btn-basic {
  color: #333;
  background-color: #f1f1f1;
  border-color: #f7ebeb;
}
.successTag {
  background: #0872bc;
  border: 1px solid #0872bc;
  padding: 1px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
}
.statusDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.statusDiv .statusBox {
  height: 30px;
  background: #fff;
  border-radius: 0;
  text-align: center;
  border: 1px solid #ddd;
  margin: 3px 0;
  color: #333;
  padding: 3px;
  font-size: 12px;
  width: 350px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.statusDiv .statusBox .icon {
  position: absolute;
  right: 10px;
  color: rgba(240, 173, 78, 0.87);
  top: 25%;
}
.bgTheme {
  background-color: #0872bc !important;
  border: 1px solid #0872bc !important;
  color: white !important;
}
.bgTheme .icon {
  color: #0872bc !important;
}
table tbody tr:nth-child(odd) {
  background: white;
}
table tbody tr:nth-child(even) {
  background: #f9f9f9;
}
.applicationProfile {
  overflow: hidden;
  height: calc(100vh - 200);
}
.applicationProfile .imgDiv {
  display: inline-flex;
}
.applicationProfile aside {
  display: none;
}
.applicationProfile .ant-layout-content {
  padding: 0;
  margin: 0;
}
.applicationProfile .ant-drawer-header {
  background-color: #fafafa;
  border-radius: 0;
  padding: 10px 20px;
}
.applicationProfile .ant-drawer-header .ant-drawer-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 15px;
}
.applicationProfile .ant-drawer-header .ant-drawer-close {
  color: rgba(0, 0, 0, 0.85);
}
.applicationProfile .input-group-box {
  width: 40%;
}
.applicationProfile .logo-link {
  display: block;
}
.applicationProfile .displayName {
  display: none;
}
.bgWhite {
  background: white !important;
}
.appProfile {
  overflow: hidden !important;
  background: #f5f8fa;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.appProfile ::-webkit-scrollbar {
  width: 5px;
}
.appProfile ::-webkit-scrollbar-thumb {
  background: #a9a9a9;
}
.appProfile .align-self-stretch {
  display: flex;
  align-self: stretch !important;
  word-break: break-word;
  overflow: visible;
  flex-grow: 1;
  width: 100%;
}
.appProfile .align-self-stretch .profile-3-column {
  overflow: scroll;
  height: calc(100vh - 1px);
  padding-bottom: 45px;
}
.appProfile .align-self-stretch .profile-3-column.profile-3-col-sidebar.profile-3-col-sidebar--left {
  border-right: 1px solid #dfe3eb;
  padding-bottom: 52px;
  flex-basis: 350px;
  max-width: 500px;
  min-width: 280px;
}
.appProfile .align-self-stretch .profile-3-column.profile-3-col-sidebar--right-wrapper {
  background-color: #fff;
  display: block;
  flex-basis: 25%;
  max-width: 500px;
  transition: flex 0.15s cubic-bezier(0.16, 0.84, 0.44, 1);
  will-change: flex;
  min-width: 280px;
}
.appProfile .align-self-stretch .profile-6-column {
  padding-bottom: 45px;
}
.appProfile .align-self-stretch .profile-6-column.noteBlock {
  flex-basis: 50%;
  height: 100vh;
  padding-left: 10px;
}
.appProfile .align-self-stretch .profile-12-column {
  padding-bottom: 45px;
}
.appProfile .align-self-stretch .profile-12-column.noteBlock {
  /* flex-basis: 100%;
           //padding-top: 10px;
           height: 100vh;
           //border-left: 1px solid #ddd;
           //border-right: 1px solid #ddd;
           padding-left: 10px;
           overflow: hidden;
           border-bottom: 3px solid #ededed;
           padding-top: 15px;*/
  flex-basis: 100%;
  height: 100vh;
  /* padding-left: 10px; */
  overflow: hidden;
  border-bottom: 3px solid #ededed;
  /* padding-top: 15px; */
  border-top: 1px solid #ededed;
}
.appProfile .align-self-stretch .profile-12-column.noteBlock.noteBlockAgent {
  height: auto !important;
  max-height: 100vh !important;
}
.appProfile .align-self-stretch .profile-12-column.noteBlock.noteBlockAgent .scrollView {
  height: auto !important;
  max-height: 100vh !important;
}
.appProfile .infoBox {
  line-height: 1.5;
  margin-bottom: 10px;
}
.appProfile .infoBox label {
  font-size: 12px;
  color: #1890ff;
}
.appProfile .bgWhite {
  background: white !important;
}
.appProfile .bgWhite .ant-card-body {
  padding: 0;
}
.appProfile .actionBlock {
  display: flex;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background: white;
  margin-top: 10px;
}
.appProfile .stuInfo {
  padding: 18px 45px;
}
.appProfile .leftBlock {
  text-align: center;
}
.appProfile .bgImg {
  height: 80px;
  width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
}
.appProfile .logo {
  height: 80px;
  width: 80px;
}
.appProfile .font15 {
  font-size: 15px;
}
.appProfile .appInfo {
  margin-top: 10px;
  font-size: 13px;
  line-height: 2.3em;
}
.appProfile .appInfo .moveBtn {
  display: flex;
  justify-content: space-between;
}
.appProfile .appInfo .moveBtn .ant-btn {
  margin-left: 10px;
}
.appProfile .ant-collapse-header {
  background: white;
}
.appProfile .ant-collapse-content {
  border: none;
}
.appProfile .noteBlock {
  height: 100vh;
  border-left: 3px solid #ededed;
  border-right: 3px solid #ededed;
  overflow: scroll;
  background: white;
  /* &::-webkit-scrollbar-track {
         background: #f1f1f1;
       }

       &::-webkit-scrollbar-thumb {
         background: @primary;
       }

       &::-webkit-scrollbar-thumb:hover {
         background: @success;
       }*/
}
.appProfile .noteBlock::-webkit-scrollbar {
  width: 3px;
}
.appProfile .noteBlock .activityBlock {
  padding: 30px 10px 10px 50px;
}
.appProfile .noteBlock .noteCardOuter {
  margin: 10px;
}
.appProfile .noteBlock .noteCardOuter .dateLabel {
  font-size: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.appProfile .noteBlock .noteCardOuter .noteCard {
  background: white;
  line-height: 2.5;
  font-size: 15px;
  box-shadow: 2px 2px 10px #ddd;
  margin-bottom: 20px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content {
  display: flex;
  padding: 5px 20px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content i {
  margin-top: 10px;
  margin-right: 20px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent {
  width: 100%;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent .title {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent .title .date {
  font-size: 12px;
  color: #9d8e8e;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm {
  padding: 0;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent {
  width: 100%;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title {
  background: #dfe6ed;
  color: black;
  padding: 0 10px;
  border: 1px solid #80808014;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  font-size: 12px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit {
  display: flex;
  padding: 5px 0;
  align-items: flex-start;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit i {
  font-size: 12px;
  margin-right: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit .subject {
  line-height: 1;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .commContent {
  padding: 10px 20px 10px 50px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .commContent strong {
  margin-right: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .date {
  display: flex;
  font-size: 10px;
  line-height: 1;
  padding: 5px 0;
  opacity: 0.7;
}
.appProfile .noteBlock .noteCardOuter .noteCard img {
  height: 30px;
  margin-right: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .commentBlock {
  border-top: 1px solid #ddd;
  padding: 5px 20px;
  color: #1890ff;
  display: flex;
  justify-items: center;
  align-items: center;
  font-weight: 600;
  margin-top: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .commentBlock a {
  color: #1890ff;
  margin-left: 10px;
}
.customTag .ant-tag {
  border: none;
  background: #ddffff !important;
  padding: 2px 8px;
}
.customTag .ant-tag.noneCls {
  border: none;
  background: none !important;
  font-size: 13px;
  color: #2a2a2a;
}
.documentTitle {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 15px;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background: white;
  margin-top: 10px;
}
.documentTitle span {
  display: flex;
  align-items: center;
}
.oldDoc {
  background: #ddffff !important;
}
.oldDoc span {
  text-decoration: line-through !important;
}
.documentBlock .ant-collapse-header {
  font-weight: bold;
}
.documentBlock .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.documentBlock .documentDownBlock {
  margin-top: 10px;
  background: #dddddd4a;
  padding: 5px;
}
.documentBlock .documentText {
  font-size: 13px;
  padding-right: 15px;
}
.btnGroup {
  display: flex;
  justify-content: space-between;
}
.btnGroup .eBtn img {
  height: 40px;
  margin-bottom: 5px;
}
.btnGroup .eBtn button {
  margin-bottom: 10px;
}
.dashboardCard .ant-card-body .ant-card-meta {
  border-bottom: 1px solid #e8e8e8 !important;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.dashboardCard .ant-col .ant-card {
  text-align: center;
  text-transform: uppercase;
  padding: 0px 12px 0px 0px;
  font-weight: 600;
  border: none;
}
.dashboardCard .ant-col .ant-card .title {
  height: 25px;
  font-size: 12px;
}
.dashboardCard .ant-col .ant-card .count {
  margin-top: 10px;
}
.dashboardCard .ant-col .ant-card .count p {
  font-size: 28px;
  width: 80px;
  margin: 0 auto;
  padding: 6px;
  border-radius: 20px;
  color: #1890ff;
  cursor: pointer;
}
.dashboardCard .universityCountBox {
  padding: 10px;
  height: 400px;
  overflow-y: scroll;
}
.dashboardCard .universityCountBox::-webkit-scrollbar {
  width: 3px;
}
.dashboardCard .universityCountBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.dashboardCard .universityCountBox::-webkit-scrollbar-thumb {
  background: #1890ff;
}
.dashboardCard .universityCountBox::-webkit-scrollbar-thumb:hover {
  background: #0872bc;
}
.ant-menu-submenu-title {
  font-size: 13px;
}
.ant-menu-submenu-title span {
  font-size: 13px !important;
}
.drawerFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 15px;
  background: #fff;
  text-align: left;
}
.drawerFooter.alignRight {
  text-align: right;
}
.drawerFooter .ml-auto i {
  margin-left: 5px !important;
}
.drawerFooter .ant-btn {
  margin-left: 10px;
  display: flex;
  justify-content: center;
}
.ql-container {
  height: 400px;
}
.paddingHz {
  padding: 10px 0;
}
.attachment-box {
  background-color: white;
}
.attachment-box a {
  color: rgba(0, 0, 0, 0.65);
}
.noteTitle {
  display: flex;
  justify-content: space-between;
}
.commentBox {
  margin-top: 10px;
  line-height: 1.5;
  font-size: 13px;
  background-color: white;
  padding: 10px;
}
.commentBox .avatar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
}
.commentBox .avatar i {
  margin: 0 !important;
}
.commentBox .userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1890ff;
}
.commentBox .userInfo .date {
  margin-left: 10px;
  font-size: 12px;
  color: #808080;
}
.commentBox .userInfo .date i {
  margin: 0 !important;
}
.commentBox .commentText {
  margin-top: 5px;
  font-size: 12px;
  margin-left: 15px;
}
.jcsb {
  display: flex;
  justify-content: space-between;
}
.jcc {
  display: flex;
  justify-content: center;
}
.jcic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customTimeLine {
  margin-left: 15%;
}
.customTimeLine .ant-timeline-item {
  min-height: 50px;
}
.customTimeLine .ant-timeline-item-head-custom {
  width: 180px;
}
.customTimeLine .ant-timeline-item-head-custom .date {
  position: absolute;
  top: 4px;
  text-align: right;
  color: #0872bc;
  font-weight: 500;
}
.customTimeLine .ant-timeline-item-head-custom .date span {
  font-size: 10px;
  color: #9d8e8e;
}
.ant-timeline-item-head-primary {
  color: #1890ff;
}
.customPanel {
  border: 1px solid #e8e8e8;
}
.customPanel .ant-collapse-header {
  background: white;
  font-size: 15px;
}
.pendencyList {
  border-bottom: 1px solid #dddddd54;
  padding: 7px 0;
}
.label {
  display: inline;
  padding: 5px;
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label.labelNew {
  display: block;
  cursor: pointer;
}
.label.round {
  border-radius: 50px;
  padding: 0.1em 0.6em 0.2em;
}
.label.sm {
  padding: 0.1em 0.6em 0.2em;
  font-weight: normal !important;
}
.label.xs {
  padding: 0.5px 8px;
  font-weight: normal !important;
  margin: 2px 0;
}
.label.xs1 {
  padding: 0 6px;
  font-weight: normal !important;
  margin: 2px 0;
  font-size: 11px;
}
.label-info {
  background-color: #5bc0de;
}
.label-default {
  background-color: #0f52ba;
}
.label-primary {
  background-color: #337ab7;
}
.label-success {
  background-color: #0872bc;
}
.label-success-green {
  background-color: #5cb85c;
}
.label-danger {
  background-color: #d9534f;
}
.label-warning {
  background-color: rgba(240, 173, 78, 0.87);
}
.label-black {
  background-color: black;
}
.label-tag {
  border: none;
  font-size: 12px !important;
  color: black;
  text-transform: capitalize;
}
.label-sm {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label-xs {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}
.pointerCls-pdf {
  width: 24.6% !important;
  display: inline-block;
}
.pointerCls {
  cursor: pointer;
}
.linkPointer {
  cursor: pointer;
  color: #1890ff;
  font-size: 13px;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: left;
}
.dotsReplace {
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  text-overflow: ellipsis;
  height: 30px;
}
.ant-btn-circle {
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 35px !important;
  min-width: 35px !important;
  background: none;
  border: none;
}
.ant-btn-circle.border {
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.bs_btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  -webkit-user-select: none;
  user-select: none;
  background-color: #efefef;
  color: #333;
  padding: 0.2rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: 35px;
  min-width: 35px;
}
.bs_btn.bs-sm {
  padding: 5px 10px;
}
.bs_btn.bs-xs {
  padding: 2px 5px;
  font-size: 11px;
  height: 25px;
}
.bs_btn.bs-xxs {
  padding: 1px 5px;
  font-size: 10px;
  height: 20px;
}
.bs_btn.bs-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.bs_btn.bs-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.bs_btn.bs-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.bs_btn.bs-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.bs_btn.bs-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.bs_btn.bs-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.bs_btn.bs-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}
.bs_btn.bs-link:hover {
  text-decoration: underline !important;
}
.bs-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
  font-size: 11px;
}
.bs-link:hover {
  text-decoration: underline !important;
}
.tableAction {
  width: 125px;
  display: flex;
  flex-wrap: wrap;
}
.tableAction button,
.tableAction a {
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 35px !important;
  min-width: 35px !important;
  background: none;
  border: none;
}
.appCommentDotsReplace {
  display: block;
  width: 150px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 35px;
}
.appTextReplace {
  display: block;
  white-space: pre-wrap;
  width: 100%;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 23px;
}
.newsContentDotsReplace {
  display: block;
  width: 350px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}
.replaceAgentDot {
  display: block;
  width: 100px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  height: 17px;
  overflow-x: hidden;
}
.colorPrimary {
  color: #1890ff;
}
.colorPrimaryDark {
  color: #0f52ba;
}
.colorSuccess {
  color: #5cb85c;
}
.label1 {
  display: block !important;
  white-space: normal !important;
  font-size: 12px;
}
.linkAA {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}
.student-tab {
  border-right: none;
}
@media only screen and (max-width: 768px) {
  .applicationProfile {
    overflow: scroll;
  }
  .applicationProfile .appProfile .align-self-stretch {
    flex-direction: column;
  }
  .applicationProfile .profile-3-column.profile-3-col-sidebar.profile-3-col-sidebar--left {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .applicationProfile .profile-3-column.profile-3-col-sidebar--right-wrapper {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .applicationProfile .profile-6-column.noteBlock {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
.tuitionBox {
  font-size: 15px;
  color: black;
}
.ant-calendar-picker,
.ant-time-picker {
  width: 100%;
}
.ant-btn {
  line-height: 1.5;
  padding: 0.225rem 0.75rem !important;
  height: auto !important;
}
.ant-btn[type='submit'] {
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  width: auto;
  height: auto !important;
}
table {
  border: none;
}
table tbody th {
  font-size: 13px;
  border-top: 2px solid #dee2e6 !important;
  background: #fafafa;
  border-bottom: 1px solid #dee2e6 !important;
}
table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
table tbody tr:nth-of-type(even) {
  background: #fafafa;
}
table tbody tr td {
  padding: 8px 10px !important;
  font-size: 12px;
  font-weight: 500;
  color: #212529 !important;
}
table td.borderNone {
  border: none;
}
.ant-table-thead {
  background: #fafafa;
}
.ant-table-thead tr th {
  padding: 8px 10px !important;
  border: none !important;
  color: #212529 !important;
}
.ant-table-small {
  border: none;
}
.ant-table-content {
  border-right: none !important;
}
.antd-pro-components-global-header-index-header {
  padding: 15px 25px !important;
  height: auto;
}
.antd-pro-components-sider-menu-index-logo {
  text-align: center;
  height: 81px;
  background: white;
  padding: 0;
  border-bottom: 3px solid #ededed !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.antd-pro-components-sider-menu-index-logo img {
  width: 65%;
  height: auto;
}
.antd-pro-components-sider-menu-index-sider {
  box-shadow: none;
  border-right: 3px solid #ededed !important;
}
.ant-layout-header {
  height: auto;
}
.ant-layout-header .antd-pro-components-global-header-index-header {
  padding: 13px 25px !important;
  height: 83px;
  box-shadow: none;
  border-bottom: 3px solid #ededed !important;
}
.sidebar {
  height: auto;
  overflow-y: initial;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  overflow-x: hidden;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  height: 100%;
}
.antd-pro-components-global-header-index-right {
  display: flex;
  flex-direction: row;
}
.antd-pro-components-global-header-index-action {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.antd-pro-components-global-header-index-action .antd-pro-components-global-header-index-name {
  line-height: 1;
  margin-top: 10px;
}
.admin-box .content h5 {
  font-size: 15px;
  white-space: nowrap;
  font-weight: 600;
  color: #1a5abd;
  margin: 0;
}
form.input-group-box {
  width: 46%;
  float: left;
  margin-top: 10px;
}
.pd-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.appAction {
  float: right;
  margin-bottom: 10px;
}
.top_P10 {
  padding-top: 10px !important;
}
.sort-box-table {
  padding-right: 20px;
}
.btnBorder {
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-box-table-width {
  width: 34%;
  margin-right: auto;
}
.round-input .ant-select-selection--single {
  font-size: 14px;
  border-radius: 50px;
  padding-left: 10px;
}
.card-body table td span.badge {
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  background: #1053ba;
  border-radius: 4px;
  color: #fff;
}
.ant-form-item label {
  color: rgba(0, 0, 0, 0.65);
  float: left;
  margin: 0 15px 0;
}
.form-control {
  height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
.PhoneInputCountrySelect {
  padding: 10px !important;
}
.PhoneInput {
  border: 1px solid #666 !important;
  height: 42px !important;
  color: #666;
  border-radius: 6px;
  padding-right: 3px;
}
.PhoneInput .PhoneInputCountry {
  padding: 10px;
  border-right: 1px solid #666 !important;
}
.PhoneInput .PhoneInputInput {
  height: 40px !important;
  color: #666;
  padding-left: 10px;
  border: none;
}
.PhoneInput .PhoneInputInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1;
  /* Firefox */
}
.PhoneInput .PhoneInputInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999 !important;
}
.PhoneInput .PhoneInputInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999 !important;
}
.ant-select-selection--single {
  height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
.ant-select-selection--multiple {
  min-height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  min-height: 40px !important;
}
.ant-select-selection--multiple .ant-select ul,
.ant-select-selection--multiple .ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 5px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
}
.ant-form-item-control {
  line-height: 30px;
}
.ant-select-selection__rendered {
  line-height: 40px !important;
}
.ant-select-arrow {
  -webkit-user-select: none;
          user-select: none;
  background: #666666;
  padding: 5px;
  color: white;
  font-weight: 600;
  margin-top: -12px;
  border-radius: 5px;
}
.ant-select-focused .ant-select-arrow {
  background: #1254bb !important;
}
.ant-input-number {
  height: 42px;
}
.heading-form.stuHeader span.img img {
  height: 18px;
  margin-right: 3px;
  position: relative;
  top: -1px;
}
.heading-form a.btn {
  box-shadow: 0 0 12px 0px #00000038 !important;
  border-radius: 0;
  margin: 0 40px;
  font-size: 13px;
  text-transform: capitalize;
  min-width: 120px;
}
.pa-0 {
  padding: 0 !important;
}
.antd-pro-layouts-basic-layout-content {
  padding: 20px 15px;
  margin: 0 !important;
}
.ant-select-selection-selected-value {
  margin-right: 20px;
}
.ant-calendar-picker-input.ant-input,
.ant-time-picker-input {
  height: 40px !important;
}
.ant-calendar {
  width: auto;
}
.stuIcon {
  font-size: 20px;
  color: #1890ff;
}
ul.pointerUl li {
  cursor: pointer;
}
.ant-menu-dark {
  background: white;
}
.ant-menu-dark svg {
  display: none;
}
.ant-menu-dark .ant-menu-item {
  font-size: 13px !important;
}
.ant-menu-dark .ant-menu-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: black !important;
}
.ant-menu-dark .ant-menu-item:hover div > span {
  color: black !important;
}
.ant-menu-dark .ant-menu-sub {
  background: white !important;
}
.ant-menu-dark li:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #343a40 !important;
}
.ant-menu-dark li:hover span,
.ant-menu-dark li:hover a {
  color: #343a40 !important;
}
.ant-menu-dark li a {
  color: #343a40 !important;
}
.ant-menu-dark li a :hover {
  color: #343a40 !important;
}
.ant-menu-dark li span {
  color: #343a40 !important;
}
.ant-menu-dark li span :hover {
  color: #343a40 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: none;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item {
  height: 40px;
  line-height: 40px;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #343a40 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover a:hover {
  color: #343a40 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover span:hover {
  color: #343a40 !important;
}
li.ant-menu-item.ant-menu-item-selected {
  background: #fff !important;
  color: #343a40 !important;
  box-shadow: 0 0 12px 0px #00000038 !important;
  border-right: 5px solid #1254bb !important;
}
li.ant-menu-item.ant-menu-item-selected a {
  color: #343a40 !important;
}
li.ant-menu-item.ant-menu-item-selected a span {
  color: #343a40 !important;
}
i.ant-menu-submenu-arrow {
  color: #343a40 !important;
}
i.ant-menu-submenu-arrow::before {
  background: #343a40 !important;
}
i.ant-menu-submenu-arrow::after {
  background: #343a40 !important;
}
.nav-item {
  color: #343a40 !important;
}
.nav-item a {
  color: #343a40 !important;
}
.imgDiv {
  display: inherit;
  width: 75%;
}
.imgDiv .logo-link {
  width: 350px;
  box-shadow: 3px 0 0 #ededed;
}
.imgDiv .logoImg {
  line-height: 0.8;
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  margin-top: -3px;
  width: auto;
  box-shadow: none !important;
  opacity: 1 !important;
  border-radius: 0;
  max-height: 45px !important;
}
.student-dashboard {
  width: calc(100% - 352px);
}
.profile-main {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-bottom: 50px;
}
.profile-main::-webkit-scrollbar {
  display: none;
}
.search-profile-main {
  height: 84vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.search-profile-main::-webkit-scrollbar {
  display: none;
}
.attachment-box {
  height: 100vh;
  overflow: scroll;
  /*  &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: @primary;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: @success;
      }
  */
  padding-bottom: 50px;
}
.scrollView {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-bottom: 50px;
}
.scrollView::-webkit-scrollbar {
  display: none;
}
.mobileMenu {
  display: none;
}
.infoBox {
  width: 350px;
}
.courseBox {
  min-height: 80px;
}
.courseFeeInfo {
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 5px;
}
.selectStudentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}
.selectStudentBox .filter-box button.apply {
  font-size: 11px;
}
.selectStudentBox .selectStudentBtn {
  display: flex;
  align-items: center;
  background: #0f52ba;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 50px;
  min-width: 100px;
  font-size: 12px;
}
.selectStudentBox .selectStudentBtn i {
  margin-right: 5px;
}
.detail-list li {
  color: #212529;
}
.ant-pagination-options-size-changer .ant-select-selection--single {
  height: 33px !important;
  border: 1px solid #ddd;
}
.ant-pagination-options-size-changer .ant-select-selection--single::after {
  content: '' !important;
}
.ant-pagination-options-size-changer .ant-select-selection--single::before {
  content: '' !important;
}
.ant-btn[type='submit'] {
  width: auto;
  height: auto !important;
}
.custom-sort-box-table {
  width: 34% !important;
}
.sort-box-table-right {
  text-align: right;
}
.btn img {
  height: 17px;
}
.btn img.plus {
  height: 15px;
  margin-right: 5px;
}
.btn.round {
  margin-right: auto;
  display: flex;
  border-radius: 50px;
  padding-left: 50px;
  position: relative;
}
.btn.roundNew {
  margin-right: auto;
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 15px;
  text-decoration: none !important;
  vertical-align: middle;
}
.btn.roundNew img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
.btn .anticon {
  margin-right: 5px;
}
.btn.ac {
  display: flex;
  align-items: center;
}
.btn.roundNew2 {
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 10px;
  float: right;
  margin-right: 10px;
}
.btn.roundNew2 img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
.btn.round-bt {
  margin-right: 5px;
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 15px;
}
.btn.round-bt img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
.btn.lg {
  padding: 8px 30px !important;
}
.btn.md {
  padding: 5px 17px !important;
  font-size: 0.87rem;
}
.btn.sm {
  padding: 3px 15px !important;
  font-size: 0.8rem;
}
.btn.xs {
  padding: 2px 10px !important;
  font-size: 0.7rem;
}
.btn.xss {
  padding: 2px 5px !important;
  font-size: 0.65rem;
}
.btn.p0 {
  padding: 0 !important;
}
.search-box-table.round {
  margin-right: auto;
  display: flex;
  border-radius: 50px;
  padding-left: 50px;
  position: relative;
  background: #fff;
}
.search-box-table.round.m0 {
  margin: 0 !important;
}
.search-box-table.round img {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.search-box-table.round input {
  border: none !important;
  border-radius: 50px !important;
  height: auto;
  padding: 8px 15px;
  padding-left: 0;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.search-box-table.ml0 {
  margin-left: 0 !important;
}
td span.badge {
  padding: 5px 7px !important;
  cursor: pointer;
}
select {
  background: white;
}
.custom-sort-box-new {
  padding-right: 0 !important;
  margin-bottom: 10px;
}
.custom-sort-box-new select {
  margin-right: auto;
  display: flex;
  position: relative;
  background: #fff;
  border: none !important;
  border-radius: 50px !important;
  padding: 8px 15px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 42px;
}
.custom-sort-box-new select ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057 !important;
  opacity: 1;
  /* Firefox */
}
.custom-sort-box-new select :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495057 !important;
}
.custom-sort-box-new select ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #495057 !important;
}
.custom-sort-box-new select:visited,
.custom-sort-box-new select:active,
.custom-sort-box-new select:focus {
  border: none !important;
  outline: none !important;
}
table td button.btn,
table td a.btn {
  padding: 0 4px !important;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057 !important;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495057 !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #495057 !important;
}
.courseList-box {
  margin-top: 43px;
}
.unizportal .ant-col-8 .inputBox {
  margin-top: 0;
  position: relative;
}
::-webkit-scrollbar {
  width: 7px;
  height: 4px;
  border-radius: 10px !important;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px #acacac;
}
::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #9b9b9b;
  border-radius: 10px !important;
}
.chooseCountryBox {
  position: absolute;
  z-index: 9;
  top: 35px;
  background: #fff;
}
.chooseCountryBox label {
  margin: 0 !important;
  border-bottom: 1px solid #ddd;
}
.selectedCountryLabel .icon {
  position: absolute;
  right: 10px;
  top: 12px;
}
.profile-activity.application-activity ul li .date {
  width: 15%;
}
.ant-menu-dark .ant-menu-sub {
  background: #dddddd42 !important;
}
.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px !important;
}
.font14 {
  font-size: 13px;
}
.font14_1 {
  font-size: 14px;
}
.font15 {
  font-size: 15px;
}
.actionBtnGroup .viewBtn {
  height: 20px;
}
.actionBtnGroup .crossBtn {
  height: 10px;
}
.antd-pro-components-sider-menu-index-sider {
  box-shadow: none;
  border-right: 3px solid #ededed !important;
}
.ant-select-selection__rendered {
  content: '' !important;
}
.ant-select-selection__clear {
  top: 42%;
  right: 15px;
}
.ant-select-selection__clear .ant-select-clear-icon {
  height: 20px;
  width: 20px;
}
.ant-select-selection__clear .ant-select-clear-icon svg {
  height: 20px;
  width: 20px;
}
.ant-select-enabled.ant-select-allow-clear .ant-select-selection--single:hover .ant-select-arrow {
  display: none;
}
.PrivacyPolicyPage {
  font-size: 12px;
  padding: 50px;
}
.PrivacyPolicyPage h4 {
  text-align: left;
  font-size: 18px;
  text-decoration: underline;
}
.PrivacyPolicyPage p {
  text-align: left;
  line-height: 1.8;
  color: #6c4a4a;
  margin-bottom: 20px;
}
.linkUl {
  float: right;
  color: #6c4a4a;
}
.linkUl a {
  text-decoration: underline !important;
}
.ant-drawer-close {
  width: 30px;
  height: 30px;
  right: 20px !important;
  line-height: 2;
  top: 1px;
}
.ant-drawer-close:focus {
  outline: none;
}
.roundBtn {
  line-height: 1.5;
  padding: 10px 15px !important;
  border-radius: 50px !important;
  height: 42px !important;
  margin-right: 5px;
  border-color: #efe8e8;
}
.roundBtn.icon {
  height: 40px !important;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.status.status1 p {
  min-height: 22px;
  height: auto !important;
}
.ant-btn-success {
  width: 150px;
  background: #1556bc !important;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff !important;
  margin: 25px 0 0;
  border-radius: 50px !important;
  font-weight: 600;
  height: 42px !important;
}
.ant-btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.ant-btn-warning {
  color: #fff !important;
  background-color: rgba(240, 173, 78, 0.87) !important;
  border-color: #eea236 !important;
}
.ant-btn-cancel {
  background: #999;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 50px;
  min-width: 100px;
  margin-right: 15px;
  height: 42px !important;
}
.ant-table-scroll .ant-table-body::-webkit-scrollbar {
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
.btn-success-green {
  border: 1px solid #5cb85c !important;
  background-color: #5cb85c !important;
  color: white !important;
}
.moreBtn {
  text-decoration: underline;
  color: #5f5f5f;
  margin-left: 20px;
  font-size: 10px;
}
.moreBtn:hover {
  color: #5f5f5f;
}
.guestEmailBox {
  background: white !important;
  padding: 5px 5px;
  margin: 2px;
  display: flex;
  border: 1px solid #ddddff;
  border-radius: 5px;
  min-height: 40px;
}
.guestEmailBox ul {
  padding-left: 0;
  margin-bottom: 0;
}
.guestEmailBox li.emailTag {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 2px 5px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  margin-bottom: 2px;
}
.guestEmailBox .emailIcon {
  font-size: 12px;
  position: relative;
  top: -2px;
  margin-left: 5px;
}
#loginForm.login-main {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
}
@media screen and (max-width: 560px) {
  .mobileMenu {
    display: block;
    position: absolute;
    top: 0;
  }
  .antd-pro-layouts-basic-layout-content {
    margin: 0;
  }
  .infoBox {
    width: 100%;
  }
  .applicationProfile aside {
    display: block;
  }
  .imgDiv .logo-link {
    display: none;
  }
  .imgDiv .logoImg {
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: -3px;
    width: auto;
    box-shadow: none !important;
    opacity: 1 !important;
    border-radius: 0;
    max-height: 45px !important;
  }
  .student-dashboard {
    width: 100% !important;
  }
  .profile-main,
  .search-profile-main,
  .attachment-box,
  .scrollView {
    height: 100%;
  }
}
@media screen and (min-width: 769px) {
  .applicationProfile .ant-layout-header .antd-pro-components-global-header-index-header {
    padding: 0 !important;
    height: 90px;
  }
  .applicationProfile .imgDiv {
    height: 90px;
  }
  .applicationProfile .imgDiv .logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .applicationProfile form.input-group-box {
    margin-top: 0;
    display: flex;
    align-items: center;
    padding-left: 70px;
  }
}
.login-box .d-flex button.btn {
  height: 42px !important;
}
.card-pane-right.listing-section ul.count {
  justify-content: start !important;
}
.card-pane-right.card-pane-new .pointerCls .linkText {
  font-size: 18px;
}
.card-pane-right.card-pane-new .pointerCls .statusLabel {
  color: black;
  font-size: 15px;
}
.mark-btn.mark-btn30 {
  width: 35% !important;
}
.mark-btn.mark-btn30 div {
  display: flex;
}
.mark-btn.mark-btn30 div a {
  margin: 0 5px;
}
.mark-btn.mark-btn40 {
  width: 50% !important;
  position: absolute;
  right: 0;
}
.mark-btn.mark-btn40 div {
  display: flex;
}
.mark-btn.mark-btn40 div a {
  margin: 0 5px;
}
.mark-btn.mark-btn70 {
  width: 70% !important;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
.mark-btn.mark-btn70 div {
  display: flex;
}
.mark-btn.mark-btn70 div a {
  margin: 0 5px;
}
.selectedUni {
  margin-right: 10px;
  background: #666666;
  padding: 2px 10px;
  border-radius: 5px;
  color: white !important;
  font-size: 12px;
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 450px !important;
  overflow-x: hidden;
  border: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}
.uniBox .col-lg-4 {
  margin-bottom: 20px;
}
.uniBox .col-lg-4 .logo img {
  height: 67px;
}
.featureCourseDiv .heading-form {
  position: fixed !important;
  top: 0;
  z-index: 9;
  background: white;
  width: 100%;
  padding: 10px 0;
}
.featureCourseDiv .cardRelative {
  position: relative;
  top: 70px;
  background: white !important;
  padding-bottom: 0 !important;
}
.featureCourseDiv .courseBox .col-lg-4 {
  background: #f1f1f1 !important;
  padding: 20px;
}
.featureCourseDiv .courseBox .newRow {
  margin-bottom: 20px;
}
.featureCourseDiv .courseBox .infoRow {
  padding-left: 20px;
}
.featureCourseDiv .courseBox .infoRow p {
  margin-bottom: 0;
}
.featureCourseDiv .logo {
  padding: 10px;
}
.featureCourseDiv .logo img {
  height: 60px;
}
.featureCourseDiv .nameBox {
  height: 70px;
  font-weight: bold;
  color: #0f52ba;
}
.featureCourseDiv .infoBox {
  /* width: 500px;
       display: flex;
       justify-content: space-between;
       align-items: center;*/
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 44%;
}
.featureCourseDiv .infoBox .totalInfo {
  display: flex;
  padding-left: 27px;
}
.featureCourseDiv .infoBox .totalInfo .totalCourseCount {
  margin-right: 50px;
  top: 4px;
}
.featureCourseDiv .infoBox .featureCourseHeader {
  padding: 0 25px;
}
.featureCourseDiv .infoBox .featureCourseHeader h5 {
  padding: 0;
}
.featureUniversityDiv .heading-form {
  padding: 20px 0 0 !important;
}
.featureUniversityDiv .ml-auto {
  display: flex;
  justify-content: center;
  align-items: center;
}
.featureUniversityDiv .ml-auto i {
  margin-left: 5px !important;
}
.featureUniversityDiv .cardRelative {
  position: relative;
  background: white !important;
}
.featureUniversityDiv .cardRelative.pb-0 {
  padding-bottom: 0 !important;
}
.featureUniversityDiv .courseBox .col-lg-4 {
  background: #f1f1f1 !important;
  padding: 20px;
}
.featureUniversityDiv .courseBox .newRow {
  margin-bottom: 20px;
}
.featureUniversityDiv .courseBox .infoRow {
  padding-left: 20px;
}
.featureUniversityDiv .courseBox .infoRow p {
  margin-bottom: 0;
}
.featureUniversityDiv .logo img {
  height: 80px;
}
.featureUniversityDiv .nameBox {
  height: 80px;
  font-weight: bold;
  color: #1890ff;
}
.featureUniversityDiv .infoBox {
  align-self: flex-end !important;
  margin-bottom: 10px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}
.loadMoreDiv {
  text-align: center;
  text-transform: uppercase;
}
.loadMoreDiv a {
  background: #f1f1f1;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
}
.loadMoreDiv a i {
  position: relative;
  bottom: 2px;
  margin-left: 5px !important;
}
.social.social1 {
  padding: 0 !important;
}
.social.social1 h6 {
  color: white;
}
.navbar-dark {
  background-color: transparent;
  border-color: #4b545c;
}
.searchAppDiv .loadMoreBox {
  text-align: right;
  text-transform: uppercase;
}
.searchNewBtn {
  background: #0f52ba !important;
  border-radius: 50px !important;
  color: #fff !important;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex !important;
}
.searchNewBtn:hover {
  opacity: 0.7;
  color: white;
}
.clearNewBtn {
  background: #efefef !important;
  border-radius: 50px !important;
  color: black !important;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex !important;
}
.clearNewBtn:hover {
  opacity: 0.7;
  color: white;
}
.btn.apply:hover {
  opacity: 0.7;
}
.invalidValueField .form-control {
  border: 2px solid #ff0000;
}
.requirementNote {
  font-size: 12px;
  color: black;
  padding-left: 10px;
}
.frontFooter .social {
  padding: 0 !important;
}
.frontFooter .social h6 {
  color: white !important;
}
.frontFooter .white {
  color: white !important;
}
.all-student-search h5 {
  width: 20% !important;
}
.all-student-search h5.allAppCountry {
  width: 70% !important;
}
.all-student-search .search-box-table {
  width: 30%;
}
.all-student-search .search-box-table.round {
  margin-left: 10px !important;
  position: relative;
}
.all-student-search .search-box-table.round a.searchBtn1 {
  position: absolute;
  right: 0;
  top: 1px;
  background: #0872bc;
  color: white;
  padding: 7px 5px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 13px;
}
.all-student-search .search-box-table.ml0 {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
.all-student-search .form-control {
  height: 35px !important;
}
.search-box-table.round {
  margin-left: 10px !important;
  position: relative;
}
.search-box-table.round a.searchBtn1 {
  position: absolute;
  right: 0;
  top: 0px;
  background: #0872bc;
  color: white;
  padding: 11.5px 8px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 13px;
}
.search-box-table.ml0 {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
header.navbar-fix nav.newNav a:first-child {
  color: black !important;
  background: none !important;
  border: none;
  margin-right: 15px;
  box-shadow: none;
  text-decoration: underline;
  font-size: 15px !important;
}
header.navbar-fix nav.newNav a:last-child {
  color: #fff !important;
  background: #0f52ba;
  border: none;
  margin-right: 15px;
  box-shadow: 2px 2px 4px #00000057;
}
@media screen and (max-width: 560px) {
  .table-head .search-box-table {
    display: none !important;
  }
  .all-student-search h5 {
    width: 60%;
  }
  .containerStu {
    margin-top: 20% !important;
  }
}
.recharts-legend-wrapper {
  position: relative !important;
  bottom: 35px !important;
}
.amt {
  font-style: normal;
  color: black;
  font-size: 13px;
  font-weight: 600;
}
.ant-scroll-number {
  position: absolute;
  top: 11px;
  right: 6px;
}
.rowWrap {
  display: flex;
  flex-wrap: wrap;
}
.custom-tooltip {
  background: #ffffff6e;
  padding: 10px 10px 2px 10px;
  line-height: 1.3;
  font-size: 13px;
}
.custom-tooltip .label {
  color: black;
  font-size: 13px;
  padding: 0;
}
.recharts-legend-wrapper {
  margin-top: 20px;
}
.barChart .recharts-legend-wrapper {
  display: none;
}
.barLabel_ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.barLabel_ul.btn_option {
  display: flex;
  justify-content: space-between;
}
.barLabel_ul li {
  display: inline-flex;
  margin-right: 10px;
  align-items: center;
  font-size: 14px;
}
.barLabel_ul li .colorBox {
  height: 12px;
  width: 12px;
  margin-right: 4px;
}
.barLabel_ul li.lg .colorBox {
  height: 25px;
  width: 40px;
  margin-right: 4px;
}
.newsRowBox {
  display: flex;
  cursor: pointer;
  margin-right: 20px !important;
}
.newsRowBox i {
  margin-right: 5px;
  font-weight: bold;
  margin-top: 2px;
  font-size: 15px;
  color: #1890ff;
}
.sideMenuSoical li {
  margin-right: 10px !important;
}
.sideMenuSoical img {
  height: 32px;
}
.tableBox {
  margin-bottom: 20px;
}
.tableBox.striped .tableRow:nth-child(odd) {
  background-color: #f9f9f9;
}
.tableBox .tableRow {
  padding: 4px 10px;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.tableBox .tableRow .title {
  font-weight: bold;
  width: 35%;
  height: 18px;
}
.tableBox .tableRow .title1 {
  font-weight: bold;
  width: 40%;
}
.tableBox .tableRow p {
  margin-bottom: 0;
}
.tableBox .tableRow:first-child {
  border-top: 1px solid #ddd;
}
.circleBtn {
  background: #0872bc;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.justifyContent {
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
}
.meetingInfo {
  line-height: 1.8;
}
.login-form label {
  display: none !important;
}
.login-form .form-control {
  border: none !important;
}
.login-form input[type='text'].form-control {
  background: #e6e6e6 !important;
}
.login-form input[type='file'].form-control {
  background: #e6e6e6 !important;
  padding: 0;
}
.login-form .documents .inputBox {
  margin-bottom: 0;
}
.login-form .documents .inputBox .labelNew {
  height: 20px;
}
.login-form .documents .inputBox input[type='file'].form-control {
  height: 35px !important;
}
.login-form input.ant-calendar-picker-input.ant-input {
  height: 55px !important;
}
.login-form .form-control {
  height: 55px !important;
}
.login-form .ant-select-selection__placeholder,
.login-form .ant-select-search__field__placeholder {
  color: #666;
  font-size: 14px;
}
.login-form .ant-select-selection {
  background: #e6e6e6;
  border: none;
  width: 100%;
  height: 55px !important;
  padding: 0 23px;
  outline: none;
  border-radius: 6px;
  padding-right: 75px;
  font-size: 14px;
}
.login-form .btnSub {
  background: #0f52ba;
  color: #fff;
  height: 42px !important;
  min-width: 120px;
  border-radius: 50px;
}
.btn-secondary.dropdown-toggle {
  background: #0f52ba !important;
  border-radius: 50px;
  width: 150px;
  margin-right: 10px;
}
header.navbar-fix nav a:first-child.regLink {
  color: #666 !important;
}
header.navbar-fix nav a.regLink {
  color: #666 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-align: left;
  justify-content: flex-start;
}
.dropbtn {
  background: #0f52ba !important;
  border-radius: 50px;
  width: 150px;
  margin-right: 10px;
  color: #fff;
  height: 37px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem !important;
}
.dropbtn i {
  margin-left: 5px;
}
.dropbtn.lite {
  background: transparent !important;
  border: 1px solid #c1c1c1;
  color: black;
}
.dropbtn:focus {
  outline: none !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 10px;
  border-radius: 10px;
}
.dropdown-content a.regLink {
  margin-right: 0 !important;
  border-radius: 0;
}
.dropdown-content a.regLink:hover {
  background: #e5dddd !important;
}
.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.studentRegForm {
  padding: 20px 20px;
  margin-top: 50px;
  border-radius: 5px;
  background: #fffdfd;
}
.studentRegForm label {
  display: block !important;
  padding: 0 !important;
  margin: 0;
}
.studentRegForm .ant-form-item-label {
  line-height: 28.9999px;
}
.studentRegForm .labelNew {
  display: block !important;
  color: #666;
  margin: 0 0 10px 0 !important;
}
.studentRegForm input[type='text'],
.studentRegForm input[type='text'].form-control,
.studentRegForm input[type='password'],
.studentRegForm input[type='email'],
.studentRegForm input[type='number'],
.studentRegForm input[type='textarea'],
.studentRegForm .ant-select-selection {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.studentRegForm input[type='text'].ant-select-focused,
.studentRegForm input[type='text'].form-control.ant-select-focused,
.studentRegForm input[type='password'].ant-select-focused,
.studentRegForm input[type='email'].ant-select-focused,
.studentRegForm input[type='number'].ant-select-focused,
.studentRegForm input[type='textarea'].ant-select-focused,
.studentRegForm .ant-select-selection.ant-select-focused {
  background: #ffffff !important;
}
.studentRegForm .ant-select-search__field {
  background: transparent !important;
  padding: 0 !important;
}
.studentRegForm .ant-calendar-picker {
  padding: 0 !important;
}
.studentRegForm input.ant-calendar-picker-input.ant-input {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.studentRegForm input[type='file'] {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.studentRegForm input[type='file']::-webkit-file-upload-button {
  width: 120px;
}
.studentRegForm input[type='file'].form-control {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.containerStu {
  max-width: 100%;
  padding: 0 calc((100% - 1140px) / 2);
  overflow-y: scroll;
  margin-top: 5%;
  z-index: 99999;
  overflow-x: hidden;
}
.containerStu::-webkit-scrollbar {
  display: none;
}
.containerStu .inner-container {
  height: 80vh;
}
.login-form label.authCheckbox {
  display: block !important;
}
.hyperLink {
  color: #00e !important;
}
.hyperLinkUnderLine {
  color: #00e !important;
  text-decoration: underline;
}
.disbaledField {
  display: flex;
  width: 100%;
  padding: 0 11px;
  text-align: left;
  border: 1px solid #666;
  border-radius: 5px;
  outline: 0;
  background: #ededed;
}
.sliderBox {
  margin: 0 15px;
}
.sliderBox .sliderImg {
  height: 65px;
  width: 100%;
}
.dotNone {
  background: none !important;
}
.owl-theme .owl-dots {
  margin-top: 5px;
}
.owl-theme .owl-dots .owl-dot span {
  background-color: #666 !important;
  height: 7px !important;
  width: 7px !important;
}
.owl-theme .owl-dots .owl-dot.active span {
  background-color: #0872bc !important;
}
.owl-theme .owl-nav {
  display: none !important;
}
.owl-theme.owl-new .owl-nav {
  display: block !important;
}
.owl-theme.owl-new .owl-nav .owl-prev {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #f1f1f1;
  line-height: 0;
  font-weight: 500;
  left: -14px;
}
.owl-theme.owl-new .owl-nav .owl-next {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #f1f1f1;
  line-height: 0;
  font-weight: 500;
  left: 97%;
}
.main-footer {
  border-bottom: none !important;
}
.ant-time-picker,
.ant-calendar-picker {
  padding: 0 !important;
  border-color: #666 !important;
  box-shadow: none !important;
}
.form-control {
  border-color: #666 !important;
}
.ant-slider-mark {
  width: 82%;
  left: 9%;
}
.custom-slider .ant-slider-mark {
  width: 102%;
  left: 0%;
}
.statusRow .statusColumn {
  margin-bottom: 10px;
  text-align: center;
}
.statusRow .statusColumn .ant-card-body {
  text-align: center;
  height: 120px;
}
.statusRow .box {
  text-align: center;
}
.statusRow .box.active {
  color: white;
  background-color: #0872bc;
}
.statusRow .box img {
  height: 20px;
  margin-bottom: 5px;
}
.statusRow .status {
  font-size: 12px;
}
.statusRow .title {
  font-size: 18px;
  font-weight: bold;
}
.statusRow .iconDiv {
  font-size: 28px;
  padding: 1px;
}
.newsImg {
  margin-top: 20px;
}
.newsImg img {
  width: 80%;
  margin-left: 10%;
}
.roundGoBackBtn {
  line-height: 1.5;
  padding: 10px 15px !important;
  border-radius: 50px !important;
  border-color: #efe8e8;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px !important;
  background-color: white;
}
.roundGoBackBtn.icon {
  width: 36px;
}
.thinLabel {
  font-weight: normal !important;
}
.flairUniversityBlockOuter .flairUniversityBlock .innerBox {
  padding: 15px 40px;
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
  min-height: 250px !important;
}
.flairUniversityBlockOuter .flairUniversityBlock .imgBlock {
  background-color: white;
  padding: 10px 20px;
  border-radius: 3px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flairUniversityBlockOuter .flairUniversityBlock .imgBlock img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.flairUniversityBlockOuter .flairUniversityBlock .applyBtn {
  background-color: white;
  padding: 6px 15px;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  border: none;
  border-radius: 3px;
}
.flairUniversityBlockOuter .flairUniversityBlock .applyBtn.dark {
  background-color: #0872bc;
  color: white;
}
.flairUniversityBlockOuter .slick-dots {
  position: absolute;
  bottom: 0px !important;
}
.flairUniversityBlockOuter .slick-dots li {
  border-bottom: none;
  background: none !important;
}
.flairUniversityBlockOuter .slick-dots li button {
  background: #0872bc !important;
  height: 5px !important;
}
.flairUniversityBlockOuter .slick-arrow.slick-prev {
  font-size: 10px;
}
.flairUniversityBlockOuter .ant-carousel .slick-prev::before {
  content: '<';
  z-index: 1000 !important;
  display: block;
  position: relative;
  bottom: 10px;
  right: -38px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.flairUniversityBlockOuter .slick-arrow.slick-next {
  font-size: 10px;
}
.flairUniversityBlockOuter .ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 0px;
  left: -39px;
  bottom: 10px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.countList {
  list-style: decimal;
  padding-left: 12px;
}
.labelDoc {
  margin-top: -2px;
  margin-bottom: 0;
}
.addStuInfo {
  width: 100%;
}
.addStuInfo .ant-row.row1 {
  width: 100%;
  display: flex;
  align-items: center;
}
.ant-drawer-body {
  overflow: hidden;
}
.ant-input-number-disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}
.label_sm {
  padding-left: 10px;
  font-size: 13px;
  font-weight: normal !important;
  margin-bottom: 0 !important;
}
.antSelect.ant-select .ant-select-selection-selected-value {
  margin-right: 10px !important;
}
.antSelect.ant-select .ant-select-selection {
  border: none !important;
  border-radius: 50px !important;
  font-size: 13px !important;
}
.antSelect.ant-select .ant-select-selection .ant-select-selection__placeholder {
  display: block;
  color: #495057 !important;
}
.antSelect.ant-select .ant-select-selection .ant-select-arrow {
  background: none !important;
  color: #495057 !important;
}
.smSelect .ant-select-selection {
  height: 25px !important;
  border-radius: 0;
}
.smSelect .ant-select-selection .ant-select-selection__rendered {
  line-height: 1 !important;
  margin-left: 5px !important;
}
.smSelect .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  margin-right: 5px !important;
}
.smSelect .ant-select-selection .ant-select-arrow {
  background: none !important;
  color: #495057 !important;
  border: none;
  position: absolute;
  right: 0;
  top: 8px;
}
.fcmtDiv {
  text-align: center;
}
.fcmtDiv img {
  height: 40px;
}
.fcmtDiv img.sm {
  height: 32px;
}
.infoUni {
  background-color: white;
  font-size: 15px;
  border-radius: 5px;
  line-height: 2;
  color: black;
}
.infoUni .title {
  width: 18% !important;
  font-size: 13px;
  font-weight: normal !important;
  border-right: 1px solid #ddd;
  margin-right: 10px;
}
.infoUni span {
  color: #0872bc;
  font-size: 13px;
}
.darkBtn {
  background: transparent !important;
  border: 1px solid #c1c1c1 !important;
  display: inline-block;
  border-radius: 50px;
  min-width: 110px;
  color: #000 !important;
  height: 38px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
}
.darkBtn span {
  color: #343a40 !important;
}
.sprBox {
  margin-top: 7px;
  padding-top: 7px;
}
.customAutoComplete ul {
  width: 100%;
}
.customAutoComplete .ant-select-search__field {
  height: 43px !important;
  border-color: #666 !important;
}
.pointerCls {
  height: 40px;
  padding-top: 3px;
}
.pCursor {
  cursor: pointer;
}
.CountCircle {
  font-size: 14px !important;
  height: 25px;
  width: 25px;
  border-radius: 5%;
  line-height: 2.5;
  background: white !important;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 2px #1890ff !important;
}
.CountCircle a {
  align-self: center;
  font-size: 12px !important;
  color: black !important;
}
.pointerCls1 {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding-top: 0 !important;
}
.pointerCls1 h5 {
  font-size: 14px !important;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
}
input[type='text']::placeholder,
input[type='password']::placeholder,
input[type='search']::placeholder,
input[type='number']::placeholder,
input[type='email']::placeholder,
input[type='file']::placeholder,
input[type='date']::placeholder,
textarea::placeholder,
.ant-select-selection::placeholder,
.ant-calendar-picker-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  font-size: 14px !important;
  opacity: 0.8 !important;
  /* Firefox */
}
.ant-select-selection__placeholder {
  color: #999 !important;
  font-size: 13px !important;
  opacity: 0.8 !important;
  /* Firefox */
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  /* overflow: hidden; */
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid #edededbf;
}
.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
  opacity: 0.5;
}
.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled:hover {
  opacity: 0.5 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
.mailerOption input[type='radio'] {
  position: relative;
  top: 2px;
  margin-left: 10px;
}
.mailerOption label {
  font-weight: normal !important;
  margin-left: 5px !important;
  margin-bottom: 0 !important;
  margin-right: 5px;
}
.mailerOption i {
  font-style: inherit;
  margin-left: 3px;
}
.countLink {
  color: #1254bb;
}
.ck-editor__editable {
  min-height: 400px;
}
.quillEditor {
  background-color: white;
}
.quillEditor .ql-editor {
  line-height: 2;
  font-size: 15px;
}
.customExtra {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #00e;
  z-index: 9;
}
.defaultEligBtn {
  display: flex;
  border: 1px solid #ddd !important;
  justify-content: center;
  align-items: center;
  padding: 1px 10px !important;
  background: white !important;
  margin-top: 8px;
  font-size: 13px !important;
  border-radius: 50px !important;
}
.defaultEligBtn i {
  margin-right: 5px !important;
}
.eligiBtn {
  border: 1px solid #ddd !important;
  background: white !important;
  height: 27px;
  margin-top: 8px;
  font-size: 11px !important;
  border-radius: 50px !important;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px !important;
}
.eligiBtn i {
  margin-right: 5px !important;
}
.flexEndCustom {
  margin-top: 10px;
  justify-content: flex-end;
}
.wrapBox {
  display: flex;
  flex-wrap: wrap;
}
.wrapBox .widthLabel {
  font-weight: bold;
  text-decoration: underline;
  color: black;
}
.wrapBox .widthLabel.w100 {
  width: 100%;
}
.wrapBox .widthLabel.w200 {
  width: 200%;
}
.wrapBox label {
  font-weight: 600 !important;
}
.status p {
  min-height: 22px !important;
  height: auto !important;
}
.status p {
  min-height: 22px !important;
  height: auto !important;
}
.courseNameDotsReplace {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  height: 40px;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.smallBtn {
  padding: 10px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lgBtn {
  padding: 15px 25px !important;
  height: auto !important;
}
.image-upload > input {
  display: none;
}
.image-upload label {
  margin-right: 10px;
}
.image-upload img {
  height: 30px;
  cursor: pointer;
}
.pendencyTab td {
  padding: 5px !important;
}
.documentBox {
  padding: 0 5px;
  /* margin: 2px; */
  border-radius: 5px;
  min-height: auto !important;
}
.documentBox ul {
  padding-left: 0;
  margin-bottom: 0;
}
.documentBox .emailTag {
  position: relative;
  float: left;
  margin-right: 4px;
  padding: 2px 5px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border-radius: 2px;
  white-space: nowrap;
  width: 180px;
  text-overflow: ellipsis;
}
.documentBox .emailIcon {
  font-size: 15px;
  position: relative;
  top: 3px;
  margin-left: 5px;
}
.cardSm {
  padding: 10px;
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.cardSm .pb0 {
  padding-bottom: 0;
}
.cardSm .smImg {
  height: 10px;
  margin-left: 5px;
}
.cardSm .smFont {
  margin-top: 5px;
  font-size: 11px;
}
.cardSm .smFont .dLab {
  color: #0872bc;
  font-weight: 600 !important;
}
.cardSm .dLab {
  font-size: 11px;
  margin-block: 0;
  font-weight: 600 !important;
}
.customTextArea {
  height: 100px !important;
}
.ratioBox label {
  margin-right: 20px;
}
.ratioBox input {
  position: relative;
  top: 2px;
  left: 3px;
}
.feedbackLabel {
  background: #0872bc;
  padding: 10px 50px;
  color: white;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.banner11 {
  padding-bottom: 40px !important;
}
.feedbackInfo {
  font-size: 25px;
  text-align: center;
  padding: 50px 0;
}
.antd-pro-components-header-dropdown-index-container {
  z-index: 99 !important;
}
.float-right {
  float: right !important;
}
.ant-avatar-sm {
  width: 40px;
  height: 40px;
  line-height: 24px;
  border-radius: 50%;
}
.avatarBig .ant-avatar-sm {
  width: 50px;
  height: 50px;
  line-height: 24px;
  border-radius: 50%;
}
.card-body.table-responsive {
  padding-top: 0px !important;
}
.ant-table-pagination.ant-pagination {
  margin-top: 0px !important;
  margin-bottom: 7px !important;
}
.slimBtn .fee-box .box .btn-group button {
  display: flex;
  width: 100%;
  border: 2px solid #ededed;
  background: #e6e7e8;
  font-size: 13px;
  text-transform: capitalize;
  margin: 0 0 10px;
  height: 30px;
  align-items: center;
  padding: 0 10px;
}
section.gic-revenue {
  border-radius: 15px;
}
section.gic-revenue a.main-btn {
  background: #11148a;
  font-size: 15px;
  color: #fff;
  border-radius: 12px;
  padding: 12px 25px;
  text-transform: capitalize;
  height: auto;
}
section.gic-revenue .list .box {
  box-shadow: inset 0 0 0 2px #e6e6e6;
  border-radius: 18px;
  padding: 10px 20px;
  margin: 0 0 15px;
  display: flex;
  padding-left: 80px;
  position: relative;
  flex-direction: column;
  text-align: left;
}
section.gic-revenue .list .box span {
  min-width: 50px;
  height: 50px;
  display: inline-flex;
  background: #e6e6e6;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}
section.gic-revenue .list .box h4 {
  font-size: 23px;
  font-weight: 600;
  color: #4d4d4d;
  line-height: normal;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  margin: 0;
}
section.gic-revenue .list .box p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  color: #666666;
}
section.gic-revenue .list .box span img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
section.gic-revenue .list button.btn {
  background: #5bb85d;
  border-radius: 18px;
  width: 100%;
  color: #fff;
}
section.gic-revenue .list button.btn span {
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.flexRow {
  display: flex;
  flex-wrap: wrap;
}
.flexRow span {
  padding: 3px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.flexRow .cross {
  margin-left: 10px;
  position: relative;
  top: -1px;
}
.actBy {
  font-weight: normal;
  color: #745e5e;
  margin-left: 27px;
  padding: 0;
  height: 15px;
  margin-bottom: 5px;
  position: relative;
  top: -5px;
}
table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
table tbody tr:nth-child(odd) {
  background: white;
}
.input-group-inner.field1 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right: 1px solid #00000017;
}
.input-group-inner.field1 .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group-inner.field2 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding-left: 20px !important;
}
.input-group-inner.field3 {
  border-radius: 50px !important;
  border-right: none;
}
.input-group-inner.field3 .form-control {
  border-radius: 50px !important;
}
.icefBlock {
  display: flex;
  flex-direction: row;
  background: #ffffff7a;
  font-weight: 700;
  line-height: 36px;
  align-items: center;
  border-left: 3px solid #1890ff;
  padding: 10px 30px 10px 10px;
  box-shadow: 2px 5px 10px #e9c4c4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  width: 80%;
}
.icefBlock h1 {
  font-size: 20px !important;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 !important;
}
.icefBlock img {
  max-width: 60px;
}
.outMotto {
  font-size: 16px !important;
}
.card.unizportal.regStu {
  padding: 20px;
}
.student-application-detail.student-application-new .inputBox {
  margin-bottom: 0 !important;
}
.student-application-detail.student-application-new .card .form-group label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 !important;
}
.student-application-detail.student-application-new.addCourseForm .card .form-group label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin: -10px 6px -5px !important;
}
.student-application-detail.student-application-new h5 {
  font-size: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.student-application-detail.student-application-new .detail-list ul {
  padding-left: 0;
}
.student-application-detail.student-application-new .detail-list ul li {
  padding: 5px 0 !important;
}
.student-application-detail.student-application-new .detail-list ul li strong {
  width: 30%;
}
.student-application-detail.student-application-new .manageBox {
  background-color: white;
}
.student-application-detail.student-application-new .manageBox .managerHeader {
  background-color: gray;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  position: absolute;
  right: 10px;
  border-bottom-left-radius: 10px;
}
.student-application-detail.student-application-new .manageBox .managerBoxOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.student-application-detail.student-application-new .manageBox .managerBoxOuter .manageBoxInfo {
  text-align: left;
  font-size: 12px;
  color: #585858;
  padding: 20px 0 20px 20px;
}
.student-application-detail.student-application-new .add-application-btn {
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 0px 0 0 !important;
  border-radius: 50px;
  font-weight: 400;
  width: auto;
  height: auto !important;
}
.fee-box .box .btn-group button.profileBtn {
  border: 2px solid #ededed;
  background: #e6e7e8 !important;
  border-radius: 5px;
  text-align: center;
  height: 30px;
  align-items: center;
  justify-content: center;
}
.login-box .form-group .countryCode img {
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin-right: 5px;
}
.referBtn {
  background-color: #0f52ba;
  color: white;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  padding: 0 20px 20px 10px;
}
.referBtn .ant-badge {
  position: relative;
  top: -17px;
}
.referBtn .ant-badge img {
  margin-right: 10px !important;
  height: 20px;
}
.referBtn a:hover {
  color: white !important;
}
.referCodeBox {
  border-color: #0f52ba;
  border-style: dashed;
  padding-top: 5px;
  padding-bottom: 5px;
}
.referHeader {
  background-color: #0f52ba;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-top: 10px;
}
.marketingHeader {
  background-color: white;
  border: 1px solid #ddd;
  padding: 15px 20px;
  margin-top: 10px;
  color: black;
  font-size: 18px;
}
.marketingHeader .fa {
  margin-right: 5px;
}
.referInfoBox {
  margin-top: 30px;
  text-align: center;
}
.conditionList {
  flex: 1 1;
  padding-left: 15px;
  padding-right: 30px;
}
.conditionListText {
  font-size: 12px;
}
.reportingBox {
  padding: 15px;
  background-color: white;
  margin: 5px;
  text-align: center;
}
.reportContainer .reportBox {
  margin: 20px 0;
}
.reportContainer .reportingBox {
  padding: 0 !important;
  box-shadow: 1px 1px 10px #b3b3b36b !important;
  background-color: #1890ff;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
.reportContainer .reportingBox .arrowIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
.reportContainer .reportingBox .reportingInnerBox {
  border-radius: 5px;
  border-bottom-right-radius: 80px !important;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
}
.reportContainer .reportingBox img {
  margin-bottom: 10px;
  height: 30px;
}
.reportContainer .reportingBox .anticon {
  margin-left: 10px;
}
.reportContainer .reportingBox .reportInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.reportContainer .reportingBoxLiveReporting {
  padding: 0 !important;
  box-shadow: 1px 1px 10px #b3b3b36b !important;
  background-color: white;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
.reportContainer .reportingBoxLiveReporting .arrowIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
.reportContainer .reportingBoxLiveReporting .reportingInnerBox {
  border-radius: 5px;
  border-bottom-right-radius: 80px !important;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
}
.reportContainer .reportingBoxLiveReporting img {
  margin-bottom: 10px;
  height: 30px;
}
.reportContainer .reportingBoxLiveReporting .anticon {
  margin-left: 10px;
}
.reportContainer .reportingBoxLiveReporting .reportInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.linkText {
  color: #0066ff;
  cursor: pointer;
}
.customGrpBtn .ant-input-group-addon {
  padding: 0 !important;
}
.customGrpBtn .ant-input-group-addon a {
  padding: 5px 15px !important;
  border-radius: 3px;
}
.customGrpBtn .ant-input-group-addon a:first-child {
  border-right: 1px solid #ddd !important;
}
.customGrpBtn .selected {
  background-color: #1890ff;
  color: white;
}
.othAppContainer {
  margin-top: 10px;
  margin-bottom: 50px;
}
.othAppContainer .flexColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.othAppContainer .flexColumn .otherAppList {
  width: 32%;
}
.othAppContainer .otherAppList {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px #ddd;
  border-radius: 5px;
  padding: 10px 10px 5px;
  flex-direction: column;
  margin-top: 10px;
}
.othAppContainer .otherAppList .leftBox {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.othAppContainer .otherAppList .leftBox img {
  width: 120px !important;
  height: auto !important;
  margin-bottom: 10px;
}
.othAppContainer .otherAppList .rightBox {
  text-align: left;
  width: 100%;
}
.othAppContainer .otherAppList .rightBox .label1 {
  font-size: 10px !important;
}
.othAppContainer .otherAppList .rightBox a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  width: 100%;
}
.othAppContainer .otherAppList .rightBox a p {
  color: #495057;
  font-size: 15px;
}
.photoShortName {
  max-height: 35px;
  margin-bottom: 5px;
  padding: 5px 10px 0;
}
.photoShortName a {
  color: black !important;
  display: block;
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.followUp {
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #ddd !important;
}
.studentRegForm .form-group input {
  padding-right: 10px !important;
}
.forgetEmailText {
  font-size: 16px !important;
  margin-top: 10px;
  text-transform: capitalize;
}
.forgetEmailText strong {
  color: #1890ff;
  font-weight: 500;
}
.gcKey {
  height: 250px !important;
}
.oshcBox {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 3px 5px;
  border-radius: 5px;
  background: #1690ff;
  color: white;
  font-weight: bold;
}
.oshcBox img {
  height: 18px;
  margin-right: 5px;
}
.noteMain {
  overflow: hidden !important;
}
.student-tab {
  overflow: hidden !important;
}
.student-tab .loadMoreBoxDiv {
  text-align: center;
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1 !important;
}
.student-tab .noteTitle {
  color: #111b21;
}
.student-tab .noteRow {
  background-color: white !important;
  position: relative;
}
.student-tab .noteRow .noteContainer {
  height: 80vh;
  overflow-y: auto;
}
.student-tab .noteRow .noteColumn .card {
  background: white !important;
  border: 1px solid #1890ff57;
  line-height: 1.5;
}
.student-tab .noteRow .noteColumn .card .dateBox {
  text-align: left;
  font-weight: bold;
  color: #0872bc;
}
.student-tab .noteRow .noteColumn .card .userBox {
  text-align: right;
  color: #667781;
  padding: 0;
  line-height: 1;
  font-size: 15px;
}
.student-tab .noteRow .noteColumn.noteRight {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.student-tab .noteRow .noteColumn.noteRight .card {
  border: 1px solid #5cb85c57;
}
.student-tab .noteRow .noteColumn.noteRight .card .dateBox {
  text-align: right;
}
.student-tab .noteRow .noteColumn.noteRight .card .userBox {
  text-align: right;
}
.student-tab .noteRow .noteBox {
  width: 75% !important;
}
.student-tab .noteRow .noteBox .card {
  background: #fff;
}
.student-tab .noteRow .noteBox .inner.card {
  padding: 10px 15px !important;
}
.textAreaCls {
  height: 200px;
}
.textAreaCls textarea.form-control {
  height: 200px !important;
}
.loginCredentialsBox {
  border: 0.3px solid #e6e7e8;
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #e6e7e8;
}
.loginCredentialsBox .header {
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
}
.localLoginCredentialsBox {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #0f52ba2e;
  border-radius: 10px;
  margin-bottom: 30px;
}
.localLoginCredentialsBox .header {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.localLoginCredentialsBox .header .loginBanner {
  margin-bottom: 10px;
}
.localLoginCredentialsBox .header .loginIcon {
  margin-bottom: 10px;
  height: 70px;
}
.localLoginCredentialsBox .verticalPadding10 {
  padding-left: 10px;
  padding-right: 10px;
}
.localLoginCredentialsBox .verticalPadding10 .label1 {
  position: relative;
  padding: 4px;
  font-size: 13px;
  color: #555;
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.localLoginCredentialsBox .verticalPadding10 .label2 {
  position: relative;
  padding: 4px;
  font-size: 13px;
  color: #555;
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.localLoginCredentialsBox .verticalPadding10 .label2 .btn-info {
  background-color: #0f52ba;
  border-color: #0f52ba;
}
.localLoginCredentialsBox .verticalPadding10 .rowC {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
}
.localLoginCredentialsBox .verticalPadding10 .rowC:last-child {
  border-bottom: none;
}
.localLoginCredentialsBox .verticalPadding10 .rowC:last-child span {
  margin-top: 5px;
}
.localLoginCredentialsBox .verticalPadding10 .rowC img {
  height: 20px;
  margin-right: 8px;
}
.localLoginCredentialsBox .verticalPadding10 .rowC span {
  border: none;
  padding: 0;
  flex: 1 1;
  text-transform: capitalize;
  display: flex;
}
.commissionBox .commissionParentBox {
  display: flex;
  flex-direction: column;
}
.commissionBox .commissionParentBox.row {
  flex-direction: row;
}
.commissionBox .commissionParentBox.row .commissionUniInfo {
  flex: 1 1;
  margin: 0 10px 20px;
}
.commissionBox .commissionParentBox .commissionUniInfo {
  border: none;
  box-shadow: 1px 1px 10px #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}
.commissionBox .commissionParentBox .commissionUniInfo .ant-card-body {
  padding: 10px;
}
.commissionBox .commissionParentBox .commissionUniInfo img {
  width: max-content;
  height: 50px;
}
.commissionBox .commissionParentBox .commissionUniInfo div[class*='ant-col'] {
  display: flex;
  justify-content: center;
  height: 70px;
  flex-direction: column;
}
.commissionBox .commissionParentBox .commissionUniInfo .logoBox {
  border-right: 1px solid #ddd;
  padding-right: 30px;
  align-items: center;
}
.commissionBox .commissionParentBox .commissionUniInfo .contantBox {
  padding-left: 50px !important;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox {
  display: flex;
  min-height: 60px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentProfile {
  width: 20%;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentProfile img {
  height: 60px;
  max-width: 100%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentInfo {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 5px 5px 5px 40px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart {
  border-right: 1px solid #f1f1f1;
  padding-right: 30px;
  width: 30%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:first-child {
  width: 25%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:last-child {
  border: none;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .studentInfo {
  justify-content: flex-start;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .uniLogo {
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .uniLogo img {
  height: 50px;
  width: 50px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew {
  border-right: 1px solid #f1f1f1;
  padding-right: 30px;
  width: 22.5%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew:first-child {
  width: 25%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew:last-child {
  border: none;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .studentInfo {
  justify-content: flex-start;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .uniLogo {
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .uniLogo img {
  height: 50px;
  width: 50px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex {
  width: 15%;
  padding-left: 20px;
}
.commissionBox .commissionParentBox .commissionUniInfo .uniName {
  font-size: 18px;
  color: #0f52ba;
}
.commissionBox .commissionParentBox .commissionUniInfo .courseInfo {
  font-size: 15px;
  color: black;
}
.commissionBox .commissionParentBox .commissionUniInfo .courseInfo.sm {
  font-size: 14px;
  color: #555;
}
.commissionBox .commissionParentBox .commissionUniInfo .commissionInfo {
  font-size: 14px;
  color: #0872bc;
  font-weight: 600;
}
.commissionBox .inputGroup {
  margin-top: 10px;
}
.commissionBox .inputGroup .input-group-text {
  background-color: #e9ecef;
  border-color: #666;
}
.commissionBox textarea.form-control {
  height: 50px !important;
}
.comBtnGroup {
  display: flex;
  flex-wrap: wrap;
}
.comBtnGroup .btn {
  margin-bottom: 3px;
  font-size: 13px !important;
  padding: 0 4px !important;
  width: 180px;
}
.comBtnGroup .btn img {
  margin-right: 5px;
  height: 13px;
}
.comBtnGroup .btn-view {
  border: 1px solid #d9d9d9 !important;
  color: #212529 !important;
  background-color: white;
}
.darkFont {
  color: black;
}
.dangerPos {
  position: absolute;
  left: 10px;
}
.commissionListBox {
  margin-top: 20px;
  position: relative;
}
.commissionListBox .addMoreCommissionBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.student-group {
  margin-top: 10px;
  margin-right: 10px;
}
.student-group label {
  margin: 0 10px 4px 3px !important;
  color: black;
  font-weight: 500 !important;
}
.student-group .form-control {
  border-color: #e5dcdc !important;
  background-image: none !important;
  height: 35px !important;
  border-radius: 5px !important;
}
.student-group .input-group-text {
  border-color: #e5dcdc !important;
  height: 35px !important;
}
.uniInfoLogo {
  border-right: 1px solid #f1f1f1;
  padding-right: 20px;
  margin-right: 20px;
}
.uniInfoLogo img {
  height: 70px !important;
}
.textCap {
  text-transform: capitalize;
}
.textUpp {
  text-transform: uppercase;
}
.customCheckbox {
  cursor: pointer;
  text-align: center;
}
.customCheckbox img {
  height: 25px;
}
.universityParentBox {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.universityParentBox .commissionUniInfo {
  border: none;
  box-shadow: 1px 1px 10px #ddd;
  border-radius: 5px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.universityParentBox .commissionUniInfo .ant-card-body {
  justify-content: center;
  flex: 1 1;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.universityParentBox .commissionUniInfo .uniLogo {
  border-right: none;
}
.universityParentBox .commissionUniInfo .uniLogo img {
  height: 70px !important;
  width: 100%;
}
.universityParentBox .commissionUniInfo .studentInfoBox {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.universityParentBox .commissionUniInfo .ant-card-body {
  padding: 10px;
}
.universityParentBox .commissionUniInfo img {
  width: max-content;
  height: 50px;
}
.universityParentBox .commissionUniInfo .uniName {
  font-size: 18px;
  color: #0f52ba;
}
.filterCard .ant-select .ant-select-selection--single {
  border: 1px solid #e9d5d5 !important;
}
.filterCard .search-box-table.round {
  border: 1px solid #e9d5d5 !important;
}
.filterCard .roundBtn {
  border: 1px solid #e9d5d5 !important;
}
.statusDate {
  margin-top: 5px;
  font-size: 13px;
  color: #0f52ba;
  font-weight: 500;
}
.btn-warning {
  background-color: rgba(240, 173, 78, 0.87) !important;
  color: white !important;
}
.btn-success {
  border-color: #0872bc !important;
  background-color: #0872bc !important;
  color: white !important;
}
.btn-view {
  border: 1px solid #d9d9d9 !important;
  color: #212529 !important;
  background-color: white !important;
  margin-top: 0 !important;
}
.btn-view.sm {
  padding: 0.25rem 0.8rem;
  font-size: 0.8rem;
  line-height: 1.5;
}
.label-info-new {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: none;
}
.btn-paid {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  color: white !important;
}
.label-paid {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  color: white !important;
}
.view-btn-box {
  margin-top: 5px;
  /* .eyeBtn {
       height: 20px;
     }*/
}
.view-btn-box .btn-view {
  font-size: 12px !important;
  border: 1px solid #d9d9d9;
  height: 32px;
  padding: 2px 5px;
  border-radius: 5px;
  color: #212529 !important;
  margin-right: 5px;
}
.view-btn-box .btn-view img {
  height: 17px;
}
.contract {
  text-transform: uppercase;
  margin-top: 5px;
  color: black;
}
.contract a {
  text-decoration: underline;
  font-weight: 500;
}
.customInput {
  margin-top: 8px;
}
.customInput label {
  margin-bottom: auto;
}
.table-head .sort-box-table.sort-box-table30 {
  width: 30% !important;
}
.flexRowBox {
  display: flex;
}
.flexRowBox .flexBox1 {
  flex: 1 1;
  width: 30%;
  margin-right: 10px;
  background-color: #f1f1f1 !important;
  margin-bottom: 18px;
}
.flexRowBox .flexBox2 {
  flex: 2 1;
}
.imgFlex {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
}
.imgFlex .img-column {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  margin-right: 10px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgFlex .img-column img {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart {
    width: 33%;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:first-child {
    width: 33%;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:nth-child(3) {
    border: none;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:last-child {
    border: none;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex {
    width: 50%;
    padding-left: 20px;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex .student-group {
    margin-top: 0;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .uniName {
    font-size: 15px;
    color: #0f52ba;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .courseInfo {
    font-size: 12px;
    color: black;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .courseInfo.sm {
    font-size: 12px;
    color: #555;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .commissionInfo {
    font-size: 12px;
    color: #0872bc;
    font-weight: 600;
  }
  .universityParentBox {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .universityParentBox .commissionUniInfo {
    border: none;
    box-shadow: 1px 1px 10px #ddd;
    border-radius: 5px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .universityParentBox .commissionUniInfo .ant-card-body {
    justify-content: center;
    flex: 1 1;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .universityParentBox .commissionUniInfo .uniLogo {
    border-right: none;
  }
  .universityParentBox .commissionUniInfo .uniLogo img {
    height: 70px !important;
    width: 100%;
  }
  .universityParentBox .commissionUniInfo .uniName {
    font-size: 15px;
    color: #0f52ba;
  }
  .customInput {
    margin-top: 8px;
  }
  .customInput label {
    margin-bottom: 0;
  }
}
.round-date-picker .ant-calendar-picker-input {
  height: 42px !important;
  border-radius: 50px;
  border: none;
}
.verifyOfferBox {
  position: relative;
  overflow: hidden;
  height: auto;
  padding-top: 7%;
  padding-bottom: 90px;
  min-height: 80vh;
}
.verifyOfferBox h3 {
  font-size: 32px;
  text-transform: capitalize;
  margin: 0 0 10px;
  color: #0f52ba;
}
.verifyOfferBox .store {
  padding-top: 7%;
}
.verifyOfferBox .store img {
  width: 100%;
}
.verifyOfferBox .iframeBox {
  margin: 5% 15% 100px;
  border: 1px solid #ddd;
  padding: 10px;
}
.announcement_banner {
  background-color: white;
  max-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.announcement_banner img {
  width: auto;
  height: 250px;
}
.announcement_banner .applyBtn {
  position: absolute;
  bottom: 15px;
  width: 350px !important;
}
.certificate_box .btn-default {
  margin-bottom: 10px;
  width: 220px;
}
.certificate_box .ant-tag {
  margin-bottom: 5px;
  border-color: #007bff33;
}
.certificate_box .ant-tag .btn-link {
  font-weight: 400;
  color: #007bff !important;
  text-decoration: none;
}
.duplicateRecordLink {
  font-size: 15px;
  margin-bottom: 10px;
}
.broadcast_info {
  font-size: 25px;
  text-align: center;
  padding: 50px 0;
  min-height: 500px;
}
.broadcast_info img {
  height: 150px;
  margin-bottom: 20px;
}
.addInfo {
  padding-left: 3px !important;
}
.addInfo .box {
  position: relative;
  margin-bottom: 5px;
  display: flex !important;
}
.addInfo .box img {
  height: 22px !important;
  width: auto !important;
}
.addInfo .box span {
  margin-left: 27px;
}
@media (max-width: 600px) {
  .verifyOfferBox {
    position: relative;
    overflow: hidden;
    height: auto;
    padding-top: 100px;
    padding-bottom: 90px;
    min-height: 80vh;
  }
  .verifyOfferBox h3 {
    font-size: 32px;
    text-transform: capitalize;
    margin: 0 0 10px;
    color: #0f52ba;
  }
  .verifyOfferBox .store {
    padding-top: 7%;
  }
  .verifyOfferBox .store img {
    width: 100%;
  }
  .verifyOfferBox .iframeBox {
    margin: 5% 2% 100px;
    border: 1px solid #ddd;
    padding: 10px;
    overflow: auto;
  }
}
.counsellerDashboard {
  width: auto;
}
.counsellerDashboard .main-logo {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.counsellerDashboard .counseller-card {
  width: 100%;
  height: 50%;
  border: 1px solid lightgrey;
}
.counsellerDashboard .counseller-card .countryInfo {
  text-align: center;
}
.counsellerDashboard .counseller-card .countryInfo .map {
  width: auto;
  height: 100px;
}
.counsellerDashboard .counseller-card .countryInfo h5 {
  color: #000;
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.counsellerDashboard .counseller-card .counselling-container {
  position: relative;
  min-height: 200px;
  border-bottom: 1px solid #ddd;
}
.counsellerDashboard .counseller-card .counselling-container .waitingBox {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #00000033;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.counsellerDashboard .counseller-card .counselling-container .waitingBox .waitingLabel {
  font-size: 25px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  padding: 20px 20px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card:nth-child(0) {
  border-bottom: 1px solid lightgrey;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title {
  width: 100%;
  padding: 10px;
  padding-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title img {
  border-radius: 50px;
  margin-right: 15px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title .box-title {
  font-size: 12px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title .tarundiv {
  display: flex;
  flex-direction: column;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .waitingInfo {
  display: flex;
  margin-bottom: 20px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .waitingInfo .counsellerInfo-num {
  background-color: #ddd;
  border-radius: 40px;
  padding: 0 10px;
  margin-left: 10px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo {
  width: 100%;
  height: 50%;
  font-size: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo.active {
  color: #0872bc;
}
.comment-boxx {
  border-bottom: lightgrey;
}
.student-informationn {
  display: flex;
  padding: 9px 14px;
}
.studentprofile {
  display: flex;
  flex-direction: row;
}
.studentprofile .image {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.studentprofile .image img {
  border-radius: 100%;
}
.studentprofile .descrip {
  width: 65%;
}
.studentprofile .descrip .border {
  width: 60%;
  border: 2px solid gray;
  padding: 10px;
}
.imagee {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imagee img {
  border-radius: 100%;
}
.studentinformationboxx {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.nameandnumberboxx {
  height: 40%;
}
.localLoginCredentialsBoxx {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #0f52ba2e;
  border-radius: 10px;
  margin-bottom: 10px;
}
.centerpage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerpage .borderr {
  width: 50%;
}
.dateandhistory {
  height: 50px;
}
.studentListContainer {
  margin-bottom: 5px;
}
.studentListContainer.active .otherAppList {
  background-color: #fff8e1;
}
.studentListContainer .otherAppList {
  display: flex;
  padding: 10px 10px 5px;
  background-color: white;
  box-shadow: 0 0 10px #f1f1;
  border-radius: 5px;
  margin-bottom: 10px;
}
.studentListContainer .otherAppList .leftBox {
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.studentListContainer .otherAppList .leftBox .logo1 {
  width: 200px;
  height: 200px;
}
.studentListContainer .otherAppList .leftBox img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.studentListContainer .otherAppList .leftBox .studentDetails {
  line-height: 1.5;
}
.studentListContainer .otherAppList .leftBox .leftBox1 {
  background-color: gray !important;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.studentListContainer .otherAppList .leftBox .leftBox1 img {
  width: 50px !important;
  height: 50px !important;
  margin-right: 20px;
}
.studentListContainer .otherAppList .leftBox .leftBox1 .studentDetails {
  line-height: 1.5;
}
.studentListContainer .otherAppList .rightBox {
  margin-top: 10px;
  text-align: left;
}
.studentListContainer .otherAppList .rightBox a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}
.studentListContainer .otherAppList .rightBox a p {
  color: #495057;
  font-size: 15px;
}
.closeconvert {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}
.flaguk {
  margin-left: 466px;
  width: 25px;
}
.nameanddate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.backanddot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.onoffdot {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: 10px;
}
.onoffdot .innerdot {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
}
.picandmore {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.caseclose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.caseclose .caseclosebtn {
  width: 100px;
  background: #0872bc;
  font-weight: 500 !important;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  border: none;
  outline: none;
}
.myTextarea {
  padding: 10px;
}
.directUniCount {
  width: 16.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.directUniCount .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.directUniCount .uniCountBox img {
  height: 30px;
  width: auto;
}
.directUniCount .uniCountBox h5 {
  font-size: 14px !important;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
.canadaDirectUniCount.canadaDirectUniCount2 {
  width: 25% !important;
}
.canadaDirectUniCount2 .innerBox {
  border: 1px solid #ccc;
}
.card.card-pdf {
  background: white !important;
}
.canadaDirectUniCount {
  width: 19.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.canadaDirectUniCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.canadaDirectUniCount .innerBox .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
.canadaDirectUniCount .innerBox .uniCountBox img {
  height: 30px;
  width: auto;
}
.canadaDirectUniCount .innerBox .uniCountBox img.sm {
  height: 25px;
}
.canadaDirectUniCount .innerBox .uniCountBox img.md {
  height: 35px !important;
}
.canadaDirectUniCount .innerBox .uniCountBox h5 {
  font-size: 14px;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
.canadaDirectUniCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
}
.canadaDirectUniCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.canadaDirectUniCount .innerBox .btnBox a i {
  margin-right: 5px;
}
.canadaDirectUniCount .innerBox .dirUniAgent {
  padding: 10px 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 27px;
}
.filter-row.ant-row {
  display: flex;
  flex-wrap: wrap;
}
.commissionBlock {
  margin: 20px 0;
}
.commissionBlock .countryCommission {
  padding: 20px;
  text-align: center;
}
.commissionBlock .countryCommission .countryName {
  font-size: 16px !important;
  height: 50px;
}
.commissionBlock .countryCommission .countryFlag {
  margin: 25px 0;
}
.commissionBlock .countryCommission .countryFlag img {
  height: 30px;
}
.commissionBlock .countryCommission .currencyName {
  font-size: 16px !important;
}
.commissionBlock .countryCommission .countryAmount {
  font-size: 25px;
  cursor: pointer;
}
.commissionBlock .countryCommission .canada {
  color: #ad2125;
}
.commissionBlock .countryCommission .uk {
  color: #ff0066;
}
.commissionBlock .countryCommission .australia {
  color: #003399;
}
.card.bgWhite {
  background: white !important;
}
.ant-col-md-0 {
  display: block;
}
.squareContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.squareContainer .squareStyle {
  width: 45px;
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
}
.squareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.squareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.squareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
.squareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
.squareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
.ukSquareContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 15px;
  grid-column-gap: 5px;
}
.ukSquareContainer .squareStyle {
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  text-align: center;
}
.ukSquareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.ukSquareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.ukSquareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
.ukSquareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
.ukSquareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
.dirUniLabelBox {
  display: flex;
  padding: 30px 2.4rem 0 !important;
}
.dirUniLabelBox div {
  margin: 0 20px;
  padding: 0 10px;
}
.dirUniLabelBox div:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.dirUniLabelBox div:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.dirUniLabelBox div:nth-child(3) {
  border-bottom: 2px solid #5bc0de;
}
.ledgerBox {
  padding: 20px 3%;
}
.ledgerBox .header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}
.ledgerBox .header .ant-col {
  color: black;
  font-weight: 500;
  padding-bottom: 10px;
}
.ledgerBox .ant-col:nth-child(3) {
  text-align: right;
}
.ledgerBox .ant-col:last-child {
  text-align: right;
}
.ledgerBox .ant-row {
  padding: 10px 10px 0;
}
.ledgerBox .ant-row:nth-child(even) {
  background-color: #f7f7f7;
}
.ledgerBox .ant-row:nth-child(odd) {
  background-color: #ff11ff08;
}
.ledgerBox .ant-row.header {
  background-color: white;
}
.ledgerBox .debit {
  color: #d9534f;
  font-weight: 600;
}
.ledgerBox .credit {
  color: #5cb85c;
  font-weight: 600;
}
.ledgerBox .dateBox {
  font-size: 15px;
  font-weight: 500;
  color: #0872bc;
  padding-bottom: 10px;
}
.filterBox .ant-calendar-picker-input {
  border-radius: 50px;
  border: none;
}
.filterBox .search-box-table.round {
  margin-left: 0 !important;
}
.agentName {
  display: flex;
}
.agentName .agentOnline {
  height: 7px;
  width: 7px;
  background-color: #5cb85c;
  border-radius: 50%;
  margin-left: 5px;
}
.display-linebreak {
  white-space: pre-line;
}
.hrFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.selectable-box {
  border: 1px solid #e5d9d9;
  margin: 5px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
}
.box-text {
  font-size: 14px;
}
.countCircle {
  font-size: 12px !important;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  line-height: 0.9;
  background: #0872bc;
  color: white !important;
  margin-left: 7px;
  margin-top: 2px;
  padding: 5px;
  display: inline-block;
}
.selectable-box.selected {
  background-color: #007bff;
  color: white;
}
.selectable-box.selected .countCircle {
  background: #5cb85c;
}
.intakeRow {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  margin-top: 10px;
}
.intakeRow .singleIntakeBox {
  border: 1px solid #d9d9d9;
  padding: 0 5px;
  background: #fafafa;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}
.intakeRow .singleIntakeBox .singleIntakeBox-check {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  bottom: 3px;
}
.intakeRow .singleIntakeBox.selected {
  background-color: #1890ff;
}
.intakeRow .singleIntakeBox.selected i {
  color: white !important;
}
.intakeRow .singleIntakeBox i {
  font-style: normal;
  color: rgba(0, 0, 0, 0.65);
}
.trainingBox {
  text-align: center;
  margin-top: 50px;
}
.trainingBox img {
  height: 150px;
}
.trainingBox .textBox {
  margin-top: 20px;
}
.trainingBox .textBox h3 {
  font-weight: 600;
  text-transform: uppercase;
  font-family: ui-monospace;
  font-size: 30px;
  margin-bottom: 3%;
}
.trainingBox .textBox.normal h3 {
  text-transform: capitalize;
}
.trainingBox .textBox .confirmLink {
  font-size: 17px;
  background-color: #0f52ba;
  color: white;
  padding: 6px 30px;
  text-transform: capitalize;
  border-radius: 5px;
}
.marketingUserBox {
  margin-bottom: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 10px 10px #ddd;
}
.marketingUserBox.managerBoxCanada {
  width: 100%;
  margin-bottom: 0;
}
.marketingUserBox.managerBoxCanada .ant-avatar img {
  left: 0 !important;
}
.marketingUserBox.managerBoxCanada .managerHead {
  margin-bottom: 5px;
  background-color: #ad2125;
}
.marketingUserBox .managerHead {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 80%;
}
.marketingUserBox .managerBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-left: 10px;
}
.marketingUserBox .managerBody.ais {
  align-items: flex-start;
}
.whatsNewCard .whatsNewBox {
  border-bottom: 1px solid #fde6e6;
  padding: 20px;
}
.whatsNewCard .whatsNewBox .flex-row {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
}
.whatsNewCard .whatsNewBox .flex-row .icon {
  width: 50px;
  height: 50px;
  margin-right: 30px;
  margin-left: 20px;
}
.whatsNewCard .whatsNewBox .flex-row .title {
  color: #0f52ba;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
}
.whatsNewCard .whatsNewBox .flex-row .dateRow {
  color: #555;
  font-size: 14px;
}
.whatsNewCard .whatsNewBox .flex-row .dateRow i {
  margin-right: 5px;
  color: #888;
}
.whatsNewCard .whatsNewBox .content-box {
  margin-top: 20px;
}
.whatsNewCard .whatsNewBox .img-box img {
  width: 80%;
}
.studentInfoTableRow {
  padding: 4px 10px;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.studentInfoTableRow .title {
  font-weight: bold;
  width: 40%;
  padding: 5px 10px;
}
.studentInfoTableRow .details {
  padding: 5px 10px;
}
.studentInfoTableRow p {
  margin-bottom: 0;
}
.studentInfoTableRow:first-child {
  border-top: 1px solid #ddd;
}
.studentInfoTableRow:nth-child(odd) {
  background-color: white;
}
.studentInfoTableRow:nth-child(even) {
  background-color: #effbf8;
}
.textAreaHeight {
  min-height: 100px !important;
}
.agent_form {
  background-color: #f7f7ff;
}
.agent_form .ant-form-item {
  margin-bottom: 0.8rem;
}
.agent_form .form-control,
.agent_form .ant-select-selection {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none;
  appearance: none;
  height: 50px !important;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-image: none !important;
}
.agent_form .form-control::-webkit-input-placeholder,
.agent_form .ant-select-selection::-webkit-input-placeholder {
  font-size: 1rem !important;
  font-weight: 400;
}
.agent_form .ant-select-selection__placeholder {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
}
.agent_form .ant-select-selection__rendered {
  margin-left: 0;
}
.agent_form .ant-select-arrow {
  background-color: white;
  color: #777 !important;
}
.agent_form .ant-select-focused .ant-select-arrow {
  background-color: white !important;
  color: #777 !important;
}
.agent_form label {
  font-size: 18px !important;
  margin: 0 !important;
  margin-bottom: 8px !important;
}
.agent_form .right_bg {
  height: 100%;
  background-position: 35%;
  background-repeat: no-repeat;
}
.agent_form .btn_sub {
  padding: 8px 16px !important;
  font-size: 1.25rem;
  border-radius: 0 !important;
  background-color: #1890ff;
  border: none;
}
.registrationClose {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.default_btn {
  border: 1px solid #ddd;
  padding: 3px 10px;
  color: #555;
  text-decoration: none;
  font-size: 0.8rem !important;
  border-radius: 3px;
  margin-right: 5px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}
.default_btn:last-child {
  margin-right: 0;
}
.default_btn img {
  height: 15px !important;
  margin-right: 4px;
  margin-bottom: 2px;
}
.btn_group {
  display: flex;
  flex-wrap: wrap;
}
.btn_group .default_btn {
  margin-right: 5px;
}
.btn_group .btn {
  margin-right: 5px;
}
.select_btn_group {
  display: flex;
  flex-wrap: wrap;
}
.select_btn_group .default_btn {
  margin-right: 5px;
  background-color: white;
  line-height: 1.5;
  padding: 0.4rem 1.75rem !important;
  height: auto !important;
  border: none;
  margin-left: 5px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 15px;
}
.select_btn_group .default_btn.selected {
  background-color: #5cb85c;
  color: white;
}
.select_btn_group .default_btn .anticon {
  font-size: 16px;
  margin-right: 7px;
}
.select_btn_group .default_btn_lg {
  margin-right: 10px;
  background-color: white;
  line-height: 1.5;
  padding: 0.4rem 3rem !important;
  height: auto !important;
  border: none;
  margin-left: 5px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  border-radius: 3px;
  color: #555;
  display: flex;
  align-items: center;
}
.select_btn_group .default_btn_lg.selected {
  color: white;
}
.select_btn_group .default_btn_lg .anticon {
  font-size: 16px;
  margin-right: 7px;
}
.btn_link {
  text-decoration: underline;
}
.custom-checkbox {
  width: 100%;
}
.custom-checkbox .ant-checkbox-group-item {
  width: 40% !important;
}
.custom-checkbox span {
  font-weight: normal;
}
.marketing_country_box {
  border: 1px solid #f1f1f1;
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.marketing_country_box .uni_row {
  border: 1px solid #f1f1f1;
  padding: 5px 0 0 0;
  margin-bottom: 5px;
  border-radius: 5px;
}
.marketing_country_box .uni_row .form-control.sm {
  height: 35px !important;
  border-color: #ddd !important;
  background-image: none !important;
  font-size: 14px !important;
}
.filter_box {
  margin-top: 15px;
  background-color: white;
  border-radius: 5px;
  padding: 15px 10px 10px;
}
.filter_box.bg {
  border: 1px solid #dee2e6 !important;
}
.filter_box .labelNew {
  font-size: 14px;
  font-weight: normal !important;
}
.filter_box input:not([class]) {
  width: 100%;
  border: 1px solid #ddd;
  padding: 0 5px 0 20px;
  border-radius: 50px;
  font-size: 14px;
  color: #555;
  height: 40px;
}
.filter_box .ant-calendar-picker-input {
  height: 42px !important;
  border-radius: 50px;
  border: 1px solid #ddd;
}
.filter_box .ant-select.ant-select-focused .ant-select-arrow {
  background: transparent !important;
  color: #555;
}
.filter_box .ant-select .ant-select-selection {
  border-radius: 50px;
  border-color: #ddd;
}
.filter_box .ant-select .ant-select-selection .ant-select-arrow {
  background: transparent;
  color: #555;
}
.filter_box .ant-select .ant-select-selection:hover .ant-select-arrow {
  background-color: white;
  display: block;
}
.filter_box .ant-select .ant-select-selection:hover .ant-select-arrow .anticon {
  color: #555;
}
.filter_box .ant-select-selection__placeholder {
  font-size: 14px !important;
  opacity: 0.8 !important;
  color: #333 !important;
}
.filter_box .search-box-table.round {
  border: 1px solid #ddd;
}
.filter_box .btn_group {
  display: flex;
  justify-content: flex-end;
}
.filter_box .btn_group .default_btn {
  margin-right: 5px;
  padding: 5px 20px;
  border-radius: 50px;
}
.filter_box .btn_group .default_btn:last-child {
  margin-right: 0;
}
.mismatchNote {
  padding: 5px 10px;
  background-color: #d9534f;
  color: white;
  font-weight: 600;
  margin-top: 10px;
}
.expenseAmtBox .successAmt {
  color: #5cb85c;
}
.expenseAmtBox .pendingAmt {
  color: rgba(240, 173, 78, 0.87);
}
.expenseAmtBox .totalAmt {
  color: #0872bc;
}
.joinDate {
  margin-top: 5px;
  color: #1890ff;
  font-size: 11px;
  border-radius: 3px;
  font-weight: 600;
}
.joinDate::before {
  content: '' !important;
  border-left: 2px solid #1890ff;
  padding-left: 3px;
}
.table-bordered {
  border: 1px solid #dee2e6 !important;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6 !important;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-bordered th,
.table-bordered td,
.table-bordered thead th,
.table-bordered tbody + tbody {
  border: 0;
}
.marketingDirectUniCount {
  width: 16.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
}
.marketingDirectUniCount .uni_box {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.marketingDirectUniCount .uni_box img {
  height: 40px;
  width: auto;
}
.marketingDirectUniCount .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 15px;
}
.marketingDirectUniCount .uniCountBox .countBox {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 40%;
  cursor: pointer;
}
.marketingDirectUniCount .uniCountBox .countBox .label {
  font-size: 12px !important;
  color: #555;
  padding: 0;
}
.marketingDirectUniCount .uniCountBox .countBox .count {
  padding: 0 5px;
  color: #1890ff;
}
.dropbtn {
  border: none;
}
input[type='date'].js_datepicker {
  background-color: white !important;
  border: 1px solid #e5dddd !important;
}
.eye_btn {
  padding: 0 3px !important;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 10px;
  color: #0f52ba !important;
}
.eye_btn img {
  height: 14px;
}
.structureDotsReplace {
  display: block;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}
.event_gallery_list {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.event_gallery_list .single_img {
  border: 1px solid #ebcfcf;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
.event_gallery_list .single_img:hover {
  border: 2px solid #0872bc;
}
.event_gallery_list .single_img .cross_btn {
  position: absolute;
  right: -8px;
  top: -8px;
  background: #0f52ba;
  border-radius: 50px;
  width: 22px;
  height: 22px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
.event_gallery_list .single_img img {
  height: 100px;
}
.btn_options {
  display: flex;
}
.btn_options a {
  font-size: 14px;
  width: 100%;
  height: 40px !important;
  padding: 0.475rem 0.75rem;
}
.btn_options .date_box {
  width: 220px;
  margin-right: 10px !important;
}
.btn_options .date_box.selected {
  background-color: #f1f1f1 !important;
}
.btn_options .date_box.selected .btn {
  background-color: #f1f1f1 !important;
}
.btn_options .date_box .form-control {
  border-color: #ddd !important;
  font-size: 15px;
}
.ant-calendar-month-calendar {
  width: 300px;
}
div#studentChart {
  padding-top: 30px !important;
}
.agent-rank-label {
  font-size: 10px !important;
  padding: 4px !important;
}
.priorityBox {
  margin-top: 10px;
}
.priorityBox .priority {
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background: rgba(240, 173, 78, 0.87);
  font-weight: 600;
  position: relative;
  top: -3px;
  left: 0;
  margin-left: 5px;
  font-style: normal;
  animation: glowS 0.5s ease-in-out infinite alternate;
  text-transform: uppercase;
}
.priorityBox .superPriority {
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background: red;
  font-weight: 600;
  position: relative;
  top: -3px;
  left: 0;
  margin-left: 5px;
  font-style: normal;
  animation: glow 0.5s ease-in-out infinite alternate !important;
  text-transform: uppercase;
}
.eventDirectCount {
  width: 20% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.eventDirectCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.eventDirectCount .innerBox .uniCountBox {
  display: flex !important;
  align-items: center;
  padding: 10px 5px;
}
.eventDirectCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
}
.eventDirectCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventDirectCount .innerBox .btnBox a i {
  margin-right: 5px;
}
.eventDirectCount .innerBox .dirUniAgent {
  padding: 10px 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 27px;
}
.eventDirectCount .innerBox .squareContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}
.eventDirectCount .innerBox .squareContainer > div {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.eventDirectCount .innerBox .squareContainer > div span {
  font-size: 12px;
  margin-bottom: 5px;
}
.eventDirectCount .innerBox .squareContainer .squareStyle {
  width: 60px;
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  text-align: center;
  align-self: center;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.totalCount {
  border-bottom: 2px solid #5cb85c;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.approvedCount {
  border-bottom: 2px solid #1890ff;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.pendingCount {
  border-bottom: 2px solid #0872bc;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.deferredCount {
  border-bottom: 2px solid #5bc0de;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.deniedCount {
  border-bottom: 2px solid #d9534f;
}
.icefMeetingCount .singleLi {
  display: inline-block;
  padding: 10px;
}
.icefMeetingCount .singleLi .li_box {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}
.icefMeetingCount .singleLi .li_box .squareStyle {
  margin-left: 20px;
  border-bottom: 2px solid;
  min-width: 30px;
  text-align: center;
  font-weight: bold;
}
.main-btn {
  width: auto !important;
  min-width: 150px;
  background: #1556bc;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  height: 42px !important;
}
.white-btn {
  background: white;
  border: 1px solid #ddd;
  color: #555;
}
.white-btn .ant-calendar-picker-input.ant-input {
  height: 35px !important;
}
.small-select .ant-select-selection {
  height: 30px !important;
  border-color: #ddd;
  border-radius: 3px;
}
.small-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.md-select .ant-select-selection {
  height: 33px !important;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.md-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.room_slider .mobile_view {
  display: none;
}
.room_slider .web_view {
  display: block;
}
@media (max-width: 768px) {
  .room_slider .mobile_view {
    display: block;
  }
  .room_slider .web_view {
    display: none;
  }
}
.shadow-select {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
}
.shadow-select .ant-select-selection {
  height: 35px !important;
  border-radius: 7px;
  padding: 15px 10px;
  border: none;
}
.shadow-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.comment-section .sendBtn {
  border-radius: 15px !important;
  background-color: #87d068;
  border: none;
  color: white;
  padding: 14px !important;
}
.comment-section .avatar_text {
  background-color: #87d068;
  margin: 0 12px;
  font-size: 20px !important;
}
.top_city {
  height: 170px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
.top_city img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
.top_city .city_name {
  position: absolute;
  bottom: 0;
  left: 7.5px;
  padding: 15px;
  z-index: 1;
  color: white;
  font-size: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: calc(100% - 15px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 500;
}
.shadow-select {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
}
.shadow-select .ant-select-selection {
  height: 35px !important;
  border-radius: 7px;
  padding: 15px 10px;
  border: none;
}
.shadow-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.comment-section .sendBtn {
  border-radius: 15px !important;
  background-color: #87d068;
  border: none;
  color: white;
  padding: 14px !important;
}
.comment-section .avatar_text {
  background-color: #87d068;
  margin: 0 12px;
  font-size: 20px !important;
}
.top_city {
  height: 170px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
.top_city img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
.top_city .city_name {
  position: absolute;
  bottom: 0;
  left: 7.5px;
  padding: 15px;
  z-index: 1;
  color: white;
  font-size: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: calc(100% - 15px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 500;
}
.icefManager {
  background: #A1A8EA;
  height: 140px;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icefManager .avatar_box {
  position: absolute;
  bottom: 0;
  left: 5%;
}
.icefManager .avatar_box img {
  height: 170px;
}
.icefManager .manager_info_box {
  position: absolute;
  left: 25%;
  font-size: 13px;
}
.icefManager .manager_info_box .head-title {
  font-size: 22px;
  color: white;
}
.icefManager .manager_info_box .manager_details .inline_a {
  display: flex;
  align-items: center;
}
.icefManager .manager_info_box .manager_details .inline_a:first-child {
  font-style: italic;
}
.event-card .card-container {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 400px !important;
  justify-content: flex-start;
}
.event-card .event-card-box h2 {
  font-size: 22px;
  margin: 15px 0 0 0;
  text-shadow: 1px 1px 5px #000;
  color: #fff;
  font-weight: bold;
}
.event-card .event-card-box h3 {
  font-size: 16px;
  margin: 5px 0 0 0;
  text-shadow: 1px 1px 5px #000;
  color: #fff;
  font-weight: bold;
}
.event-card .event-card-box p {
  font-size: 16px;
  position: absolute;
  bottom: 45px;
  left: 50%;
  right: 25%;
  width: 250px;
  margin-left: -145px;
  text-transform: uppercase;
  text-shadow: 1px 1px 5px #000;
  font-weight: bold;
}
.event-card .event-card-box .event-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-bottom: 20px;
  font-size: 16px;
}
.event-card .event-card-box .event-info .add-event-btn {
  margin-top: 1rem;
  border: 2px solid white;
  padding: 6px;
  position: absolute;
  right: 20px;
  bottom: 5px;
  cursor: pointer;
}
.task_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task_box .label-sm {
  padding: 0.5em 0.6em 0.3em;
}
.completeTask {
  text-decoration: line-through;
}
.event_list_card {
  height: 82vh;
  overflow-y: auto;
  padding: 5px;
}
.event_list_card .single_event_card {
  margin-bottom: 15px;
  padding: 20px;
  box-shadow: 0 0 10px #ddd;
  border-radius: 10px;
}
.event_list_card .single_event_card .ant-collapse {
  border: 1px solid #f4efef !important;
  margin-top: 10px;
}
.event_list_card .single_event_card .ant-collapse .ant-collapse-header {
  padding: 8px 40px;
}
.align_center {
  align-items: center;
  display: flex;
  color: #0f52ba;
}
.aic {
  align-items: center !important;
  display: flex !important;
}
.aic label {
  margin-bottom: 0;
}
.ant-select-disabled .ant-select-selection {
  background-color: #ededed !important;
}
.calendar-container.bgWhite {
  background: white !important;
}
.calendar-container.bgWhite .ant-card-body {
  padding: 0;
}
.calendar-container .table-event td {
  width: calc(100% / 7);
}
.small-select .ant-select-selection--single {
  height: 30px !important;
  border-color: #666;
  border-radius: 5px;
}
.small-select .ant-select-selection--single .ant-select-arrow {
  padding: 3px;
  margin-top: -10px;
}
.custom_row {
  display: flex;
  margin-left: -9px;
  margin-right: -9px;
}
.custom_row .custom_col {
  flex: 1 1;
  padding-left: 9px;
  padding-right: 9px;
}
.w-65 {
  width: 65% !important;
}
.login--wCard {
  width: 65% !important;
}
.commission-listing-section {
  padding: 30px 2rem !important;
}
.custom-calendar-1 {
  width: 100% !important;
  font-size: 26px;
  border: none !important;
}
.custom-calendar-1 .rc-calendar-header {
  border-bottom: none;
}
.custom-calendar-1 .rc-calendar-body {
  padding: 0;
}
.custom-calendar-1 .rc-calendar-body .rc-calendar-cell > div {
  margin-left: auto;
  margin-right: auto;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 20px !important;
}
.autoCompleted ul {
  width: 100%;
}
.autoCompleted .ant-select-search__field__wrap {
  width: 100%;
}
.help_btn {
  position: absolute !important;
  font-size: 14px;
  right: 10px;
  margin-top: 2px;
  border: 1px solid #d5c4c4;
  padding: 0px 20px;
  border-radius: 5px;
  color: #555;
  bottom: 10px;
  display: flex;
  align-items: center;
}
.help_btn i {
  margin-right: 5px;
}
.help_btn:hover {
  color: black;
}
.req_btn {
  font-size: 14px;
  right: 10px;
  border: 1px solid #d5c4c4;
  padding: 0px 5px;
  border-radius: 5px;
  color: #555;
  bottom: 40px;
  display: flex;
  align-items: center;
}
.req_btn i {
  margin-right: 5px;
}
.req_btn:hover {
  color: black;
}
.custom-container .custom_card .ant-card-head {
  height: 30px;
  background: #f1f1f1;
  font-size: 15px;
  color: #555;
}
.custom-container .custom_card .ant-card-head span {
  font-size: 14px;
  /* margin-left: 5px; */
}
.custom-container .custom_card .ant-card-body {
  padding: 14px 14px 14px 24px;
  color: black;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem !important;
  font-size: 12px !important;
}
.table-striped tr:nth-of-type(odd) {
  background-color: #dff0d8;
}
.flexWrap {
  display: flex;
  flex-wrap: wrap;
}
.commissionRow {
  display: flex;
}
.commissionRow .commBox {
  flex: 1 1;
}
.direct-uni-box .count {
  display: flex !important;
}
.direct-uni-box .canadaDirectUniCount {
  width: 20% !important;
  display: inline-block;
  padding: 0 10px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.direct-uni-box .canadaDirectUniCount:last-child {
  padding-right: 0;
}
.direct-uni-box .canadaDirectUniCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox .record-count {
  display: flex;
  justify-content: center;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox img {
  height: 30px;
  width: auto;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox img.sm {
  height: 25px;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox h5 {
  font-size: 14px;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
.direct-uni-box .canadaDirectUniCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
}
.direct-uni-box .canadaDirectUniCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.direct-uni-box .canadaDirectUniCount .innerBox .btnBox a i {
  margin-right: 5px;
}
.direct-uni-box .canadaDirectUniCount .innerBox .dirUniAgent {
  padding: 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 1px;
  background: #f1f1f1;
  margin: 10px 0;
}
.direct-uni-box .squareContainer {
  margin-top: 15px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
}
.direct-uni-box .squareContainer .squareStyle {
  width: auto;
  height: 32px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  margin-bottom: 0;
  text-align: center;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
.direct-uni-box .squareContainer .squareStyle:nth-child(6) {
  border-bottom: 2px solid #1890ff;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(7) {
  border-bottom: 2px solid #5cb85c;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(8) {
  border-bottom: 2px solid #5cb85c;
}
.commissionBlock .countryCommission {
  width: 20%;
}
@media only screen and (max-width: 1441px) {
  .canadaDirectUniCount .innerBox .uniCountBox {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
  }
  .canadaDirectUniCount .innerBox .uniCountBox img {
    max-height: 20px;
    width: auto;
  }
  .canadaDirectUniCount .innerBox .uniCountBox h5 {
    font-size: 9px !important;
    height: 25px;
    width: auto;
    min-width: 25px;
    line-height: 3;
  }
  .canadaDirectUniCount .innerBox .btnBox a {
    font-size: 11px;
  }
}
@media only screen and (max-width: 560px) {
  section.banner1 {
    height: auto !important;
  }
  section.banner1 .img {
    display: none;
  }
  .login--wCard {
    width: 80% !important;
  }
  .direct-uni-box .canadaDirectUniCount {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .commissionBlock .countryCommission {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .institute--event .table-head h5 {
    width: 65% !important;
  }
  .institute--event .event-teaser .event-meta {
    flex-direction: column;
  }
  .institute--event .event-teaser .event-title {
    padding-right: 0;
  }
  .institute--event .event-teaser .event-date-day1 {
    width: 100%;
  }
  .institute--event .event-teaser .event-venue-wrap1 {
    padding: 20px 0;
    margin-left: 0;
  }
  .institute--event .event-teaser .reg_btn {
    margin-left: 0 !important;
  }
  .institute--event .event-teaser .regTime {
    margin: 10px 0;
  }
}
.WcStyle {
  padding: 3px !important;
}
.penSpan {
  background: #fff;
  white-space: nowrap;
  text-transform: capitalize;
  padding: 3px 12px;
  border-radius: 50px;
  font-weight: 500 !important;
  font-size: 12px;
  border: 2px solid;
}
.gic-list .active {
  color: #50C37E;
  border-color: #50C37E;
}
.gic-list .pending {
  color: #bccf5b;
  border-color: #bccf5b;
}
.rc-table table td span.inactive {
  color: #ff3f5b;
  border-color: #ff3f5b;
}
.rc-table table td:first-child img {
  width: 35px;
}
.rc-table table td a {
  font-weight: 500;
  color: #3E6AB4;
}
.rc-table table td:last-child {
  white-space: nowrap;
}
.rc-table table td:last-child button {
  padding: 0;
  width: 30px;
  height: 30px;
}
.rc-table table td:last-child button img {
  width: 100%;
}
.debit2 {
  color: #ff0000;
  font-weight: 600;
}
.credit2 {
  color: #008000;
  font-weight: 600;
}
.nav-sidebar .nav-item .nav-link1 {
  font-size: 13px !important;
}
.table-head.table-head-new h6 {
  font-size: 15px !important;
  margin: 0 !important;
  width: auto !important;
  padding-right: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.travel-plan-table .ant-table-bordered .ant-table-thead > tr > th,
.travel-plan-table .ant-table-bordered .ant-table-tbody > tr > td {
  vertical-align: top;
}
.gic-manager .header-box {
  padding: 10px 20px 5px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #0f52ba;
}
.round-selector .ant-select-selection__rendered ul {
  padding: 0 10px;
}
.round-selector .ant-select-selection__rendered ul li {
  border-radius: 50px;
}
.feedback-button.selected {
  border: 2px solid #1890ff !important;
}
.tag-gic {
  color: rgba(0, 0, 0, 0.65);
  border-radius: 10px;
  width: 200px;
  display: flex;
  align-items: center;
}
.travel-carousel .slick-list {
  padding-left: 20px;
}
.travel-carousel .slick-dots {
  position: absolute;
  bottom: 0px !important;
}
.travel-carousel .slick-dots li {
  border-bottom: none;
  background: none !important;
}
.travel-carousel .slick-dots li button {
  background: #0872bc !important;
  height: 5px !important;
}
.travel-carousel .slick-arrow.slick-prev {
  font-size: 10px;
}
.travel-carousel .ant-carousel .slick-prev::before {
  content: '<';
  z-index: 1000 !important;
  display: block;
  position: relative;
  bottom: 10px;
  right: 0px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.travel-carousel .slick-arrow.slick-next {
  font-size: 10px;
}
.travel-carousel .ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 0px;
  left: 0;
  bottom: 10px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.marketingUserBox2 .managerHead2 {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
}
.marketingUserBox2 .managerHead2 .universityFlag {
  font-size: 11px;
  font-weight: 600;
}
.marketingUserBox2 .managerHead2 .universityFlag img {
  height: 20px;
  width: auto;
  border-radius: 3px;
}
.marketingUserBox2 .travel-avator {
  border: 2px solid;
  padding-right: 0;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.text-black {
  color: #212529;
}
.ant-input-group-wrapper {
  padding: 0 !important;
}
.ant-input-group-wrapper .ant-input {
  height: 40px;
}
tr.warning_tr td {
  background-color: #fcf8e3 !important;
}
.feedbackButton.selected {
  /* Selected button styles */
  background-color: #f0f0f0 !important;
  color: #333;
  border: 1px solid #ccc;
}
.successTag2 {
  background: #c2cdd5;
  border: 1px solid #0872bc;
  padding: 1px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
}
.managerHead2 {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.universityWiseBlock {
  margin-top: 15px !important;
}
.flagBox {
  height: 35px;
}
.liveReportingOuter {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 30px;
}
.liveReportingOuter .liveReportingInnerBox {
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  font-size: 15px;
  text-align: center;
}
.liveReportingOuter .liveReportingInnerBox .reportingInnerBox {
  height: 100px;
}
.sopModalRow {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
.successMessage {
  font-size: 18px;
  /*color: #1556bc;*/
  color: black;
  margin-bottom: 20px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.viewButton,
.closeButton {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.viewButton {
  background-color: #2196F3;
  color: white;
}
.viewButton:hover {
  background-color: #1976D2;
}
.closeButton {
  background-color: #f44336;
  color: white;
}
.closeButton:hover {
  background-color: #d32f2f;
}
.status-list-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
}
.status-list-grid .status-item-grid {
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
  /*    &::after {
            content: "|";
            display: inline-block;
          }*/
}
.status-list-grid .status-item-grid .status-box {
  display: inline-block;
}
.status-list-grid .status-item-grid .status-box .status-name {
  font-size: 15px;
  color: #666;
}
.status-list-grid .status-item-grid .status-box .status-count {
  color: #0f52ba;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
}
.liveReportTarget {
  margin-top: 10px;
}
.target-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 13px;
  font-weight: 500;
}
.target-box .value-box {
  display: flex;
  justify-content: center;
}
.target-box img {
  height: 20px;
  margin-right: 5px;
}
.target-box span {
  font-size: 17px;
}
.custom-autocomplete .ant-select-search__field__wrap {
  width: 100%;
}
/* Larger screens */
@media (min-width: 1400px) {
  .antd-pro-containers-student-student-diversity-styles-canadaDirectUniCount {
    width: 32% !important;
    padding: 0 15px !important;
  }
  .antd-pro-containers-student-student-diversity-styles-card-pane-right.antd-pro-containers-student-student-diversity-styles-listing-section {
    padding: 30px 3rem 30px 4rem;
    display: flex;
    align-items: center;
  }
  .antd-pro-containers-student-student-diversity-styles-download-pdf-flag {
    width: 25px !important;
    height: 21px !important;
  }
  .antd-pro-containers-student-student-diversity-styles-download-pdf-text {
    font-weight: bold !important;
    font-size: 19px !important;
  }
  .antd-pro-containers-student-student-diversity-styles-card-pane-right.antd-pro-containers-student-student-diversity-styles-listing-section ul li p {
    word-spacing: normal;
  }
  .antd-pro-containers-student-student-diversity-styles-card-pane-right.antd-pro-containers-student-student-diversity-styles-listing-section ul li {
    width: 23.6% !important;
    display: inline-block;
  }
}
@media print {
  body {
    font-family: Arial, sans-serif;
    font-size: 12px;
  }
  .antd-pro-containers-student-student-diversity-styles-card-body {
    padding: 8px;
  }
  .antd-pro-containers-student-student-diversity-styles-download-pdf-flag {
    height: 20px;
    width: 25px;
    margin-right: 15px;
  }
  h5 {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
}
.antd-pro-containers-student-student-diversity-styles-flexUl {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}
.antd-pro-containers-student-student-diversity-styles-flexUl li {
  width: 32%;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 5px;
  height: 38px;
  border-radius: 5px;
}
.antd-pro-containers-student-student-diversity-styles-flexUl li:nth-child(2) {
  margin: 0 2%;
}
.antd-pro-containers-student-student-diversity-styles-singleCountry {
  padding-left: 5px;
}
.antd-pro-containers-student-student-diversity-styles-singleCountry img {
  height: 15px;
  margin-right: 5px;
  opacity: 0.8;
}
.antd-pro-containers-student-student-diversity-styles-singleCountry div {
  font-size: 12px;
  margin-top: 0;
  transform: rotate(335deg);
  color: #555;
  font-weight: bold;
}
.antd-pro-containers-student-student-diversity-styles-count {
  font-size: 17px;
  color: #0f52ba;
  padding-right: 10px;
}

.date-picker {
    height: 33px !important;
}

/* TaskManager.css */

.task-manager-card {
    width: 90%;
    margin: 20px 30px;
}

.task-manager-table-head {
    display: flex;
    align-items: center;
}

.task-manager-card-body {
    display: flex;
    flex-direction: column;
}

.task-manager-input-section {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.task-manager-input {
    width: 40%;
    margin-right: 8px;
}

.task-manager-datepicker {
    width: 150px;
    height: 32px;
}

.task-manager-assignee-input {
    width: 150px;
    margin-left: 8px;
}

.task-manager-priority-input {
    width: 150px;
    margin-left: 8px;
    border-radius: 15px;
}

.task-manager-add-task-button {
    background-color: #164fb6;
    border: none;
    margin-right: 14px;
}


body {
    font-family: 'Arial', sans-serif; /* Choose a suitable font-family */
}

/* Component-specific styles */
.card {
    background-color: #f5f5f5; /* Light gray background */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Add padding for better spacing */
}

.taskWrapper {
    background-color: #0f52ba; /* Dark blue header background */
    color: white; /* White text color */
    padding: 10px; /* Add padding to the header */
    border-radius: 8px; /* Rounded corners for the header */
    height: 20px;
}

.card-body {
    margin-top: 20px; /* Add some space between the header and content */
}

/* Input and Button styles */
.ant-input,
.date-picker,
.ant-select-selection {
    border-radius: 5px; /* Rounded corners for input and select elements */
}

/* Button styles */
.ant-btn-primary {
    background-color: #0f52ba; /* Dark red for primary buttons */
    border-color: #0f52ba; /* Dark red border color */
    border-radius: 5px; /* Rounded corners for buttons */
}

.ant-btn-link {
    color: #000; /* Dark red for link buttons */
}

/* Modal styles */
.ant-modal-header {

    color: white; /* White text color for modal header */
    border-radius: 8px 8px 0 0; /* Rounded corners for modal header */
}

.ant-modal-body {
    padding: 20px; /* Add padding to the modal body */
}

/* List styles */
.ant-list-item {
    border-bottom: 1px solid #d9d9d9; /* Light gray border between list items */
}

/* Button inside List styles */
.ant-btn-link {
    color: #000; /* Dark red for link buttons inside the list */
}

/* Button inside Popconfirm styles */
.ant-popover-inner-content {
    background-color: #fff; /* White background for Popconfirm content */
}

.ant-btn-danger {
    color: #fff; /* White text color for danger buttons */
    background-color: #f5222d; /* Red background for danger buttons */
    border-color: #f5222d; /* Red border color for danger buttons */
}

.card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

/* style.css or your custom CSS file */
.input-hover {
    transition: box-shadow 0.3s ease;
}

.input-hover:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.button-hover {
    transition: transform 0.3s ease;
}

.button-hover:hover {
    transform: scale(1.2);
}

.primary-button-hover {
    transition: background-color 0.3s ease;
}

.cancel-button-hover {
    transition: background-color 0.3s ease;
}

.cancel-button-hover:hover {
    color: white
}

.remove-btn:hover {
    color: white;
    border: none;
}

.datepicker-input {
    height: 34px;
}

.group-by-button-hover {
    border-bottom: 1px solid transparent !important;
    border: none;
    margin-left: 8px;
    transition: border-color 0.3s ease-in-out;
}

.group-by-button-hover:hover {
    border-color: #1890ff !important;
}

.show-complete-tasks-button-hover,
.show-future-tasks-button-hover {
    border-bottom: 1px solid transparent !important;
    border: none;
    margin-left: 8px;
    transition: border-color 0.3s ease-in-out;
}

.show-complete-tasks-button-hover:hover,
.show-future-tasks-button-hover:hover {
    border-color: #1890ff !important;
}

/* Add these styles to your CSS or SCSS file */
.group-by-button-hover,
.show-complete-tasks-button-hover,
.show-future-tasks-button-hover {
    border-bottom: 1px solid transparent !important;
    border: none;
    margin-left: 8px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

.group-by-button-hover:hover,
.show-complete-tasks-button-hover:hover,
.show-future-tasks-button-hover:hover {
    border-color: #1890ff !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust box shadow on hover */
}


/* Add these styles to your CSS or SCSS file */
.table-comp-shadow {
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1); /* Add box shadow */
    transition: box-shadow 0.3s ease-in-out;
}

.table-comp-shadow:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust box shadow on hover */
}


/* Recorder styling */
.recorder-container {
    margin: 20px;
}

.recorder-buttons {
    margin-bottom: 10px;
    position: relative;

}

.btn-record {
    margin-right: 10px;
}

.live-video {

    width: 100%;
    height: 100%;
}

.audio-recording-icon {
    animation: blink-animation 0.5s infinite alternate;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.audio-recording-modal .ant-modal-content {
    position: relative;
}

.audio-recording-modal .delete-audio-btn {
    position: absolute;
    top: 92px;
    padding-left: 10px;
    /* right: 324px; */
    color: red;
    font-size: 18px;
}

.mini-screen-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    background-color: black;
}

.mini-screen {
    position: relative;
    width: 300px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
    border: 1px solid #ccc;
}

.mini-screen-video {
    width: 100%;
    height: 100%;
}


/*drawer task profile */

.head-text h6 {
    font: 30px/30px var(--ui-font-family-secondary, var(--ui-font-family-open-sans));
    font-weight: var(--ui-font-weight-light, 300);
    margin: 0;
    padding: 21px 0 21px 0;
    display: block;
    word-wrap: break-word;
    margin-left: 20px;
}

.drawer-head {
    margin: 0px 5px 5px 5px;

}

.contents {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.left-side {
    background-color: white;
    border: 1px solid white;
    border-radius: 8px;
    width: 63%;
    margin: 0px 22px 10px 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    height: 320px;
}

.progress-wrap {
    background-color: white;
    border: 1px solid white;
    border-radius: 8px;
    width: 64%;
    margin: 0px 10px 10px 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.right-side {
    background-color: white;
    border: 1px solid white;
    border-radius: 8px;
    width: 35%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.text-area {
    overflow: auto;
    resize: vertical;
    border-radius: 7px;
    border: none;
    width: 100%;
    height: 109px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
}


.tabs-container1 {
    background: #f0f0f0;
}

.custom-tabs1 {
    display: flex;
    padding: 1px;
}

.custom-tabs1 button {
    font-size: 15px;
    margin: 5px;
    margin-right: 0px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease;
    padding: 8px 12px;
    border-radius: 5px;
}

.custom-tabs1 button:hover {
    background-color: #56D1E0;
    color: white;
}

.custom-tabs1 button.active {
    background-color: #56D1E0;
    color: white;
}

.custom-tabs1 button:active {
    background-color: #56D1E0;
    color: white;
}

.custom-tabs1 button:focus {
    outline: none;
}

.progress-color {
    position: relative;
    display: inline-block;
    width: 90%;
    overflow: hidden;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 100px;
    margin: 16px;
}

.right-text {
    margin: 14px;
    color: gray;
}

.info-text {
    display: flex;
    align-items: center;
    padding: 0px;
    background: rgba(47, 198, 246, .1);
    border-radius: 48px;
    margin: 0px 63px;
    width: 80%;
}

.comments-box, .history-box {
    width: 62%;
    background-color: white;
    /* border-radius: 0px 8px 0px 8px; */
    padding: 15px;
    height: 300px;
    overflow-y: auto;
    margin-left: 19px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


.comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    /*margin-right: 30px;*/
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.comment-text {
    flex: 1 1;
    background-color: #f0f0f0;
    border-radius: 35px;
    padding: 12px 20px 12px 20px;
    max-width: 85%;
}
.comment-author {
    margin-bottom: 5px;
}

.comment-author .name {
    text-transform: capitalize;
}

.comment-author .date {
    color: #0f52ba;
    font-size: 10px;
    font-style: italic;
    float: right;
    font-weight: bold;
}

.time-span {
    color: #0f52ba;
    font-size: 10px !important;
    font-style: italic;
}

.tab-menu {
    padding: 11px;
    border: none;
    border-radius: 12px 12px 0 0;
    margin-right: 5px;
    margin-left: 19px;
    background: #e5e0e9;
}

.tab-menu:hover {
    background-color: white;
}

.tab-menu.active {
    background-color: white;
}

.input-comment {
    text-overflow: ellipsis;
    flex-grow: 1;
    margin-right: 10px;
    height: 50px !important;
    border-radius: 20px;
    width: 80%;
}

.activityTab {
    margin-left: 19px;
    background-color: white;
    width: 62%;
    border-radius: 10px;
    padding: 10px;
    flex-direction: column;
}

.left-row {

    margin-bottom: 10px;
    background: #0f52ba;
    padding: 10px;
    margin-top: -6px;
    border-radius: 5px;
}


/* Add this CSS in your stylesheet */
.priority-select {
    position: relative;
    display: inline-block;
}

.priority-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    font-size: 14px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
    width: 120px;
}

.priority-select::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.priority-select select:hover,
.priority-select select:focus {
    border-color: #007bff;
}


.task-text {
    font-size: 14px;
    color: #555;

}

.even-row {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;
}

.odd-row {
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
    padding: 10px;
}

.filter-select {
    border: none;
    height: 34px !important;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.filter-select .ant-calendar-picker-input.ant-input, .ant-time-picker-input {
    height: 34px !important;
    border: none;
    border-radius: 5px;
}
.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  overflow: hidden;
}

.card-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.card-content {
  text-align: center;
  font-size: 14px;
  transition: transform 0.3s ease-in-out;
}

/*.card-container:hover .card-content {
  transform: scale(1.01);
  height: 330px;
}*/

.icon-plus {
  float: right;
  font-size: 20px;
  border: 1px solid white;
  padding: 4px;
  margin-right: -71px;
  transition: font-size 0.3s ease-in-out;
}

.icon-plus:hover {
  font-size: 22px;
  color: white;
}

.custom-panel:hover,
.custom-panel:active,
.custom-panel:focus {
  background: linear-gradient(to right, #87ceeb, #0f52ba);
}

.custom-panel:hover > .ant-collapse-header,
.custom-panel:active > .ant-collapse-header,
.custom-panel:focus > .ant-collapse-header {
  color: white;
}

.collapsible {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.button-part,
.button-part1,
.button-part2,
.button-part3 {
  background-color: rgb(142, 16, 41);
  color: white;
  width: 100%;
  border: none;
  min-height: 37px;
  transition: transform 0.3s ease;
}

.button-part:hover,
.button-part1:hover,
.button-part2:hover,
.button-part3:hover {
  transform: scale(1.1);
  background-color: rgb(142, 16, 41);
  color: white;
}

.button-part1 {
  background-color: #0f52ba;
}

.button-part1:hover {
  background-color: #0f52ba;
  color: white;
}

.button-part2 {
  background-color: rgb(4, 147, 4);
}

.button-part2:hover {
  background-color: rgb(4, 147, 4);
  color: white;
}

.button-part3 {
  background-color: purple;
}

.button-part3:hover {
  background-color: purple;
  color: white;
}


.profile-area {
  padding: 10px;
}

.profile-img-container {
  text-align: center;
}

.profile-img {
  border: 2px solid #0f52ba;
  border-radius: 50%;
  font-weight: 400;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.head-text {
  color: #0f52ba;
  margin-bottom: 15px;
}

.profile-text {
  color: gray;
  font-size: 14px;
  margin-bottom: 5px;
}

.profile-text a {
  text-decoration: underline;
  font-size: 12px;
  color: black;
}

.profile-text i {
  margin-right: 5px;
  margin-left: -6px;
}

.upcoming-events-title {
  color: #0f52ba;
  margin-left: 10px;
}

/*.add-event-btn {
  margin-top: 1rem;
  border: 2px solid white;
  padding: 6px;
  margin-left: 240px;
}*/

.past-events-title {
  color: #0f52ba;
  margin-left: 8px;
}

.past-events-row {
  margin-top: 30px;
}

.card-content {
  color: white;
}

.table-container {
  overflow-x: auto;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 100%;
}

.custom-icon {
  font-size: medium;
  color: gray;
  transition: transform 0.3s ease-in-out;
  background: white;
  border: 1px solid grey;
  border-radius: 3px;
  padding: 5px;
  margin-right: 5px;
}

.custom-icon:hover {
  transform: scale(1.2)
}

.custom-cell {
  background: gray;
  color: white;
  border-radius: 2px;
  padding: 5px;
  text-align: center;
}

.record-type {
  float: right;
  padding: 0px 3px;
}

.record-type.agent {
  background-color: rgb(142, 16, 41);
}

.record-type.educator {
  background-color: #0f52ba;
}

.record-type.university {
  background-color: rgb(4, 147, 4);

}

.recordEmail {
  color: blue;
}

.custom-form {
  margin: 20px;
  padding: 20px;
}


/* add-followup.css */

.form-container-follow {
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.form-item-follow {
  margin-bottom: 20px;
}

.button-row-follow {
  margin-top: 20px;
}

.save-button-follow {
  margin-right: 10px;
}

.ant-select-combobox .ant-select-search__field__wrap {
  width: 200%;
  height: 100%;
}

.drawer-content {
  margin-top: 5rem;
}

.agent-details {
  background: linear-gradient(to right, #87ceeb, #0f52ba);
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.agent-details h6 {
  margin: 0;
}

.event-details {
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  border-radius: 7px;
}

.all-details {
  padding: 20px;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid #fbf7f7;
}

.event-details-left {
  margin-left: 10px;
}

.event-details h6 {
  margin: 0;
}

.event-details p {
  margin: 0;
}

.agent-details h6, .history-div h6 {
  font-size: 14px;
}

.agent-name1, .history-div h6 {
  background-color: #0f52ba;
  color: white;
  padding: 4px;
  border-radius: 6px;
  text-align: center;
}

.history-div h6 {
  padding: 10px;
}

.agent-details span, .event-details-left span {
  float: right;
}

.event-details-left span {
  color: gray;
  font-size: 13px;
}

.avatar_text1 {
  background-color: #0f52ba;
  margin: 0 12px;
  font-size: 16px !important;
  /* border: 2px solid white; */
}

.sendBtn1 {
  border-radius: 15px !important;
  background-color: #0f52ba;
  border: none;
  color: white;
  padding: 14px !important;
}

.comment-author .date1 {
    /* color: #0f52ba; */
    font-size: 10px;
    font-style: italic;
    /* float: right; */
    /* font-weight: bold; */
    padding-left: 8px;
    color: #0f52ba;
    font-weight: 600;
}

.input-comment1 {
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 10px;
  height: 50px !important;
  /* border-radius: 20px; */
  width: 80%;
  border: none;
  background: #efeded;
}

.meeting-details-table {
  width: 100%;
}

.label-column {
  width: 150px;
  font-weight: bold;
}

.agent-details h6 {
  margin: 0;
  font-size: 16px;
  color: #fff;
}

.all-details {
  padding: 20px;
}

.meeting-details-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.meeting-details-table td:first-child {
  font-weight: bold;
}

.meeting-details-table tr:last-child td {
  border-bottom: none;
}


.meeting-panel {
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
}

.meeting-panel .meeting-details {
  padding: 10px;
}

.comment-panel {
  margin-top: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
}

.comment-wrap {
  max-height: 400px;
  overflow-y: auto;
}

.comment-wrap-new {
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}

.comment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.comment-text {
  margin-left: 10px;
}

.comment-text .mb5 {
  margin-bottom: 5px;
}

.time-span {
  color: #888;
  font-size: 12px;
}


.translucent-bg {
  background-color: rgba(255, 255, 255, 0.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.card-event {
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  overflow: hidden;
}

.card-content1 {
  /* text-align: center; */
  /* font-size: 16px; */
  color: white;
  transition: transform 0.3s ease-in-out;
  margin-top: 127px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.event-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.description {
  margin-bottom: 10px;
}

.show-more {
  color: orange;
  cursor: pointer;
}

.details-section {
  margin-top: 20px;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detail-item .detail-label {
  margin-right: 10px;
  min-width: 80px;
  display: inline-block;
  font-weight: bold;
}

.details-header-info {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 17px;
}

.col-with-line {
  border-right: 1px solid #ccc;
  padding-right: 20px;
}

.commentText1 {
  line-height: 13px;

}

 {
  /*.inputBox {
    margin-top: 10px;
    margin-bottom: 10px;

    .labelRow {
      line-height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0;

      .extra {
        a {
          margin-left: 10px;
        }
      }
    }

    .labelNew {
      //line-height: 25px;
      line-height: 34px;
      color: rgba(0, 0, 0, 0.65);
      float: left;
      margin: 0 15px 0;
    }

    .innerBox {
      //margin-top: 14px;

      &.logoCls {
        //margin-top: 10px;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }

    .uniTypeCheckbox {
      .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        label {
          width: 45%;
          margin-bottom: 5px;
        }
      }
    }
  }*/
  /*.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
    color: rgba(255, 255, 255, 0.9);

    &:hover {
      text-decoration: underline;
    }
  }*/
  /* .ant-menu-inline-collapsed > .ant-menu-item .anticon + span {
     max-width: 100%;
   }*/
  /*  .PhoneInputInput {
      border-width: 1px !important;
      height: 42px !important;
      border-color: #666;
      color: #666;
      border-radius: 6px;
    }*/
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  /* Dropdown Button */
  /* The container <div> - needed to position the dropdown content */
  /* Dropdown Content (Hidden by Default) */
  /* Links inside the dropdown */
  /* .btn {
     width: 150px;
     background: #1556bc;
     font-size: 14px;
     text-transform: uppercase;
     color: #fff;
     margin: 25px 0 0;
     border-radius: 50px;
     font-weight: 600;
     height: 42px;
   }*/
}
body {
  color: #212529 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
}
.logo-link {
  display: none;
}
.ant-layout {
  background: white;
}
.ant-card-head .ant-drawer-title,
.ant-drawer-header .ant-drawer-title {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin: 0;
}
input[type='text'],
input[type='password'],
input[type='number'],
.ant-select-selection {
  height: 33px;
}
.ant-input,
.ant-input-number,
.ant-select-selection--single {
  height: 33px;
}
.ant-input.ant-select-search__field {
  height: 43px !important;
  border: 1px solid #666 !important;
}
.ant-select {
  width: 100%;
  font-size: 12px;
}
.mt0 {
  margin-top: 0 !important;
}
.mt1 {
  margin-top: 1px !important;
}
.ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100%;
}
.mt3 {
  margin-top: 3px !important;
}
.mt5 {
  margin-top: 7px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt18 {
  margin-top: 18px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb5 {
  margin-bottom: 5px;
}
.ml5 {
  margin-left: 5px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr0 {
  margin-right: 0 !important;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mb100 {
  margin-bottom: 100px;
}
.customForm .ant-select {
  width: 100%;
}
.customForm .ant-form-item-label {
  line-height: 28.9999px;
}
.customForm .ant-form-item {
  margin-bottom: 2px;
}
.ant-layout-sider {
  background: white;
}
.ant-form-item {
  margin-bottom: 2px;
}
.antd-pro-components-sider-menu-index-logo {
  text-align: center;
  height: 60px;
  background: white !important;
  padding: 5px 5px 5px 0;
  display: flex;
}
.antd-pro-components-sider-menu-index-logo img {
  height: 100%;
}
.ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo {
  text-align: left;
  height: 60px;
  background: white;
  padding: 5px;
  display: flex;
}
.ant-layout-sider-collapsed {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}
.ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo a {
  text-align: center;
}
.ant-menu-submenu-popup {
  left: 120px !important;
}
.ant-menu-inline-collapsed {
  padding-top: 0 !important;
  /*.ant-menu-item-selected {
      background-color: #22ade2 !important;
    }*/
  /* li {
       padding: 0 10px !important;
       display: flex;
       width: 100% !important;
       justify-content: center;
       align-items: center;
       height: 60px !important;

       .ant-menu-submenu > .ant-menu-submenu-title {
         padding: 0 10px !important;
       }

       span, a {
         line-height: 15px;
         color: #fff;
         display: flex !important;
         flex-direction: column !important;
         width: 100% !important;
         justify-content: center;
         align-items: center;

         span {
           opacity: 1 !important;
         }
       }
     }*/
}
.englishTypeBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.englishTypeBox div {
  width: 80px;
}
.inputBox {
  /*margin-top: 10px;
    margin-bottom: 10px;*/
  margin-bottom: 12px;
}
.inputBox .labelRow {
  padding: 0 10px;
  display: inline-block;
  overflow: hidden;
  line-height: 30px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.inputBox .labelRow .extra a {
  color: #000000d9;
  margin-left: 10px;
  text-decoration: underline;
}
.inputBox .labelNew {
  float: left;
  color: #666;
  margin-bottom: 0;
}
.inputBox .innerBox {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
.inputBox .innerBox .ant-calendar-picker {
  width: 100%;
}
.inputBox .uniTypeCheckbox .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.inputBox .uniTypeCheckbox .ant-checkbox-group label {
  width: 45%;
  margin-bottom: 5px;
}
.ant-select-selection--multiple {
  height: auto;
}
.ant-input-number {
  width: 100%;
}
.courseBox {
  line-height: 22px;
}
.courseBox .ant-card-body {
  padding-bottom: 0;
}
.courseBox a {
  color: #0064e1;
}
.courseBox .ant-card-bordered {
  border: none;
  border-bottom: 1px solid #e8e8e8;
}
.courseBox .uniInfo {
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 8px 24px;
}
.courseBox .uniInfo img {
  height: 48px !important;
  width: 48px !important;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.courseBox .uniInfo .uniName {
  font-size: 20px;
}
.courseBox .uniInfo .uniName .uniAdd {
  font-size: 15px;
}
.courseBox .courseRow {
  line-height: 22px;
  margin-bottom: 17px;
  margin-top: 9px;
  padding: 12px 24px;
}
.courseBox .courseRow:last-child {
  border: none;
}
.courseBox .courseRow .courseName {
  font-size: 13px;
  margin-right: 20px;
}
.courseBox .courseRow .courseInfo {
  font-weight: normal;
  font-size: 15px;
  margin-top: 8px;
}
.courseBox .courseRow .courseInfo .ant-col {
  line-height: 1;
}
.courseBox .courseRow .courseInfo .ant-col span {
  font-size: 12px;
}
.courseBox .courseRow .courseInfo .ant-col small {
  font-size: 12px;
}
.courseBox .courseRow .courseInfo .ant-col button {
  color: #2f8ac9;
}
.courseBox .loadAllBtn {
  display: flex;
  justify-content: space-between;
  padding: 10px 100px;
  position: absolute;
  z-index: 999;
  top: 100px;
  width: 100%;
  background: #1890ff54;
  font-size: 17px;
  font-weight: 500;
  align-items: center;
  border-left: 10px solid #1890ff;
}
.universityBox a {
  color: #0064e1;
}
.universityBox .ant-col {
  margin-bottom: 10px;
}
.universityBox .uniInfo {
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 8px 5px;
  height: 50px;
}
.universityBox .uniInfo img {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.universityBox .uniInfo .uniName {
  font-size: 15px;
}
.universityBox .uniInfo .uniName .uniAdd {
  font-size: 13px;
}
.linkBtn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  height: 32px;
  padding: 3px 40px;
  font-size: 13px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}
.linkBtn i {
  margin-right: 5px;
}
.linkBtn.small {
  height: 28px;
  padding: 2px 10px;
}
.alignCenter {
  text-align: center;
  cursor: pointer;
}
.alignRight {
  text-align: right;
}
.alignLeft {
  text-align: left !important;
}
.loadMoreBox {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 15px;
}
.totalCourseCount {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
}
.vertical-form .header {
  font-size: 25px !important;
  color: #606a84;
  margin-top: 10px;
}
.vertical-form .ant-card {
  margin-bottom: 20px;
}
.vertical-form .ant-row {
  margin-bottom: 0;
}
.vertical-form .ant-row .ant-form-item-label {
  text-align: left;
  line-height: 30px;
}
.vertical-form .ant-calendar-picker {
  width: 100%;
}
.sticky {
  /*position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    left: 0;*/
  position: fixed;
  top: 0;
  width: calc(100% - 168px);
  z-index: 10;
  opacity: 0.9;
}
.outerLoader {
  height: 100%;
  width: 100%;
  background: #ffffffc7;
  z-index: 9999;
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1890ff;
  font-size: 25px;
}
.outerLoader .ant-spin-dot {
  font-size: 40px;
}
.outerLoader .mainLoader {
  margin-top: 20px;
  font-size: 50px;
}
.outerLoader .mainLoader i {
  margin-right: 20px;
}
.imgSm {
  height: 50px;
  width: 50px;
}
input[type='file']::-webkit-file-upload-button {
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  font-weight: normal;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  height: 100%;
  border: none;
  border-right: 1px solid #ddd;
  width: 150px;
  margin-right: 10px;
}
.ant-form input[type='file']:focus {
  outline: 0 auto -webkit-focus-ring-color !important;
  border-color: white;
}
.rowFlex {
  display: flex;
}
.rowFlex > div {
  width: 100%;
}
.rowFlex button {
  margin-left: 10px;
}
.rowFlex .d1 {
  width: 100%;
}
.rowFlex .d1.mr10 {
  margin-right: 10px;
}
.rowFlex .d2 {
  width: 100%;
  margin-left: 10px;
}
.linkBtn {
  line-height: 1.499;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  height: 40px;
  padding: 0 10px;
  font-size: 18px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #666;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 5px;
  background-color: white;
  margin-top: 1px;
}
.linkBtn.sm {
  height: 25px;
  padding: 0 10px;
  font-size: 13px;
}
ul.list {
  padding-left: 0;
}
ul.list li {
  justify-content: space-between;
  border: 1px solid #ddd;
  align-items: center;
  padding: 5px 10px;
  border-bottom: none;
}
ul.list li:last-child {
  border-bottom: 1px solid #ddd;
}
.padd-5-bb-1 {
  border-bottom: 1px solid #d3d3d34a;
  padding: 7px 5px;
  font-size: 13px;
  color: #2a2a2a;
}
.padd-5-bb-1 .ant-row .ant-col:first-child {
  color: #333 !important;
}
.ant-success {
  color: #0872bc;
}
.ant-info {
  color: #5bc0de;
}
.ant-warning {
  color: rgba(240, 173, 78, 0.87);
}
.card.unizportal button.btn[type='submit'] {
  width: 150px;
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  height: 42px !important;
}
.ant-btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.ant-btn-basic {
  color: #333;
  background-color: #f1f1f1;
  border-color: #f7ebeb;
}
.successTag {
  background: #0872bc;
  border: 1px solid #0872bc;
  padding: 1px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
}
.statusDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.statusDiv .statusBox {
  height: 30px;
  background: #fff;
  border-radius: 0;
  text-align: center;
  border: 1px solid #ddd;
  margin: 3px 0;
  color: #333;
  padding: 3px;
  font-size: 12px;
  width: 350px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.statusDiv .statusBox .icon {
  position: absolute;
  right: 10px;
  color: rgba(240, 173, 78, 0.87);
  top: 25%;
}
.bgTheme {
  background-color: #0872bc !important;
  border: 1px solid #0872bc !important;
  color: white !important;
}
.bgTheme .icon {
  color: #0872bc !important;
}
table tbody tr:nth-child(odd) {
  background: white;
}
table tbody tr:nth-child(even) {
  background: #f9f9f9;
}
.applicationProfile {
  overflow: hidden;
  height: calc(100vh - 200);
}
.applicationProfile .imgDiv {
  display: inline-flex;
}
.applicationProfile aside {
  display: none;
}
.applicationProfile .ant-layout-content {
  padding: 0;
  margin: 0;
}
.applicationProfile .ant-drawer-header {
  background-color: #fafafa;
  border-radius: 0;
  padding: 10px 20px;
}
.applicationProfile .ant-drawer-header .ant-drawer-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 15px;
}
.applicationProfile .ant-drawer-header .ant-drawer-close {
  color: rgba(0, 0, 0, 0.85);
}
.applicationProfile .input-group-box {
  width: 40%;
}
.applicationProfile .logo-link {
  display: block;
}
.applicationProfile .displayName {
  display: none;
}
.bgWhite {
  background: white !important;
}
.appProfile {
  overflow: hidden !important;
  background: #f5f8fa;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.appProfile ::-webkit-scrollbar {
  width: 5px;
}
.appProfile ::-webkit-scrollbar-thumb {
  background: #a9a9a9;
}
.appProfile .align-self-stretch {
  display: flex;
  align-self: stretch !important;
  word-break: break-word;
  overflow: visible;
  flex-grow: 1;
  width: 100%;
}
.appProfile .align-self-stretch .profile-3-column {
  overflow: scroll;
  height: calc(100vh - 1px);
  padding-bottom: 45px;
}
.appProfile .align-self-stretch .profile-3-column.profile-3-col-sidebar.profile-3-col-sidebar--left {
  border-right: 1px solid #dfe3eb;
  padding-bottom: 52px;
  flex-basis: 350px;
  max-width: 500px;
  min-width: 280px;
}
.appProfile .align-self-stretch .profile-3-column.profile-3-col-sidebar--right-wrapper {
  background-color: #fff;
  display: block;
  flex-basis: 25%;
  max-width: 500px;
  transition: flex 0.15s cubic-bezier(0.16, 0.84, 0.44, 1);
  will-change: flex;
  min-width: 280px;
}
.appProfile .align-self-stretch .profile-6-column {
  padding-bottom: 45px;
}
.appProfile .align-self-stretch .profile-6-column.noteBlock {
  flex-basis: 50%;
  height: 100vh;
  padding-left: 10px;
}
.appProfile .align-self-stretch .profile-12-column {
  padding-bottom: 45px;
}
.appProfile .align-self-stretch .profile-12-column.noteBlock {
  /* flex-basis: 100%;
           //padding-top: 10px;
           height: 100vh;
           //border-left: 1px solid #ddd;
           //border-right: 1px solid #ddd;
           padding-left: 10px;
           overflow: hidden;
           border-bottom: 3px solid #ededed;
           padding-top: 15px;*/
  flex-basis: 100%;
  height: 100vh;
  /* padding-left: 10px; */
  overflow: hidden;
  border-bottom: 3px solid #ededed;
  /* padding-top: 15px; */
  border-top: 1px solid #ededed;
}
.appProfile .align-self-stretch .profile-12-column.noteBlock.noteBlockAgent {
  height: auto !important;
  max-height: 100vh !important;
}
.appProfile .align-self-stretch .profile-12-column.noteBlock.noteBlockAgent .scrollView {
  height: auto !important;
  max-height: 100vh !important;
}
.appProfile .infoBox {
  line-height: 1.5;
  margin-bottom: 10px;
}
.appProfile .infoBox label {
  font-size: 12px;
  color: #1890ff;
}
.appProfile .bgWhite {
  background: white !important;
}
.appProfile .bgWhite .ant-card-body {
  padding: 0;
}
.appProfile .actionBlock {
  display: flex;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background: white;
  margin-top: 10px;
}
.appProfile .stuInfo {
  padding: 18px 45px;
}
.appProfile .leftBlock {
  text-align: center;
}
.appProfile .bgImg {
  height: 80px;
  width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
}
.appProfile .logo {
  height: 80px;
  width: 80px;
}
.appProfile .font15 {
  font-size: 15px;
}
.appProfile .appInfo {
  margin-top: 10px;
  font-size: 13px;
  line-height: 2.3em;
}
.appProfile .appInfo .moveBtn {
  display: flex;
  justify-content: space-between;
}
.appProfile .appInfo .moveBtn .ant-btn {
  margin-left: 10px;
}
.appProfile .ant-collapse-header {
  background: white;
}
.appProfile .ant-collapse-content {
  border: none;
}
.appProfile .noteBlock {
  height: 100vh;
  border-left: 3px solid #ededed;
  border-right: 3px solid #ededed;
  overflow: scroll;
  background: white;
  /* &::-webkit-scrollbar-track {
         background: #f1f1f1;
       }

       &::-webkit-scrollbar-thumb {
         background: @primary;
       }

       &::-webkit-scrollbar-thumb:hover {
         background: @success;
       }*/
}
.appProfile .noteBlock::-webkit-scrollbar {
  width: 3px;
}
.appProfile .noteBlock .activityBlock {
  padding: 30px 10px 10px 50px;
}
.appProfile .noteBlock .noteCardOuter {
  margin: 10px;
}
.appProfile .noteBlock .noteCardOuter .dateLabel {
  font-size: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.appProfile .noteBlock .noteCardOuter .noteCard {
  background: white;
  line-height: 2.5;
  font-size: 15px;
  box-shadow: 2px 2px 10px #ddd;
  margin-bottom: 20px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content {
  display: flex;
  padding: 5px 20px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content i {
  margin-top: 10px;
  margin-right: 20px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent {
  width: 100%;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent .title {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent .title .date {
  font-size: 12px;
  color: #9d8e8e;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm {
  padding: 0;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent {
  width: 100%;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title {
  background: #dfe6ed;
  color: black;
  padding: 0 10px;
  border: 1px solid #80808014;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  font-size: 12px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit {
  display: flex;
  padding: 5px 0;
  align-items: flex-start;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit i {
  font-size: 12px;
  margin-right: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit .subject {
  line-height: 1;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .commContent {
  padding: 10px 20px 10px 50px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .commContent strong {
  margin-right: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .date {
  display: flex;
  font-size: 10px;
  line-height: 1;
  padding: 5px 0;
  opacity: 0.7;
}
.appProfile .noteBlock .noteCardOuter .noteCard img {
  height: 30px;
  margin-right: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .commentBlock {
  border-top: 1px solid #ddd;
  padding: 5px 20px;
  color: #1890ff;
  display: flex;
  justify-items: center;
  align-items: center;
  font-weight: 600;
  margin-top: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .commentBlock a {
  color: #1890ff;
  margin-left: 10px;
}
.customTag .ant-tag {
  border: none;
  background: #ddffff !important;
  padding: 2px 8px;
}
.customTag .ant-tag.noneCls {
  border: none;
  background: none !important;
  font-size: 13px;
  color: #2a2a2a;
}
.documentTitle {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 15px;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background: white;
  margin-top: 10px;
}
.documentTitle span {
  display: flex;
  align-items: center;
}
.oldDoc {
  background: #ddffff !important;
}
.oldDoc span {
  text-decoration: line-through !important;
}
.documentBlock .ant-collapse-header {
  font-weight: bold;
}
.documentBlock .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.documentBlock .documentDownBlock {
  margin-top: 10px;
  background: #dddddd4a;
  padding: 5px;
}
.documentBlock .documentText {
  font-size: 13px;
  padding-right: 15px;
}
.btnGroup {
  display: flex;
  justify-content: space-between;
}
.btnGroup .eBtn img {
  height: 40px;
  margin-bottom: 5px;
}
.btnGroup .eBtn button {
  margin-bottom: 10px;
}
.dashboardCard .ant-card-body .ant-card-meta {
  border-bottom: 1px solid #e8e8e8 !important;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.dashboardCard .ant-col .ant-card {
  text-align: center;
  text-transform: uppercase;
  padding: 0px 12px 0px 0px;
  font-weight: 600;
  border: none;
}
.dashboardCard .ant-col .ant-card .title {
  height: 25px;
  font-size: 12px;
}
.dashboardCard .ant-col .ant-card .count {
  margin-top: 10px;
}
.dashboardCard .ant-col .ant-card .count p {
  font-size: 28px;
  width: 80px;
  margin: 0 auto;
  padding: 6px;
  border-radius: 20px;
  color: #1890ff;
  cursor: pointer;
}
.dashboardCard .universityCountBox {
  padding: 10px;
  height: 400px;
  overflow-y: scroll;
}
.dashboardCard .universityCountBox::-webkit-scrollbar {
  width: 3px;
}
.dashboardCard .universityCountBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.dashboardCard .universityCountBox::-webkit-scrollbar-thumb {
  background: #1890ff;
}
.dashboardCard .universityCountBox::-webkit-scrollbar-thumb:hover {
  background: #0872bc;
}
.ant-menu-submenu-title {
  font-size: 13px;
}
.ant-menu-submenu-title span {
  font-size: 13px !important;
}
.drawerFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 15px;
  background: #fff;
  text-align: left;
}
.drawerFooter.alignRight {
  text-align: right;
}
.drawerFooter .ml-auto i {
  margin-left: 5px !important;
}
.drawerFooter .ant-btn {
  margin-left: 10px;
  display: flex;
  justify-content: center;
}
.ql-container {
  height: 400px;
}
.paddingHz {
  padding: 10px 0;
}
.attachment-box {
  background-color: white;
}
.attachment-box a {
  color: rgba(0, 0, 0, 0.65);
}
.noteTitle {
  display: flex;
  justify-content: space-between;
}
.commentBox {
  margin-top: 10px;
  line-height: 1.5;
  font-size: 13px;
  background-color: white;
  padding: 10px;
}
.commentBox .avatar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
}
.commentBox .avatar i {
  margin: 0 !important;
}
.commentBox .userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1890ff;
}
.commentBox .userInfo .date {
  margin-left: 10px;
  font-size: 12px;
  color: #808080;
}
.commentBox .userInfo .date i {
  margin: 0 !important;
}
.commentBox .commentText {
  margin-top: 5px;
  font-size: 12px;
  margin-left: 15px;
}
.jcsb {
  display: flex;
  justify-content: space-between;
}
.jcc {
  display: flex;
  justify-content: center;
}
.jcic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customTimeLine {
  margin-left: 15%;
}
.customTimeLine .ant-timeline-item {
  min-height: 50px;
}
.customTimeLine .ant-timeline-item-head-custom {
  width: 180px;
}
.customTimeLine .ant-timeline-item-head-custom .date {
  position: absolute;
  top: 4px;
  text-align: right;
  color: #0872bc;
  font-weight: 500;
}
.customTimeLine .ant-timeline-item-head-custom .date span {
  font-size: 10px;
  color: #9d8e8e;
}
.ant-timeline-item-head-primary {
  color: #1890ff;
}
.customPanel {
  border: 1px solid #e8e8e8;
}
.customPanel .ant-collapse-header {
  background: white;
  font-size: 15px;
}
.pendencyList {
  border-bottom: 1px solid #dddddd54;
  padding: 7px 0;
}
.label {
  display: inline;
  padding: 5px;
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label.labelNew {
  display: block;
  cursor: pointer;
}
.label.round {
  border-radius: 50px;
  padding: 0.1em 0.6em 0.2em;
}
.label.sm {
  padding: 0.1em 0.6em 0.2em;
  font-weight: normal !important;
}
.label.xs {
  padding: 0.5px 8px;
  font-weight: normal !important;
  margin: 2px 0;
}
.label.xs1 {
  padding: 0 6px;
  font-weight: normal !important;
  margin: 2px 0;
  font-size: 11px;
}
.label-info {
  background-color: #5bc0de;
}
.label-default {
  background-color: #0f52ba;
}
.label-primary {
  background-color: #337ab7;
}
.label-success {
  background-color: #0872bc;
}
.label-success-green {
  background-color: #5cb85c;
}
.label-danger {
  background-color: #d9534f;
}
.label-warning {
  background-color: rgba(240, 173, 78, 0.87);
}
.label-black {
  background-color: black;
}
.label-tag {
  border: none;
  font-size: 12px !important;
  color: black;
  text-transform: capitalize;
}
.label-sm {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label-xs {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}
.pointerCls-pdf {
  width: 24.6% !important;
  display: inline-block;
}
.pointerCls {
  cursor: pointer;
}
.linkPointer {
  cursor: pointer;
  color: #1890ff;
  font-size: 13px;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: left;
}
.dotsReplace {
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  text-overflow: ellipsis;
  height: 30px;
}
.ant-btn-circle {
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 35px !important;
  min-width: 35px !important;
  background: none;
  border: none;
}
.ant-btn-circle.border {
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.bs_btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  -webkit-user-select: none;
  user-select: none;
  background-color: #efefef;
  color: #333;
  padding: 0.2rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: 35px;
  min-width: 35px;
}
.bs_btn.bs-sm {
  padding: 5px 10px;
}
.bs_btn.bs-xs {
  padding: 2px 5px;
  font-size: 11px;
  height: 25px;
}
.bs_btn.bs-xxs {
  padding: 1px 5px;
  font-size: 10px;
  height: 20px;
}
.bs_btn.bs-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.bs_btn.bs-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.bs_btn.bs-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.bs_btn.bs-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.bs_btn.bs-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.bs_btn.bs-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.bs_btn.bs-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}
.bs_btn.bs-link:hover {
  text-decoration: underline !important;
}
.bs-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
  font-size: 11px;
}
.bs-link:hover {
  text-decoration: underline !important;
}
.tableAction {
  width: 125px;
  display: flex;
  flex-wrap: wrap;
}
.tableAction button,
.tableAction a {
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 35px !important;
  min-width: 35px !important;
  background: none;
  border: none;
}
.appCommentDotsReplace {
  display: block;
  width: 150px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 35px;
}
.appTextReplace {
  display: block;
  white-space: pre-wrap;
  width: 100%;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 23px;
}
.newsContentDotsReplace {
  display: block;
  width: 350px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}
.replaceAgentDot {
  display: block;
  width: 100px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  height: 17px;
  overflow-x: hidden;
}
.colorPrimary {
  color: #1890ff;
}
.colorPrimaryDark {
  color: #0f52ba;
}
.colorSuccess {
  color: #5cb85c;
}
.label1 {
  display: block !important;
  white-space: normal !important;
  font-size: 12px;
}
.linkAA {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}
.student-tab {
  border-right: none;
}
@media only screen and (max-width: 768px) {
  .applicationProfile {
    overflow: scroll;
  }
  .applicationProfile .appProfile .align-self-stretch {
    flex-direction: column;
  }
  .applicationProfile .profile-3-column.profile-3-col-sidebar.profile-3-col-sidebar--left {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .applicationProfile .profile-3-column.profile-3-col-sidebar--right-wrapper {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .applicationProfile .profile-6-column.noteBlock {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
.tuitionBox {
  font-size: 15px;
  color: black;
}
.ant-calendar-picker,
.ant-time-picker {
  width: 100%;
}
.ant-btn {
  line-height: 1.5;
  padding: 0.225rem 0.75rem !important;
  height: auto !important;
}
.ant-btn[type='submit'] {
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  width: auto;
  height: auto !important;
}
table {
  border: none;
}
table tbody th {
  font-size: 13px;
  border-top: 2px solid #dee2e6 !important;
  background: #fafafa;
  border-bottom: 1px solid #dee2e6 !important;
}
table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
table tbody tr:nth-of-type(even) {
  background: #fafafa;
}
table tbody tr td {
  padding: 8px 10px !important;
  font-size: 12px;
  font-weight: 500;
  color: #212529 !important;
}
table td.borderNone {
  border: none;
}
.ant-table-thead {
  background: #fafafa;
}
.ant-table-thead tr th {
  padding: 8px 10px !important;
  border: none !important;
  color: #212529 !important;
}
.ant-table-small {
  border: none;
}
.ant-table-content {
  border-right: none !important;
}
.antd-pro-components-global-header-index-header {
  padding: 15px 25px !important;
  height: auto;
}
.antd-pro-components-sider-menu-index-logo {
  text-align: center;
  height: 81px;
  background: white;
  padding: 0;
  border-bottom: 3px solid #ededed !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.antd-pro-components-sider-menu-index-logo img {
  width: 65%;
  height: auto;
}
.antd-pro-components-sider-menu-index-sider {
  box-shadow: none;
  border-right: 3px solid #ededed !important;
}
.ant-layout-header {
  height: auto;
}
.ant-layout-header .antd-pro-components-global-header-index-header {
  padding: 13px 25px !important;
  height: 83px;
  box-shadow: none;
  border-bottom: 3px solid #ededed !important;
}
.sidebar {
  height: auto;
  overflow-y: initial;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  overflow-x: hidden;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  height: 100%;
}
.antd-pro-components-global-header-index-right {
  display: flex;
  flex-direction: row;
}
.antd-pro-components-global-header-index-action {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.antd-pro-components-global-header-index-action .antd-pro-components-global-header-index-name {
  line-height: 1;
  margin-top: 10px;
}
.admin-box .content h5 {
  font-size: 15px;
  white-space: nowrap;
  font-weight: 600;
  color: #1a5abd;
  margin: 0;
}
form.input-group-box {
  width: 46%;
  float: left;
  margin-top: 10px;
}
.pd-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.appAction {
  float: right;
  margin-bottom: 10px;
}
.top_P10 {
  padding-top: 10px !important;
}
.sort-box-table {
  padding-right: 20px;
}
.btnBorder {
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-box-table-width {
  width: 34%;
  margin-right: auto;
}
.round-input .ant-select-selection--single {
  font-size: 14px;
  border-radius: 50px;
  padding-left: 10px;
}
.card-body table td span.badge {
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  background: #1053ba;
  border-radius: 4px;
  color: #fff;
}
.ant-form-item label {
  color: rgba(0, 0, 0, 0.65);
  float: left;
  margin: 0 15px 0;
}
.form-control {
  height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
.PhoneInputCountrySelect {
  padding: 10px !important;
}
.PhoneInput {
  border: 1px solid #666 !important;
  height: 42px !important;
  color: #666;
  border-radius: 6px;
  padding-right: 3px;
}
.PhoneInput .PhoneInputCountry {
  padding: 10px;
  border-right: 1px solid #666 !important;
}
.PhoneInput .PhoneInputInput {
  height: 40px !important;
  color: #666;
  padding-left: 10px;
  border: none;
}
.PhoneInput .PhoneInputInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1;
  /* Firefox */
}
.PhoneInput .PhoneInputInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999 !important;
}
.PhoneInput .PhoneInputInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999 !important;
}
.ant-select-selection--single {
  height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
.ant-select-selection--multiple {
  min-height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  min-height: 40px !important;
}
.ant-select-selection--multiple .ant-select ul,
.ant-select-selection--multiple .ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 5px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
}
.ant-form-item-control {
  line-height: 30px;
}
.ant-select-selection__rendered {
  line-height: 40px !important;
}
.ant-select-arrow {
  -webkit-user-select: none;
          user-select: none;
  background: #666666;
  padding: 5px;
  color: white;
  font-weight: 600;
  margin-top: -12px;
  border-radius: 5px;
}
.ant-select-focused .ant-select-arrow {
  background: #1254bb !important;
}
.ant-input-number {
  height: 42px;
}
.heading-form.stuHeader span.img img {
  height: 18px;
  margin-right: 3px;
  position: relative;
  top: -1px;
}
.heading-form a.btn {
  box-shadow: 0 0 12px 0px #00000038 !important;
  border-radius: 0;
  margin: 0 40px;
  font-size: 13px;
  text-transform: capitalize;
  min-width: 120px;
}
.pa-0 {
  padding: 0 !important;
}
.antd-pro-layouts-basic-layout-content {
  padding: 20px 15px;
  margin: 0 !important;
}
.ant-select-selection-selected-value {
  margin-right: 20px;
}
.ant-calendar-picker-input.ant-input,
.ant-time-picker-input {
  height: 40px !important;
}
.ant-calendar {
  width: auto;
}
.stuIcon {
  font-size: 20px;
  color: #1890ff;
}
ul.pointerUl li {
  cursor: pointer;
}
.ant-menu-dark {
  background: white;
}
.ant-menu-dark svg {
  display: none;
}
.ant-menu-dark .ant-menu-item {
  font-size: 13px !important;
}
.ant-menu-dark .ant-menu-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: black !important;
}
.ant-menu-dark .ant-menu-item:hover div > span {
  color: black !important;
}
.ant-menu-dark .ant-menu-sub {
  background: white !important;
}
.ant-menu-dark li:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #343a40 !important;
}
.ant-menu-dark li:hover span,
.ant-menu-dark li:hover a {
  color: #343a40 !important;
}
.ant-menu-dark li a {
  color: #343a40 !important;
}
.ant-menu-dark li a :hover {
  color: #343a40 !important;
}
.ant-menu-dark li span {
  color: #343a40 !important;
}
.ant-menu-dark li span :hover {
  color: #343a40 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: none;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item {
  height: 40px;
  line-height: 40px;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #343a40 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover a:hover {
  color: #343a40 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover span:hover {
  color: #343a40 !important;
}
li.ant-menu-item.ant-menu-item-selected {
  background: #fff !important;
  color: #343a40 !important;
  box-shadow: 0 0 12px 0px #00000038 !important;
  border-right: 5px solid #1254bb !important;
}
li.ant-menu-item.ant-menu-item-selected a {
  color: #343a40 !important;
}
li.ant-menu-item.ant-menu-item-selected a span {
  color: #343a40 !important;
}
i.ant-menu-submenu-arrow {
  color: #343a40 !important;
}
i.ant-menu-submenu-arrow::before {
  background: #343a40 !important;
}
i.ant-menu-submenu-arrow::after {
  background: #343a40 !important;
}
.nav-item {
  color: #343a40 !important;
}
.nav-item a {
  color: #343a40 !important;
}
.imgDiv {
  display: inherit;
  width: 75%;
}
.imgDiv .logo-link {
  width: 350px;
  box-shadow: 3px 0 0 #ededed;
}
.imgDiv .logoImg {
  line-height: 0.8;
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  margin-top: -3px;
  width: auto;
  box-shadow: none !important;
  opacity: 1 !important;
  border-radius: 0;
  max-height: 45px !important;
}
.student-dashboard {
  width: calc(100% - 352px);
}
.profile-main {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-bottom: 50px;
}
.profile-main::-webkit-scrollbar {
  display: none;
}
.search-profile-main {
  height: 84vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.search-profile-main::-webkit-scrollbar {
  display: none;
}
.attachment-box {
  height: 100vh;
  overflow: scroll;
  /*  &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: @primary;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: @success;
      }
  */
  padding-bottom: 50px;
}
.scrollView {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-bottom: 50px;
}
.scrollView::-webkit-scrollbar {
  display: none;
}
.mobileMenu {
  display: none;
}
.infoBox {
  width: 350px;
}
.courseBox {
  min-height: 80px;
}
.courseFeeInfo {
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 5px;
}
.selectStudentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}
.selectStudentBox .filter-box button.apply {
  font-size: 11px;
}
.selectStudentBox .selectStudentBtn {
  display: flex;
  align-items: center;
  background: #0f52ba;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 50px;
  min-width: 100px;
  font-size: 12px;
}
.selectStudentBox .selectStudentBtn i {
  margin-right: 5px;
}
.detail-list li {
  color: #212529;
}
.ant-pagination-options-size-changer .ant-select-selection--single {
  height: 33px !important;
  border: 1px solid #ddd;
}
.ant-pagination-options-size-changer .ant-select-selection--single::after {
  content: '' !important;
}
.ant-pagination-options-size-changer .ant-select-selection--single::before {
  content: '' !important;
}
.ant-btn[type='submit'] {
  width: auto;
  height: auto !important;
}
.custom-sort-box-table {
  width: 34% !important;
}
.sort-box-table-right {
  text-align: right;
}
.btn img {
  height: 17px;
}
.btn img.plus {
  height: 15px;
  margin-right: 5px;
}
.btn.round {
  margin-right: auto;
  display: flex;
  border-radius: 50px;
  padding-left: 50px;
  position: relative;
}
.btn.roundNew {
  margin-right: auto;
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 15px;
  text-decoration: none !important;
  vertical-align: middle;
}
.btn.roundNew img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
.btn .anticon {
  margin-right: 5px;
}
.btn.ac {
  display: flex;
  align-items: center;
}
.btn.roundNew2 {
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 10px;
  float: right;
  margin-right: 10px;
}
.btn.roundNew2 img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
.btn.round-bt {
  margin-right: 5px;
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 15px;
}
.btn.round-bt img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
.btn.lg {
  padding: 8px 30px !important;
}
.btn.md {
  padding: 5px 17px !important;
  font-size: 0.87rem;
}
.btn.sm {
  padding: 3px 15px !important;
  font-size: 0.8rem;
}
.btn.xs {
  padding: 2px 10px !important;
  font-size: 0.7rem;
}
.btn.xss {
  padding: 2px 5px !important;
  font-size: 0.65rem;
}
.btn.p0 {
  padding: 0 !important;
}
.search-box-table.round {
  margin-right: auto;
  display: flex;
  border-radius: 50px;
  padding-left: 50px;
  position: relative;
  background: #fff;
}
.search-box-table.round.m0 {
  margin: 0 !important;
}
.search-box-table.round img {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.search-box-table.round input {
  border: none !important;
  border-radius: 50px !important;
  height: auto;
  padding: 8px 15px;
  padding-left: 0;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.search-box-table.ml0 {
  margin-left: 0 !important;
}
td span.badge {
  padding: 5px 7px !important;
  cursor: pointer;
}
select {
  background: white;
}
.custom-sort-box-new {
  padding-right: 0 !important;
  margin-bottom: 10px;
}
.custom-sort-box-new select {
  margin-right: auto;
  display: flex;
  position: relative;
  background: #fff;
  border: none !important;
  border-radius: 50px !important;
  padding: 8px 15px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 42px;
}
.custom-sort-box-new select ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057 !important;
  opacity: 1;
  /* Firefox */
}
.custom-sort-box-new select :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495057 !important;
}
.custom-sort-box-new select ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #495057 !important;
}
.custom-sort-box-new select:visited,
.custom-sort-box-new select:active,
.custom-sort-box-new select:focus {
  border: none !important;
  outline: none !important;
}
table td button.btn,
table td a.btn {
  padding: 0 4px !important;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057 !important;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495057 !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #495057 !important;
}
.courseList-box {
  margin-top: 43px;
}
.unizportal .ant-col-8 .inputBox {
  margin-top: 0;
  position: relative;
}
::-webkit-scrollbar {
  width: 7px;
  height: 4px;
  border-radius: 10px !important;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px #acacac;
}
::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #9b9b9b;
  border-radius: 10px !important;
}
.chooseCountryBox {
  position: absolute;
  z-index: 9;
  top: 35px;
  background: #fff;
}
.chooseCountryBox label {
  margin: 0 !important;
  border-bottom: 1px solid #ddd;
}
.selectedCountryLabel .icon {
  position: absolute;
  right: 10px;
  top: 12px;
}
.profile-activity.application-activity ul li .date {
  width: 15%;
}
.ant-menu-dark .ant-menu-sub {
  background: #dddddd42 !important;
}
.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px !important;
}
.font14 {
  font-size: 13px;
}
.font14_1 {
  font-size: 14px;
}
.font15 {
  font-size: 15px;
}
.actionBtnGroup .viewBtn {
  height: 20px;
}
.actionBtnGroup .crossBtn {
  height: 10px;
}
.antd-pro-components-sider-menu-index-sider {
  box-shadow: none;
  border-right: 3px solid #ededed !important;
}
.ant-select-selection__rendered {
  content: '' !important;
}
.ant-select-selection__clear {
  top: 42%;
  right: 15px;
}
.ant-select-selection__clear .ant-select-clear-icon {
  height: 20px;
  width: 20px;
}
.ant-select-selection__clear .ant-select-clear-icon svg {
  height: 20px;
  width: 20px;
}
.ant-select-enabled.ant-select-allow-clear .ant-select-selection--single:hover .ant-select-arrow {
  display: none;
}
.PrivacyPolicyPage {
  font-size: 12px;
  padding: 50px;
}
.PrivacyPolicyPage h4 {
  text-align: left;
  font-size: 18px;
  text-decoration: underline;
}
.PrivacyPolicyPage p {
  text-align: left;
  line-height: 1.8;
  color: #6c4a4a;
  margin-bottom: 20px;
}
.linkUl {
  float: right;
  color: #6c4a4a;
}
.linkUl a {
  text-decoration: underline !important;
}
.ant-drawer-close {
  width: 30px;
  height: 30px;
  right: 20px !important;
  line-height: 2;
  top: 1px;
}
.ant-drawer-close:focus {
  outline: none;
}
.roundBtn {
  line-height: 1.5;
  padding: 10px 15px !important;
  border-radius: 50px !important;
  height: 42px !important;
  margin-right: 5px;
  border-color: #efe8e8;
}
.roundBtn.icon {
  height: 40px !important;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.status.status1 p {
  min-height: 22px;
  height: auto !important;
}
.ant-btn-success {
  width: 150px;
  background: #1556bc !important;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff !important;
  margin: 25px 0 0;
  border-radius: 50px !important;
  font-weight: 600;
  height: 42px !important;
}
.ant-btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.ant-btn-warning {
  color: #fff !important;
  background-color: rgba(240, 173, 78, 0.87) !important;
  border-color: #eea236 !important;
}
.ant-btn-cancel {
  background: #999;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 50px;
  min-width: 100px;
  margin-right: 15px;
  height: 42px !important;
}
.ant-table-scroll .ant-table-body::-webkit-scrollbar {
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
.btn-success-green {
  border: 1px solid #5cb85c !important;
  background-color: #5cb85c !important;
  color: white !important;
}
.moreBtn {
  text-decoration: underline;
  color: #5f5f5f;
  margin-left: 20px;
  font-size: 10px;
}
.moreBtn:hover {
  color: #5f5f5f;
}
.guestEmailBox {
  background: white !important;
  padding: 5px 5px;
  margin: 2px;
  display: flex;
  border: 1px solid #ddddff;
  border-radius: 5px;
  min-height: 40px;
}
.guestEmailBox ul {
  padding-left: 0;
  margin-bottom: 0;
}
.guestEmailBox li.emailTag {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 2px 5px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  margin-bottom: 2px;
}
.guestEmailBox .emailIcon {
  font-size: 12px;
  position: relative;
  top: -2px;
  margin-left: 5px;
}
#loginForm.login-main {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
}
@media screen and (max-width: 560px) {
  .mobileMenu {
    display: block;
    position: absolute;
    top: 0;
  }
  .antd-pro-layouts-basic-layout-content {
    margin: 0;
  }
  .infoBox {
    width: 100%;
  }
  .applicationProfile aside {
    display: block;
  }
  .imgDiv .logo-link {
    display: none;
  }
  .imgDiv .logoImg {
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: -3px;
    width: auto;
    box-shadow: none !important;
    opacity: 1 !important;
    border-radius: 0;
    max-height: 45px !important;
  }
  .student-dashboard {
    width: 100% !important;
  }
  .profile-main,
  .search-profile-main,
  .attachment-box,
  .scrollView {
    height: 100%;
  }
}
@media screen and (min-width: 769px) {
  .applicationProfile .ant-layout-header .antd-pro-components-global-header-index-header {
    padding: 0 !important;
    height: 90px;
  }
  .applicationProfile .imgDiv {
    height: 90px;
  }
  .applicationProfile .imgDiv .logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .applicationProfile form.input-group-box {
    margin-top: 0;
    display: flex;
    align-items: center;
    padding-left: 70px;
  }
}
.login-box .d-flex button.btn {
  height: 42px !important;
}
.card-pane-right.listing-section ul.count {
  justify-content: start !important;
}
.card-pane-right.card-pane-new .pointerCls .linkText {
  font-size: 18px;
}
.card-pane-right.card-pane-new .pointerCls .statusLabel {
  color: black;
  font-size: 15px;
}
.mark-btn.mark-btn30 {
  width: 35% !important;
}
.mark-btn.mark-btn30 div {
  display: flex;
}
.mark-btn.mark-btn30 div a {
  margin: 0 5px;
}
.mark-btn.mark-btn40 {
  width: 50% !important;
  position: absolute;
  right: 0;
}
.mark-btn.mark-btn40 div {
  display: flex;
}
.mark-btn.mark-btn40 div a {
  margin: 0 5px;
}
.mark-btn.mark-btn70 {
  width: 70% !important;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
.mark-btn.mark-btn70 div {
  display: flex;
}
.mark-btn.mark-btn70 div a {
  margin: 0 5px;
}
.selectedUni {
  margin-right: 10px;
  background: #666666;
  padding: 2px 10px;
  border-radius: 5px;
  color: white !important;
  font-size: 12px;
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 450px !important;
  overflow-x: hidden;
  border: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}
.uniBox .col-lg-4 {
  margin-bottom: 20px;
}
.uniBox .col-lg-4 .logo img {
  height: 67px;
}
.featureCourseDiv .heading-form {
  position: fixed !important;
  top: 0;
  z-index: 9;
  background: white;
  width: 100%;
  padding: 10px 0;
}
.featureCourseDiv .cardRelative {
  position: relative;
  top: 70px;
  background: white !important;
  padding-bottom: 0 !important;
}
.featureCourseDiv .courseBox .col-lg-4 {
  background: #f1f1f1 !important;
  padding: 20px;
}
.featureCourseDiv .courseBox .newRow {
  margin-bottom: 20px;
}
.featureCourseDiv .courseBox .infoRow {
  padding-left: 20px;
}
.featureCourseDiv .courseBox .infoRow p {
  margin-bottom: 0;
}
.featureCourseDiv .logo {
  padding: 10px;
}
.featureCourseDiv .logo img {
  height: 60px;
}
.featureCourseDiv .nameBox {
  height: 70px;
  font-weight: bold;
  color: #0f52ba;
}
.featureCourseDiv .infoBox {
  /* width: 500px;
       display: flex;
       justify-content: space-between;
       align-items: center;*/
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 44%;
}
.featureCourseDiv .infoBox .totalInfo {
  display: flex;
  padding-left: 27px;
}
.featureCourseDiv .infoBox .totalInfo .totalCourseCount {
  margin-right: 50px;
  top: 4px;
}
.featureCourseDiv .infoBox .featureCourseHeader {
  padding: 0 25px;
}
.featureCourseDiv .infoBox .featureCourseHeader h5 {
  padding: 0;
}
.featureUniversityDiv .heading-form {
  padding: 20px 0 0 !important;
}
.featureUniversityDiv .ml-auto {
  display: flex;
  justify-content: center;
  align-items: center;
}
.featureUniversityDiv .ml-auto i {
  margin-left: 5px !important;
}
.featureUniversityDiv .cardRelative {
  position: relative;
  background: white !important;
}
.featureUniversityDiv .cardRelative.pb-0 {
  padding-bottom: 0 !important;
}
.featureUniversityDiv .courseBox .col-lg-4 {
  background: #f1f1f1 !important;
  padding: 20px;
}
.featureUniversityDiv .courseBox .newRow {
  margin-bottom: 20px;
}
.featureUniversityDiv .courseBox .infoRow {
  padding-left: 20px;
}
.featureUniversityDiv .courseBox .infoRow p {
  margin-bottom: 0;
}
.featureUniversityDiv .logo img {
  height: 80px;
}
.featureUniversityDiv .nameBox {
  height: 80px;
  font-weight: bold;
  color: #1890ff;
}
.featureUniversityDiv .infoBox {
  align-self: flex-end !important;
  margin-bottom: 10px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}
.loadMoreDiv {
  text-align: center;
  text-transform: uppercase;
}
.loadMoreDiv a {
  background: #f1f1f1;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
}
.loadMoreDiv a i {
  position: relative;
  bottom: 2px;
  margin-left: 5px !important;
}
.social.social1 {
  padding: 0 !important;
}
.social.social1 h6 {
  color: white;
}
.navbar-dark {
  background-color: transparent;
  border-color: #4b545c;
}
.searchAppDiv .loadMoreBox {
  text-align: right;
  text-transform: uppercase;
}
.searchNewBtn {
  background: #0f52ba !important;
  border-radius: 50px !important;
  color: #fff !important;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex !important;
}
.searchNewBtn:hover {
  opacity: 0.7;
  color: white;
}
.clearNewBtn {
  background: #efefef !important;
  border-radius: 50px !important;
  color: black !important;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex !important;
}
.clearNewBtn:hover {
  opacity: 0.7;
  color: white;
}
.btn.apply:hover {
  opacity: 0.7;
}
.invalidValueField .form-control {
  border: 2px solid #ff0000;
}
.requirementNote {
  font-size: 12px;
  color: black;
  padding-left: 10px;
}
.frontFooter .social {
  padding: 0 !important;
}
.frontFooter .social h6 {
  color: white !important;
}
.frontFooter .white {
  color: white !important;
}
.all-student-search h5 {
  width: 20% !important;
}
.all-student-search h5.allAppCountry {
  width: 70% !important;
}
.all-student-search .search-box-table {
  width: 30%;
}
.all-student-search .search-box-table.round {
  margin-left: 10px !important;
  position: relative;
}
.all-student-search .search-box-table.round a.searchBtn1 {
  position: absolute;
  right: 0;
  top: 1px;
  background: #0872bc;
  color: white;
  padding: 7px 5px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 13px;
}
.all-student-search .search-box-table.ml0 {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
.all-student-search .form-control {
  height: 35px !important;
}
.search-box-table.round {
  margin-left: 10px !important;
  position: relative;
}
.search-box-table.round a.searchBtn1 {
  position: absolute;
  right: 0;
  top: 0px;
  background: #0872bc;
  color: white;
  padding: 11.5px 8px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 13px;
}
.search-box-table.ml0 {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
header.navbar-fix nav.newNav a:first-child {
  color: black !important;
  background: none !important;
  border: none;
  margin-right: 15px;
  box-shadow: none;
  text-decoration: underline;
  font-size: 15px !important;
}
header.navbar-fix nav.newNav a:last-child {
  color: #fff !important;
  background: #0f52ba;
  border: none;
  margin-right: 15px;
  box-shadow: 2px 2px 4px #00000057;
}
@media screen and (max-width: 560px) {
  .table-head .search-box-table {
    display: none !important;
  }
  .all-student-search h5 {
    width: 60%;
  }
  .containerStu {
    margin-top: 20% !important;
  }
}
.recharts-legend-wrapper {
  position: relative !important;
  bottom: 35px !important;
}
.amt {
  font-style: normal;
  color: black;
  font-size: 13px;
  font-weight: 600;
}
.ant-scroll-number {
  position: absolute;
  top: 11px;
  right: 6px;
}
.rowWrap {
  display: flex;
  flex-wrap: wrap;
}
.custom-tooltip {
  background: #ffffff6e;
  padding: 10px 10px 2px 10px;
  line-height: 1.3;
  font-size: 13px;
}
.custom-tooltip .label {
  color: black;
  font-size: 13px;
  padding: 0;
}
.recharts-legend-wrapper {
  margin-top: 20px;
}
.barChart .recharts-legend-wrapper {
  display: none;
}
.barLabel_ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.barLabel_ul.btn_option {
  display: flex;
  justify-content: space-between;
}
.barLabel_ul li {
  display: inline-flex;
  margin-right: 10px;
  align-items: center;
  font-size: 14px;
}
.barLabel_ul li .colorBox {
  height: 12px;
  width: 12px;
  margin-right: 4px;
}
.barLabel_ul li.lg .colorBox {
  height: 25px;
  width: 40px;
  margin-right: 4px;
}
.newsRowBox {
  display: flex;
  cursor: pointer;
  margin-right: 20px !important;
}
.newsRowBox i {
  margin-right: 5px;
  font-weight: bold;
  margin-top: 2px;
  font-size: 15px;
  color: #1890ff;
}
.sideMenuSoical li {
  margin-right: 10px !important;
}
.sideMenuSoical img {
  height: 32px;
}
.tableBox {
  margin-bottom: 20px;
}
.tableBox.striped .tableRow:nth-child(odd) {
  background-color: #f9f9f9;
}
.tableBox .tableRow {
  padding: 4px 10px;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.tableBox .tableRow .title {
  font-weight: bold;
  width: 35%;
  height: 18px;
}
.tableBox .tableRow .title1 {
  font-weight: bold;
  width: 40%;
}
.tableBox .tableRow p {
  margin-bottom: 0;
}
.tableBox .tableRow:first-child {
  border-top: 1px solid #ddd;
}
.circleBtn {
  background: #0872bc;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.justifyContent {
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
}
.meetingInfo {
  line-height: 1.8;
}
.login-form label {
  display: none !important;
}
.login-form .form-control {
  border: none !important;
}
.login-form input[type='text'].form-control {
  background: #e6e6e6 !important;
}
.login-form input[type='file'].form-control {
  background: #e6e6e6 !important;
  padding: 0;
}
.login-form .documents .inputBox {
  margin-bottom: 0;
}
.login-form .documents .inputBox .labelNew {
  height: 20px;
}
.login-form .documents .inputBox input[type='file'].form-control {
  height: 35px !important;
}
.login-form input.ant-calendar-picker-input.ant-input {
  height: 55px !important;
}
.login-form .form-control {
  height: 55px !important;
}
.login-form .ant-select-selection__placeholder,
.login-form .ant-select-search__field__placeholder {
  color: #666;
  font-size: 14px;
}
.login-form .ant-select-selection {
  background: #e6e6e6;
  border: none;
  width: 100%;
  height: 55px !important;
  padding: 0 23px;
  outline: none;
  border-radius: 6px;
  padding-right: 75px;
  font-size: 14px;
}
.login-form .btnSub {
  background: #0f52ba;
  color: #fff;
  height: 42px !important;
  min-width: 120px;
  border-radius: 50px;
}
.btn-secondary.dropdown-toggle {
  background: #0f52ba !important;
  border-radius: 50px;
  width: 150px;
  margin-right: 10px;
}
header.navbar-fix nav a:first-child.regLink {
  color: #666 !important;
}
header.navbar-fix nav a.regLink {
  color: #666 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-align: left;
  justify-content: flex-start;
}
.dropbtn {
  background: #0f52ba !important;
  border-radius: 50px;
  width: 150px;
  margin-right: 10px;
  color: #fff;
  height: 37px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem !important;
}
.dropbtn i {
  margin-left: 5px;
}
.dropbtn.lite {
  background: transparent !important;
  border: 1px solid #c1c1c1;
  color: black;
}
.dropbtn:focus {
  outline: none !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 10px;
  border-radius: 10px;
}
.dropdown-content a.regLink {
  margin-right: 0 !important;
  border-radius: 0;
}
.dropdown-content a.regLink:hover {
  background: #e5dddd !important;
}
.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.studentRegForm {
  padding: 20px 20px;
  margin-top: 50px;
  border-radius: 5px;
  background: #fffdfd;
}
.studentRegForm label {
  display: block !important;
  padding: 0 !important;
  margin: 0;
}
.studentRegForm .ant-form-item-label {
  line-height: 28.9999px;
}
.studentRegForm .labelNew {
  display: block !important;
  color: #666;
  margin: 0 0 10px 0 !important;
}
.studentRegForm input[type='text'],
.studentRegForm input[type='text'].form-control,
.studentRegForm input[type='password'],
.studentRegForm input[type='email'],
.studentRegForm input[type='number'],
.studentRegForm input[type='textarea'],
.studentRegForm .ant-select-selection {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.studentRegForm input[type='text'].ant-select-focused,
.studentRegForm input[type='text'].form-control.ant-select-focused,
.studentRegForm input[type='password'].ant-select-focused,
.studentRegForm input[type='email'].ant-select-focused,
.studentRegForm input[type='number'].ant-select-focused,
.studentRegForm input[type='textarea'].ant-select-focused,
.studentRegForm .ant-select-selection.ant-select-focused {
  background: #ffffff !important;
}
.studentRegForm .ant-select-search__field {
  background: transparent !important;
  padding: 0 !important;
}
.studentRegForm .ant-calendar-picker {
  padding: 0 !important;
}
.studentRegForm input.ant-calendar-picker-input.ant-input {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.studentRegForm input[type='file'] {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.studentRegForm input[type='file']::-webkit-file-upload-button {
  width: 120px;
}
.studentRegForm input[type='file'].form-control {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.containerStu {
  max-width: 100%;
  padding: 0 calc((100% - 1140px) / 2);
  overflow-y: scroll;
  margin-top: 5%;
  z-index: 99999;
  overflow-x: hidden;
}
.containerStu::-webkit-scrollbar {
  display: none;
}
.containerStu .inner-container {
  height: 80vh;
}
.login-form label.authCheckbox {
  display: block !important;
}
.hyperLink {
  color: #00e !important;
}
.hyperLinkUnderLine {
  color: #00e !important;
  text-decoration: underline;
}
.disbaledField {
  display: flex;
  width: 100%;
  padding: 0 11px;
  text-align: left;
  border: 1px solid #666;
  border-radius: 5px;
  outline: 0;
  background: #ededed;
}
.sliderBox {
  margin: 0 15px;
}
.sliderBox .sliderImg {
  height: 65px;
  width: 100%;
}
.dotNone {
  background: none !important;
}
.owl-theme .owl-dots {
  margin-top: 5px;
}
.owl-theme .owl-dots .owl-dot span {
  background-color: #666 !important;
  height: 7px !important;
  width: 7px !important;
}
.owl-theme .owl-dots .owl-dot.active span {
  background-color: #0872bc !important;
}
.owl-theme .owl-nav {
  display: none !important;
}
.owl-theme.owl-new .owl-nav {
  display: block !important;
}
.owl-theme.owl-new .owl-nav .owl-prev {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #f1f1f1;
  line-height: 0;
  font-weight: 500;
  left: -14px;
}
.owl-theme.owl-new .owl-nav .owl-next {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #f1f1f1;
  line-height: 0;
  font-weight: 500;
  left: 97%;
}
.main-footer {
  border-bottom: none !important;
}
.ant-time-picker,
.ant-calendar-picker {
  padding: 0 !important;
  border-color: #666 !important;
  box-shadow: none !important;
}
.form-control {
  border-color: #666 !important;
}
.ant-slider-mark {
  width: 82%;
  left: 9%;
}
.custom-slider .ant-slider-mark {
  width: 102%;
  left: 0%;
}
.statusRow .statusColumn {
  margin-bottom: 10px;
  text-align: center;
}
.statusRow .statusColumn .ant-card-body {
  text-align: center;
  height: 120px;
}
.statusRow .box {
  text-align: center;
}
.statusRow .box.active {
  color: white;
  background-color: #0872bc;
}
.statusRow .box img {
  height: 20px;
  margin-bottom: 5px;
}
.statusRow .status {
  font-size: 12px;
}
.statusRow .title {
  font-size: 18px;
  font-weight: bold;
}
.statusRow .iconDiv {
  font-size: 28px;
  padding: 1px;
}
.newsImg {
  margin-top: 20px;
}
.newsImg img {
  width: 80%;
  margin-left: 10%;
}
.roundGoBackBtn {
  line-height: 1.5;
  padding: 10px 15px !important;
  border-radius: 50px !important;
  border-color: #efe8e8;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px !important;
  background-color: white;
}
.roundGoBackBtn.icon {
  width: 36px;
}
.thinLabel {
  font-weight: normal !important;
}
.flairUniversityBlockOuter .flairUniversityBlock .innerBox {
  padding: 15px 40px;
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
  min-height: 250px !important;
}
.flairUniversityBlockOuter .flairUniversityBlock .imgBlock {
  background-color: white;
  padding: 10px 20px;
  border-radius: 3px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flairUniversityBlockOuter .flairUniversityBlock .imgBlock img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.flairUniversityBlockOuter .flairUniversityBlock .applyBtn {
  background-color: white;
  padding: 6px 15px;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  border: none;
  border-radius: 3px;
}
.flairUniversityBlockOuter .flairUniversityBlock .applyBtn.dark {
  background-color: #0872bc;
  color: white;
}
.flairUniversityBlockOuter .slick-dots {
  position: absolute;
  bottom: 0px !important;
}
.flairUniversityBlockOuter .slick-dots li {
  border-bottom: none;
  background: none !important;
}
.flairUniversityBlockOuter .slick-dots li button {
  background: #0872bc !important;
  height: 5px !important;
}
.flairUniversityBlockOuter .slick-arrow.slick-prev {
  font-size: 10px;
}
.flairUniversityBlockOuter .ant-carousel .slick-prev::before {
  content: '<';
  z-index: 1000 !important;
  display: block;
  position: relative;
  bottom: 10px;
  right: -38px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.flairUniversityBlockOuter .slick-arrow.slick-next {
  font-size: 10px;
}
.flairUniversityBlockOuter .ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 0px;
  left: -39px;
  bottom: 10px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.countList {
  list-style: decimal;
  padding-left: 12px;
}
.labelDoc {
  margin-top: -2px;
  margin-bottom: 0;
}
.addStuInfo {
  width: 100%;
}
.addStuInfo .ant-row.row1 {
  width: 100%;
  display: flex;
  align-items: center;
}
.ant-drawer-body {
  overflow: hidden;
}
.ant-input-number-disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}
.label_sm {
  padding-left: 10px;
  font-size: 13px;
  font-weight: normal !important;
  margin-bottom: 0 !important;
}
.antSelect.ant-select .ant-select-selection-selected-value {
  margin-right: 10px !important;
}
.antSelect.ant-select .ant-select-selection {
  border: none !important;
  border-radius: 50px !important;
  font-size: 13px !important;
}
.antSelect.ant-select .ant-select-selection .ant-select-selection__placeholder {
  display: block;
  color: #495057 !important;
}
.antSelect.ant-select .ant-select-selection .ant-select-arrow {
  background: none !important;
  color: #495057 !important;
}
.smSelect .ant-select-selection {
  height: 25px !important;
  border-radius: 0;
}
.smSelect .ant-select-selection .ant-select-selection__rendered {
  line-height: 1 !important;
  margin-left: 5px !important;
}
.smSelect .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  margin-right: 5px !important;
}
.smSelect .ant-select-selection .ant-select-arrow {
  background: none !important;
  color: #495057 !important;
  border: none;
  position: absolute;
  right: 0;
  top: 8px;
}
.fcmtDiv {
  text-align: center;
}
.fcmtDiv img {
  height: 40px;
}
.fcmtDiv img.sm {
  height: 32px;
}
.infoUni {
  background-color: white;
  font-size: 15px;
  border-radius: 5px;
  line-height: 2;
  color: black;
}
.infoUni .title {
  width: 18% !important;
  font-size: 13px;
  font-weight: normal !important;
  border-right: 1px solid #ddd;
  margin-right: 10px;
}
.infoUni span {
  color: #0872bc;
  font-size: 13px;
}
.darkBtn {
  background: transparent !important;
  border: 1px solid #c1c1c1 !important;
  display: inline-block;
  border-radius: 50px;
  min-width: 110px;
  color: #000 !important;
  height: 38px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
}
.darkBtn span {
  color: #343a40 !important;
}
.sprBox {
  margin-top: 7px;
  padding-top: 7px;
}
.customAutoComplete ul {
  width: 100%;
}
.customAutoComplete .ant-select-search__field {
  height: 43px !important;
  border-color: #666 !important;
}
.pointerCls {
  height: 40px;
  padding-top: 3px;
}
.pCursor {
  cursor: pointer;
}
.CountCircle {
  font-size: 14px !important;
  height: 25px;
  width: 25px;
  border-radius: 5%;
  line-height: 2.5;
  background: white !important;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 2px #1890ff !important;
}
.CountCircle a {
  align-self: center;
  font-size: 12px !important;
  color: black !important;
}
.pointerCls1 {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding-top: 0 !important;
}
.pointerCls1 h5 {
  font-size: 14px !important;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
}
input[type='text']::placeholder,
input[type='password']::placeholder,
input[type='search']::placeholder,
input[type='number']::placeholder,
input[type='email']::placeholder,
input[type='file']::placeholder,
input[type='date']::placeholder,
textarea::placeholder,
.ant-select-selection::placeholder,
.ant-calendar-picker-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  font-size: 14px !important;
  opacity: 0.8 !important;
  /* Firefox */
}
.ant-select-selection__placeholder {
  color: #999 !important;
  font-size: 13px !important;
  opacity: 0.8 !important;
  /* Firefox */
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  /* overflow: hidden; */
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid #edededbf;
}
.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
  opacity: 0.5;
}
.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled:hover {
  opacity: 0.5 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
.mailerOption input[type='radio'] {
  position: relative;
  top: 2px;
  margin-left: 10px;
}
.mailerOption label {
  font-weight: normal !important;
  margin-left: 5px !important;
  margin-bottom: 0 !important;
  margin-right: 5px;
}
.mailerOption i {
  font-style: inherit;
  margin-left: 3px;
}
.countLink {
  color: #1254bb;
}
.ck-editor__editable {
  min-height: 400px;
}
.quillEditor {
  background-color: white;
}
.quillEditor .ql-editor {
  line-height: 2;
  font-size: 15px;
}
.customExtra {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #00e;
  z-index: 9;
}
.defaultEligBtn {
  display: flex;
  border: 1px solid #ddd !important;
  justify-content: center;
  align-items: center;
  padding: 1px 10px !important;
  background: white !important;
  margin-top: 8px;
  font-size: 13px !important;
  border-radius: 50px !important;
}
.defaultEligBtn i {
  margin-right: 5px !important;
}
.eligiBtn {
  border: 1px solid #ddd !important;
  background: white !important;
  height: 27px;
  margin-top: 8px;
  font-size: 11px !important;
  border-radius: 50px !important;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px !important;
}
.eligiBtn i {
  margin-right: 5px !important;
}
.flexEndCustom {
  margin-top: 10px;
  justify-content: flex-end;
}
.wrapBox {
  display: flex;
  flex-wrap: wrap;
}
.wrapBox .widthLabel {
  font-weight: bold;
  text-decoration: underline;
  color: black;
}
.wrapBox .widthLabel.w100 {
  width: 100%;
}
.wrapBox .widthLabel.w200 {
  width: 200%;
}
.wrapBox label {
  font-weight: 600 !important;
}
.status p {
  min-height: 22px !important;
  height: auto !important;
}
.status p {
  min-height: 22px !important;
  height: auto !important;
}
.courseNameDotsReplace {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  height: 40px;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.smallBtn {
  padding: 10px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lgBtn {
  padding: 15px 25px !important;
  height: auto !important;
}
.image-upload > input {
  display: none;
}
.image-upload label {
  margin-right: 10px;
}
.image-upload img {
  height: 30px;
  cursor: pointer;
}
.pendencyTab td {
  padding: 5px !important;
}
.documentBox {
  padding: 0 5px;
  /* margin: 2px; */
  border-radius: 5px;
  min-height: auto !important;
}
.documentBox ul {
  padding-left: 0;
  margin-bottom: 0;
}
.documentBox .emailTag {
  position: relative;
  float: left;
  margin-right: 4px;
  padding: 2px 5px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border-radius: 2px;
  white-space: nowrap;
  width: 180px;
  text-overflow: ellipsis;
}
.documentBox .emailIcon {
  font-size: 15px;
  position: relative;
  top: 3px;
  margin-left: 5px;
}
.cardSm {
  padding: 10px;
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.cardSm .pb0 {
  padding-bottom: 0;
}
.cardSm .smImg {
  height: 10px;
  margin-left: 5px;
}
.cardSm .smFont {
  margin-top: 5px;
  font-size: 11px;
}
.cardSm .smFont .dLab {
  color: #0872bc;
  font-weight: 600 !important;
}
.cardSm .dLab {
  font-size: 11px;
  margin-block: 0;
  font-weight: 600 !important;
}
.customTextArea {
  height: 100px !important;
}
.ratioBox label {
  margin-right: 20px;
}
.ratioBox input {
  position: relative;
  top: 2px;
  left: 3px;
}
.feedbackLabel {
  background: #0872bc;
  padding: 10px 50px;
  color: white;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.banner11 {
  padding-bottom: 40px !important;
}
.feedbackInfo {
  font-size: 25px;
  text-align: center;
  padding: 50px 0;
}
.antd-pro-components-header-dropdown-index-container {
  z-index: 99 !important;
}
.float-right {
  float: right !important;
}
.ant-avatar-sm {
  width: 40px;
  height: 40px;
  line-height: 24px;
  border-radius: 50%;
}
.avatarBig .ant-avatar-sm {
  width: 50px;
  height: 50px;
  line-height: 24px;
  border-radius: 50%;
}
.card-body.table-responsive {
  padding-top: 0px !important;
}
.ant-table-pagination.ant-pagination {
  margin-top: 0px !important;
  margin-bottom: 7px !important;
}
.slimBtn .fee-box .box .btn-group button {
  display: flex;
  width: 100%;
  border: 2px solid #ededed;
  background: #e6e7e8;
  font-size: 13px;
  text-transform: capitalize;
  margin: 0 0 10px;
  height: 30px;
  align-items: center;
  padding: 0 10px;
}
section.gic-revenue {
  border-radius: 15px;
}
section.gic-revenue a.main-btn {
  background: #11148a;
  font-size: 15px;
  color: #fff;
  border-radius: 12px;
  padding: 12px 25px;
  text-transform: capitalize;
  height: auto;
}
section.gic-revenue .list .box {
  box-shadow: inset 0 0 0 2px #e6e6e6;
  border-radius: 18px;
  padding: 10px 20px;
  margin: 0 0 15px;
  display: flex;
  padding-left: 80px;
  position: relative;
  flex-direction: column;
  text-align: left;
}
section.gic-revenue .list .box span {
  min-width: 50px;
  height: 50px;
  display: inline-flex;
  background: #e6e6e6;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}
section.gic-revenue .list .box h4 {
  font-size: 23px;
  font-weight: 600;
  color: #4d4d4d;
  line-height: normal;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  margin: 0;
}
section.gic-revenue .list .box p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  color: #666666;
}
section.gic-revenue .list .box span img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
section.gic-revenue .list button.btn {
  background: #5bb85d;
  border-radius: 18px;
  width: 100%;
  color: #fff;
}
section.gic-revenue .list button.btn span {
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.flexRow {
  display: flex;
  flex-wrap: wrap;
}
.flexRow span {
  padding: 3px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.flexRow .cross {
  margin-left: 10px;
  position: relative;
  top: -1px;
}
.actBy {
  font-weight: normal;
  color: #745e5e;
  margin-left: 27px;
  padding: 0;
  height: 15px;
  margin-bottom: 5px;
  position: relative;
  top: -5px;
}
table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
table tbody tr:nth-child(odd) {
  background: white;
}
.input-group-inner.field1 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right: 1px solid #00000017;
}
.input-group-inner.field1 .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group-inner.field2 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding-left: 20px !important;
}
.input-group-inner.field3 {
  border-radius: 50px !important;
  border-right: none;
}
.input-group-inner.field3 .form-control {
  border-radius: 50px !important;
}
.icefBlock {
  display: flex;
  flex-direction: row;
  background: #ffffff7a;
  font-weight: 700;
  line-height: 36px;
  align-items: center;
  border-left: 3px solid #1890ff;
  padding: 10px 30px 10px 10px;
  box-shadow: 2px 5px 10px #e9c4c4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  width: 80%;
}
.icefBlock h1 {
  font-size: 20px !important;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 !important;
}
.icefBlock img {
  max-width: 60px;
}
.outMotto {
  font-size: 16px !important;
}
.card.unizportal.regStu {
  padding: 20px;
}
.student-application-detail.student-application-new .inputBox {
  margin-bottom: 0 !important;
}
.student-application-detail.student-application-new .card .form-group label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 !important;
}
.student-application-detail.student-application-new.addCourseForm .card .form-group label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin: -10px 6px -5px !important;
}
.student-application-detail.student-application-new h5 {
  font-size: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.student-application-detail.student-application-new .detail-list ul {
  padding-left: 0;
}
.student-application-detail.student-application-new .detail-list ul li {
  padding: 5px 0 !important;
}
.student-application-detail.student-application-new .detail-list ul li strong {
  width: 30%;
}
.student-application-detail.student-application-new .manageBox {
  background-color: white;
}
.student-application-detail.student-application-new .manageBox .managerHeader {
  background-color: gray;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  position: absolute;
  right: 10px;
  border-bottom-left-radius: 10px;
}
.student-application-detail.student-application-new .manageBox .managerBoxOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.student-application-detail.student-application-new .manageBox .managerBoxOuter .manageBoxInfo {
  text-align: left;
  font-size: 12px;
  color: #585858;
  padding: 20px 0 20px 20px;
}
.student-application-detail.student-application-new .add-application-btn {
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 0px 0 0 !important;
  border-radius: 50px;
  font-weight: 400;
  width: auto;
  height: auto !important;
}
.fee-box .box .btn-group button.profileBtn {
  border: 2px solid #ededed;
  background: #e6e7e8 !important;
  border-radius: 5px;
  text-align: center;
  height: 30px;
  align-items: center;
  justify-content: center;
}
.login-box .form-group .countryCode img {
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin-right: 5px;
}
.referBtn {
  background-color: #0f52ba;
  color: white;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  padding: 0 20px 20px 10px;
}
.referBtn .ant-badge {
  position: relative;
  top: -17px;
}
.referBtn .ant-badge img {
  margin-right: 10px !important;
  height: 20px;
}
.referBtn a:hover {
  color: white !important;
}
.referCodeBox {
  border-color: #0f52ba;
  border-style: dashed;
  padding-top: 5px;
  padding-bottom: 5px;
}
.referHeader {
  background-color: #0f52ba;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-top: 10px;
}
.marketingHeader {
  background-color: white;
  border: 1px solid #ddd;
  padding: 15px 20px;
  margin-top: 10px;
  color: black;
  font-size: 18px;
}
.marketingHeader .fa {
  margin-right: 5px;
}
.referInfoBox {
  margin-top: 30px;
  text-align: center;
}
.conditionList {
  flex: 1 1;
  padding-left: 15px;
  padding-right: 30px;
}
.conditionListText {
  font-size: 12px;
}
.reportingBox {
  padding: 15px;
  background-color: white;
  margin: 5px;
  text-align: center;
}
.reportContainer .reportBox {
  margin: 20px 0;
}
.reportContainer .reportingBox {
  padding: 0 !important;
  box-shadow: 1px 1px 10px #b3b3b36b !important;
  background-color: #1890ff;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
.reportContainer .reportingBox .arrowIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
.reportContainer .reportingBox .reportingInnerBox {
  border-radius: 5px;
  border-bottom-right-radius: 80px !important;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
}
.reportContainer .reportingBox img {
  margin-bottom: 10px;
  height: 30px;
}
.reportContainer .reportingBox .anticon {
  margin-left: 10px;
}
.reportContainer .reportingBox .reportInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.reportContainer .reportingBoxLiveReporting {
  padding: 0 !important;
  box-shadow: 1px 1px 10px #b3b3b36b !important;
  background-color: white;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
.reportContainer .reportingBoxLiveReporting .arrowIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
.reportContainer .reportingBoxLiveReporting .reportingInnerBox {
  border-radius: 5px;
  border-bottom-right-radius: 80px !important;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
}
.reportContainer .reportingBoxLiveReporting img {
  margin-bottom: 10px;
  height: 30px;
}
.reportContainer .reportingBoxLiveReporting .anticon {
  margin-left: 10px;
}
.reportContainer .reportingBoxLiveReporting .reportInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.linkText {
  color: #0066ff;
  cursor: pointer;
}
.customGrpBtn .ant-input-group-addon {
  padding: 0 !important;
}
.customGrpBtn .ant-input-group-addon a {
  padding: 5px 15px !important;
  border-radius: 3px;
}
.customGrpBtn .ant-input-group-addon a:first-child {
  border-right: 1px solid #ddd !important;
}
.customGrpBtn .selected {
  background-color: #1890ff;
  color: white;
}
.othAppContainer {
  margin-top: 10px;
  margin-bottom: 50px;
}
.othAppContainer .flexColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.othAppContainer .flexColumn .otherAppList {
  width: 32%;
}
.othAppContainer .otherAppList {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px #ddd;
  border-radius: 5px;
  padding: 10px 10px 5px;
  flex-direction: column;
  margin-top: 10px;
}
.othAppContainer .otherAppList .leftBox {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.othAppContainer .otherAppList .leftBox img {
  width: 120px !important;
  height: auto !important;
  margin-bottom: 10px;
}
.othAppContainer .otherAppList .rightBox {
  text-align: left;
  width: 100%;
}
.othAppContainer .otherAppList .rightBox .label1 {
  font-size: 10px !important;
}
.othAppContainer .otherAppList .rightBox a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  width: 100%;
}
.othAppContainer .otherAppList .rightBox a p {
  color: #495057;
  font-size: 15px;
}
.photoShortName {
  max-height: 35px;
  margin-bottom: 5px;
  padding: 5px 10px 0;
}
.photoShortName a {
  color: black !important;
  display: block;
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.followUp {
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #ddd !important;
}
.studentRegForm .form-group input {
  padding-right: 10px !important;
}
.forgetEmailText {
  font-size: 16px !important;
  margin-top: 10px;
  text-transform: capitalize;
}
.forgetEmailText strong {
  color: #1890ff;
  font-weight: 500;
}
.gcKey {
  height: 250px !important;
}
.oshcBox {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 3px 5px;
  border-radius: 5px;
  background: #1690ff;
  color: white;
  font-weight: bold;
}
.oshcBox img {
  height: 18px;
  margin-right: 5px;
}
.noteMain {
  overflow: hidden !important;
}
.student-tab {
  overflow: hidden !important;
}
.student-tab .loadMoreBoxDiv {
  text-align: center;
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1 !important;
}
.student-tab .noteTitle {
  color: #111b21;
}
.student-tab .noteRow {
  background-color: white !important;
  position: relative;
}
.student-tab .noteRow .noteContainer {
  height: 80vh;
  overflow-y: auto;
}
.student-tab .noteRow .noteColumn .card {
  background: white !important;
  border: 1px solid #1890ff57;
  line-height: 1.5;
}
.student-tab .noteRow .noteColumn .card .dateBox {
  text-align: left;
  font-weight: bold;
  color: #0872bc;
}
.student-tab .noteRow .noteColumn .card .userBox {
  text-align: right;
  color: #667781;
  padding: 0;
  line-height: 1;
  font-size: 15px;
}
.student-tab .noteRow .noteColumn.noteRight {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.student-tab .noteRow .noteColumn.noteRight .card {
  border: 1px solid #5cb85c57;
}
.student-tab .noteRow .noteColumn.noteRight .card .dateBox {
  text-align: right;
}
.student-tab .noteRow .noteColumn.noteRight .card .userBox {
  text-align: right;
}
.student-tab .noteRow .noteBox {
  width: 75% !important;
}
.student-tab .noteRow .noteBox .card {
  background: #fff;
}
.student-tab .noteRow .noteBox .inner.card {
  padding: 10px 15px !important;
}
.textAreaCls {
  height: 200px;
}
.textAreaCls textarea.form-control {
  height: 200px !important;
}
.loginCredentialsBox {
  border: 0.3px solid #e6e7e8;
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #e6e7e8;
}
.loginCredentialsBox .header {
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
}
.localLoginCredentialsBox {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #0f52ba2e;
  border-radius: 10px;
  margin-bottom: 30px;
}
.localLoginCredentialsBox .header {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.localLoginCredentialsBox .header .loginBanner {
  margin-bottom: 10px;
}
.localLoginCredentialsBox .header .loginIcon {
  margin-bottom: 10px;
  height: 70px;
}
.localLoginCredentialsBox .verticalPadding10 {
  padding-left: 10px;
  padding-right: 10px;
}
.localLoginCredentialsBox .verticalPadding10 .label1 {
  position: relative;
  padding: 4px;
  font-size: 13px;
  color: #555;
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.localLoginCredentialsBox .verticalPadding10 .label2 {
  position: relative;
  padding: 4px;
  font-size: 13px;
  color: #555;
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.localLoginCredentialsBox .verticalPadding10 .label2 .btn-info {
  background-color: #0f52ba;
  border-color: #0f52ba;
}
.localLoginCredentialsBox .verticalPadding10 .rowC {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
}
.localLoginCredentialsBox .verticalPadding10 .rowC:last-child {
  border-bottom: none;
}
.localLoginCredentialsBox .verticalPadding10 .rowC:last-child span {
  margin-top: 5px;
}
.localLoginCredentialsBox .verticalPadding10 .rowC img {
  height: 20px;
  margin-right: 8px;
}
.localLoginCredentialsBox .verticalPadding10 .rowC span {
  border: none;
  padding: 0;
  flex: 1 1;
  text-transform: capitalize;
  display: flex;
}
.commissionBox .commissionParentBox {
  display: flex;
  flex-direction: column;
}
.commissionBox .commissionParentBox.row {
  flex-direction: row;
}
.commissionBox .commissionParentBox.row .commissionUniInfo {
  flex: 1 1;
  margin: 0 10px 20px;
}
.commissionBox .commissionParentBox .commissionUniInfo {
  border: none;
  box-shadow: 1px 1px 10px #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}
.commissionBox .commissionParentBox .commissionUniInfo .ant-card-body {
  padding: 10px;
}
.commissionBox .commissionParentBox .commissionUniInfo img {
  width: max-content;
  height: 50px;
}
.commissionBox .commissionParentBox .commissionUniInfo div[class*='ant-col'] {
  display: flex;
  justify-content: center;
  height: 70px;
  flex-direction: column;
}
.commissionBox .commissionParentBox .commissionUniInfo .logoBox {
  border-right: 1px solid #ddd;
  padding-right: 30px;
  align-items: center;
}
.commissionBox .commissionParentBox .commissionUniInfo .contantBox {
  padding-left: 50px !important;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox {
  display: flex;
  min-height: 60px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentProfile {
  width: 20%;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentProfile img {
  height: 60px;
  max-width: 100%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentInfo {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 5px 5px 5px 40px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart {
  border-right: 1px solid #f1f1f1;
  padding-right: 30px;
  width: 30%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:first-child {
  width: 25%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:last-child {
  border: none;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .studentInfo {
  justify-content: flex-start;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .uniLogo {
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .uniLogo img {
  height: 50px;
  width: 50px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew {
  border-right: 1px solid #f1f1f1;
  padding-right: 30px;
  width: 22.5%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew:first-child {
  width: 25%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew:last-child {
  border: none;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .studentInfo {
  justify-content: flex-start;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .uniLogo {
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .uniLogo img {
  height: 50px;
  width: 50px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex {
  width: 15%;
  padding-left: 20px;
}
.commissionBox .commissionParentBox .commissionUniInfo .uniName {
  font-size: 18px;
  color: #0f52ba;
}
.commissionBox .commissionParentBox .commissionUniInfo .courseInfo {
  font-size: 15px;
  color: black;
}
.commissionBox .commissionParentBox .commissionUniInfo .courseInfo.sm {
  font-size: 14px;
  color: #555;
}
.commissionBox .commissionParentBox .commissionUniInfo .commissionInfo {
  font-size: 14px;
  color: #0872bc;
  font-weight: 600;
}
.commissionBox .inputGroup {
  margin-top: 10px;
}
.commissionBox .inputGroup .input-group-text {
  background-color: #e9ecef;
  border-color: #666;
}
.commissionBox textarea.form-control {
  height: 50px !important;
}
.comBtnGroup {
  display: flex;
  flex-wrap: wrap;
}
.comBtnGroup .btn {
  margin-bottom: 3px;
  font-size: 13px !important;
  padding: 0 4px !important;
  width: 180px;
}
.comBtnGroup .btn img {
  margin-right: 5px;
  height: 13px;
}
.comBtnGroup .btn-view {
  border: 1px solid #d9d9d9 !important;
  color: #212529 !important;
  background-color: white;
}
.darkFont {
  color: black;
}
.dangerPos {
  position: absolute;
  left: 10px;
}
.commissionListBox {
  margin-top: 20px;
  position: relative;
}
.commissionListBox .addMoreCommissionBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.student-group {
  margin-top: 10px;
  margin-right: 10px;
}
.student-group label {
  margin: 0 10px 4px 3px !important;
  color: black;
  font-weight: 500 !important;
}
.student-group .form-control {
  border-color: #e5dcdc !important;
  background-image: none !important;
  height: 35px !important;
  border-radius: 5px !important;
}
.student-group .input-group-text {
  border-color: #e5dcdc !important;
  height: 35px !important;
}
.uniInfoLogo {
  border-right: 1px solid #f1f1f1;
  padding-right: 20px;
  margin-right: 20px;
}
.uniInfoLogo img {
  height: 70px !important;
}
.textCap {
  text-transform: capitalize;
}
.textUpp {
  text-transform: uppercase;
}
.customCheckbox {
  cursor: pointer;
  text-align: center;
}
.customCheckbox img {
  height: 25px;
}
.universityParentBox {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.universityParentBox .commissionUniInfo {
  border: none;
  box-shadow: 1px 1px 10px #ddd;
  border-radius: 5px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.universityParentBox .commissionUniInfo .ant-card-body {
  justify-content: center;
  flex: 1 1;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.universityParentBox .commissionUniInfo .uniLogo {
  border-right: none;
}
.universityParentBox .commissionUniInfo .uniLogo img {
  height: 70px !important;
  width: 100%;
}
.universityParentBox .commissionUniInfo .studentInfoBox {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.universityParentBox .commissionUniInfo .ant-card-body {
  padding: 10px;
}
.universityParentBox .commissionUniInfo img {
  width: max-content;
  height: 50px;
}
.universityParentBox .commissionUniInfo .uniName {
  font-size: 18px;
  color: #0f52ba;
}
.filterCard .ant-select .ant-select-selection--single {
  border: 1px solid #e9d5d5 !important;
}
.filterCard .search-box-table.round {
  border: 1px solid #e9d5d5 !important;
}
.filterCard .roundBtn {
  border: 1px solid #e9d5d5 !important;
}
.statusDate {
  margin-top: 5px;
  font-size: 13px;
  color: #0f52ba;
  font-weight: 500;
}
.btn-warning {
  background-color: rgba(240, 173, 78, 0.87) !important;
  color: white !important;
}
.btn-success {
  border-color: #0872bc !important;
  background-color: #0872bc !important;
  color: white !important;
}
.btn-view {
  border: 1px solid #d9d9d9 !important;
  color: #212529 !important;
  background-color: white !important;
  margin-top: 0 !important;
}
.btn-view.sm {
  padding: 0.25rem 0.8rem;
  font-size: 0.8rem;
  line-height: 1.5;
}
.label-info-new {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: none;
}
.btn-paid {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  color: white !important;
}
.label-paid {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  color: white !important;
}
.view-btn-box {
  margin-top: 5px;
  /* .eyeBtn {
       height: 20px;
     }*/
}
.view-btn-box .btn-view {
  font-size: 12px !important;
  border: 1px solid #d9d9d9;
  height: 32px;
  padding: 2px 5px;
  border-radius: 5px;
  color: #212529 !important;
  margin-right: 5px;
}
.view-btn-box .btn-view img {
  height: 17px;
}
.contract {
  text-transform: uppercase;
  margin-top: 5px;
  color: black;
}
.contract a {
  text-decoration: underline;
  font-weight: 500;
}
.customInput {
  margin-top: 8px;
}
.customInput label {
  margin-bottom: auto;
}
.table-head .sort-box-table.sort-box-table30 {
  width: 30% !important;
}
.flexRowBox {
  display: flex;
}
.flexRowBox .flexBox1 {
  flex: 1 1;
  width: 30%;
  margin-right: 10px;
  background-color: #f1f1f1 !important;
  margin-bottom: 18px;
}
.flexRowBox .flexBox2 {
  flex: 2 1;
}
.imgFlex {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
}
.imgFlex .img-column {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  margin-right: 10px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgFlex .img-column img {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart {
    width: 33%;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:first-child {
    width: 33%;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:nth-child(3) {
    border: none;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:last-child {
    border: none;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex {
    width: 50%;
    padding-left: 20px;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex .student-group {
    margin-top: 0;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .uniName {
    font-size: 15px;
    color: #0f52ba;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .courseInfo {
    font-size: 12px;
    color: black;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .courseInfo.sm {
    font-size: 12px;
    color: #555;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .commissionInfo {
    font-size: 12px;
    color: #0872bc;
    font-weight: 600;
  }
  .universityParentBox {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .universityParentBox .commissionUniInfo {
    border: none;
    box-shadow: 1px 1px 10px #ddd;
    border-radius: 5px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .universityParentBox .commissionUniInfo .ant-card-body {
    justify-content: center;
    flex: 1 1;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .universityParentBox .commissionUniInfo .uniLogo {
    border-right: none;
  }
  .universityParentBox .commissionUniInfo .uniLogo img {
    height: 70px !important;
    width: 100%;
  }
  .universityParentBox .commissionUniInfo .uniName {
    font-size: 15px;
    color: #0f52ba;
  }
  .customInput {
    margin-top: 8px;
  }
  .customInput label {
    margin-bottom: 0;
  }
}
.round-date-picker .ant-calendar-picker-input {
  height: 42px !important;
  border-radius: 50px;
  border: none;
}
.verifyOfferBox {
  position: relative;
  overflow: hidden;
  height: auto;
  padding-top: 7%;
  padding-bottom: 90px;
  min-height: 80vh;
}
.verifyOfferBox h3 {
  font-size: 32px;
  text-transform: capitalize;
  margin: 0 0 10px;
  color: #0f52ba;
}
.verifyOfferBox .store {
  padding-top: 7%;
}
.verifyOfferBox .store img {
  width: 100%;
}
.verifyOfferBox .iframeBox {
  margin: 5% 15% 100px;
  border: 1px solid #ddd;
  padding: 10px;
}
.announcement_banner {
  background-color: white;
  max-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.announcement_banner img {
  width: auto;
  height: 250px;
}
.announcement_banner .applyBtn {
  position: absolute;
  bottom: 15px;
  width: 350px !important;
}
.certificate_box .btn-default {
  margin-bottom: 10px;
  width: 220px;
}
.certificate_box .ant-tag {
  margin-bottom: 5px;
  border-color: #007bff33;
}
.certificate_box .ant-tag .btn-link {
  font-weight: 400;
  color: #007bff !important;
  text-decoration: none;
}
.duplicateRecordLink {
  font-size: 15px;
  margin-bottom: 10px;
}
.broadcast_info {
  font-size: 25px;
  text-align: center;
  padding: 50px 0;
  min-height: 500px;
}
.broadcast_info img {
  height: 150px;
  margin-bottom: 20px;
}
.addInfo {
  padding-left: 3px !important;
}
.addInfo .box {
  position: relative;
  margin-bottom: 5px;
  display: flex !important;
}
.addInfo .box img {
  height: 22px !important;
  width: auto !important;
}
.addInfo .box span {
  margin-left: 27px;
}
@media (max-width: 600px) {
  .verifyOfferBox {
    position: relative;
    overflow: hidden;
    height: auto;
    padding-top: 100px;
    padding-bottom: 90px;
    min-height: 80vh;
  }
  .verifyOfferBox h3 {
    font-size: 32px;
    text-transform: capitalize;
    margin: 0 0 10px;
    color: #0f52ba;
  }
  .verifyOfferBox .store {
    padding-top: 7%;
  }
  .verifyOfferBox .store img {
    width: 100%;
  }
  .verifyOfferBox .iframeBox {
    margin: 5% 2% 100px;
    border: 1px solid #ddd;
    padding: 10px;
    overflow: auto;
  }
}
.counsellerDashboard {
  width: auto;
}
.counsellerDashboard .main-logo {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.counsellerDashboard .counseller-card {
  width: 100%;
  height: 50%;
  border: 1px solid lightgrey;
}
.counsellerDashboard .counseller-card .countryInfo {
  text-align: center;
}
.counsellerDashboard .counseller-card .countryInfo .map {
  width: auto;
  height: 100px;
}
.counsellerDashboard .counseller-card .countryInfo h5 {
  color: #000;
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.counsellerDashboard .counseller-card .counselling-container {
  position: relative;
  min-height: 200px;
  border-bottom: 1px solid #ddd;
}
.counsellerDashboard .counseller-card .counselling-container .waitingBox {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #00000033;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.counsellerDashboard .counseller-card .counselling-container .waitingBox .waitingLabel {
  font-size: 25px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  padding: 20px 20px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card:nth-child(0) {
  border-bottom: 1px solid lightgrey;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title {
  width: 100%;
  padding: 10px;
  padding-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title img {
  border-radius: 50px;
  margin-right: 15px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title .box-title {
  font-size: 12px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title .tarundiv {
  display: flex;
  flex-direction: column;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .waitingInfo {
  display: flex;
  margin-bottom: 20px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .waitingInfo .counsellerInfo-num {
  background-color: #ddd;
  border-radius: 40px;
  padding: 0 10px;
  margin-left: 10px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo {
  width: 100%;
  height: 50%;
  font-size: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo.active {
  color: #0872bc;
}
.comment-boxx {
  border-bottom: lightgrey;
}
.student-informationn {
  display: flex;
  padding: 9px 14px;
}
.studentprofile {
  display: flex;
  flex-direction: row;
}
.studentprofile .image {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.studentprofile .image img {
  border-radius: 100%;
}
.studentprofile .descrip {
  width: 65%;
}
.studentprofile .descrip .border {
  width: 60%;
  border: 2px solid gray;
  padding: 10px;
}
.imagee {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imagee img {
  border-radius: 100%;
}
.studentinformationboxx {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.nameandnumberboxx {
  height: 40%;
}
.localLoginCredentialsBoxx {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #0f52ba2e;
  border-radius: 10px;
  margin-bottom: 10px;
}
.centerpage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerpage .borderr {
  width: 50%;
}
.dateandhistory {
  height: 50px;
}
.studentListContainer {
  margin-bottom: 5px;
}
.studentListContainer.active .otherAppList {
  background-color: #fff8e1;
}
.studentListContainer .otherAppList {
  display: flex;
  padding: 10px 10px 5px;
  background-color: white;
  box-shadow: 0 0 10px #f1f1;
  border-radius: 5px;
  margin-bottom: 10px;
}
.studentListContainer .otherAppList .leftBox {
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.studentListContainer .otherAppList .leftBox .logo1 {
  width: 200px;
  height: 200px;
}
.studentListContainer .otherAppList .leftBox img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.studentListContainer .otherAppList .leftBox .studentDetails {
  line-height: 1.5;
}
.studentListContainer .otherAppList .leftBox .leftBox1 {
  background-color: gray !important;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.studentListContainer .otherAppList .leftBox .leftBox1 img {
  width: 50px !important;
  height: 50px !important;
  margin-right: 20px;
}
.studentListContainer .otherAppList .leftBox .leftBox1 .studentDetails {
  line-height: 1.5;
}
.studentListContainer .otherAppList .rightBox {
  margin-top: 10px;
  text-align: left;
}
.studentListContainer .otherAppList .rightBox a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}
.studentListContainer .otherAppList .rightBox a p {
  color: #495057;
  font-size: 15px;
}
.closeconvert {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}
.flaguk {
  margin-left: 466px;
  width: 25px;
}
.nameanddate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.backanddot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.onoffdot {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: 10px;
}
.onoffdot .innerdot {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
}
.picandmore {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.caseclose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.caseclose .caseclosebtn {
  width: 100px;
  background: #0872bc;
  font-weight: 500 !important;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  border: none;
  outline: none;
}
.myTextarea {
  padding: 10px;
}
.directUniCount {
  width: 16.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.directUniCount .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.directUniCount .uniCountBox img {
  height: 30px;
  width: auto;
}
.directUniCount .uniCountBox h5 {
  font-size: 14px !important;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
.canadaDirectUniCount.canadaDirectUniCount2 {
  width: 25% !important;
}
.canadaDirectUniCount2 .innerBox {
  border: 1px solid #ccc;
}
.card.card-pdf {
  background: white !important;
}
.canadaDirectUniCount {
  width: 19.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.canadaDirectUniCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.canadaDirectUniCount .innerBox .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
.canadaDirectUniCount .innerBox .uniCountBox img {
  height: 30px;
  width: auto;
}
.canadaDirectUniCount .innerBox .uniCountBox img.sm {
  height: 25px;
}
.canadaDirectUniCount .innerBox .uniCountBox img.md {
  height: 35px !important;
}
.canadaDirectUniCount .innerBox .uniCountBox h5 {
  font-size: 14px;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
.canadaDirectUniCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
}
.canadaDirectUniCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.canadaDirectUniCount .innerBox .btnBox a i {
  margin-right: 5px;
}
.canadaDirectUniCount .innerBox .dirUniAgent {
  padding: 10px 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 27px;
}
.filter-row.ant-row {
  display: flex;
  flex-wrap: wrap;
}
.commissionBlock {
  margin: 20px 0;
}
.commissionBlock .countryCommission {
  padding: 20px;
  text-align: center;
}
.commissionBlock .countryCommission .countryName {
  font-size: 16px !important;
  height: 50px;
}
.commissionBlock .countryCommission .countryFlag {
  margin: 25px 0;
}
.commissionBlock .countryCommission .countryFlag img {
  height: 30px;
}
.commissionBlock .countryCommission .currencyName {
  font-size: 16px !important;
}
.commissionBlock .countryCommission .countryAmount {
  font-size: 25px;
  cursor: pointer;
}
.commissionBlock .countryCommission .canada {
  color: #ad2125;
}
.commissionBlock .countryCommission .uk {
  color: #ff0066;
}
.commissionBlock .countryCommission .australia {
  color: #003399;
}
.card.bgWhite {
  background: white !important;
}
.ant-col-md-0 {
  display: block;
}
.squareContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.squareContainer .squareStyle {
  width: 45px;
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
}
.squareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.squareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.squareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
.squareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
.squareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
.ukSquareContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 15px;
  grid-column-gap: 5px;
}
.ukSquareContainer .squareStyle {
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  text-align: center;
}
.ukSquareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.ukSquareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.ukSquareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
.ukSquareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
.ukSquareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
.dirUniLabelBox {
  display: flex;
  padding: 30px 2.4rem 0 !important;
}
.dirUniLabelBox div {
  margin: 0 20px;
  padding: 0 10px;
}
.dirUniLabelBox div:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.dirUniLabelBox div:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.dirUniLabelBox div:nth-child(3) {
  border-bottom: 2px solid #5bc0de;
}
.ledgerBox {
  padding: 20px 3%;
}
.ledgerBox .header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}
.ledgerBox .header .ant-col {
  color: black;
  font-weight: 500;
  padding-bottom: 10px;
}
.ledgerBox .ant-col:nth-child(3) {
  text-align: right;
}
.ledgerBox .ant-col:last-child {
  text-align: right;
}
.ledgerBox .ant-row {
  padding: 10px 10px 0;
}
.ledgerBox .ant-row:nth-child(even) {
  background-color: #f7f7f7;
}
.ledgerBox .ant-row:nth-child(odd) {
  background-color: #ff11ff08;
}
.ledgerBox .ant-row.header {
  background-color: white;
}
.ledgerBox .debit {
  color: #d9534f;
  font-weight: 600;
}
.ledgerBox .credit {
  color: #5cb85c;
  font-weight: 600;
}
.ledgerBox .dateBox {
  font-size: 15px;
  font-weight: 500;
  color: #0872bc;
  padding-bottom: 10px;
}
.filterBox .ant-calendar-picker-input {
  border-radius: 50px;
  border: none;
}
.filterBox .search-box-table.round {
  margin-left: 0 !important;
}
.agentName {
  display: flex;
}
.agentName .agentOnline {
  height: 7px;
  width: 7px;
  background-color: #5cb85c;
  border-radius: 50%;
  margin-left: 5px;
}
.display-linebreak {
  white-space: pre-line;
}
.hrFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.selectable-box {
  border: 1px solid #e5d9d9;
  margin: 5px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
}
.box-text {
  font-size: 14px;
}
.countCircle {
  font-size: 12px !important;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  line-height: 0.9;
  background: #0872bc;
  color: white !important;
  margin-left: 7px;
  margin-top: 2px;
  padding: 5px;
  display: inline-block;
}
.selectable-box.selected {
  background-color: #007bff;
  color: white;
}
.selectable-box.selected .countCircle {
  background: #5cb85c;
}
.intakeRow {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  margin-top: 10px;
}
.intakeRow .singleIntakeBox {
  border: 1px solid #d9d9d9;
  padding: 0 5px;
  background: #fafafa;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}
.intakeRow .singleIntakeBox .singleIntakeBox-check {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  bottom: 3px;
}
.intakeRow .singleIntakeBox.selected {
  background-color: #1890ff;
}
.intakeRow .singleIntakeBox.selected i {
  color: white !important;
}
.intakeRow .singleIntakeBox i {
  font-style: normal;
  color: rgba(0, 0, 0, 0.65);
}
.trainingBox {
  text-align: center;
  margin-top: 50px;
}
.trainingBox img {
  height: 150px;
}
.trainingBox .textBox {
  margin-top: 20px;
}
.trainingBox .textBox h3 {
  font-weight: 600;
  text-transform: uppercase;
  font-family: ui-monospace;
  font-size: 30px;
  margin-bottom: 3%;
}
.trainingBox .textBox.normal h3 {
  text-transform: capitalize;
}
.trainingBox .textBox .confirmLink {
  font-size: 17px;
  background-color: #0f52ba;
  color: white;
  padding: 6px 30px;
  text-transform: capitalize;
  border-radius: 5px;
}
.marketingUserBox {
  margin-bottom: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 10px 10px #ddd;
}
.marketingUserBox.managerBoxCanada {
  width: 100%;
  margin-bottom: 0;
}
.marketingUserBox.managerBoxCanada .ant-avatar img {
  left: 0 !important;
}
.marketingUserBox.managerBoxCanada .managerHead {
  margin-bottom: 5px;
  background-color: #ad2125;
}
.marketingUserBox .managerHead {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 80%;
}
.marketingUserBox .managerBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-left: 10px;
}
.marketingUserBox .managerBody.ais {
  align-items: flex-start;
}
.whatsNewCard .whatsNewBox {
  border-bottom: 1px solid #fde6e6;
  padding: 20px;
}
.whatsNewCard .whatsNewBox .flex-row {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
}
.whatsNewCard .whatsNewBox .flex-row .icon {
  width: 50px;
  height: 50px;
  margin-right: 30px;
  margin-left: 20px;
}
.whatsNewCard .whatsNewBox .flex-row .title {
  color: #0f52ba;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
}
.whatsNewCard .whatsNewBox .flex-row .dateRow {
  color: #555;
  font-size: 14px;
}
.whatsNewCard .whatsNewBox .flex-row .dateRow i {
  margin-right: 5px;
  color: #888;
}
.whatsNewCard .whatsNewBox .content-box {
  margin-top: 20px;
}
.whatsNewCard .whatsNewBox .img-box img {
  width: 80%;
}
.studentInfoTableRow {
  padding: 4px 10px;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.studentInfoTableRow .title {
  font-weight: bold;
  width: 40%;
  padding: 5px 10px;
}
.studentInfoTableRow .details {
  padding: 5px 10px;
}
.studentInfoTableRow p {
  margin-bottom: 0;
}
.studentInfoTableRow:first-child {
  border-top: 1px solid #ddd;
}
.studentInfoTableRow:nth-child(odd) {
  background-color: white;
}
.studentInfoTableRow:nth-child(even) {
  background-color: #effbf8;
}
.textAreaHeight {
  min-height: 100px !important;
}
.agent_form {
  background-color: #f7f7ff;
}
.agent_form .ant-form-item {
  margin-bottom: 0.8rem;
}
.agent_form .form-control,
.agent_form .ant-select-selection {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none;
  appearance: none;
  height: 50px !important;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-image: none !important;
}
.agent_form .form-control::-webkit-input-placeholder,
.agent_form .ant-select-selection::-webkit-input-placeholder {
  font-size: 1rem !important;
  font-weight: 400;
}
.agent_form .ant-select-selection__placeholder {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
}
.agent_form .ant-select-selection__rendered {
  margin-left: 0;
}
.agent_form .ant-select-arrow {
  background-color: white;
  color: #777 !important;
}
.agent_form .ant-select-focused .ant-select-arrow {
  background-color: white !important;
  color: #777 !important;
}
.agent_form label {
  font-size: 18px !important;
  margin: 0 !important;
  margin-bottom: 8px !important;
}
.agent_form .right_bg {
  height: 100%;
  background-position: 35%;
  background-repeat: no-repeat;
}
.agent_form .btn_sub {
  padding: 8px 16px !important;
  font-size: 1.25rem;
  border-radius: 0 !important;
  background-color: #1890ff;
  border: none;
}
.registrationClose {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.default_btn {
  border: 1px solid #ddd;
  padding: 3px 10px;
  color: #555;
  text-decoration: none;
  font-size: 0.8rem !important;
  border-radius: 3px;
  margin-right: 5px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}
.default_btn:last-child {
  margin-right: 0;
}
.default_btn img {
  height: 15px !important;
  margin-right: 4px;
  margin-bottom: 2px;
}
.btn_group {
  display: flex;
  flex-wrap: wrap;
}
.btn_group .default_btn {
  margin-right: 5px;
}
.btn_group .btn {
  margin-right: 5px;
}
.select_btn_group {
  display: flex;
  flex-wrap: wrap;
}
.select_btn_group .default_btn {
  margin-right: 5px;
  background-color: white;
  line-height: 1.5;
  padding: 0.4rem 1.75rem !important;
  height: auto !important;
  border: none;
  margin-left: 5px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 15px;
}
.select_btn_group .default_btn.selected {
  background-color: #5cb85c;
  color: white;
}
.select_btn_group .default_btn .anticon {
  font-size: 16px;
  margin-right: 7px;
}
.select_btn_group .default_btn_lg {
  margin-right: 10px;
  background-color: white;
  line-height: 1.5;
  padding: 0.4rem 3rem !important;
  height: auto !important;
  border: none;
  margin-left: 5px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  border-radius: 3px;
  color: #555;
  display: flex;
  align-items: center;
}
.select_btn_group .default_btn_lg.selected {
  color: white;
}
.select_btn_group .default_btn_lg .anticon {
  font-size: 16px;
  margin-right: 7px;
}
.btn_link {
  text-decoration: underline;
}
.custom-checkbox {
  width: 100%;
}
.custom-checkbox .ant-checkbox-group-item {
  width: 40% !important;
}
.custom-checkbox span {
  font-weight: normal;
}
.marketing_country_box {
  border: 1px solid #f1f1f1;
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.marketing_country_box .uni_row {
  border: 1px solid #f1f1f1;
  padding: 5px 0 0 0;
  margin-bottom: 5px;
  border-radius: 5px;
}
.marketing_country_box .uni_row .form-control.sm {
  height: 35px !important;
  border-color: #ddd !important;
  background-image: none !important;
  font-size: 14px !important;
}
.filter_box {
  margin-top: 15px;
  background-color: white;
  border-radius: 5px;
  padding: 15px 10px 10px;
}
.filter_box.bg {
  border: 1px solid #dee2e6 !important;
}
.filter_box .labelNew {
  font-size: 14px;
  font-weight: normal !important;
}
.filter_box input:not([class]) {
  width: 100%;
  border: 1px solid #ddd;
  padding: 0 5px 0 20px;
  border-radius: 50px;
  font-size: 14px;
  color: #555;
  height: 40px;
}
.filter_box .ant-calendar-picker-input {
  height: 42px !important;
  border-radius: 50px;
  border: 1px solid #ddd;
}
.filter_box .ant-select.ant-select-focused .ant-select-arrow {
  background: transparent !important;
  color: #555;
}
.filter_box .ant-select .ant-select-selection {
  border-radius: 50px;
  border-color: #ddd;
}
.filter_box .ant-select .ant-select-selection .ant-select-arrow {
  background: transparent;
  color: #555;
}
.filter_box .ant-select .ant-select-selection:hover .ant-select-arrow {
  background-color: white;
  display: block;
}
.filter_box .ant-select .ant-select-selection:hover .ant-select-arrow .anticon {
  color: #555;
}
.filter_box .ant-select-selection__placeholder {
  font-size: 14px !important;
  opacity: 0.8 !important;
  color: #333 !important;
}
.filter_box .search-box-table.round {
  border: 1px solid #ddd;
}
.filter_box .btn_group {
  display: flex;
  justify-content: flex-end;
}
.filter_box .btn_group .default_btn {
  margin-right: 5px;
  padding: 5px 20px;
  border-radius: 50px;
}
.filter_box .btn_group .default_btn:last-child {
  margin-right: 0;
}
.mismatchNote {
  padding: 5px 10px;
  background-color: #d9534f;
  color: white;
  font-weight: 600;
  margin-top: 10px;
}
.expenseAmtBox .successAmt {
  color: #5cb85c;
}
.expenseAmtBox .pendingAmt {
  color: rgba(240, 173, 78, 0.87);
}
.expenseAmtBox .totalAmt {
  color: #0872bc;
}
.joinDate {
  margin-top: 5px;
  color: #1890ff;
  font-size: 11px;
  border-radius: 3px;
  font-weight: 600;
}
.joinDate::before {
  content: '' !important;
  border-left: 2px solid #1890ff;
  padding-left: 3px;
}
.table-bordered {
  border: 1px solid #dee2e6 !important;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6 !important;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-bordered th,
.table-bordered td,
.table-bordered thead th,
.table-bordered tbody + tbody {
  border: 0;
}
.marketingDirectUniCount {
  width: 16.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
}
.marketingDirectUniCount .uni_box {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.marketingDirectUniCount .uni_box img {
  height: 40px;
  width: auto;
}
.marketingDirectUniCount .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 15px;
}
.marketingDirectUniCount .uniCountBox .countBox {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 40%;
  cursor: pointer;
}
.marketingDirectUniCount .uniCountBox .countBox .label {
  font-size: 12px !important;
  color: #555;
  padding: 0;
}
.marketingDirectUniCount .uniCountBox .countBox .count {
  padding: 0 5px;
  color: #1890ff;
}
.dropbtn {
  border: none;
}
input[type='date'].js_datepicker {
  background-color: white !important;
  border: 1px solid #e5dddd !important;
}
.eye_btn {
  padding: 0 3px !important;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 10px;
  color: #0f52ba !important;
}
.eye_btn img {
  height: 14px;
}
.structureDotsReplace {
  display: block;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}
.event_gallery_list {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.event_gallery_list .single_img {
  border: 1px solid #ebcfcf;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
.event_gallery_list .single_img:hover {
  border: 2px solid #0872bc;
}
.event_gallery_list .single_img .cross_btn {
  position: absolute;
  right: -8px;
  top: -8px;
  background: #0f52ba;
  border-radius: 50px;
  width: 22px;
  height: 22px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
.event_gallery_list .single_img img {
  height: 100px;
}
.btn_options {
  display: flex;
}
.btn_options a {
  font-size: 14px;
  width: 100%;
  height: 40px !important;
  padding: 0.475rem 0.75rem;
}
.btn_options .date_box {
  width: 220px;
  margin-right: 10px !important;
}
.btn_options .date_box.selected {
  background-color: #f1f1f1 !important;
}
.btn_options .date_box.selected .btn {
  background-color: #f1f1f1 !important;
}
.btn_options .date_box .form-control {
  border-color: #ddd !important;
  font-size: 15px;
}
.ant-calendar-month-calendar {
  width: 300px;
}
div#studentChart {
  padding-top: 30px !important;
}
.agent-rank-label {
  font-size: 10px !important;
  padding: 4px !important;
}
.priorityBox {
  margin-top: 10px;
}
.priorityBox .priority {
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background: rgba(240, 173, 78, 0.87);
  font-weight: 600;
  position: relative;
  top: -3px;
  left: 0;
  margin-left: 5px;
  font-style: normal;
  animation: glowS 0.5s ease-in-out infinite alternate;
  text-transform: uppercase;
}
.priorityBox .superPriority {
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background: red;
  font-weight: 600;
  position: relative;
  top: -3px;
  left: 0;
  margin-left: 5px;
  font-style: normal;
  animation: glow 0.5s ease-in-out infinite alternate !important;
  text-transform: uppercase;
}
.eventDirectCount {
  width: 20% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.eventDirectCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.eventDirectCount .innerBox .uniCountBox {
  display: flex !important;
  align-items: center;
  padding: 10px 5px;
}
.eventDirectCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
}
.eventDirectCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventDirectCount .innerBox .btnBox a i {
  margin-right: 5px;
}
.eventDirectCount .innerBox .dirUniAgent {
  padding: 10px 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 27px;
}
.eventDirectCount .innerBox .squareContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}
.eventDirectCount .innerBox .squareContainer > div {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.eventDirectCount .innerBox .squareContainer > div span {
  font-size: 12px;
  margin-bottom: 5px;
}
.eventDirectCount .innerBox .squareContainer .squareStyle {
  width: 60px;
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  text-align: center;
  align-self: center;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.totalCount {
  border-bottom: 2px solid #5cb85c;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.approvedCount {
  border-bottom: 2px solid #1890ff;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.pendingCount {
  border-bottom: 2px solid #0872bc;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.deferredCount {
  border-bottom: 2px solid #5bc0de;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.deniedCount {
  border-bottom: 2px solid #d9534f;
}
.icefMeetingCount .singleLi {
  display: inline-block;
  padding: 10px;
}
.icefMeetingCount .singleLi .li_box {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}
.icefMeetingCount .singleLi .li_box .squareStyle {
  margin-left: 20px;
  border-bottom: 2px solid;
  min-width: 30px;
  text-align: center;
  font-weight: bold;
}
.main-btn {
  width: auto !important;
  min-width: 150px;
  background: #1556bc;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  height: 42px !important;
}
.white-btn {
  background: white;
  border: 1px solid #ddd;
  color: #555;
}
.white-btn .ant-calendar-picker-input.ant-input {
  height: 35px !important;
}
.small-select .ant-select-selection {
  height: 30px !important;
  border-color: #ddd;
  border-radius: 3px;
}
.small-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.md-select .ant-select-selection {
  height: 33px !important;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.md-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.room_slider .mobile_view {
  display: none;
}
.room_slider .web_view {
  display: block;
}
@media (max-width: 768px) {
  .room_slider .mobile_view {
    display: block;
  }
  .room_slider .web_view {
    display: none;
  }
}
.shadow-select {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
}
.shadow-select .ant-select-selection {
  height: 35px !important;
  border-radius: 7px;
  padding: 15px 10px;
  border: none;
}
.shadow-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.comment-section .sendBtn {
  border-radius: 15px !important;
  background-color: #87d068;
  border: none;
  color: white;
  padding: 14px !important;
}
.comment-section .avatar_text {
  background-color: #87d068;
  margin: 0 12px;
  font-size: 20px !important;
}
.top_city {
  height: 170px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
.top_city img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
.top_city .city_name {
  position: absolute;
  bottom: 0;
  left: 7.5px;
  padding: 15px;
  z-index: 1;
  color: white;
  font-size: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: calc(100% - 15px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 500;
}
.shadow-select {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
}
.shadow-select .ant-select-selection {
  height: 35px !important;
  border-radius: 7px;
  padding: 15px 10px;
  border: none;
}
.shadow-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.comment-section .sendBtn {
  border-radius: 15px !important;
  background-color: #87d068;
  border: none;
  color: white;
  padding: 14px !important;
}
.comment-section .avatar_text {
  background-color: #87d068;
  margin: 0 12px;
  font-size: 20px !important;
}
.top_city {
  height: 170px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
.top_city img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
.top_city .city_name {
  position: absolute;
  bottom: 0;
  left: 7.5px;
  padding: 15px;
  z-index: 1;
  color: white;
  font-size: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: calc(100% - 15px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 500;
}
.icefManager {
  background: #A1A8EA;
  height: 140px;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icefManager .avatar_box {
  position: absolute;
  bottom: 0;
  left: 5%;
}
.icefManager .avatar_box img {
  height: 170px;
}
.icefManager .manager_info_box {
  position: absolute;
  left: 25%;
  font-size: 13px;
}
.icefManager .manager_info_box .head-title {
  font-size: 22px;
  color: white;
}
.icefManager .manager_info_box .manager_details .inline_a {
  display: flex;
  align-items: center;
}
.icefManager .manager_info_box .manager_details .inline_a:first-child {
  font-style: italic;
}
.event-card .card-container {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 400px !important;
  justify-content: flex-start;
}
.event-card .event-card-box h2 {
  font-size: 22px;
  margin: 15px 0 0 0;
  text-shadow: 1px 1px 5px #000;
  color: #fff;
  font-weight: bold;
}
.event-card .event-card-box h3 {
  font-size: 16px;
  margin: 5px 0 0 0;
  text-shadow: 1px 1px 5px #000;
  color: #fff;
  font-weight: bold;
}
.event-card .event-card-box p {
  font-size: 16px;
  position: absolute;
  bottom: 45px;
  left: 50%;
  right: 25%;
  width: 250px;
  margin-left: -145px;
  text-transform: uppercase;
  text-shadow: 1px 1px 5px #000;
  font-weight: bold;
}
.event-card .event-card-box .event-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-bottom: 20px;
  font-size: 16px;
}
.event-card .event-card-box .event-info .add-event-btn {
  margin-top: 1rem;
  border: 2px solid white;
  padding: 6px;
  position: absolute;
  right: 20px;
  bottom: 5px;
  cursor: pointer;
}
.task_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task_box .label-sm {
  padding: 0.5em 0.6em 0.3em;
}
.completeTask {
  text-decoration: line-through;
}
.event_list_card {
  height: 82vh;
  overflow-y: auto;
  padding: 5px;
}
.event_list_card .single_event_card {
  margin-bottom: 15px;
  padding: 20px;
  box-shadow: 0 0 10px #ddd;
  border-radius: 10px;
}
.event_list_card .single_event_card .ant-collapse {
  border: 1px solid #f4efef !important;
  margin-top: 10px;
}
.event_list_card .single_event_card .ant-collapse .ant-collapse-header {
  padding: 8px 40px;
}
.align_center {
  align-items: center;
  display: flex;
  color: #0f52ba;
}
.aic {
  align-items: center !important;
  display: flex !important;
}
.aic label {
  margin-bottom: 0;
}
.ant-select-disabled .ant-select-selection {
  background-color: #ededed !important;
}
.calendar-container.bgWhite {
  background: white !important;
}
.calendar-container.bgWhite .ant-card-body {
  padding: 0;
}
.calendar-container .table-event td {
  width: calc(100% / 7);
}
.small-select .ant-select-selection--single {
  height: 30px !important;
  border-color: #666;
  border-radius: 5px;
}
.small-select .ant-select-selection--single .ant-select-arrow {
  padding: 3px;
  margin-top: -10px;
}
.custom_row {
  display: flex;
  margin-left: -9px;
  margin-right: -9px;
}
.custom_row .custom_col {
  flex: 1 1;
  padding-left: 9px;
  padding-right: 9px;
}
.w-65 {
  width: 65% !important;
}
.login--wCard {
  width: 65% !important;
}
.commission-listing-section {
  padding: 30px 2rem !important;
}
.custom-calendar-1 {
  width: 100% !important;
  font-size: 26px;
  border: none !important;
}
.custom-calendar-1 .rc-calendar-header {
  border-bottom: none;
}
.custom-calendar-1 .rc-calendar-body {
  padding: 0;
}
.custom-calendar-1 .rc-calendar-body .rc-calendar-cell > div {
  margin-left: auto;
  margin-right: auto;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 20px !important;
}
.autoCompleted ul {
  width: 100%;
}
.autoCompleted .ant-select-search__field__wrap {
  width: 100%;
}
.help_btn {
  position: absolute !important;
  font-size: 14px;
  right: 10px;
  margin-top: 2px;
  border: 1px solid #d5c4c4;
  padding: 0px 20px;
  border-radius: 5px;
  color: #555;
  bottom: 10px;
  display: flex;
  align-items: center;
}
.help_btn i {
  margin-right: 5px;
}
.help_btn:hover {
  color: black;
}
.req_btn {
  font-size: 14px;
  right: 10px;
  border: 1px solid #d5c4c4;
  padding: 0px 5px;
  border-radius: 5px;
  color: #555;
  bottom: 40px;
  display: flex;
  align-items: center;
}
.req_btn i {
  margin-right: 5px;
}
.req_btn:hover {
  color: black;
}
.custom-container .custom_card .ant-card-head {
  height: 30px;
  background: #f1f1f1;
  font-size: 15px;
  color: #555;
}
.custom-container .custom_card .ant-card-head span {
  font-size: 14px;
  /* margin-left: 5px; */
}
.custom-container .custom_card .ant-card-body {
  padding: 14px 14px 14px 24px;
  color: black;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem !important;
  font-size: 12px !important;
}
.table-striped tr:nth-of-type(odd) {
  background-color: #dff0d8;
}
.flexWrap {
  display: flex;
  flex-wrap: wrap;
}
.commissionRow {
  display: flex;
}
.commissionRow .commBox {
  flex: 1 1;
}
.direct-uni-box .count {
  display: flex !important;
}
.direct-uni-box .canadaDirectUniCount {
  width: 20% !important;
  display: inline-block;
  padding: 0 10px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.direct-uni-box .canadaDirectUniCount:last-child {
  padding-right: 0;
}
.direct-uni-box .canadaDirectUniCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox .record-count {
  display: flex;
  justify-content: center;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox img {
  height: 30px;
  width: auto;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox img.sm {
  height: 25px;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox h5 {
  font-size: 14px;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
.direct-uni-box .canadaDirectUniCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
}
.direct-uni-box .canadaDirectUniCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.direct-uni-box .canadaDirectUniCount .innerBox .btnBox a i {
  margin-right: 5px;
}
.direct-uni-box .canadaDirectUniCount .innerBox .dirUniAgent {
  padding: 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 1px;
  background: #f1f1f1;
  margin: 10px 0;
}
.direct-uni-box .squareContainer {
  margin-top: 15px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
}
.direct-uni-box .squareContainer .squareStyle {
  width: auto;
  height: 32px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  margin-bottom: 0;
  text-align: center;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
.direct-uni-box .squareContainer .squareStyle:nth-child(6) {
  border-bottom: 2px solid #1890ff;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(7) {
  border-bottom: 2px solid #5cb85c;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(8) {
  border-bottom: 2px solid #5cb85c;
}
.commissionBlock .countryCommission {
  width: 20%;
}
@media only screen and (max-width: 1441px) {
  .canadaDirectUniCount .innerBox .uniCountBox {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
  }
  .canadaDirectUniCount .innerBox .uniCountBox img {
    max-height: 20px;
    width: auto;
  }
  .canadaDirectUniCount .innerBox .uniCountBox h5 {
    font-size: 9px !important;
    height: 25px;
    width: auto;
    min-width: 25px;
    line-height: 3;
  }
  .canadaDirectUniCount .innerBox .btnBox a {
    font-size: 11px;
  }
}
@media only screen and (max-width: 560px) {
  section.banner1 {
    height: auto !important;
  }
  section.banner1 .img {
    display: none;
  }
  .login--wCard {
    width: 80% !important;
  }
  .direct-uni-box .canadaDirectUniCount {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .commissionBlock .countryCommission {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .institute--event .table-head h5 {
    width: 65% !important;
  }
  .institute--event .event-teaser .event-meta {
    flex-direction: column;
  }
  .institute--event .event-teaser .event-title {
    padding-right: 0;
  }
  .institute--event .event-teaser .event-date-day1 {
    width: 100%;
  }
  .institute--event .event-teaser .event-venue-wrap1 {
    padding: 20px 0;
    margin-left: 0;
  }
  .institute--event .event-teaser .reg_btn {
    margin-left: 0 !important;
  }
  .institute--event .event-teaser .regTime {
    margin: 10px 0;
  }
}
.WcStyle {
  padding: 3px !important;
}
.penSpan {
  background: #fff;
  white-space: nowrap;
  text-transform: capitalize;
  padding: 3px 12px;
  border-radius: 50px;
  font-weight: 500 !important;
  font-size: 12px;
  border: 2px solid;
}
.gic-list .active {
  color: #50C37E;
  border-color: #50C37E;
}
.gic-list .pending {
  color: #bccf5b;
  border-color: #bccf5b;
}
.rc-table table td span.inactive {
  color: #ff3f5b;
  border-color: #ff3f5b;
}
.rc-table table td:first-child img {
  width: 35px;
}
.rc-table table td a {
  font-weight: 500;
  color: #3E6AB4;
}
.rc-table table td:last-child {
  white-space: nowrap;
}
.rc-table table td:last-child button {
  padding: 0;
  width: 30px;
  height: 30px;
}
.rc-table table td:last-child button img {
  width: 100%;
}
.debit2 {
  color: #ff0000;
  font-weight: 600;
}
.credit2 {
  color: #008000;
  font-weight: 600;
}
.nav-sidebar .nav-item .nav-link1 {
  font-size: 13px !important;
}
.table-head.table-head-new h6 {
  font-size: 15px !important;
  margin: 0 !important;
  width: auto !important;
  padding-right: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.travel-plan-table .ant-table-bordered .ant-table-thead > tr > th,
.travel-plan-table .ant-table-bordered .ant-table-tbody > tr > td {
  vertical-align: top;
}
.gic-manager .header-box {
  padding: 10px 20px 5px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #0f52ba;
}
.round-selector .ant-select-selection__rendered ul {
  padding: 0 10px;
}
.round-selector .ant-select-selection__rendered ul li {
  border-radius: 50px;
}
.feedback-button.selected {
  border: 2px solid #1890ff !important;
}
.tag-gic {
  color: rgba(0, 0, 0, 0.65);
  border-radius: 10px;
  width: 200px;
  display: flex;
  align-items: center;
}
.travel-carousel .slick-list {
  padding-left: 20px;
}
.travel-carousel .slick-dots {
  position: absolute;
  bottom: 0px !important;
}
.travel-carousel .slick-dots li {
  border-bottom: none;
  background: none !important;
}
.travel-carousel .slick-dots li button {
  background: #0872bc !important;
  height: 5px !important;
}
.travel-carousel .slick-arrow.slick-prev {
  font-size: 10px;
}
.travel-carousel .ant-carousel .slick-prev::before {
  content: '<';
  z-index: 1000 !important;
  display: block;
  position: relative;
  bottom: 10px;
  right: 0px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.travel-carousel .slick-arrow.slick-next {
  font-size: 10px;
}
.travel-carousel .ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 0px;
  left: 0;
  bottom: 10px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.marketingUserBox2 .managerHead2 {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
}
.marketingUserBox2 .managerHead2 .universityFlag {
  font-size: 11px;
  font-weight: 600;
}
.marketingUserBox2 .managerHead2 .universityFlag img {
  height: 20px;
  width: auto;
  border-radius: 3px;
}
.marketingUserBox2 .travel-avator {
  border: 2px solid;
  padding-right: 0;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.text-black {
  color: #212529;
}
.ant-input-group-wrapper {
  padding: 0 !important;
}
.ant-input-group-wrapper .ant-input {
  height: 40px;
}
tr.warning_tr td {
  background-color: #fcf8e3 !important;
}
.feedbackButton.selected {
  /* Selected button styles */
  background-color: #f0f0f0 !important;
  color: #333;
  border: 1px solid #ccc;
}
.successTag2 {
  background: #c2cdd5;
  border: 1px solid #0872bc;
  padding: 1px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
}
.managerHead2 {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.universityWiseBlock {
  margin-top: 15px !important;
}
.flagBox {
  height: 35px;
}
.liveReportingOuter {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 30px;
}
.liveReportingOuter .liveReportingInnerBox {
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  font-size: 15px;
  text-align: center;
}
.liveReportingOuter .liveReportingInnerBox .reportingInnerBox {
  height: 100px;
}
.sopModalRow {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
.successMessage {
  font-size: 18px;
  /*color: #1556bc;*/
  color: black;
  margin-bottom: 20px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.viewButton,
.closeButton {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.viewButton {
  background-color: #2196F3;
  color: white;
}
.viewButton:hover {
  background-color: #1976D2;
}
.closeButton {
  background-color: #f44336;
  color: white;
}
.closeButton:hover {
  background-color: #d32f2f;
}
.status-list-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
}
.status-list-grid .status-item-grid {
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
  /*    &::after {
            content: "|";
            display: inline-block;
          }*/
}
.status-list-grid .status-item-grid .status-box {
  display: inline-block;
}
.status-list-grid .status-item-grid .status-box .status-name {
  font-size: 15px;
  color: #666;
}
.status-list-grid .status-item-grid .status-box .status-count {
  color: #0f52ba;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
}
.liveReportTarget {
  margin-top: 10px;
}
.target-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 13px;
  font-weight: 500;
}
.target-box .value-box {
  display: flex;
  justify-content: center;
}
.target-box img {
  height: 20px;
  margin-right: 5px;
}
.target-box span {
  font-size: 17px;
}
.custom-autocomplete .ant-select-search__field__wrap {
  width: 100%;
}
/* Larger screens */
@media (min-width: 1400px) {
  .antd-pro-containers-icef-event-styles-canadaDirectUniCount {
    width: 32% !important;
    padding: 0 15px !important;
  }
  .antd-pro-containers-icef-event-styles-card-pane-right.antd-pro-containers-icef-event-styles-listing-section {
    padding: 30px 3rem 30px 4rem;
    display: flex;
    align-items: center;
  }
  .antd-pro-containers-icef-event-styles-download-pdf-flag {
    width: 25px !important;
    height: 21px !important;
  }
  .antd-pro-containers-icef-event-styles-download-pdf-text {
    font-weight: bold !important;
    font-size: 19px !important;
  }
  .antd-pro-containers-icef-event-styles-card-pane-right.antd-pro-containers-icef-event-styles-listing-section ul li p {
    word-spacing: normal;
  }
  .antd-pro-containers-icef-event-styles-card-pane-right.antd-pro-containers-icef-event-styles-listing-section ul li {
    width: 23.6% !important;
    display: inline-block;
  }
}
@media print {
  body {
    font-family: Arial, sans-serif;
    font-size: 12px;
  }
  .antd-pro-containers-icef-event-styles-card-body {
    padding: 8px;
  }
  .antd-pro-containers-icef-event-styles-download-pdf-flag {
    height: 20px;
    width: 25px;
    margin-right: 15px;
  }
  h5 {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-info_card {
  border-right: 1px solid #f1f1f1;
  height: 93vh;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-information {
  padding: 20px 20px 5px 30px;
  height: 150px;
  display: flex;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-information img {
  height: 100%;
  width: auto;
  margin-right: 30px;
  border-radius: 10px;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-comments {
  padding: 0 20px 10px;
  position: relative;
  height: calc(100vh - 290px);
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-banner {
  height: 250px;
  width: 100%;
  background-size: 100% auto;
  background-position: center;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-alignCenter {
  align-items: center;
  display: flex;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-input_box {
  display: flex;
  align-items: center;
  width: 96%;
  position: absolute;
  bottom: 0;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-input_box .antd-pro-containers-icef-event-styles-input_comment1 {
  border-radius: 50px;
  background-color: #efeded;
  height: 45px;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-single_info_card {
  border-right: 1px solid #f1f1f1;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-single_info_card .antd-pro-containers-icef-event-styles-information {
  margin-bottom: 5px;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-single_info_card .antd-pro-containers-icef-event-styles-information h6 {
  color: black;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-single_info_card .antd-pro-containers-icef-event-styles-banner {
  height: 250px;
  width: 100%;
  background-size: 100% auto;
  background-position: center;
}
.antd-pro-containers-icef-event-styles-participant_card .antd-pro-containers-icef-event-styles-single_info_card .antd-pro-containers-icef-event-styles-alignCenter {
  align-items: center;
  display: flex;
}
.antd-pro-containers-icef-event-styles-comment_text {
  flex: 1 1;
  background-color: white;
  border-radius: 35px;
  padding: 5px 10px 5px 15px;
  border: 1px solid #f1f1f1;
}
.antd-pro-containers-icef-event-styles-bg_theme {
  color: #0f52ba !important;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-info_card {
  border-right: 1px solid #f1f1f1;
  height: 93.8vh;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-information {
  padding: 20px 20px 5px 30px;
  height: 270px;
  display: flex;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-information .antd-pro-containers-icef-event-styles-complaintBox {
  max-height: 130px;
  overflow-x: auto;
  line-height: 1.2;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-information img {
  height: 100%;
  width: auto;
  margin-right: 30px;
  border-radius: 10px;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-information .antd-pro-containers-icef-event-styles-intoBox {
  padding: 10px;
  background-color: #f1f1f1;
  margin: 20px 0 0;
  font-size: 13px;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-comments {
  padding: 0 20px 10px;
  position: relative;
  height: calc(100vh - 400px);
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-banner {
  height: 250px;
  width: 100%;
  background-size: 100% auto;
  background-position: center;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-info_card .antd-pro-containers-icef-event-styles-alignCenter {
  align-items: center;
  display: flex;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-input_box {
  display: flex;
  align-items: center;
  width: 96%;
  position: absolute;
  bottom: 0;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-input_box .antd-pro-containers-icef-event-styles-input_comment1 {
  border-radius: 50px;
  background-color: #efeded;
  height: 45px;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-footer {
  display: flex;
  align-items: center;
  width: 96%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #f1f1f1;
  padding: 10px 0 0;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-single_info_card {
  border-right: 1px solid #f1f1f1;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-single_info_card .antd-pro-containers-icef-event-styles-information {
  margin-bottom: 5px;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-single_info_card .antd-pro-containers-icef-event-styles-information h6 {
  color: black;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-single_info_card .antd-pro-containers-icef-event-styles-banner {
  height: 250px;
  width: 100%;
  background-size: 100% auto;
  background-position: center;
}
.antd-pro-containers-icef-event-styles-complaint_card .antd-pro-containers-icef-event-styles-single_info_card .antd-pro-containers-icef-event-styles-alignCenter {
  align-items: center;
  display: flex;
}

.ant-select.ant-fullcalendar-month-select {
    width: 15%;

}

.ant-select.ant-fullcalendar-year-select {
    width: 15%;
}

.ant-fullcalendar-header .ant-radio-group {
    display: none !important;
}

.ant-fullcalendar-column-header {
    padding: 10px !important;
    background-color: white;
}

.ant-descriptions {
    border: 2px solid #000;
    padding: 20px;
    border-radius: 8px;
}

.ant-descriptions-item-label {
    font-weight: bold;
    color: #333;
}

.ant-descriptions-item-content {
    color: #666;
}

.menu-label {
    padding: 6px !important;
    font-size: 13px !important;
}

.comment-text {
    flex: 1 1;
    background-color: #f0f0f0;
    border-radius: 35px;
    padding: 6px 20px 5px 20px;
    max-width: 85%;
}

.time-span {
    color: #0f52ba;
    font-size: 8px !important;
    font-style: italic;
}
.comment-text .mb5 {
    margin-bottom: 5px;
    font-size: 12px;
}
.comment-author .name {
    text-transform: capitalize;
    font-weight: 600;
}

.avatar_text1 {
    background-color: #0f52ba;
    margin: 0 12px;
    font-size: 17px !important;
}
.comment-section .sendBtn1 {
    border-radius: 15px !important;
    background-color: #0f52ba;
    border: none;
    color: white;
    padding: 14px !important;
}

.act-container {
    padding: 20px;
    border-bottom: 1px solid #ccc;
}

.act-card {
    background-color: #efefef;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.act-card-body {
    padding: 15px;
}
.act-list {
    list-style: none;
    padding: 26px;
}


.act-list-item {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 15px;
    display: flex;
}

.act-date {
    float: left;
    width: 100px;
    text-align: center;
    font-size: 14px;
    color: #333;
    border-right: 1px solid #ccc;
    padding-right: 15px;
    font-weight: bold;
}

.act-content {
    margin-left: 15px;
    flex: 1 1;
}

.act-label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 17px;
    color: #333;
}

.act-dot {
    color: blue;
    margin-right: 5px;
}

.act-info {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
}

.act-message {
    margin-left: 0;
    padding-left: 0;
    color: #000;
}

.act-message-item {
    font-size: 15px;
    line-height: 1.5;
}

.act-subject {
    margin-bottom: 10px;
    font-size: 15px;
    color: #000;
}

 {
  /*.inputBox {
    margin-top: 10px;
    margin-bottom: 10px;

    .labelRow {
      line-height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0;

      .extra {
        a {
          margin-left: 10px;
        }
      }
    }

    .labelNew {
      //line-height: 25px;
      line-height: 34px;
      color: rgba(0, 0, 0, 0.65);
      float: left;
      margin: 0 15px 0;
    }

    .innerBox {
      //margin-top: 14px;

      &.logoCls {
        //margin-top: 10px;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }

    .uniTypeCheckbox {
      .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        label {
          width: 45%;
          margin-bottom: 5px;
        }
      }
    }
  }*/
  /*.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
    color: rgba(255, 255, 255, 0.9);

    &:hover {
      text-decoration: underline;
    }
  }*/
  /* .ant-menu-inline-collapsed > .ant-menu-item .anticon + span {
     max-width: 100%;
   }*/
  /*  .PhoneInputInput {
      border-width: 1px !important;
      height: 42px !important;
      border-color: #666;
      color: #666;
      border-radius: 6px;
    }*/
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  /* Dropdown Button */
  /* The container <div> - needed to position the dropdown content */
  /* Dropdown Content (Hidden by Default) */
  /* Links inside the dropdown */
  /* .btn {
     width: 150px;
     background: #1556bc;
     font-size: 14px;
     text-transform: uppercase;
     color: #fff;
     margin: 25px 0 0;
     border-radius: 50px;
     font-weight: 600;
     height: 42px;
   }*/
}
body {
  color: #212529 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
}
.logo-link {
  display: none;
}
.ant-layout {
  background: white;
}
.ant-card-head .ant-drawer-title,
.ant-drawer-header .ant-drawer-title {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin: 0;
}
input[type='text'],
input[type='password'],
input[type='number'],
.ant-select-selection {
  height: 33px;
}
.ant-input,
.ant-input-number,
.ant-select-selection--single {
  height: 33px;
}
.ant-input.ant-select-search__field {
  height: 43px !important;
  border: 1px solid #666 !important;
}
.ant-select {
  width: 100%;
  font-size: 12px;
}
.mt0 {
  margin-top: 0 !important;
}
.mt1 {
  margin-top: 1px !important;
}
.ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100%;
}
.mt3 {
  margin-top: 3px !important;
}
.mt5 {
  margin-top: 7px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mt18 {
  margin-top: 18px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.p10 {
  padding: 10px;
}
.p20 {
  padding: 20px;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb5 {
  margin-bottom: 5px;
}
.ml5 {
  margin-left: 5px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr0 {
  margin-right: 0 !important;
}
.ml10 {
  margin-left: 10px;
}
.ml15 {
  margin-left: 15px;
}
.ml20 {
  margin-left: 20px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 20px;
}
.mb100 {
  margin-bottom: 100px;
}
.customForm .ant-select {
  width: 100%;
}
.customForm .ant-form-item-label {
  line-height: 28.9999px;
}
.customForm .ant-form-item {
  margin-bottom: 2px;
}
.ant-layout-sider {
  background: white;
}
.ant-form-item {
  margin-bottom: 2px;
}
.antd-pro-components-sider-menu-index-logo {
  text-align: center;
  height: 60px;
  background: white !important;
  padding: 5px 5px 5px 0;
  display: flex;
}
.antd-pro-components-sider-menu-index-logo img {
  height: 100%;
}
.ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo {
  text-align: left;
  height: 60px;
  background: white;
  padding: 5px;
  display: flex;
}
.ant-layout-sider-collapsed {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}
.ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo a {
  text-align: center;
}
.ant-menu-submenu-popup {
  left: 120px !important;
}
.ant-menu-inline-collapsed {
  padding-top: 0 !important;
  /*.ant-menu-item-selected {
      background-color: #22ade2 !important;
    }*/
  /* li {
       padding: 0 10px !important;
       display: flex;
       width: 100% !important;
       justify-content: center;
       align-items: center;
       height: 60px !important;

       .ant-menu-submenu > .ant-menu-submenu-title {
         padding: 0 10px !important;
       }

       span, a {
         line-height: 15px;
         color: #fff;
         display: flex !important;
         flex-direction: column !important;
         width: 100% !important;
         justify-content: center;
         align-items: center;

         span {
           opacity: 1 !important;
         }
       }
     }*/
}
.englishTypeBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.englishTypeBox div {
  width: 80px;
}
.inputBox {
  /*margin-top: 10px;
    margin-bottom: 10px;*/
  margin-bottom: 12px;
}
.inputBox .labelRow {
  padding: 0 10px;
  display: inline-block;
  overflow: hidden;
  line-height: 30px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.inputBox .labelRow .extra a {
  color: #000000d9;
  margin-left: 10px;
  text-decoration: underline;
}
.inputBox .labelNew {
  float: left;
  color: #666;
  margin-bottom: 0;
}
.inputBox .innerBox {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
.inputBox .innerBox .ant-calendar-picker {
  width: 100%;
}
.inputBox .uniTypeCheckbox .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.inputBox .uniTypeCheckbox .ant-checkbox-group label {
  width: 45%;
  margin-bottom: 5px;
}
.ant-select-selection--multiple {
  height: auto;
}
.ant-input-number {
  width: 100%;
}
.courseBox {
  line-height: 22px;
}
.courseBox .ant-card-body {
  padding-bottom: 0;
}
.courseBox a {
  color: #0064e1;
}
.courseBox .ant-card-bordered {
  border: none;
  border-bottom: 1px solid #e8e8e8;
}
.courseBox .uniInfo {
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 8px 24px;
}
.courseBox .uniInfo img {
  height: 48px !important;
  width: 48px !important;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.courseBox .uniInfo .uniName {
  font-size: 20px;
}
.courseBox .uniInfo .uniName .uniAdd {
  font-size: 15px;
}
.courseBox .courseRow {
  line-height: 22px;
  margin-bottom: 17px;
  margin-top: 9px;
  padding: 12px 24px;
}
.courseBox .courseRow:last-child {
  border: none;
}
.courseBox .courseRow .courseName {
  font-size: 13px;
  margin-right: 20px;
}
.courseBox .courseRow .courseInfo {
  font-weight: normal;
  font-size: 15px;
  margin-top: 8px;
}
.courseBox .courseRow .courseInfo .ant-col {
  line-height: 1;
}
.courseBox .courseRow .courseInfo .ant-col span {
  font-size: 12px;
}
.courseBox .courseRow .courseInfo .ant-col small {
  font-size: 12px;
}
.courseBox .courseRow .courseInfo .ant-col button {
  color: #2f8ac9;
}
.courseBox .loadAllBtn {
  display: flex;
  justify-content: space-between;
  padding: 10px 100px;
  position: absolute;
  z-index: 999;
  top: 100px;
  width: 100%;
  background: #1890ff54;
  font-size: 17px;
  font-weight: 500;
  align-items: center;
  border-left: 10px solid #1890ff;
}
.universityBox a {
  color: #0064e1;
}
.universityBox .ant-col {
  margin-bottom: 10px;
}
.universityBox .uniInfo {
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 8px 5px;
  height: 50px;
}
.universityBox .uniInfo img {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.universityBox .uniInfo .uniName {
  font-size: 15px;
}
.universityBox .uniInfo .uniName .uniAdd {
  font-size: 13px;
}
.linkBtn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  height: 32px;
  padding: 3px 40px;
  font-size: 13px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}
.linkBtn i {
  margin-right: 5px;
}
.linkBtn.small {
  height: 28px;
  padding: 2px 10px;
}
.alignCenter {
  text-align: center;
  cursor: pointer;
}
.alignRight {
  text-align: right;
}
.alignLeft {
  text-align: left !important;
}
.loadMoreBox {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 15px;
}
.totalCourseCount {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
}
.vertical-form .header {
  font-size: 25px !important;
  color: #606a84;
  margin-top: 10px;
}
.vertical-form .ant-card {
  margin-bottom: 20px;
}
.vertical-form .ant-row {
  margin-bottom: 0;
}
.vertical-form .ant-row .ant-form-item-label {
  text-align: left;
  line-height: 30px;
}
.vertical-form .ant-calendar-picker {
  width: 100%;
}
.sticky {
  /*position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    left: 0;*/
  position: fixed;
  top: 0;
  width: calc(100% - 168px);
  z-index: 10;
  opacity: 0.9;
}
.outerLoader {
  height: 100%;
  width: 100%;
  background: #ffffffc7;
  z-index: 9999;
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1890ff;
  font-size: 25px;
}
.outerLoader .ant-spin-dot {
  font-size: 40px;
}
.outerLoader .mainLoader {
  margin-top: 20px;
  font-size: 50px;
}
.outerLoader .mainLoader i {
  margin-right: 20px;
}
.imgSm {
  height: 50px;
  width: 50px;
}
input[type='file']::-webkit-file-upload-button {
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  font-weight: normal;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  height: 100%;
  border: none;
  border-right: 1px solid #ddd;
  width: 150px;
  margin-right: 10px;
}
.ant-form input[type='file']:focus {
  outline: 0 auto -webkit-focus-ring-color !important;
  border-color: white;
}
.rowFlex {
  display: flex;
}
.rowFlex > div {
  width: 100%;
}
.rowFlex button {
  margin-left: 10px;
}
.rowFlex .d1 {
  width: 100%;
}
.rowFlex .d1.mr10 {
  margin-right: 10px;
}
.rowFlex .d2 {
  width: 100%;
  margin-left: 10px;
}
.linkBtn {
  line-height: 1.499;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  height: 40px;
  padding: 0 10px;
  font-size: 18px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #666;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 5px;
  background-color: white;
  margin-top: 1px;
}
.linkBtn.sm {
  height: 25px;
  padding: 0 10px;
  font-size: 13px;
}
ul.list {
  padding-left: 0;
}
ul.list li {
  justify-content: space-between;
  border: 1px solid #ddd;
  align-items: center;
  padding: 5px 10px;
  border-bottom: none;
}
ul.list li:last-child {
  border-bottom: 1px solid #ddd;
}
.padd-5-bb-1 {
  border-bottom: 1px solid #d3d3d34a;
  padding: 7px 5px;
  font-size: 13px;
  color: #2a2a2a;
}
.padd-5-bb-1 .ant-row .ant-col:first-child {
  color: #333 !important;
}
.ant-success {
  color: #0872bc;
}
.ant-info {
  color: #5bc0de;
}
.ant-warning {
  color: rgba(240, 173, 78, 0.87);
}
.card.unizportal button.btn[type='submit'] {
  width: 150px;
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  height: 42px !important;
}
.ant-btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.ant-btn-basic {
  color: #333;
  background-color: #f1f1f1;
  border-color: #f7ebeb;
}
.successTag {
  background: #0872bc;
  border: 1px solid #0872bc;
  padding: 1px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
}
.statusDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.statusDiv .statusBox {
  height: 30px;
  background: #fff;
  border-radius: 0;
  text-align: center;
  border: 1px solid #ddd;
  margin: 3px 0;
  color: #333;
  padding: 3px;
  font-size: 12px;
  width: 350px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.statusDiv .statusBox .icon {
  position: absolute;
  right: 10px;
  color: rgba(240, 173, 78, 0.87);
  top: 25%;
}
.bgTheme {
  background-color: #0872bc !important;
  border: 1px solid #0872bc !important;
  color: white !important;
}
.bgTheme .icon {
  color: #0872bc !important;
}
table tbody tr:nth-child(odd) {
  background: white;
}
table tbody tr:nth-child(even) {
  background: #f9f9f9;
}
.applicationProfile {
  overflow: hidden;
  height: calc(100vh - 200);
}
.applicationProfile .imgDiv {
  display: inline-flex;
}
.applicationProfile aside {
  display: none;
}
.applicationProfile .ant-layout-content {
  padding: 0;
  margin: 0;
}
.applicationProfile .ant-drawer-header {
  background-color: #fafafa;
  border-radius: 0;
  padding: 10px 20px;
}
.applicationProfile .ant-drawer-header .ant-drawer-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 15px;
}
.applicationProfile .ant-drawer-header .ant-drawer-close {
  color: rgba(0, 0, 0, 0.85);
}
.applicationProfile .input-group-box {
  width: 40%;
}
.applicationProfile .logo-link {
  display: block;
}
.applicationProfile .displayName {
  display: none;
}
.bgWhite {
  background: white !important;
}
.appProfile {
  overflow: hidden !important;
  background: #f5f8fa;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.appProfile ::-webkit-scrollbar {
  width: 5px;
}
.appProfile ::-webkit-scrollbar-thumb {
  background: #a9a9a9;
}
.appProfile .align-self-stretch {
  display: flex;
  align-self: stretch !important;
  word-break: break-word;
  overflow: visible;
  flex-grow: 1;
  width: 100%;
}
.appProfile .align-self-stretch .profile-3-column {
  overflow: scroll;
  height: calc(100vh - 1px);
  padding-bottom: 45px;
}
.appProfile .align-self-stretch .profile-3-column.profile-3-col-sidebar.profile-3-col-sidebar--left {
  border-right: 1px solid #dfe3eb;
  padding-bottom: 52px;
  flex-basis: 350px;
  max-width: 500px;
  min-width: 280px;
}
.appProfile .align-self-stretch .profile-3-column.profile-3-col-sidebar--right-wrapper {
  background-color: #fff;
  display: block;
  flex-basis: 25%;
  max-width: 500px;
  transition: flex 0.15s cubic-bezier(0.16, 0.84, 0.44, 1);
  will-change: flex;
  min-width: 280px;
}
.appProfile .align-self-stretch .profile-6-column {
  padding-bottom: 45px;
}
.appProfile .align-self-stretch .profile-6-column.noteBlock {
  flex-basis: 50%;
  height: 100vh;
  padding-left: 10px;
}
.appProfile .align-self-stretch .profile-12-column {
  padding-bottom: 45px;
}
.appProfile .align-self-stretch .profile-12-column.noteBlock {
  /* flex-basis: 100%;
           //padding-top: 10px;
           height: 100vh;
           //border-left: 1px solid #ddd;
           //border-right: 1px solid #ddd;
           padding-left: 10px;
           overflow: hidden;
           border-bottom: 3px solid #ededed;
           padding-top: 15px;*/
  flex-basis: 100%;
  height: 100vh;
  /* padding-left: 10px; */
  overflow: hidden;
  border-bottom: 3px solid #ededed;
  /* padding-top: 15px; */
  border-top: 1px solid #ededed;
}
.appProfile .align-self-stretch .profile-12-column.noteBlock.noteBlockAgent {
  height: auto !important;
  max-height: 100vh !important;
}
.appProfile .align-self-stretch .profile-12-column.noteBlock.noteBlockAgent .scrollView {
  height: auto !important;
  max-height: 100vh !important;
}
.appProfile .infoBox {
  line-height: 1.5;
  margin-bottom: 10px;
}
.appProfile .infoBox label {
  font-size: 12px;
  color: #1890ff;
}
.appProfile .bgWhite {
  background: white !important;
}
.appProfile .bgWhite .ant-card-body {
  padding: 0;
}
.appProfile .actionBlock {
  display: flex;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background: white;
  margin-top: 10px;
}
.appProfile .stuInfo {
  padding: 18px 45px;
}
.appProfile .leftBlock {
  text-align: center;
}
.appProfile .bgImg {
  height: 80px;
  width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
}
.appProfile .logo {
  height: 80px;
  width: 80px;
}
.appProfile .font15 {
  font-size: 15px;
}
.appProfile .appInfo {
  margin-top: 10px;
  font-size: 13px;
  line-height: 2.3em;
}
.appProfile .appInfo .moveBtn {
  display: flex;
  justify-content: space-between;
}
.appProfile .appInfo .moveBtn .ant-btn {
  margin-left: 10px;
}
.appProfile .ant-collapse-header {
  background: white;
}
.appProfile .ant-collapse-content {
  border: none;
}
.appProfile .noteBlock {
  height: 100vh;
  border-left: 3px solid #ededed;
  border-right: 3px solid #ededed;
  overflow: scroll;
  background: white;
  /* &::-webkit-scrollbar-track {
         background: #f1f1f1;
       }

       &::-webkit-scrollbar-thumb {
         background: @primary;
       }

       &::-webkit-scrollbar-thumb:hover {
         background: @success;
       }*/
}
.appProfile .noteBlock::-webkit-scrollbar {
  width: 3px;
}
.appProfile .noteBlock .activityBlock {
  padding: 30px 10px 10px 50px;
}
.appProfile .noteBlock .noteCardOuter {
  margin: 10px;
}
.appProfile .noteBlock .noteCardOuter .dateLabel {
  font-size: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.appProfile .noteBlock .noteCardOuter .noteCard {
  background: white;
  line-height: 2.5;
  font-size: 15px;
  box-shadow: 2px 2px 10px #ddd;
  margin-bottom: 20px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content {
  display: flex;
  padding: 5px 20px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content i {
  margin-top: 10px;
  margin-right: 20px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent {
  width: 100%;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent .title {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent .title .date {
  font-size: 12px;
  color: #9d8e8e;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm {
  padding: 0;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent {
  width: 100%;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title {
  background: #dfe6ed;
  color: black;
  padding: 0 10px;
  border: 1px solid #80808014;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  font-size: 12px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit {
  display: flex;
  padding: 5px 0;
  align-items: flex-start;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit i {
  font-size: 12px;
  margin-right: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit .subject {
  line-height: 1;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .commContent {
  padding: 10px 20px 10px 50px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .commContent strong {
  margin-right: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .date {
  display: flex;
  font-size: 10px;
  line-height: 1;
  padding: 5px 0;
  opacity: 0.7;
}
.appProfile .noteBlock .noteCardOuter .noteCard img {
  height: 30px;
  margin-right: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .commentBlock {
  border-top: 1px solid #ddd;
  padding: 5px 20px;
  color: #1890ff;
  display: flex;
  justify-items: center;
  align-items: center;
  font-weight: 600;
  margin-top: 10px;
}
.appProfile .noteBlock .noteCardOuter .noteCard .commentBlock a {
  color: #1890ff;
  margin-left: 10px;
}
.customTag .ant-tag {
  border: none;
  background: #ddffff !important;
  padding: 2px 8px;
}
.customTag .ant-tag.noneCls {
  border: none;
  background: none !important;
  font-size: 13px;
  color: #2a2a2a;
}
.documentTitle {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 15px;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background: white;
  margin-top: 10px;
}
.documentTitle span {
  display: flex;
  align-items: center;
}
.oldDoc {
  background: #ddffff !important;
}
.oldDoc span {
  text-decoration: line-through !important;
}
.documentBlock .ant-collapse-header {
  font-weight: bold;
}
.documentBlock .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.documentBlock .documentDownBlock {
  margin-top: 10px;
  background: #dddddd4a;
  padding: 5px;
}
.documentBlock .documentText {
  font-size: 13px;
  padding-right: 15px;
}
.btnGroup {
  display: flex;
  justify-content: space-between;
}
.btnGroup .eBtn img {
  height: 40px;
  margin-bottom: 5px;
}
.btnGroup .eBtn button {
  margin-bottom: 10px;
}
.dashboardCard .ant-card-body .ant-card-meta {
  border-bottom: 1px solid #e8e8e8 !important;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.dashboardCard .ant-col .ant-card {
  text-align: center;
  text-transform: uppercase;
  padding: 0px 12px 0px 0px;
  font-weight: 600;
  border: none;
}
.dashboardCard .ant-col .ant-card .title {
  height: 25px;
  font-size: 12px;
}
.dashboardCard .ant-col .ant-card .count {
  margin-top: 10px;
}
.dashboardCard .ant-col .ant-card .count p {
  font-size: 28px;
  width: 80px;
  margin: 0 auto;
  padding: 6px;
  border-radius: 20px;
  color: #1890ff;
  cursor: pointer;
}
.dashboardCard .universityCountBox {
  padding: 10px;
  height: 400px;
  overflow-y: scroll;
}
.dashboardCard .universityCountBox::-webkit-scrollbar {
  width: 3px;
}
.dashboardCard .universityCountBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.dashboardCard .universityCountBox::-webkit-scrollbar-thumb {
  background: #1890ff;
}
.dashboardCard .universityCountBox::-webkit-scrollbar-thumb:hover {
  background: #0872bc;
}
.ant-menu-submenu-title {
  font-size: 13px;
}
.ant-menu-submenu-title span {
  font-size: 13px !important;
}
.drawerFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 15px;
  background: #fff;
  text-align: left;
}
.drawerFooter.alignRight {
  text-align: right;
}
.drawerFooter .ml-auto i {
  margin-left: 5px !important;
}
.drawerFooter .ant-btn {
  margin-left: 10px;
  display: flex;
  justify-content: center;
}
.ql-container {
  height: 400px;
}
.paddingHz {
  padding: 10px 0;
}
.attachment-box {
  background-color: white;
}
.attachment-box a {
  color: rgba(0, 0, 0, 0.65);
}
.noteTitle {
  display: flex;
  justify-content: space-between;
}
.commentBox {
  margin-top: 10px;
  line-height: 1.5;
  font-size: 13px;
  background-color: white;
  padding: 10px;
}
.commentBox .avatar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
}
.commentBox .avatar i {
  margin: 0 !important;
}
.commentBox .userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1890ff;
}
.commentBox .userInfo .date {
  margin-left: 10px;
  font-size: 12px;
  color: #808080;
}
.commentBox .userInfo .date i {
  margin: 0 !important;
}
.commentBox .commentText {
  margin-top: 5px;
  font-size: 12px;
  margin-left: 15px;
}
.jcsb {
  display: flex;
  justify-content: space-between;
}
.jcc {
  display: flex;
  justify-content: center;
}
.jcic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customTimeLine {
  margin-left: 15%;
}
.customTimeLine .ant-timeline-item {
  min-height: 50px;
}
.customTimeLine .ant-timeline-item-head-custom {
  width: 180px;
}
.customTimeLine .ant-timeline-item-head-custom .date {
  position: absolute;
  top: 4px;
  text-align: right;
  color: #0872bc;
  font-weight: 500;
}
.customTimeLine .ant-timeline-item-head-custom .date span {
  font-size: 10px;
  color: #9d8e8e;
}
.ant-timeline-item-head-primary {
  color: #1890ff;
}
.customPanel {
  border: 1px solid #e8e8e8;
}
.customPanel .ant-collapse-header {
  background: white;
  font-size: 15px;
}
.pendencyList {
  border-bottom: 1px solid #dddddd54;
  padding: 7px 0;
}
.label {
  display: inline;
  padding: 5px;
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label.labelNew {
  display: block;
  cursor: pointer;
}
.label.round {
  border-radius: 50px;
  padding: 0.1em 0.6em 0.2em;
}
.label.sm {
  padding: 0.1em 0.6em 0.2em;
  font-weight: normal !important;
}
.label.xs {
  padding: 0.5px 8px;
  font-weight: normal !important;
  margin: 2px 0;
}
.label.xs1 {
  padding: 0 6px;
  font-weight: normal !important;
  margin: 2px 0;
  font-size: 11px;
}
.label-info {
  background-color: #5bc0de;
}
.label-default {
  background-color: #0f52ba;
}
.label-primary {
  background-color: #337ab7;
}
.label-success {
  background-color: #0872bc;
}
.label-success-green {
  background-color: #5cb85c;
}
.label-danger {
  background-color: #d9534f;
}
.label-warning {
  background-color: rgba(240, 173, 78, 0.87);
}
.label-black {
  background-color: black;
}
.label-tag {
  border: none;
  font-size: 12px !important;
  color: black;
  text-transform: capitalize;
}
.label-sm {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.label-xs {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}
.pointerCls-pdf {
  width: 24.6% !important;
  display: inline-block;
}
.pointerCls {
  cursor: pointer;
}
.linkPointer {
  cursor: pointer;
  color: #1890ff;
  font-size: 13px;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: left;
}
.dotsReplace {
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  text-overflow: ellipsis;
  height: 30px;
}
.ant-btn-circle {
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 35px !important;
  min-width: 35px !important;
  background: none;
  border: none;
}
.ant-btn-circle.border {
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.bs_btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  -webkit-user-select: none;
  user-select: none;
  background-color: #efefef;
  color: #333;
  padding: 0.2rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: 35px;
  min-width: 35px;
}
.bs_btn.bs-sm {
  padding: 5px 10px;
}
.bs_btn.bs-xs {
  padding: 2px 5px;
  font-size: 11px;
  height: 25px;
}
.bs_btn.bs-xxs {
  padding: 1px 5px;
  font-size: 10px;
  height: 20px;
}
.bs_btn.bs-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.bs_btn.bs-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.bs_btn.bs-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.bs_btn.bs-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.bs_btn.bs-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.bs_btn.bs-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.bs_btn.bs-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}
.bs_btn.bs-link:hover {
  text-decoration: underline !important;
}
.bs-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
  font-size: 11px;
}
.bs-link:hover {
  text-decoration: underline !important;
}
.tableAction {
  width: 125px;
  display: flex;
  flex-wrap: wrap;
}
.tableAction button,
.tableAction a {
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 35px !important;
  min-width: 35px !important;
  background: none;
  border: none;
}
.appCommentDotsReplace {
  display: block;
  width: 150px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 35px;
}
.appTextReplace {
  display: block;
  white-space: pre-wrap;
  width: 100%;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 23px;
}
.newsContentDotsReplace {
  display: block;
  width: 350px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}
.replaceAgentDot {
  display: block;
  width: 100px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  height: 17px;
  overflow-x: hidden;
}
.colorPrimary {
  color: #1890ff;
}
.colorPrimaryDark {
  color: #0f52ba;
}
.colorSuccess {
  color: #5cb85c;
}
.label1 {
  display: block !important;
  white-space: normal !important;
  font-size: 12px;
}
.linkAA {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}
.student-tab {
  border-right: none;
}
@media only screen and (max-width: 768px) {
  .applicationProfile {
    overflow: scroll;
  }
  .applicationProfile .appProfile .align-self-stretch {
    flex-direction: column;
  }
  .applicationProfile .profile-3-column.profile-3-col-sidebar.profile-3-col-sidebar--left {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .applicationProfile .profile-3-column.profile-3-col-sidebar--right-wrapper {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .applicationProfile .profile-6-column.noteBlock {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
.tuitionBox {
  font-size: 15px;
  color: black;
}
.ant-calendar-picker,
.ant-time-picker {
  width: 100%;
}
.ant-btn {
  line-height: 1.5;
  padding: 0.225rem 0.75rem !important;
  height: auto !important;
}
.ant-btn[type='submit'] {
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  width: auto;
  height: auto !important;
}
table {
  border: none;
}
table tbody th {
  font-size: 13px;
  border-top: 2px solid #dee2e6 !important;
  background: #fafafa;
  border-bottom: 1px solid #dee2e6 !important;
}
table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
table tbody tr:nth-of-type(even) {
  background: #fafafa;
}
table tbody tr td {
  padding: 8px 10px !important;
  font-size: 12px;
  font-weight: 500;
  color: #212529 !important;
}
table td.borderNone {
  border: none;
}
.ant-table-thead {
  background: #fafafa;
}
.ant-table-thead tr th {
  padding: 8px 10px !important;
  border: none !important;
  color: #212529 !important;
}
.ant-table-small {
  border: none;
}
.ant-table-content {
  border-right: none !important;
}
.antd-pro-components-global-header-index-header {
  padding: 15px 25px !important;
  height: auto;
}
.antd-pro-components-sider-menu-index-logo {
  text-align: center;
  height: 81px;
  background: white;
  padding: 0;
  border-bottom: 3px solid #ededed !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.antd-pro-components-sider-menu-index-logo img {
  width: 65%;
  height: auto;
}
.antd-pro-components-sider-menu-index-sider {
  box-shadow: none;
  border-right: 3px solid #ededed !important;
}
.ant-layout-header {
  height: auto;
}
.ant-layout-header .antd-pro-components-global-header-index-header {
  padding: 13px 25px !important;
  height: 83px;
  box-shadow: none;
  border-bottom: 3px solid #ededed !important;
}
.sidebar {
  height: auto;
  overflow-y: initial;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  overflow-x: hidden;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  height: 100%;
}
.antd-pro-components-global-header-index-right {
  display: flex;
  flex-direction: row;
}
.antd-pro-components-global-header-index-action {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.antd-pro-components-global-header-index-action .antd-pro-components-global-header-index-name {
  line-height: 1;
  margin-top: 10px;
}
.admin-box .content h5 {
  font-size: 15px;
  white-space: nowrap;
  font-weight: 600;
  color: #1a5abd;
  margin: 0;
}
form.input-group-box {
  width: 46%;
  float: left;
  margin-top: 10px;
}
.pd-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.appAction {
  float: right;
  margin-bottom: 10px;
}
.top_P10 {
  padding-top: 10px !important;
}
.sort-box-table {
  padding-right: 20px;
}
.btnBorder {
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-box-table-width {
  width: 34%;
  margin-right: auto;
}
.round-input .ant-select-selection--single {
  font-size: 14px;
  border-radius: 50px;
  padding-left: 10px;
}
.card-body table td span.badge {
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  background: #1053ba;
  border-radius: 4px;
  color: #fff;
}
.ant-form-item label {
  color: rgba(0, 0, 0, 0.65);
  float: left;
  margin: 0 15px 0;
}
.form-control {
  height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
.PhoneInputCountrySelect {
  padding: 10px !important;
}
.PhoneInput {
  border: 1px solid #666 !important;
  height: 42px !important;
  color: #666;
  border-radius: 6px;
  padding-right: 3px;
}
.PhoneInput .PhoneInputCountry {
  padding: 10px;
  border-right: 1px solid #666 !important;
}
.PhoneInput .PhoneInputInput {
  height: 40px !important;
  color: #666;
  padding-left: 10px;
  border: none;
}
.PhoneInput .PhoneInputInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1;
  /* Firefox */
}
.PhoneInput .PhoneInputInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999 !important;
}
.PhoneInput .PhoneInputInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999 !important;
}
.ant-select-selection--single {
  height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
.ant-select-selection--multiple {
  min-height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
.ant-select-selection--multiple .ant-select-selection__rendered {
  min-height: 40px !important;
}
.ant-select-selection--multiple .ant-select ul,
.ant-select-selection--multiple .ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 5px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
}
.ant-form-item-control {
  line-height: 30px;
}
.ant-select-selection__rendered {
  line-height: 40px !important;
}
.ant-select-arrow {
  -webkit-user-select: none;
          user-select: none;
  background: #666666;
  padding: 5px;
  color: white;
  font-weight: 600;
  margin-top: -12px;
  border-radius: 5px;
}
.ant-select-focused .ant-select-arrow {
  background: #1254bb !important;
}
.ant-input-number {
  height: 42px;
}
.heading-form.stuHeader span.img img {
  height: 18px;
  margin-right: 3px;
  position: relative;
  top: -1px;
}
.heading-form a.btn {
  box-shadow: 0 0 12px 0px #00000038 !important;
  border-radius: 0;
  margin: 0 40px;
  font-size: 13px;
  text-transform: capitalize;
  min-width: 120px;
}
.pa-0 {
  padding: 0 !important;
}
.antd-pro-layouts-basic-layout-content {
  padding: 20px 15px;
  margin: 0 !important;
}
.ant-select-selection-selected-value {
  margin-right: 20px;
}
.ant-calendar-picker-input.ant-input,
.ant-time-picker-input {
  height: 40px !important;
}
.ant-calendar {
  width: auto;
}
.stuIcon {
  font-size: 20px;
  color: #1890ff;
}
ul.pointerUl li {
  cursor: pointer;
}
.ant-menu-dark {
  background: white;
}
.ant-menu-dark svg {
  display: none;
}
.ant-menu-dark .ant-menu-item {
  font-size: 13px !important;
}
.ant-menu-dark .ant-menu-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: black !important;
}
.ant-menu-dark .ant-menu-item:hover div > span {
  color: black !important;
}
.ant-menu-dark .ant-menu-sub {
  background: white !important;
}
.ant-menu-dark li:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #343a40 !important;
}
.ant-menu-dark li:hover span,
.ant-menu-dark li:hover a {
  color: #343a40 !important;
}
.ant-menu-dark li a {
  color: #343a40 !important;
}
.ant-menu-dark li a :hover {
  color: #343a40 !important;
}
.ant-menu-dark li span {
  color: #343a40 !important;
}
.ant-menu-dark li span :hover {
  color: #343a40 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: none;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item {
  height: 40px;
  line-height: 40px;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #343a40 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover a:hover {
  color: #343a40 !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover span:hover {
  color: #343a40 !important;
}
li.ant-menu-item.ant-menu-item-selected {
  background: #fff !important;
  color: #343a40 !important;
  box-shadow: 0 0 12px 0px #00000038 !important;
  border-right: 5px solid #1254bb !important;
}
li.ant-menu-item.ant-menu-item-selected a {
  color: #343a40 !important;
}
li.ant-menu-item.ant-menu-item-selected a span {
  color: #343a40 !important;
}
i.ant-menu-submenu-arrow {
  color: #343a40 !important;
}
i.ant-menu-submenu-arrow::before {
  background: #343a40 !important;
}
i.ant-menu-submenu-arrow::after {
  background: #343a40 !important;
}
.nav-item {
  color: #343a40 !important;
}
.nav-item a {
  color: #343a40 !important;
}
.imgDiv {
  display: inherit;
  width: 75%;
}
.imgDiv .logo-link {
  width: 350px;
  box-shadow: 3px 0 0 #ededed;
}
.imgDiv .logoImg {
  line-height: 0.8;
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  margin-top: -3px;
  width: auto;
  box-shadow: none !important;
  opacity: 1 !important;
  border-radius: 0;
  max-height: 45px !important;
}
.student-dashboard {
  width: calc(100% - 352px);
}
.profile-main {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-bottom: 50px;
}
.profile-main::-webkit-scrollbar {
  display: none;
}
.search-profile-main {
  height: 84vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.search-profile-main::-webkit-scrollbar {
  display: none;
}
.attachment-box {
  height: 100vh;
  overflow: scroll;
  /*  &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: @primary;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: @success;
      }
  */
  padding-bottom: 50px;
}
.scrollView {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-bottom: 50px;
}
.scrollView::-webkit-scrollbar {
  display: none;
}
.mobileMenu {
  display: none;
}
.infoBox {
  width: 350px;
}
.courseBox {
  min-height: 80px;
}
.courseFeeInfo {
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 5px;
}
.selectStudentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}
.selectStudentBox .filter-box button.apply {
  font-size: 11px;
}
.selectStudentBox .selectStudentBtn {
  display: flex;
  align-items: center;
  background: #0f52ba;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 50px;
  min-width: 100px;
  font-size: 12px;
}
.selectStudentBox .selectStudentBtn i {
  margin-right: 5px;
}
.detail-list li {
  color: #212529;
}
.ant-pagination-options-size-changer .ant-select-selection--single {
  height: 33px !important;
  border: 1px solid #ddd;
}
.ant-pagination-options-size-changer .ant-select-selection--single::after {
  content: '' !important;
}
.ant-pagination-options-size-changer .ant-select-selection--single::before {
  content: '' !important;
}
.ant-btn[type='submit'] {
  width: auto;
  height: auto !important;
}
.custom-sort-box-table {
  width: 34% !important;
}
.sort-box-table-right {
  text-align: right;
}
.btn img {
  height: 17px;
}
.btn img.plus {
  height: 15px;
  margin-right: 5px;
}
.btn.round {
  margin-right: auto;
  display: flex;
  border-radius: 50px;
  padding-left: 50px;
  position: relative;
}
.btn.roundNew {
  margin-right: auto;
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 15px;
  text-decoration: none !important;
  vertical-align: middle;
}
.btn.roundNew img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
.btn .anticon {
  margin-right: 5px;
}
.btn.ac {
  display: flex;
  align-items: center;
}
.btn.roundNew2 {
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 10px;
  float: right;
  margin-right: 10px;
}
.btn.roundNew2 img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
.btn.round-bt {
  margin-right: 5px;
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 15px;
}
.btn.round-bt img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
.btn.lg {
  padding: 8px 30px !important;
}
.btn.md {
  padding: 5px 17px !important;
  font-size: 0.87rem;
}
.btn.sm {
  padding: 3px 15px !important;
  font-size: 0.8rem;
}
.btn.xs {
  padding: 2px 10px !important;
  font-size: 0.7rem;
}
.btn.xss {
  padding: 2px 5px !important;
  font-size: 0.65rem;
}
.btn.p0 {
  padding: 0 !important;
}
.search-box-table.round {
  margin-right: auto;
  display: flex;
  border-radius: 50px;
  padding-left: 50px;
  position: relative;
  background: #fff;
}
.search-box-table.round.m0 {
  margin: 0 !important;
}
.search-box-table.round img {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.search-box-table.round input {
  border: none !important;
  border-radius: 50px !important;
  height: auto;
  padding: 8px 15px;
  padding-left: 0;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.search-box-table.ml0 {
  margin-left: 0 !important;
}
td span.badge {
  padding: 5px 7px !important;
  cursor: pointer;
}
select {
  background: white;
}
.custom-sort-box-new {
  padding-right: 0 !important;
  margin-bottom: 10px;
}
.custom-sort-box-new select {
  margin-right: auto;
  display: flex;
  position: relative;
  background: #fff;
  border: none !important;
  border-radius: 50px !important;
  padding: 8px 15px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 42px;
}
.custom-sort-box-new select ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057 !important;
  opacity: 1;
  /* Firefox */
}
.custom-sort-box-new select :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495057 !important;
}
.custom-sort-box-new select ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #495057 !important;
}
.custom-sort-box-new select:visited,
.custom-sort-box-new select:active,
.custom-sort-box-new select:focus {
  border: none !important;
  outline: none !important;
}
table td button.btn,
table td a.btn {
  padding: 0 4px !important;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057 !important;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495057 !important;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #495057 !important;
}
.courseList-box {
  margin-top: 43px;
}
.unizportal .ant-col-8 .inputBox {
  margin-top: 0;
  position: relative;
}
::-webkit-scrollbar {
  width: 7px;
  height: 4px;
  border-radius: 10px !important;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px #acacac;
}
::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #9b9b9b;
  border-radius: 10px !important;
}
.chooseCountryBox {
  position: absolute;
  z-index: 9;
  top: 35px;
  background: #fff;
}
.chooseCountryBox label {
  margin: 0 !important;
  border-bottom: 1px solid #ddd;
}
.selectedCountryLabel .icon {
  position: absolute;
  right: 10px;
  top: 12px;
}
.profile-activity.application-activity ul li .date {
  width: 15%;
}
.ant-menu-dark .ant-menu-sub {
  background: #dddddd42 !important;
}
.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px !important;
}
.font14 {
  font-size: 13px;
}
.font14_1 {
  font-size: 14px;
}
.font15 {
  font-size: 15px;
}
.actionBtnGroup .viewBtn {
  height: 20px;
}
.actionBtnGroup .crossBtn {
  height: 10px;
}
.antd-pro-components-sider-menu-index-sider {
  box-shadow: none;
  border-right: 3px solid #ededed !important;
}
.ant-select-selection__rendered {
  content: '' !important;
}
.ant-select-selection__clear {
  top: 42%;
  right: 15px;
}
.ant-select-selection__clear .ant-select-clear-icon {
  height: 20px;
  width: 20px;
}
.ant-select-selection__clear .ant-select-clear-icon svg {
  height: 20px;
  width: 20px;
}
.ant-select-enabled.ant-select-allow-clear .ant-select-selection--single:hover .ant-select-arrow {
  display: none;
}
.PrivacyPolicyPage {
  font-size: 12px;
  padding: 50px;
}
.PrivacyPolicyPage h4 {
  text-align: left;
  font-size: 18px;
  text-decoration: underline;
}
.PrivacyPolicyPage p {
  text-align: left;
  line-height: 1.8;
  color: #6c4a4a;
  margin-bottom: 20px;
}
.linkUl {
  float: right;
  color: #6c4a4a;
}
.linkUl a {
  text-decoration: underline !important;
}
.ant-drawer-close {
  width: 30px;
  height: 30px;
  right: 20px !important;
  line-height: 2;
  top: 1px;
}
.ant-drawer-close:focus {
  outline: none;
}
.roundBtn {
  line-height: 1.5;
  padding: 10px 15px !important;
  border-radius: 50px !important;
  height: 42px !important;
  margin-right: 5px;
  border-color: #efe8e8;
}
.roundBtn.icon {
  height: 40px !important;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.status.status1 p {
  min-height: 22px;
  height: auto !important;
}
.ant-btn-success {
  width: 150px;
  background: #1556bc !important;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff !important;
  margin: 25px 0 0;
  border-radius: 50px !important;
  font-weight: 600;
  height: 42px !important;
}
.ant-btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.ant-btn-warning {
  color: #fff !important;
  background-color: rgba(240, 173, 78, 0.87) !important;
  border-color: #eea236 !important;
}
.ant-btn-cancel {
  background: #999;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 50px;
  min-width: 100px;
  margin-right: 15px;
  height: 42px !important;
}
.ant-table-scroll .ant-table-body::-webkit-scrollbar {
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
.btn-success-green {
  border: 1px solid #5cb85c !important;
  background-color: #5cb85c !important;
  color: white !important;
}
.moreBtn {
  text-decoration: underline;
  color: #5f5f5f;
  margin-left: 20px;
  font-size: 10px;
}
.moreBtn:hover {
  color: #5f5f5f;
}
.guestEmailBox {
  background: white !important;
  padding: 5px 5px;
  margin: 2px;
  display: flex;
  border: 1px solid #ddddff;
  border-radius: 5px;
  min-height: 40px;
}
.guestEmailBox ul {
  padding-left: 0;
  margin-bottom: 0;
}
.guestEmailBox li.emailTag {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 2px 5px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  margin-bottom: 2px;
}
.guestEmailBox .emailIcon {
  font-size: 12px;
  position: relative;
  top: -2px;
  margin-left: 5px;
}
#loginForm.login-main {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
}
@media screen and (max-width: 560px) {
  .mobileMenu {
    display: block;
    position: absolute;
    top: 0;
  }
  .antd-pro-layouts-basic-layout-content {
    margin: 0;
  }
  .infoBox {
    width: 100%;
  }
  .applicationProfile aside {
    display: block;
  }
  .imgDiv .logo-link {
    display: none;
  }
  .imgDiv .logoImg {
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: -3px;
    width: auto;
    box-shadow: none !important;
    opacity: 1 !important;
    border-radius: 0;
    max-height: 45px !important;
  }
  .student-dashboard {
    width: 100% !important;
  }
  .profile-main,
  .search-profile-main,
  .attachment-box,
  .scrollView {
    height: 100%;
  }
}
@media screen and (min-width: 769px) {
  .applicationProfile .ant-layout-header .antd-pro-components-global-header-index-header {
    padding: 0 !important;
    height: 90px;
  }
  .applicationProfile .imgDiv {
    height: 90px;
  }
  .applicationProfile .imgDiv .logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .applicationProfile form.input-group-box {
    margin-top: 0;
    display: flex;
    align-items: center;
    padding-left: 70px;
  }
}
.login-box .d-flex button.btn {
  height: 42px !important;
}
.card-pane-right.listing-section ul.count {
  justify-content: start !important;
}
.card-pane-right.card-pane-new .pointerCls .linkText {
  font-size: 18px;
}
.card-pane-right.card-pane-new .pointerCls .statusLabel {
  color: black;
  font-size: 15px;
}
.mark-btn.mark-btn30 {
  width: 35% !important;
}
.mark-btn.mark-btn30 div {
  display: flex;
}
.mark-btn.mark-btn30 div a {
  margin: 0 5px;
}
.mark-btn.mark-btn40 {
  width: 50% !important;
  position: absolute;
  right: 0;
}
.mark-btn.mark-btn40 div {
  display: flex;
}
.mark-btn.mark-btn40 div a {
  margin: 0 5px;
}
.mark-btn.mark-btn70 {
  width: 70% !important;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
.mark-btn.mark-btn70 div {
  display: flex;
}
.mark-btn.mark-btn70 div a {
  margin: 0 5px;
}
.selectedUni {
  margin-right: 10px;
  background: #666666;
  padding: 2px 10px;
  border-radius: 5px;
  color: white !important;
  font-size: 12px;
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 450px !important;
  overflow-x: hidden;
  border: 0;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
}
.uniBox .col-lg-4 {
  margin-bottom: 20px;
}
.uniBox .col-lg-4 .logo img {
  height: 67px;
}
.featureCourseDiv .heading-form {
  position: fixed !important;
  top: 0;
  z-index: 9;
  background: white;
  width: 100%;
  padding: 10px 0;
}
.featureCourseDiv .cardRelative {
  position: relative;
  top: 70px;
  background: white !important;
  padding-bottom: 0 !important;
}
.featureCourseDiv .courseBox .col-lg-4 {
  background: #f1f1f1 !important;
  padding: 20px;
}
.featureCourseDiv .courseBox .newRow {
  margin-bottom: 20px;
}
.featureCourseDiv .courseBox .infoRow {
  padding-left: 20px;
}
.featureCourseDiv .courseBox .infoRow p {
  margin-bottom: 0;
}
.featureCourseDiv .logo {
  padding: 10px;
}
.featureCourseDiv .logo img {
  height: 60px;
}
.featureCourseDiv .nameBox {
  height: 70px;
  font-weight: bold;
  color: #0f52ba;
}
.featureCourseDiv .infoBox {
  /* width: 500px;
       display: flex;
       justify-content: space-between;
       align-items: center;*/
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 44%;
}
.featureCourseDiv .infoBox .totalInfo {
  display: flex;
  padding-left: 27px;
}
.featureCourseDiv .infoBox .totalInfo .totalCourseCount {
  margin-right: 50px;
  top: 4px;
}
.featureCourseDiv .infoBox .featureCourseHeader {
  padding: 0 25px;
}
.featureCourseDiv .infoBox .featureCourseHeader h5 {
  padding: 0;
}
.featureUniversityDiv .heading-form {
  padding: 20px 0 0 !important;
}
.featureUniversityDiv .ml-auto {
  display: flex;
  justify-content: center;
  align-items: center;
}
.featureUniversityDiv .ml-auto i {
  margin-left: 5px !important;
}
.featureUniversityDiv .cardRelative {
  position: relative;
  background: white !important;
}
.featureUniversityDiv .cardRelative.pb-0 {
  padding-bottom: 0 !important;
}
.featureUniversityDiv .courseBox .col-lg-4 {
  background: #f1f1f1 !important;
  padding: 20px;
}
.featureUniversityDiv .courseBox .newRow {
  margin-bottom: 20px;
}
.featureUniversityDiv .courseBox .infoRow {
  padding-left: 20px;
}
.featureUniversityDiv .courseBox .infoRow p {
  margin-bottom: 0;
}
.featureUniversityDiv .logo img {
  height: 80px;
}
.featureUniversityDiv .nameBox {
  height: 80px;
  font-weight: bold;
  color: #1890ff;
}
.featureUniversityDiv .infoBox {
  align-self: flex-end !important;
  margin-bottom: 10px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}
.loadMoreDiv {
  text-align: center;
  text-transform: uppercase;
}
.loadMoreDiv a {
  background: #f1f1f1;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
}
.loadMoreDiv a i {
  position: relative;
  bottom: 2px;
  margin-left: 5px !important;
}
.social.social1 {
  padding: 0 !important;
}
.social.social1 h6 {
  color: white;
}
.navbar-dark {
  background-color: transparent;
  border-color: #4b545c;
}
.searchAppDiv .loadMoreBox {
  text-align: right;
  text-transform: uppercase;
}
.searchNewBtn {
  background: #0f52ba !important;
  border-radius: 50px !important;
  color: #fff !important;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex !important;
}
.searchNewBtn:hover {
  opacity: 0.7;
  color: white;
}
.clearNewBtn {
  background: #efefef !important;
  border-radius: 50px !important;
  color: black !important;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex !important;
}
.clearNewBtn:hover {
  opacity: 0.7;
  color: white;
}
.btn.apply:hover {
  opacity: 0.7;
}
.invalidValueField .form-control {
  border: 2px solid #ff0000;
}
.requirementNote {
  font-size: 12px;
  color: black;
  padding-left: 10px;
}
.frontFooter .social {
  padding: 0 !important;
}
.frontFooter .social h6 {
  color: white !important;
}
.frontFooter .white {
  color: white !important;
}
.all-student-search h5 {
  width: 20% !important;
}
.all-student-search h5.allAppCountry {
  width: 70% !important;
}
.all-student-search .search-box-table {
  width: 30%;
}
.all-student-search .search-box-table.round {
  margin-left: 10px !important;
  position: relative;
}
.all-student-search .search-box-table.round a.searchBtn1 {
  position: absolute;
  right: 0;
  top: 1px;
  background: #0872bc;
  color: white;
  padding: 7px 5px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 13px;
}
.all-student-search .search-box-table.ml0 {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
.all-student-search .form-control {
  height: 35px !important;
}
.search-box-table.round {
  margin-left: 10px !important;
  position: relative;
}
.search-box-table.round a.searchBtn1 {
  position: absolute;
  right: 0;
  top: 0px;
  background: #0872bc;
  color: white;
  padding: 11.5px 8px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 13px;
}
.search-box-table.ml0 {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
header.navbar-fix nav.newNav a:first-child {
  color: black !important;
  background: none !important;
  border: none;
  margin-right: 15px;
  box-shadow: none;
  text-decoration: underline;
  font-size: 15px !important;
}
header.navbar-fix nav.newNav a:last-child {
  color: #fff !important;
  background: #0f52ba;
  border: none;
  margin-right: 15px;
  box-shadow: 2px 2px 4px #00000057;
}
@media screen and (max-width: 560px) {
  .table-head .search-box-table {
    display: none !important;
  }
  .all-student-search h5 {
    width: 60%;
  }
  .containerStu {
    margin-top: 20% !important;
  }
}
.recharts-legend-wrapper {
  position: relative !important;
  bottom: 35px !important;
}
.amt {
  font-style: normal;
  color: black;
  font-size: 13px;
  font-weight: 600;
}
.ant-scroll-number {
  position: absolute;
  top: 11px;
  right: 6px;
}
.rowWrap {
  display: flex;
  flex-wrap: wrap;
}
.custom-tooltip {
  background: #ffffff6e;
  padding: 10px 10px 2px 10px;
  line-height: 1.3;
  font-size: 13px;
}
.custom-tooltip .label {
  color: black;
  font-size: 13px;
  padding: 0;
}
.recharts-legend-wrapper {
  margin-top: 20px;
}
.barChart .recharts-legend-wrapper {
  display: none;
}
.barLabel_ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.barLabel_ul.btn_option {
  display: flex;
  justify-content: space-between;
}
.barLabel_ul li {
  display: inline-flex;
  margin-right: 10px;
  align-items: center;
  font-size: 14px;
}
.barLabel_ul li .colorBox {
  height: 12px;
  width: 12px;
  margin-right: 4px;
}
.barLabel_ul li.lg .colorBox {
  height: 25px;
  width: 40px;
  margin-right: 4px;
}
.newsRowBox {
  display: flex;
  cursor: pointer;
  margin-right: 20px !important;
}
.newsRowBox i {
  margin-right: 5px;
  font-weight: bold;
  margin-top: 2px;
  font-size: 15px;
  color: #1890ff;
}
.sideMenuSoical li {
  margin-right: 10px !important;
}
.sideMenuSoical img {
  height: 32px;
}
.tableBox {
  margin-bottom: 20px;
}
.tableBox.striped .tableRow:nth-child(odd) {
  background-color: #f9f9f9;
}
.tableBox .tableRow {
  padding: 4px 10px;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.tableBox .tableRow .title {
  font-weight: bold;
  width: 35%;
  height: 18px;
}
.tableBox .tableRow .title1 {
  font-weight: bold;
  width: 40%;
}
.tableBox .tableRow p {
  margin-bottom: 0;
}
.tableBox .tableRow:first-child {
  border-top: 1px solid #ddd;
}
.circleBtn {
  background: #0872bc;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.justifyContent {
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
}
.meetingInfo {
  line-height: 1.8;
}
.login-form label {
  display: none !important;
}
.login-form .form-control {
  border: none !important;
}
.login-form input[type='text'].form-control {
  background: #e6e6e6 !important;
}
.login-form input[type='file'].form-control {
  background: #e6e6e6 !important;
  padding: 0;
}
.login-form .documents .inputBox {
  margin-bottom: 0;
}
.login-form .documents .inputBox .labelNew {
  height: 20px;
}
.login-form .documents .inputBox input[type='file'].form-control {
  height: 35px !important;
}
.login-form input.ant-calendar-picker-input.ant-input {
  height: 55px !important;
}
.login-form .form-control {
  height: 55px !important;
}
.login-form .ant-select-selection__placeholder,
.login-form .ant-select-search__field__placeholder {
  color: #666;
  font-size: 14px;
}
.login-form .ant-select-selection {
  background: #e6e6e6;
  border: none;
  width: 100%;
  height: 55px !important;
  padding: 0 23px;
  outline: none;
  border-radius: 6px;
  padding-right: 75px;
  font-size: 14px;
}
.login-form .btnSub {
  background: #0f52ba;
  color: #fff;
  height: 42px !important;
  min-width: 120px;
  border-radius: 50px;
}
.btn-secondary.dropdown-toggle {
  background: #0f52ba !important;
  border-radius: 50px;
  width: 150px;
  margin-right: 10px;
}
header.navbar-fix nav a:first-child.regLink {
  color: #666 !important;
}
header.navbar-fix nav a.regLink {
  color: #666 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-align: left;
  justify-content: flex-start;
}
.dropbtn {
  background: #0f52ba !important;
  border-radius: 50px;
  width: 150px;
  margin-right: 10px;
  color: #fff;
  height: 37px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem !important;
}
.dropbtn i {
  margin-left: 5px;
}
.dropbtn.lite {
  background: transparent !important;
  border: 1px solid #c1c1c1;
  color: black;
}
.dropbtn:focus {
  outline: none !important;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 10px;
  border-radius: 10px;
}
.dropdown-content a.regLink {
  margin-right: 0 !important;
  border-radius: 0;
}
.dropdown-content a.regLink:hover {
  background: #e5dddd !important;
}
.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.studentRegForm {
  padding: 20px 20px;
  margin-top: 50px;
  border-radius: 5px;
  background: #fffdfd;
}
.studentRegForm label {
  display: block !important;
  padding: 0 !important;
  margin: 0;
}
.studentRegForm .ant-form-item-label {
  line-height: 28.9999px;
}
.studentRegForm .labelNew {
  display: block !important;
  color: #666;
  margin: 0 0 10px 0 !important;
}
.studentRegForm input[type='text'],
.studentRegForm input[type='text'].form-control,
.studentRegForm input[type='password'],
.studentRegForm input[type='email'],
.studentRegForm input[type='number'],
.studentRegForm input[type='textarea'],
.studentRegForm .ant-select-selection {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.studentRegForm input[type='text'].ant-select-focused,
.studentRegForm input[type='text'].form-control.ant-select-focused,
.studentRegForm input[type='password'].ant-select-focused,
.studentRegForm input[type='email'].ant-select-focused,
.studentRegForm input[type='number'].ant-select-focused,
.studentRegForm input[type='textarea'].ant-select-focused,
.studentRegForm .ant-select-selection.ant-select-focused {
  background: #ffffff !important;
}
.studentRegForm .ant-select-search__field {
  background: transparent !important;
  padding: 0 !important;
}
.studentRegForm .ant-calendar-picker {
  padding: 0 !important;
}
.studentRegForm input.ant-calendar-picker-input.ant-input {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.studentRegForm input[type='file'] {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.studentRegForm input[type='file']::-webkit-file-upload-button {
  width: 120px;
}
.studentRegForm input[type='file'].form-control {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
.containerStu {
  max-width: 100%;
  padding: 0 calc((100% - 1140px) / 2);
  overflow-y: scroll;
  margin-top: 5%;
  z-index: 99999;
  overflow-x: hidden;
}
.containerStu::-webkit-scrollbar {
  display: none;
}
.containerStu .inner-container {
  height: 80vh;
}
.login-form label.authCheckbox {
  display: block !important;
}
.hyperLink {
  color: #00e !important;
}
.hyperLinkUnderLine {
  color: #00e !important;
  text-decoration: underline;
}
.disbaledField {
  display: flex;
  width: 100%;
  padding: 0 11px;
  text-align: left;
  border: 1px solid #666;
  border-radius: 5px;
  outline: 0;
  background: #ededed;
}
.sliderBox {
  margin: 0 15px;
}
.sliderBox .sliderImg {
  height: 65px;
  width: 100%;
}
.dotNone {
  background: none !important;
}
.owl-theme .owl-dots {
  margin-top: 5px;
}
.owl-theme .owl-dots .owl-dot span {
  background-color: #666 !important;
  height: 7px !important;
  width: 7px !important;
}
.owl-theme .owl-dots .owl-dot.active span {
  background-color: #0872bc !important;
}
.owl-theme .owl-nav {
  display: none !important;
}
.owl-theme.owl-new .owl-nav {
  display: block !important;
}
.owl-theme.owl-new .owl-nav .owl-prev {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #f1f1f1;
  line-height: 0;
  font-weight: 500;
  left: -14px;
}
.owl-theme.owl-new .owl-nav .owl-next {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #f1f1f1;
  line-height: 0;
  font-weight: 500;
  left: 97%;
}
.main-footer {
  border-bottom: none !important;
}
.ant-time-picker,
.ant-calendar-picker {
  padding: 0 !important;
  border-color: #666 !important;
  box-shadow: none !important;
}
.form-control {
  border-color: #666 !important;
}
.ant-slider-mark {
  width: 82%;
  left: 9%;
}
.custom-slider .ant-slider-mark {
  width: 102%;
  left: 0%;
}
.statusRow .statusColumn {
  margin-bottom: 10px;
  text-align: center;
}
.statusRow .statusColumn .ant-card-body {
  text-align: center;
  height: 120px;
}
.statusRow .box {
  text-align: center;
}
.statusRow .box.active {
  color: white;
  background-color: #0872bc;
}
.statusRow .box img {
  height: 20px;
  margin-bottom: 5px;
}
.statusRow .status {
  font-size: 12px;
}
.statusRow .title {
  font-size: 18px;
  font-weight: bold;
}
.statusRow .iconDiv {
  font-size: 28px;
  padding: 1px;
}
.newsImg {
  margin-top: 20px;
}
.newsImg img {
  width: 80%;
  margin-left: 10%;
}
.roundGoBackBtn {
  line-height: 1.5;
  padding: 10px 15px !important;
  border-radius: 50px !important;
  border-color: #efe8e8;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px !important;
  background-color: white;
}
.roundGoBackBtn.icon {
  width: 36px;
}
.thinLabel {
  font-weight: normal !important;
}
.flairUniversityBlockOuter .flairUniversityBlock .innerBox {
  padding: 15px 40px;
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
  min-height: 250px !important;
}
.flairUniversityBlockOuter .flairUniversityBlock .imgBlock {
  background-color: white;
  padding: 10px 20px;
  border-radius: 3px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flairUniversityBlockOuter .flairUniversityBlock .imgBlock img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.flairUniversityBlockOuter .flairUniversityBlock .applyBtn {
  background-color: white;
  padding: 6px 15px;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  border: none;
  border-radius: 3px;
}
.flairUniversityBlockOuter .flairUniversityBlock .applyBtn.dark {
  background-color: #0872bc;
  color: white;
}
.flairUniversityBlockOuter .slick-dots {
  position: absolute;
  bottom: 0px !important;
}
.flairUniversityBlockOuter .slick-dots li {
  border-bottom: none;
  background: none !important;
}
.flairUniversityBlockOuter .slick-dots li button {
  background: #0872bc !important;
  height: 5px !important;
}
.flairUniversityBlockOuter .slick-arrow.slick-prev {
  font-size: 10px;
}
.flairUniversityBlockOuter .ant-carousel .slick-prev::before {
  content: '<';
  z-index: 1000 !important;
  display: block;
  position: relative;
  bottom: 10px;
  right: -38px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.flairUniversityBlockOuter .slick-arrow.slick-next {
  font-size: 10px;
}
.flairUniversityBlockOuter .ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 0px;
  left: -39px;
  bottom: 10px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.countList {
  list-style: decimal;
  padding-left: 12px;
}
.labelDoc {
  margin-top: -2px;
  margin-bottom: 0;
}
.addStuInfo {
  width: 100%;
}
.addStuInfo .ant-row.row1 {
  width: 100%;
  display: flex;
  align-items: center;
}
.ant-drawer-body {
  overflow: hidden;
}
.ant-input-number-disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}
.label_sm {
  padding-left: 10px;
  font-size: 13px;
  font-weight: normal !important;
  margin-bottom: 0 !important;
}
.antSelect.ant-select .ant-select-selection-selected-value {
  margin-right: 10px !important;
}
.antSelect.ant-select .ant-select-selection {
  border: none !important;
  border-radius: 50px !important;
  font-size: 13px !important;
}
.antSelect.ant-select .ant-select-selection .ant-select-selection__placeholder {
  display: block;
  color: #495057 !important;
}
.antSelect.ant-select .ant-select-selection .ant-select-arrow {
  background: none !important;
  color: #495057 !important;
}
.smSelect .ant-select-selection {
  height: 25px !important;
  border-radius: 0;
}
.smSelect .ant-select-selection .ant-select-selection__rendered {
  line-height: 1 !important;
  margin-left: 5px !important;
}
.smSelect .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  margin-right: 5px !important;
}
.smSelect .ant-select-selection .ant-select-arrow {
  background: none !important;
  color: #495057 !important;
  border: none;
  position: absolute;
  right: 0;
  top: 8px;
}
.fcmtDiv {
  text-align: center;
}
.fcmtDiv img {
  height: 40px;
}
.fcmtDiv img.sm {
  height: 32px;
}
.infoUni {
  background-color: white;
  font-size: 15px;
  border-radius: 5px;
  line-height: 2;
  color: black;
}
.infoUni .title {
  width: 18% !important;
  font-size: 13px;
  font-weight: normal !important;
  border-right: 1px solid #ddd;
  margin-right: 10px;
}
.infoUni span {
  color: #0872bc;
  font-size: 13px;
}
.darkBtn {
  background: transparent !important;
  border: 1px solid #c1c1c1 !important;
  display: inline-block;
  border-radius: 50px;
  min-width: 110px;
  color: #000 !important;
  height: 38px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
}
.darkBtn span {
  color: #343a40 !important;
}
.sprBox {
  margin-top: 7px;
  padding-top: 7px;
}
.customAutoComplete ul {
  width: 100%;
}
.customAutoComplete .ant-select-search__field {
  height: 43px !important;
  border-color: #666 !important;
}
.pointerCls {
  height: 40px;
  padding-top: 3px;
}
.pCursor {
  cursor: pointer;
}
.CountCircle {
  font-size: 14px !important;
  height: 25px;
  width: 25px;
  border-radius: 5%;
  line-height: 2.5;
  background: white !important;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 2px #1890ff !important;
}
.CountCircle a {
  align-self: center;
  font-size: 12px !important;
  color: black !important;
}
.pointerCls1 {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding-top: 0 !important;
}
.pointerCls1 h5 {
  font-size: 14px !important;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
}
input[type='text']::placeholder,
input[type='password']::placeholder,
input[type='search']::placeholder,
input[type='number']::placeholder,
input[type='email']::placeholder,
input[type='file']::placeholder,
input[type='date']::placeholder,
textarea::placeholder,
.ant-select-selection::placeholder,
.ant-calendar-picker-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  font-size: 14px !important;
  opacity: 0.8 !important;
  /* Firefox */
}
.ant-select-selection__placeholder {
  color: #999 !important;
  font-size: 13px !important;
  opacity: 0.8 !important;
  /* Firefox */
}
.ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  /* overflow: hidden; */
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid #edededbf;
}
.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
  opacity: 0.5;
}
.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled:hover {
  opacity: 0.5 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
.mailerOption input[type='radio'] {
  position: relative;
  top: 2px;
  margin-left: 10px;
}
.mailerOption label {
  font-weight: normal !important;
  margin-left: 5px !important;
  margin-bottom: 0 !important;
  margin-right: 5px;
}
.mailerOption i {
  font-style: inherit;
  margin-left: 3px;
}
.countLink {
  color: #1254bb;
}
.ck-editor__editable {
  min-height: 400px;
}
.quillEditor {
  background-color: white;
}
.quillEditor .ql-editor {
  line-height: 2;
  font-size: 15px;
}
.customExtra {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #00e;
  z-index: 9;
}
.defaultEligBtn {
  display: flex;
  border: 1px solid #ddd !important;
  justify-content: center;
  align-items: center;
  padding: 1px 10px !important;
  background: white !important;
  margin-top: 8px;
  font-size: 13px !important;
  border-radius: 50px !important;
}
.defaultEligBtn i {
  margin-right: 5px !important;
}
.eligiBtn {
  border: 1px solid #ddd !important;
  background: white !important;
  height: 27px;
  margin-top: 8px;
  font-size: 11px !important;
  border-radius: 50px !important;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px !important;
}
.eligiBtn i {
  margin-right: 5px !important;
}
.flexEndCustom {
  margin-top: 10px;
  justify-content: flex-end;
}
.wrapBox {
  display: flex;
  flex-wrap: wrap;
}
.wrapBox .widthLabel {
  font-weight: bold;
  text-decoration: underline;
  color: black;
}
.wrapBox .widthLabel.w100 {
  width: 100%;
}
.wrapBox .widthLabel.w200 {
  width: 200%;
}
.wrapBox label {
  font-weight: 600 !important;
}
.status p {
  min-height: 22px !important;
  height: auto !important;
}
.status p {
  min-height: 22px !important;
  height: auto !important;
}
.courseNameDotsReplace {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  height: 40px;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
.smallBtn {
  padding: 10px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lgBtn {
  padding: 15px 25px !important;
  height: auto !important;
}
.image-upload > input {
  display: none;
}
.image-upload label {
  margin-right: 10px;
}
.image-upload img {
  height: 30px;
  cursor: pointer;
}
.pendencyTab td {
  padding: 5px !important;
}
.documentBox {
  padding: 0 5px;
  /* margin: 2px; */
  border-radius: 5px;
  min-height: auto !important;
}
.documentBox ul {
  padding-left: 0;
  margin-bottom: 0;
}
.documentBox .emailTag {
  position: relative;
  float: left;
  margin-right: 4px;
  padding: 2px 5px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border-radius: 2px;
  white-space: nowrap;
  width: 180px;
  text-overflow: ellipsis;
}
.documentBox .emailIcon {
  font-size: 15px;
  position: relative;
  top: 3px;
  margin-left: 5px;
}
.cardSm {
  padding: 10px;
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.cardSm .pb0 {
  padding-bottom: 0;
}
.cardSm .smImg {
  height: 10px;
  margin-left: 5px;
}
.cardSm .smFont {
  margin-top: 5px;
  font-size: 11px;
}
.cardSm .smFont .dLab {
  color: #0872bc;
  font-weight: 600 !important;
}
.cardSm .dLab {
  font-size: 11px;
  margin-block: 0;
  font-weight: 600 !important;
}
.customTextArea {
  height: 100px !important;
}
.ratioBox label {
  margin-right: 20px;
}
.ratioBox input {
  position: relative;
  top: 2px;
  left: 3px;
}
.feedbackLabel {
  background: #0872bc;
  padding: 10px 50px;
  color: white;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.banner11 {
  padding-bottom: 40px !important;
}
.feedbackInfo {
  font-size: 25px;
  text-align: center;
  padding: 50px 0;
}
.antd-pro-components-header-dropdown-index-container {
  z-index: 99 !important;
}
.float-right {
  float: right !important;
}
.ant-avatar-sm {
  width: 40px;
  height: 40px;
  line-height: 24px;
  border-radius: 50%;
}
.avatarBig .ant-avatar-sm {
  width: 50px;
  height: 50px;
  line-height: 24px;
  border-radius: 50%;
}
.card-body.table-responsive {
  padding-top: 0px !important;
}
.ant-table-pagination.ant-pagination {
  margin-top: 0px !important;
  margin-bottom: 7px !important;
}
.slimBtn .fee-box .box .btn-group button {
  display: flex;
  width: 100%;
  border: 2px solid #ededed;
  background: #e6e7e8;
  font-size: 13px;
  text-transform: capitalize;
  margin: 0 0 10px;
  height: 30px;
  align-items: center;
  padding: 0 10px;
}
section.gic-revenue {
  border-radius: 15px;
}
section.gic-revenue a.main-btn {
  background: #11148a;
  font-size: 15px;
  color: #fff;
  border-radius: 12px;
  padding: 12px 25px;
  text-transform: capitalize;
  height: auto;
}
section.gic-revenue .list .box {
  box-shadow: inset 0 0 0 2px #e6e6e6;
  border-radius: 18px;
  padding: 10px 20px;
  margin: 0 0 15px;
  display: flex;
  padding-left: 80px;
  position: relative;
  flex-direction: column;
  text-align: left;
}
section.gic-revenue .list .box span {
  min-width: 50px;
  height: 50px;
  display: inline-flex;
  background: #e6e6e6;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}
section.gic-revenue .list .box h4 {
  font-size: 23px;
  font-weight: 600;
  color: #4d4d4d;
  line-height: normal;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  margin: 0;
}
section.gic-revenue .list .box p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  color: #666666;
}
section.gic-revenue .list .box span img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
section.gic-revenue .list button.btn {
  background: #5bb85d;
  border-radius: 18px;
  width: 100%;
  color: #fff;
}
section.gic-revenue .list button.btn span {
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.flexRow {
  display: flex;
  flex-wrap: wrap;
}
.flexRow span {
  padding: 3px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.flexRow .cross {
  margin-left: 10px;
  position: relative;
  top: -1px;
}
.actBy {
  font-weight: normal;
  color: #745e5e;
  margin-left: 27px;
  padding: 0;
  height: 15px;
  margin-bottom: 5px;
  position: relative;
  top: -5px;
}
table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
table tbody tr:nth-child(odd) {
  background: white;
}
.input-group-inner.field1 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right: 1px solid #00000017;
}
.input-group-inner.field1 .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group-inner.field2 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding-left: 20px !important;
}
.input-group-inner.field3 {
  border-radius: 50px !important;
  border-right: none;
}
.input-group-inner.field3 .form-control {
  border-radius: 50px !important;
}
.icefBlock {
  display: flex;
  flex-direction: row;
  background: #ffffff7a;
  font-weight: 700;
  line-height: 36px;
  align-items: center;
  border-left: 3px solid #1890ff;
  padding: 10px 30px 10px 10px;
  box-shadow: 2px 5px 10px #e9c4c4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  width: 80%;
}
.icefBlock h1 {
  font-size: 20px !important;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 !important;
}
.icefBlock img {
  max-width: 60px;
}
.outMotto {
  font-size: 16px !important;
}
.card.unizportal.regStu {
  padding: 20px;
}
.student-application-detail.student-application-new .inputBox {
  margin-bottom: 0 !important;
}
.student-application-detail.student-application-new .card .form-group label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 !important;
}
.student-application-detail.student-application-new.addCourseForm .card .form-group label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin: -10px 6px -5px !important;
}
.student-application-detail.student-application-new h5 {
  font-size: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.student-application-detail.student-application-new .detail-list ul {
  padding-left: 0;
}
.student-application-detail.student-application-new .detail-list ul li {
  padding: 5px 0 !important;
}
.student-application-detail.student-application-new .detail-list ul li strong {
  width: 30%;
}
.student-application-detail.student-application-new .manageBox {
  background-color: white;
}
.student-application-detail.student-application-new .manageBox .managerHeader {
  background-color: gray;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  position: absolute;
  right: 10px;
  border-bottom-left-radius: 10px;
}
.student-application-detail.student-application-new .manageBox .managerBoxOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.student-application-detail.student-application-new .manageBox .managerBoxOuter .manageBoxInfo {
  text-align: left;
  font-size: 12px;
  color: #585858;
  padding: 20px 0 20px 20px;
}
.student-application-detail.student-application-new .add-application-btn {
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 0px 0 0 !important;
  border-radius: 50px;
  font-weight: 400;
  width: auto;
  height: auto !important;
}
.fee-box .box .btn-group button.profileBtn {
  border: 2px solid #ededed;
  background: #e6e7e8 !important;
  border-radius: 5px;
  text-align: center;
  height: 30px;
  align-items: center;
  justify-content: center;
}
.login-box .form-group .countryCode img {
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin-right: 5px;
}
.referBtn {
  background-color: #0f52ba;
  color: white;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  padding: 0 20px 20px 10px;
}
.referBtn .ant-badge {
  position: relative;
  top: -17px;
}
.referBtn .ant-badge img {
  margin-right: 10px !important;
  height: 20px;
}
.referBtn a:hover {
  color: white !important;
}
.referCodeBox {
  border-color: #0f52ba;
  border-style: dashed;
  padding-top: 5px;
  padding-bottom: 5px;
}
.referHeader {
  background-color: #0f52ba;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-top: 10px;
}
.marketingHeader {
  background-color: white;
  border: 1px solid #ddd;
  padding: 15px 20px;
  margin-top: 10px;
  color: black;
  font-size: 18px;
}
.marketingHeader .fa {
  margin-right: 5px;
}
.referInfoBox {
  margin-top: 30px;
  text-align: center;
}
.conditionList {
  flex: 1 1;
  padding-left: 15px;
  padding-right: 30px;
}
.conditionListText {
  font-size: 12px;
}
.reportingBox {
  padding: 15px;
  background-color: white;
  margin: 5px;
  text-align: center;
}
.reportContainer .reportBox {
  margin: 20px 0;
}
.reportContainer .reportingBox {
  padding: 0 !important;
  box-shadow: 1px 1px 10px #b3b3b36b !important;
  background-color: #1890ff;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
.reportContainer .reportingBox .arrowIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
.reportContainer .reportingBox .reportingInnerBox {
  border-radius: 5px;
  border-bottom-right-radius: 80px !important;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
}
.reportContainer .reportingBox img {
  margin-bottom: 10px;
  height: 30px;
}
.reportContainer .reportingBox .anticon {
  margin-left: 10px;
}
.reportContainer .reportingBox .reportInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.reportContainer .reportingBoxLiveReporting {
  padding: 0 !important;
  box-shadow: 1px 1px 10px #b3b3b36b !important;
  background-color: white;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
.reportContainer .reportingBoxLiveReporting .arrowIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
.reportContainer .reportingBoxLiveReporting .reportingInnerBox {
  border-radius: 5px;
  border-bottom-right-radius: 80px !important;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
}
.reportContainer .reportingBoxLiveReporting img {
  margin-bottom: 10px;
  height: 30px;
}
.reportContainer .reportingBoxLiveReporting .anticon {
  margin-left: 10px;
}
.reportContainer .reportingBoxLiveReporting .reportInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
.linkText {
  color: #0066ff;
  cursor: pointer;
}
.customGrpBtn .ant-input-group-addon {
  padding: 0 !important;
}
.customGrpBtn .ant-input-group-addon a {
  padding: 5px 15px !important;
  border-radius: 3px;
}
.customGrpBtn .ant-input-group-addon a:first-child {
  border-right: 1px solid #ddd !important;
}
.customGrpBtn .selected {
  background-color: #1890ff;
  color: white;
}
.othAppContainer {
  margin-top: 10px;
  margin-bottom: 50px;
}
.othAppContainer .flexColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.othAppContainer .flexColumn .otherAppList {
  width: 32%;
}
.othAppContainer .otherAppList {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px #ddd;
  border-radius: 5px;
  padding: 10px 10px 5px;
  flex-direction: column;
  margin-top: 10px;
}
.othAppContainer .otherAppList .leftBox {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.othAppContainer .otherAppList .leftBox img {
  width: 120px !important;
  height: auto !important;
  margin-bottom: 10px;
}
.othAppContainer .otherAppList .rightBox {
  text-align: left;
  width: 100%;
}
.othAppContainer .otherAppList .rightBox .label1 {
  font-size: 10px !important;
}
.othAppContainer .otherAppList .rightBox a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  width: 100%;
}
.othAppContainer .otherAppList .rightBox a p {
  color: #495057;
  font-size: 15px;
}
.photoShortName {
  max-height: 35px;
  margin-bottom: 5px;
  padding: 5px 10px 0;
}
.photoShortName a {
  color: black !important;
  display: block;
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.followUp {
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #ddd !important;
}
.studentRegForm .form-group input {
  padding-right: 10px !important;
}
.forgetEmailText {
  font-size: 16px !important;
  margin-top: 10px;
  text-transform: capitalize;
}
.forgetEmailText strong {
  color: #1890ff;
  font-weight: 500;
}
.gcKey {
  height: 250px !important;
}
.oshcBox {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 3px 5px;
  border-radius: 5px;
  background: #1690ff;
  color: white;
  font-weight: bold;
}
.oshcBox img {
  height: 18px;
  margin-right: 5px;
}
.noteMain {
  overflow: hidden !important;
}
.student-tab {
  overflow: hidden !important;
}
.student-tab .loadMoreBoxDiv {
  text-align: center;
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1 !important;
}
.student-tab .noteTitle {
  color: #111b21;
}
.student-tab .noteRow {
  background-color: white !important;
  position: relative;
}
.student-tab .noteRow .noteContainer {
  height: 80vh;
  overflow-y: auto;
}
.student-tab .noteRow .noteColumn .card {
  background: white !important;
  border: 1px solid #1890ff57;
  line-height: 1.5;
}
.student-tab .noteRow .noteColumn .card .dateBox {
  text-align: left;
  font-weight: bold;
  color: #0872bc;
}
.student-tab .noteRow .noteColumn .card .userBox {
  text-align: right;
  color: #667781;
  padding: 0;
  line-height: 1;
  font-size: 15px;
}
.student-tab .noteRow .noteColumn.noteRight {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.student-tab .noteRow .noteColumn.noteRight .card {
  border: 1px solid #5cb85c57;
}
.student-tab .noteRow .noteColumn.noteRight .card .dateBox {
  text-align: right;
}
.student-tab .noteRow .noteColumn.noteRight .card .userBox {
  text-align: right;
}
.student-tab .noteRow .noteBox {
  width: 75% !important;
}
.student-tab .noteRow .noteBox .card {
  background: #fff;
}
.student-tab .noteRow .noteBox .inner.card {
  padding: 10px 15px !important;
}
.textAreaCls {
  height: 200px;
}
.textAreaCls textarea.form-control {
  height: 200px !important;
}
.loginCredentialsBox {
  border: 0.3px solid #e6e7e8;
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #e6e7e8;
}
.loginCredentialsBox .header {
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
}
.localLoginCredentialsBox {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #0f52ba2e;
  border-radius: 10px;
  margin-bottom: 30px;
}
.localLoginCredentialsBox .header {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
.localLoginCredentialsBox .header .loginBanner {
  margin-bottom: 10px;
}
.localLoginCredentialsBox .header .loginIcon {
  margin-bottom: 10px;
  height: 70px;
}
.localLoginCredentialsBox .verticalPadding10 {
  padding-left: 10px;
  padding-right: 10px;
}
.localLoginCredentialsBox .verticalPadding10 .label1 {
  position: relative;
  padding: 4px;
  font-size: 13px;
  color: #555;
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.localLoginCredentialsBox .verticalPadding10 .label2 {
  position: relative;
  padding: 4px;
  font-size: 13px;
  color: #555;
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.localLoginCredentialsBox .verticalPadding10 .label2 .btn-info {
  background-color: #0f52ba;
  border-color: #0f52ba;
}
.localLoginCredentialsBox .verticalPadding10 .rowC {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
}
.localLoginCredentialsBox .verticalPadding10 .rowC:last-child {
  border-bottom: none;
}
.localLoginCredentialsBox .verticalPadding10 .rowC:last-child span {
  margin-top: 5px;
}
.localLoginCredentialsBox .verticalPadding10 .rowC img {
  height: 20px;
  margin-right: 8px;
}
.localLoginCredentialsBox .verticalPadding10 .rowC span {
  border: none;
  padding: 0;
  flex: 1 1;
  text-transform: capitalize;
  display: flex;
}
.commissionBox .commissionParentBox {
  display: flex;
  flex-direction: column;
}
.commissionBox .commissionParentBox.row {
  flex-direction: row;
}
.commissionBox .commissionParentBox.row .commissionUniInfo {
  flex: 1 1;
  margin: 0 10px 20px;
}
.commissionBox .commissionParentBox .commissionUniInfo {
  border: none;
  box-shadow: 1px 1px 10px #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}
.commissionBox .commissionParentBox .commissionUniInfo .ant-card-body {
  padding: 10px;
}
.commissionBox .commissionParentBox .commissionUniInfo img {
  width: max-content;
  height: 50px;
}
.commissionBox .commissionParentBox .commissionUniInfo div[class*='ant-col'] {
  display: flex;
  justify-content: center;
  height: 70px;
  flex-direction: column;
}
.commissionBox .commissionParentBox .commissionUniInfo .logoBox {
  border-right: 1px solid #ddd;
  padding-right: 30px;
  align-items: center;
}
.commissionBox .commissionParentBox .commissionUniInfo .contantBox {
  padding-left: 50px !important;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox {
  display: flex;
  min-height: 60px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentProfile {
  width: 20%;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentProfile img {
  height: 60px;
  max-width: 100%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentInfo {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 5px 5px 5px 40px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart {
  border-right: 1px solid #f1f1f1;
  padding-right: 30px;
  width: 30%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:first-child {
  width: 25%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:last-child {
  border: none;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .studentInfo {
  justify-content: flex-start;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .uniLogo {
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .uniLogo img {
  height: 50px;
  width: 50px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew {
  border-right: 1px solid #f1f1f1;
  padding-right: 30px;
  width: 22.5%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew:first-child {
  width: 25%;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew:last-child {
  border: none;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .studentInfo {
  justify-content: flex-start;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .uniLogo {
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .uniLogo img {
  height: 50px;
  width: 50px;
}
.commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex {
  width: 15%;
  padding-left: 20px;
}
.commissionBox .commissionParentBox .commissionUniInfo .uniName {
  font-size: 18px;
  color: #0f52ba;
}
.commissionBox .commissionParentBox .commissionUniInfo .courseInfo {
  font-size: 15px;
  color: black;
}
.commissionBox .commissionParentBox .commissionUniInfo .courseInfo.sm {
  font-size: 14px;
  color: #555;
}
.commissionBox .commissionParentBox .commissionUniInfo .commissionInfo {
  font-size: 14px;
  color: #0872bc;
  font-weight: 600;
}
.commissionBox .inputGroup {
  margin-top: 10px;
}
.commissionBox .inputGroup .input-group-text {
  background-color: #e9ecef;
  border-color: #666;
}
.commissionBox textarea.form-control {
  height: 50px !important;
}
.comBtnGroup {
  display: flex;
  flex-wrap: wrap;
}
.comBtnGroup .btn {
  margin-bottom: 3px;
  font-size: 13px !important;
  padding: 0 4px !important;
  width: 180px;
}
.comBtnGroup .btn img {
  margin-right: 5px;
  height: 13px;
}
.comBtnGroup .btn-view {
  border: 1px solid #d9d9d9 !important;
  color: #212529 !important;
  background-color: white;
}
.darkFont {
  color: black;
}
.dangerPos {
  position: absolute;
  left: 10px;
}
.commissionListBox {
  margin-top: 20px;
  position: relative;
}
.commissionListBox .addMoreCommissionBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}
.student-group {
  margin-top: 10px;
  margin-right: 10px;
}
.student-group label {
  margin: 0 10px 4px 3px !important;
  color: black;
  font-weight: 500 !important;
}
.student-group .form-control {
  border-color: #e5dcdc !important;
  background-image: none !important;
  height: 35px !important;
  border-radius: 5px !important;
}
.student-group .input-group-text {
  border-color: #e5dcdc !important;
  height: 35px !important;
}
.uniInfoLogo {
  border-right: 1px solid #f1f1f1;
  padding-right: 20px;
  margin-right: 20px;
}
.uniInfoLogo img {
  height: 70px !important;
}
.textCap {
  text-transform: capitalize;
}
.textUpp {
  text-transform: uppercase;
}
.customCheckbox {
  cursor: pointer;
  text-align: center;
}
.customCheckbox img {
  height: 25px;
}
.universityParentBox {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.universityParentBox .commissionUniInfo {
  border: none;
  box-shadow: 1px 1px 10px #ddd;
  border-radius: 5px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
.universityParentBox .commissionUniInfo .ant-card-body {
  justify-content: center;
  flex: 1 1;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.universityParentBox .commissionUniInfo .uniLogo {
  border-right: none;
}
.universityParentBox .commissionUniInfo .uniLogo img {
  height: 70px !important;
  width: 100%;
}
.universityParentBox .commissionUniInfo .studentInfoBox {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.universityParentBox .commissionUniInfo .ant-card-body {
  padding: 10px;
}
.universityParentBox .commissionUniInfo img {
  width: max-content;
  height: 50px;
}
.universityParentBox .commissionUniInfo .uniName {
  font-size: 18px;
  color: #0f52ba;
}
.filterCard .ant-select .ant-select-selection--single {
  border: 1px solid #e9d5d5 !important;
}
.filterCard .search-box-table.round {
  border: 1px solid #e9d5d5 !important;
}
.filterCard .roundBtn {
  border: 1px solid #e9d5d5 !important;
}
.statusDate {
  margin-top: 5px;
  font-size: 13px;
  color: #0f52ba;
  font-weight: 500;
}
.btn-warning {
  background-color: rgba(240, 173, 78, 0.87) !important;
  color: white !important;
}
.btn-success {
  border-color: #0872bc !important;
  background-color: #0872bc !important;
  color: white !important;
}
.btn-view {
  border: 1px solid #d9d9d9 !important;
  color: #212529 !important;
  background-color: white !important;
  margin-top: 0 !important;
}
.btn-view.sm {
  padding: 0.25rem 0.8rem;
  font-size: 0.8rem;
  line-height: 1.5;
}
.label-info-new {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: none;
}
.btn-paid {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  color: white !important;
}
.label-paid {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  color: white !important;
}
.view-btn-box {
  margin-top: 5px;
  /* .eyeBtn {
       height: 20px;
     }*/
}
.view-btn-box .btn-view {
  font-size: 12px !important;
  border: 1px solid #d9d9d9;
  height: 32px;
  padding: 2px 5px;
  border-radius: 5px;
  color: #212529 !important;
  margin-right: 5px;
}
.view-btn-box .btn-view img {
  height: 17px;
}
.contract {
  text-transform: uppercase;
  margin-top: 5px;
  color: black;
}
.contract a {
  text-decoration: underline;
  font-weight: 500;
}
.customInput {
  margin-top: 8px;
}
.customInput label {
  margin-bottom: auto;
}
.table-head .sort-box-table.sort-box-table30 {
  width: 30% !important;
}
.flexRowBox {
  display: flex;
}
.flexRowBox .flexBox1 {
  flex: 1 1;
  width: 30%;
  margin-right: 10px;
  background-color: #f1f1f1 !important;
  margin-bottom: 18px;
}
.flexRowBox .flexBox2 {
  flex: 2 1;
}
.imgFlex {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
}
.imgFlex .img-column {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  margin-right: 10px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgFlex .img-column img {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart {
    width: 33%;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:first-child {
    width: 33%;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:nth-child(3) {
    border: none;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:last-child {
    border: none;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex {
    width: 50%;
    padding-left: 20px;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex .student-group {
    margin-top: 0;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .uniName {
    font-size: 15px;
    color: #0f52ba;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .courseInfo {
    font-size: 12px;
    color: black;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .courseInfo.sm {
    font-size: 12px;
    color: #555;
  }
  .commissionBox .commissionParentBox .commissionUniInfo .commissionInfo {
    font-size: 12px;
    color: #0872bc;
    font-weight: 600;
  }
  .universityParentBox {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .universityParentBox .commissionUniInfo {
    border: none;
    box-shadow: 1px 1px 10px #ddd;
    border-radius: 5px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .universityParentBox .commissionUniInfo .ant-card-body {
    justify-content: center;
    flex: 1 1;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .universityParentBox .commissionUniInfo .uniLogo {
    border-right: none;
  }
  .universityParentBox .commissionUniInfo .uniLogo img {
    height: 70px !important;
    width: 100%;
  }
  .universityParentBox .commissionUniInfo .uniName {
    font-size: 15px;
    color: #0f52ba;
  }
  .customInput {
    margin-top: 8px;
  }
  .customInput label {
    margin-bottom: 0;
  }
}
.round-date-picker .ant-calendar-picker-input {
  height: 42px !important;
  border-radius: 50px;
  border: none;
}
.verifyOfferBox {
  position: relative;
  overflow: hidden;
  height: auto;
  padding-top: 7%;
  padding-bottom: 90px;
  min-height: 80vh;
}
.verifyOfferBox h3 {
  font-size: 32px;
  text-transform: capitalize;
  margin: 0 0 10px;
  color: #0f52ba;
}
.verifyOfferBox .store {
  padding-top: 7%;
}
.verifyOfferBox .store img {
  width: 100%;
}
.verifyOfferBox .iframeBox {
  margin: 5% 15% 100px;
  border: 1px solid #ddd;
  padding: 10px;
}
.announcement_banner {
  background-color: white;
  max-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.announcement_banner img {
  width: auto;
  height: 250px;
}
.announcement_banner .applyBtn {
  position: absolute;
  bottom: 15px;
  width: 350px !important;
}
.certificate_box .btn-default {
  margin-bottom: 10px;
  width: 220px;
}
.certificate_box .ant-tag {
  margin-bottom: 5px;
  border-color: #007bff33;
}
.certificate_box .ant-tag .btn-link {
  font-weight: 400;
  color: #007bff !important;
  text-decoration: none;
}
.duplicateRecordLink {
  font-size: 15px;
  margin-bottom: 10px;
}
.broadcast_info {
  font-size: 25px;
  text-align: center;
  padding: 50px 0;
  min-height: 500px;
}
.broadcast_info img {
  height: 150px;
  margin-bottom: 20px;
}
.addInfo {
  padding-left: 3px !important;
}
.addInfo .box {
  position: relative;
  margin-bottom: 5px;
  display: flex !important;
}
.addInfo .box img {
  height: 22px !important;
  width: auto !important;
}
.addInfo .box span {
  margin-left: 27px;
}
@media (max-width: 600px) {
  .verifyOfferBox {
    position: relative;
    overflow: hidden;
    height: auto;
    padding-top: 100px;
    padding-bottom: 90px;
    min-height: 80vh;
  }
  .verifyOfferBox h3 {
    font-size: 32px;
    text-transform: capitalize;
    margin: 0 0 10px;
    color: #0f52ba;
  }
  .verifyOfferBox .store {
    padding-top: 7%;
  }
  .verifyOfferBox .store img {
    width: 100%;
  }
  .verifyOfferBox .iframeBox {
    margin: 5% 2% 100px;
    border: 1px solid #ddd;
    padding: 10px;
    overflow: auto;
  }
}
.counsellerDashboard {
  width: auto;
}
.counsellerDashboard .main-logo {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.counsellerDashboard .counseller-card {
  width: 100%;
  height: 50%;
  border: 1px solid lightgrey;
}
.counsellerDashboard .counseller-card .countryInfo {
  text-align: center;
}
.counsellerDashboard .counseller-card .countryInfo .map {
  width: auto;
  height: 100px;
}
.counsellerDashboard .counseller-card .countryInfo h5 {
  color: #000;
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.counsellerDashboard .counseller-card .counselling-container {
  position: relative;
  min-height: 200px;
  border-bottom: 1px solid #ddd;
}
.counsellerDashboard .counseller-card .counselling-container .waitingBox {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #00000033;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.counsellerDashboard .counseller-card .counselling-container .waitingBox .waitingLabel {
  font-size: 25px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  padding: 20px 20px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card:nth-child(0) {
  border-bottom: 1px solid lightgrey;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title {
  width: 100%;
  padding: 10px;
  padding-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title img {
  border-radius: 50px;
  margin-right: 15px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title .box-title {
  font-size: 12px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title .tarundiv {
  display: flex;
  flex-direction: column;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .waitingInfo {
  display: flex;
  margin-bottom: 20px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .waitingInfo .counsellerInfo-num {
  background-color: #ddd;
  border-radius: 40px;
  padding: 0 10px;
  margin-left: 10px;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo {
  width: 100%;
  height: 50%;
  font-size: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo.active {
  color: #0872bc;
}
.comment-boxx {
  border-bottom: lightgrey;
}
.student-informationn {
  display: flex;
  padding: 9px 14px;
}
.studentprofile {
  display: flex;
  flex-direction: row;
}
.studentprofile .image {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.studentprofile .image img {
  border-radius: 100%;
}
.studentprofile .descrip {
  width: 65%;
}
.studentprofile .descrip .border {
  width: 60%;
  border: 2px solid gray;
  padding: 10px;
}
.imagee {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imagee img {
  border-radius: 100%;
}
.studentinformationboxx {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.nameandnumberboxx {
  height: 40%;
}
.localLoginCredentialsBoxx {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #0f52ba2e;
  border-radius: 10px;
  margin-bottom: 10px;
}
.centerpage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.centerpage .borderr {
  width: 50%;
}
.dateandhistory {
  height: 50px;
}
.studentListContainer {
  margin-bottom: 5px;
}
.studentListContainer.active .otherAppList {
  background-color: #fff8e1;
}
.studentListContainer .otherAppList {
  display: flex;
  padding: 10px 10px 5px;
  background-color: white;
  box-shadow: 0 0 10px #f1f1;
  border-radius: 5px;
  margin-bottom: 10px;
}
.studentListContainer .otherAppList .leftBox {
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.studentListContainer .otherAppList .leftBox .logo1 {
  width: 200px;
  height: 200px;
}
.studentListContainer .otherAppList .leftBox img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.studentListContainer .otherAppList .leftBox .studentDetails {
  line-height: 1.5;
}
.studentListContainer .otherAppList .leftBox .leftBox1 {
  background-color: gray !important;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.studentListContainer .otherAppList .leftBox .leftBox1 img {
  width: 50px !important;
  height: 50px !important;
  margin-right: 20px;
}
.studentListContainer .otherAppList .leftBox .leftBox1 .studentDetails {
  line-height: 1.5;
}
.studentListContainer .otherAppList .rightBox {
  margin-top: 10px;
  text-align: left;
}
.studentListContainer .otherAppList .rightBox a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}
.studentListContainer .otherAppList .rightBox a p {
  color: #495057;
  font-size: 15px;
}
.closeconvert {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}
.flaguk {
  margin-left: 466px;
  width: 25px;
}
.nameanddate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.backanddot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.onoffdot {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: 10px;
}
.onoffdot .innerdot {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
}
.picandmore {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.caseclose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
.caseclose .caseclosebtn {
  width: 100px;
  background: #0872bc;
  font-weight: 500 !important;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  border: none;
  outline: none;
}
.myTextarea {
  padding: 10px;
}
.directUniCount {
  width: 16.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.directUniCount .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.directUniCount .uniCountBox img {
  height: 30px;
  width: auto;
}
.directUniCount .uniCountBox h5 {
  font-size: 14px !important;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
.canadaDirectUniCount.canadaDirectUniCount2 {
  width: 25% !important;
}
.canadaDirectUniCount2 .innerBox {
  border: 1px solid #ccc;
}
.card.card-pdf {
  background: white !important;
}
.canadaDirectUniCount {
  width: 19.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.canadaDirectUniCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.canadaDirectUniCount .innerBox .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
.canadaDirectUniCount .innerBox .uniCountBox img {
  height: 30px;
  width: auto;
}
.canadaDirectUniCount .innerBox .uniCountBox img.sm {
  height: 25px;
}
.canadaDirectUniCount .innerBox .uniCountBox img.md {
  height: 35px !important;
}
.canadaDirectUniCount .innerBox .uniCountBox h5 {
  font-size: 14px;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
.canadaDirectUniCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
}
.canadaDirectUniCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.canadaDirectUniCount .innerBox .btnBox a i {
  margin-right: 5px;
}
.canadaDirectUniCount .innerBox .dirUniAgent {
  padding: 10px 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 27px;
}
.filter-row.ant-row {
  display: flex;
  flex-wrap: wrap;
}
.commissionBlock {
  margin: 20px 0;
}
.commissionBlock .countryCommission {
  padding: 20px;
  text-align: center;
}
.commissionBlock .countryCommission .countryName {
  font-size: 16px !important;
  height: 50px;
}
.commissionBlock .countryCommission .countryFlag {
  margin: 25px 0;
}
.commissionBlock .countryCommission .countryFlag img {
  height: 30px;
}
.commissionBlock .countryCommission .currencyName {
  font-size: 16px !important;
}
.commissionBlock .countryCommission .countryAmount {
  font-size: 25px;
  cursor: pointer;
}
.commissionBlock .countryCommission .canada {
  color: #ad2125;
}
.commissionBlock .countryCommission .uk {
  color: #ff0066;
}
.commissionBlock .countryCommission .australia {
  color: #003399;
}
.card.bgWhite {
  background: white !important;
}
.ant-col-md-0 {
  display: block;
}
.squareContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.squareContainer .squareStyle {
  width: 45px;
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
}
.squareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.squareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.squareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
.squareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
.squareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
.ukSquareContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 15px;
  grid-column-gap: 5px;
}
.ukSquareContainer .squareStyle {
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  text-align: center;
}
.ukSquareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.ukSquareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.ukSquareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
.ukSquareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
.ukSquareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
.dirUniLabelBox {
  display: flex;
  padding: 30px 2.4rem 0 !important;
}
.dirUniLabelBox div {
  margin: 0 20px;
  padding: 0 10px;
}
.dirUniLabelBox div:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.dirUniLabelBox div:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.dirUniLabelBox div:nth-child(3) {
  border-bottom: 2px solid #5bc0de;
}
.ledgerBox {
  padding: 20px 3%;
}
.ledgerBox .header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}
.ledgerBox .header .ant-col {
  color: black;
  font-weight: 500;
  padding-bottom: 10px;
}
.ledgerBox .ant-col:nth-child(3) {
  text-align: right;
}
.ledgerBox .ant-col:last-child {
  text-align: right;
}
.ledgerBox .ant-row {
  padding: 10px 10px 0;
}
.ledgerBox .ant-row:nth-child(even) {
  background-color: #f7f7f7;
}
.ledgerBox .ant-row:nth-child(odd) {
  background-color: #ff11ff08;
}
.ledgerBox .ant-row.header {
  background-color: white;
}
.ledgerBox .debit {
  color: #d9534f;
  font-weight: 600;
}
.ledgerBox .credit {
  color: #5cb85c;
  font-weight: 600;
}
.ledgerBox .dateBox {
  font-size: 15px;
  font-weight: 500;
  color: #0872bc;
  padding-bottom: 10px;
}
.filterBox .ant-calendar-picker-input {
  border-radius: 50px;
  border: none;
}
.filterBox .search-box-table.round {
  margin-left: 0 !important;
}
.agentName {
  display: flex;
}
.agentName .agentOnline {
  height: 7px;
  width: 7px;
  background-color: #5cb85c;
  border-radius: 50%;
  margin-left: 5px;
}
.display-linebreak {
  white-space: pre-line;
}
.hrFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.selectable-box {
  border: 1px solid #e5d9d9;
  margin: 5px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
}
.box-text {
  font-size: 14px;
}
.countCircle {
  font-size: 12px !important;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  line-height: 0.9;
  background: #0872bc;
  color: white !important;
  margin-left: 7px;
  margin-top: 2px;
  padding: 5px;
  display: inline-block;
}
.selectable-box.selected {
  background-color: #007bff;
  color: white;
}
.selectable-box.selected .countCircle {
  background: #5cb85c;
}
.intakeRow {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  margin-top: 10px;
}
.intakeRow .singleIntakeBox {
  border: 1px solid #d9d9d9;
  padding: 0 5px;
  background: #fafafa;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}
.intakeRow .singleIntakeBox .singleIntakeBox-check {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  bottom: 3px;
}
.intakeRow .singleIntakeBox.selected {
  background-color: #1890ff;
}
.intakeRow .singleIntakeBox.selected i {
  color: white !important;
}
.intakeRow .singleIntakeBox i {
  font-style: normal;
  color: rgba(0, 0, 0, 0.65);
}
.trainingBox {
  text-align: center;
  margin-top: 50px;
}
.trainingBox img {
  height: 150px;
}
.trainingBox .textBox {
  margin-top: 20px;
}
.trainingBox .textBox h3 {
  font-weight: 600;
  text-transform: uppercase;
  font-family: ui-monospace;
  font-size: 30px;
  margin-bottom: 3%;
}
.trainingBox .textBox.normal h3 {
  text-transform: capitalize;
}
.trainingBox .textBox .confirmLink {
  font-size: 17px;
  background-color: #0f52ba;
  color: white;
  padding: 6px 30px;
  text-transform: capitalize;
  border-radius: 5px;
}
.marketingUserBox {
  margin-bottom: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 10px 10px #ddd;
}
.marketingUserBox.managerBoxCanada {
  width: 100%;
  margin-bottom: 0;
}
.marketingUserBox.managerBoxCanada .ant-avatar img {
  left: 0 !important;
}
.marketingUserBox.managerBoxCanada .managerHead {
  margin-bottom: 5px;
  background-color: #ad2125;
}
.marketingUserBox .managerHead {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 80%;
}
.marketingUserBox .managerBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-left: 10px;
}
.marketingUserBox .managerBody.ais {
  align-items: flex-start;
}
.whatsNewCard .whatsNewBox {
  border-bottom: 1px solid #fde6e6;
  padding: 20px;
}
.whatsNewCard .whatsNewBox .flex-row {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
}
.whatsNewCard .whatsNewBox .flex-row .icon {
  width: 50px;
  height: 50px;
  margin-right: 30px;
  margin-left: 20px;
}
.whatsNewCard .whatsNewBox .flex-row .title {
  color: #0f52ba;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
}
.whatsNewCard .whatsNewBox .flex-row .dateRow {
  color: #555;
  font-size: 14px;
}
.whatsNewCard .whatsNewBox .flex-row .dateRow i {
  margin-right: 5px;
  color: #888;
}
.whatsNewCard .whatsNewBox .content-box {
  margin-top: 20px;
}
.whatsNewCard .whatsNewBox .img-box img {
  width: 80%;
}
.studentInfoTableRow {
  padding: 4px 10px;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.studentInfoTableRow .title {
  font-weight: bold;
  width: 40%;
  padding: 5px 10px;
}
.studentInfoTableRow .details {
  padding: 5px 10px;
}
.studentInfoTableRow p {
  margin-bottom: 0;
}
.studentInfoTableRow:first-child {
  border-top: 1px solid #ddd;
}
.studentInfoTableRow:nth-child(odd) {
  background-color: white;
}
.studentInfoTableRow:nth-child(even) {
  background-color: #effbf8;
}
.textAreaHeight {
  min-height: 100px !important;
}
.agent_form {
  background-color: #f7f7ff;
}
.agent_form .ant-form-item {
  margin-bottom: 0.8rem;
}
.agent_form .form-control,
.agent_form .ant-select-selection {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none;
  appearance: none;
  height: 50px !important;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-image: none !important;
}
.agent_form .form-control::-webkit-input-placeholder,
.agent_form .ant-select-selection::-webkit-input-placeholder {
  font-size: 1rem !important;
  font-weight: 400;
}
.agent_form .ant-select-selection__placeholder {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
}
.agent_form .ant-select-selection__rendered {
  margin-left: 0;
}
.agent_form .ant-select-arrow {
  background-color: white;
  color: #777 !important;
}
.agent_form .ant-select-focused .ant-select-arrow {
  background-color: white !important;
  color: #777 !important;
}
.agent_form label {
  font-size: 18px !important;
  margin: 0 !important;
  margin-bottom: 8px !important;
}
.agent_form .right_bg {
  height: 100%;
  background-position: 35%;
  background-repeat: no-repeat;
}
.agent_form .btn_sub {
  padding: 8px 16px !important;
  font-size: 1.25rem;
  border-radius: 0 !important;
  background-color: #1890ff;
  border: none;
}
.registrationClose {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.default_btn {
  border: 1px solid #ddd;
  padding: 3px 10px;
  color: #555;
  text-decoration: none;
  font-size: 0.8rem !important;
  border-radius: 3px;
  margin-right: 5px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}
.default_btn:last-child {
  margin-right: 0;
}
.default_btn img {
  height: 15px !important;
  margin-right: 4px;
  margin-bottom: 2px;
}
.btn_group {
  display: flex;
  flex-wrap: wrap;
}
.btn_group .default_btn {
  margin-right: 5px;
}
.btn_group .btn {
  margin-right: 5px;
}
.select_btn_group {
  display: flex;
  flex-wrap: wrap;
}
.select_btn_group .default_btn {
  margin-right: 5px;
  background-color: white;
  line-height: 1.5;
  padding: 0.4rem 1.75rem !important;
  height: auto !important;
  border: none;
  margin-left: 5px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 15px;
}
.select_btn_group .default_btn.selected {
  background-color: #5cb85c;
  color: white;
}
.select_btn_group .default_btn .anticon {
  font-size: 16px;
  margin-right: 7px;
}
.select_btn_group .default_btn_lg {
  margin-right: 10px;
  background-color: white;
  line-height: 1.5;
  padding: 0.4rem 3rem !important;
  height: auto !important;
  border: none;
  margin-left: 5px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  border-radius: 3px;
  color: #555;
  display: flex;
  align-items: center;
}
.select_btn_group .default_btn_lg.selected {
  color: white;
}
.select_btn_group .default_btn_lg .anticon {
  font-size: 16px;
  margin-right: 7px;
}
.btn_link {
  text-decoration: underline;
}
.custom-checkbox {
  width: 100%;
}
.custom-checkbox .ant-checkbox-group-item {
  width: 40% !important;
}
.custom-checkbox span {
  font-weight: normal;
}
.marketing_country_box {
  border: 1px solid #f1f1f1;
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.marketing_country_box .uni_row {
  border: 1px solid #f1f1f1;
  padding: 5px 0 0 0;
  margin-bottom: 5px;
  border-radius: 5px;
}
.marketing_country_box .uni_row .form-control.sm {
  height: 35px !important;
  border-color: #ddd !important;
  background-image: none !important;
  font-size: 14px !important;
}
.filter_box {
  margin-top: 15px;
  background-color: white;
  border-radius: 5px;
  padding: 15px 10px 10px;
}
.filter_box.bg {
  border: 1px solid #dee2e6 !important;
}
.filter_box .labelNew {
  font-size: 14px;
  font-weight: normal !important;
}
.filter_box input:not([class]) {
  width: 100%;
  border: 1px solid #ddd;
  padding: 0 5px 0 20px;
  border-radius: 50px;
  font-size: 14px;
  color: #555;
  height: 40px;
}
.filter_box .ant-calendar-picker-input {
  height: 42px !important;
  border-radius: 50px;
  border: 1px solid #ddd;
}
.filter_box .ant-select.ant-select-focused .ant-select-arrow {
  background: transparent !important;
  color: #555;
}
.filter_box .ant-select .ant-select-selection {
  border-radius: 50px;
  border-color: #ddd;
}
.filter_box .ant-select .ant-select-selection .ant-select-arrow {
  background: transparent;
  color: #555;
}
.filter_box .ant-select .ant-select-selection:hover .ant-select-arrow {
  background-color: white;
  display: block;
}
.filter_box .ant-select .ant-select-selection:hover .ant-select-arrow .anticon {
  color: #555;
}
.filter_box .ant-select-selection__placeholder {
  font-size: 14px !important;
  opacity: 0.8 !important;
  color: #333 !important;
}
.filter_box .search-box-table.round {
  border: 1px solid #ddd;
}
.filter_box .btn_group {
  display: flex;
  justify-content: flex-end;
}
.filter_box .btn_group .default_btn {
  margin-right: 5px;
  padding: 5px 20px;
  border-radius: 50px;
}
.filter_box .btn_group .default_btn:last-child {
  margin-right: 0;
}
.mismatchNote {
  padding: 5px 10px;
  background-color: #d9534f;
  color: white;
  font-weight: 600;
  margin-top: 10px;
}
.expenseAmtBox .successAmt {
  color: #5cb85c;
}
.expenseAmtBox .pendingAmt {
  color: rgba(240, 173, 78, 0.87);
}
.expenseAmtBox .totalAmt {
  color: #0872bc;
}
.joinDate {
  margin-top: 5px;
  color: #1890ff;
  font-size: 11px;
  border-radius: 3px;
  font-weight: 600;
}
.joinDate::before {
  content: '' !important;
  border-left: 2px solid #1890ff;
  padding-left: 3px;
}
.table-bordered {
  border: 1px solid #dee2e6 !important;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6 !important;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-bordered th,
.table-bordered td,
.table-bordered thead th,
.table-bordered tbody + tbody {
  border: 0;
}
.marketingDirectUniCount {
  width: 16.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
}
.marketingDirectUniCount .uni_box {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.marketingDirectUniCount .uni_box img {
  height: 40px;
  width: auto;
}
.marketingDirectUniCount .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 15px;
}
.marketingDirectUniCount .uniCountBox .countBox {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 40%;
  cursor: pointer;
}
.marketingDirectUniCount .uniCountBox .countBox .label {
  font-size: 12px !important;
  color: #555;
  padding: 0;
}
.marketingDirectUniCount .uniCountBox .countBox .count {
  padding: 0 5px;
  color: #1890ff;
}
.dropbtn {
  border: none;
}
input[type='date'].js_datepicker {
  background-color: white !important;
  border: 1px solid #e5dddd !important;
}
.eye_btn {
  padding: 0 3px !important;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 10px;
  color: #0f52ba !important;
}
.eye_btn img {
  height: 14px;
}
.structureDotsReplace {
  display: block;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}
.event_gallery_list {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.event_gallery_list .single_img {
  border: 1px solid #ebcfcf;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
.event_gallery_list .single_img:hover {
  border: 2px solid #0872bc;
}
.event_gallery_list .single_img .cross_btn {
  position: absolute;
  right: -8px;
  top: -8px;
  background: #0f52ba;
  border-radius: 50px;
  width: 22px;
  height: 22px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
.event_gallery_list .single_img img {
  height: 100px;
}
.btn_options {
  display: flex;
}
.btn_options a {
  font-size: 14px;
  width: 100%;
  height: 40px !important;
  padding: 0.475rem 0.75rem;
}
.btn_options .date_box {
  width: 220px;
  margin-right: 10px !important;
}
.btn_options .date_box.selected {
  background-color: #f1f1f1 !important;
}
.btn_options .date_box.selected .btn {
  background-color: #f1f1f1 !important;
}
.btn_options .date_box .form-control {
  border-color: #ddd !important;
  font-size: 15px;
}
.ant-calendar-month-calendar {
  width: 300px;
}
div#studentChart {
  padding-top: 30px !important;
}
.agent-rank-label {
  font-size: 10px !important;
  padding: 4px !important;
}
.priorityBox {
  margin-top: 10px;
}
.priorityBox .priority {
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background: rgba(240, 173, 78, 0.87);
  font-weight: 600;
  position: relative;
  top: -3px;
  left: 0;
  margin-left: 5px;
  font-style: normal;
  animation: glowS 0.5s ease-in-out infinite alternate;
  text-transform: uppercase;
}
.priorityBox .superPriority {
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background: red;
  font-weight: 600;
  position: relative;
  top: -3px;
  left: 0;
  margin-left: 5px;
  font-style: normal;
  animation: glow 0.5s ease-in-out infinite alternate !important;
  text-transform: uppercase;
}
.eventDirectCount {
  width: 20% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.eventDirectCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.eventDirectCount .innerBox .uniCountBox {
  display: flex !important;
  align-items: center;
  padding: 10px 5px;
}
.eventDirectCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
}
.eventDirectCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventDirectCount .innerBox .btnBox a i {
  margin-right: 5px;
}
.eventDirectCount .innerBox .dirUniAgent {
  padding: 10px 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 27px;
}
.eventDirectCount .innerBox .squareContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}
.eventDirectCount .innerBox .squareContainer > div {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.eventDirectCount .innerBox .squareContainer > div span {
  font-size: 12px;
  margin-bottom: 5px;
}
.eventDirectCount .innerBox .squareContainer .squareStyle {
  width: 60px;
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  text-align: center;
  align-self: center;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.totalCount {
  border-bottom: 2px solid #5cb85c;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.approvedCount {
  border-bottom: 2px solid #1890ff;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.pendingCount {
  border-bottom: 2px solid #0872bc;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.deferredCount {
  border-bottom: 2px solid #5bc0de;
}
.eventDirectCount .innerBox .squareContainer .squareStyle.deniedCount {
  border-bottom: 2px solid #d9534f;
}
.icefMeetingCount .singleLi {
  display: inline-block;
  padding: 10px;
}
.icefMeetingCount .singleLi .li_box {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}
.icefMeetingCount .singleLi .li_box .squareStyle {
  margin-left: 20px;
  border-bottom: 2px solid;
  min-width: 30px;
  text-align: center;
  font-weight: bold;
}
.main-btn {
  width: auto !important;
  min-width: 150px;
  background: #1556bc;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  height: 42px !important;
}
.white-btn {
  background: white;
  border: 1px solid #ddd;
  color: #555;
}
.white-btn .ant-calendar-picker-input.ant-input {
  height: 35px !important;
}
.small-select .ant-select-selection {
  height: 30px !important;
  border-color: #ddd;
  border-radius: 3px;
}
.small-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.md-select .ant-select-selection {
  height: 33px !important;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.md-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.room_slider .mobile_view {
  display: none;
}
.room_slider .web_view {
  display: block;
}
@media (max-width: 768px) {
  .room_slider .mobile_view {
    display: block;
  }
  .room_slider .web_view {
    display: none;
  }
}
.shadow-select {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
}
.shadow-select .ant-select-selection {
  height: 35px !important;
  border-radius: 7px;
  padding: 15px 10px;
  border: none;
}
.shadow-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.comment-section .sendBtn {
  border-radius: 15px !important;
  background-color: #87d068;
  border: none;
  color: white;
  padding: 14px !important;
}
.comment-section .avatar_text {
  background-color: #87d068;
  margin: 0 12px;
  font-size: 20px !important;
}
.top_city {
  height: 170px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
.top_city img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
.top_city .city_name {
  position: absolute;
  bottom: 0;
  left: 7.5px;
  padding: 15px;
  z-index: 1;
  color: white;
  font-size: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: calc(100% - 15px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 500;
}
.shadow-select {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
}
.shadow-select .ant-select-selection {
  height: 35px !important;
  border-radius: 7px;
  padding: 15px 10px;
  border: none;
}
.shadow-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
.comment-section .sendBtn {
  border-radius: 15px !important;
  background-color: #87d068;
  border: none;
  color: white;
  padding: 14px !important;
}
.comment-section .avatar_text {
  background-color: #87d068;
  margin: 0 12px;
  font-size: 20px !important;
}
.top_city {
  height: 170px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
.top_city img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
.top_city .city_name {
  position: absolute;
  bottom: 0;
  left: 7.5px;
  padding: 15px;
  z-index: 1;
  color: white;
  font-size: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: calc(100% - 15px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 500;
}
.icefManager {
  background: #A1A8EA;
  height: 140px;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icefManager .avatar_box {
  position: absolute;
  bottom: 0;
  left: 5%;
}
.icefManager .avatar_box img {
  height: 170px;
}
.icefManager .manager_info_box {
  position: absolute;
  left: 25%;
  font-size: 13px;
}
.icefManager .manager_info_box .head-title {
  font-size: 22px;
  color: white;
}
.icefManager .manager_info_box .manager_details .inline_a {
  display: flex;
  align-items: center;
}
.icefManager .manager_info_box .manager_details .inline_a:first-child {
  font-style: italic;
}
.event-card .card-container {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 400px !important;
  justify-content: flex-start;
}
.event-card .event-card-box h2 {
  font-size: 22px;
  margin: 15px 0 0 0;
  text-shadow: 1px 1px 5px #000;
  color: #fff;
  font-weight: bold;
}
.event-card .event-card-box h3 {
  font-size: 16px;
  margin: 5px 0 0 0;
  text-shadow: 1px 1px 5px #000;
  color: #fff;
  font-weight: bold;
}
.event-card .event-card-box p {
  font-size: 16px;
  position: absolute;
  bottom: 45px;
  left: 50%;
  right: 25%;
  width: 250px;
  margin-left: -145px;
  text-transform: uppercase;
  text-shadow: 1px 1px 5px #000;
  font-weight: bold;
}
.event-card .event-card-box .event-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-bottom: 20px;
  font-size: 16px;
}
.event-card .event-card-box .event-info .add-event-btn {
  margin-top: 1rem;
  border: 2px solid white;
  padding: 6px;
  position: absolute;
  right: 20px;
  bottom: 5px;
  cursor: pointer;
}
.task_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.task_box .label-sm {
  padding: 0.5em 0.6em 0.3em;
}
.completeTask {
  text-decoration: line-through;
}
.event_list_card {
  height: 82vh;
  overflow-y: auto;
  padding: 5px;
}
.event_list_card .single_event_card {
  margin-bottom: 15px;
  padding: 20px;
  box-shadow: 0 0 10px #ddd;
  border-radius: 10px;
}
.event_list_card .single_event_card .ant-collapse {
  border: 1px solid #f4efef !important;
  margin-top: 10px;
}
.event_list_card .single_event_card .ant-collapse .ant-collapse-header {
  padding: 8px 40px;
}
.align_center {
  align-items: center;
  display: flex;
  color: #0f52ba;
}
.aic {
  align-items: center !important;
  display: flex !important;
}
.aic label {
  margin-bottom: 0;
}
.ant-select-disabled .ant-select-selection {
  background-color: #ededed !important;
}
.calendar-container.bgWhite {
  background: white !important;
}
.calendar-container.bgWhite .ant-card-body {
  padding: 0;
}
.calendar-container .table-event td {
  width: calc(100% / 7);
}
.small-select .ant-select-selection--single {
  height: 30px !important;
  border-color: #666;
  border-radius: 5px;
}
.small-select .ant-select-selection--single .ant-select-arrow {
  padding: 3px;
  margin-top: -10px;
}
.custom_row {
  display: flex;
  margin-left: -9px;
  margin-right: -9px;
}
.custom_row .custom_col {
  flex: 1 1;
  padding-left: 9px;
  padding-right: 9px;
}
.w-65 {
  width: 65% !important;
}
.login--wCard {
  width: 65% !important;
}
.commission-listing-section {
  padding: 30px 2rem !important;
}
.custom-calendar-1 {
  width: 100% !important;
  font-size: 26px;
  border: none !important;
}
.custom-calendar-1 .rc-calendar-header {
  border-bottom: none;
}
.custom-calendar-1 .rc-calendar-body {
  padding: 0;
}
.custom-calendar-1 .rc-calendar-body .rc-calendar-cell > div {
  margin-left: auto;
  margin-right: auto;
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 20px !important;
}
.autoCompleted ul {
  width: 100%;
}
.autoCompleted .ant-select-search__field__wrap {
  width: 100%;
}
.help_btn {
  position: absolute !important;
  font-size: 14px;
  right: 10px;
  margin-top: 2px;
  border: 1px solid #d5c4c4;
  padding: 0px 20px;
  border-radius: 5px;
  color: #555;
  bottom: 10px;
  display: flex;
  align-items: center;
}
.help_btn i {
  margin-right: 5px;
}
.help_btn:hover {
  color: black;
}
.req_btn {
  font-size: 14px;
  right: 10px;
  border: 1px solid #d5c4c4;
  padding: 0px 5px;
  border-radius: 5px;
  color: #555;
  bottom: 40px;
  display: flex;
  align-items: center;
}
.req_btn i {
  margin-right: 5px;
}
.req_btn:hover {
  color: black;
}
.custom-container .custom_card .ant-card-head {
  height: 30px;
  background: #f1f1f1;
  font-size: 15px;
  color: #555;
}
.custom-container .custom_card .ant-card-head span {
  font-size: 14px;
  /* margin-left: 5px; */
}
.custom-container .custom_card .ant-card-body {
  padding: 14px 14px 14px 24px;
  color: black;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem !important;
  font-size: 12px !important;
}
.table-striped tr:nth-of-type(odd) {
  background-color: #dff0d8;
}
.flexWrap {
  display: flex;
  flex-wrap: wrap;
}
.commissionRow {
  display: flex;
}
.commissionRow .commBox {
  flex: 1 1;
}
.direct-uni-box .count {
  display: flex !important;
}
.direct-uni-box .canadaDirectUniCount {
  width: 20% !important;
  display: inline-block;
  padding: 0 10px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
.direct-uni-box .canadaDirectUniCount:last-child {
  padding-right: 0;
}
.direct-uni-box .canadaDirectUniCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox .record-count {
  display: flex;
  justify-content: center;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox img {
  height: 30px;
  width: auto;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox img.sm {
  height: 25px;
}
.direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox h5 {
  font-size: 14px;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
.direct-uni-box .canadaDirectUniCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
}
.direct-uni-box .canadaDirectUniCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.direct-uni-box .canadaDirectUniCount .innerBox .btnBox a i {
  margin-right: 5px;
}
.direct-uni-box .canadaDirectUniCount .innerBox .dirUniAgent {
  padding: 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 1px;
  background: #f1f1f1;
  margin: 10px 0;
}
.direct-uni-box .squareContainer {
  margin-top: 15px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
}
.direct-uni-box .squareContainer .squareStyle {
  width: auto;
  height: 32px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  margin-bottom: 0;
  text-align: center;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
.direct-uni-box .squareContainer .squareStyle:nth-child(6) {
  border-bottom: 2px solid #1890ff;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(7) {
  border-bottom: 2px solid #5cb85c;
}
.direct-uni-box .squareContainer .squareStyle:nth-child(8) {
  border-bottom: 2px solid #5cb85c;
}
.commissionBlock .countryCommission {
  width: 20%;
}
@media only screen and (max-width: 1441px) {
  .canadaDirectUniCount .innerBox .uniCountBox {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
  }
  .canadaDirectUniCount .innerBox .uniCountBox img {
    max-height: 20px;
    width: auto;
  }
  .canadaDirectUniCount .innerBox .uniCountBox h5 {
    font-size: 9px !important;
    height: 25px;
    width: auto;
    min-width: 25px;
    line-height: 3;
  }
  .canadaDirectUniCount .innerBox .btnBox a {
    font-size: 11px;
  }
}
@media only screen and (max-width: 560px) {
  section.banner1 {
    height: auto !important;
  }
  section.banner1 .img {
    display: none;
  }
  .login--wCard {
    width: 80% !important;
  }
  .direct-uni-box .canadaDirectUniCount {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  .commissionBlock .countryCommission {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .institute--event .table-head h5 {
    width: 65% !important;
  }
  .institute--event .event-teaser .event-meta {
    flex-direction: column;
  }
  .institute--event .event-teaser .event-title {
    padding-right: 0;
  }
  .institute--event .event-teaser .event-date-day1 {
    width: 100%;
  }
  .institute--event .event-teaser .event-venue-wrap1 {
    padding: 20px 0;
    margin-left: 0;
  }
  .institute--event .event-teaser .reg_btn {
    margin-left: 0 !important;
  }
  .institute--event .event-teaser .regTime {
    margin: 10px 0;
  }
}
.WcStyle {
  padding: 3px !important;
}
.penSpan {
  background: #fff;
  white-space: nowrap;
  text-transform: capitalize;
  padding: 3px 12px;
  border-radius: 50px;
  font-weight: 500 !important;
  font-size: 12px;
  border: 2px solid;
}
.gic-list .active {
  color: #50C37E;
  border-color: #50C37E;
}
.gic-list .pending {
  color: #bccf5b;
  border-color: #bccf5b;
}
.rc-table table td span.inactive {
  color: #ff3f5b;
  border-color: #ff3f5b;
}
.rc-table table td:first-child img {
  width: 35px;
}
.rc-table table td a {
  font-weight: 500;
  color: #3E6AB4;
}
.rc-table table td:last-child {
  white-space: nowrap;
}
.rc-table table td:last-child button {
  padding: 0;
  width: 30px;
  height: 30px;
}
.rc-table table td:last-child button img {
  width: 100%;
}
.debit2 {
  color: #ff0000;
  font-weight: 600;
}
.credit2 {
  color: #008000;
  font-weight: 600;
}
.nav-sidebar .nav-item .nav-link1 {
  font-size: 13px !important;
}
.table-head.table-head-new h6 {
  font-size: 15px !important;
  margin: 0 !important;
  width: auto !important;
  padding-right: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.travel-plan-table .ant-table-bordered .ant-table-thead > tr > th,
.travel-plan-table .ant-table-bordered .ant-table-tbody > tr > td {
  vertical-align: top;
}
.gic-manager .header-box {
  padding: 10px 20px 5px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #0f52ba;
}
.round-selector .ant-select-selection__rendered ul {
  padding: 0 10px;
}
.round-selector .ant-select-selection__rendered ul li {
  border-radius: 50px;
}
.feedback-button.selected {
  border: 2px solid #1890ff !important;
}
.tag-gic {
  color: rgba(0, 0, 0, 0.65);
  border-radius: 10px;
  width: 200px;
  display: flex;
  align-items: center;
}
.travel-carousel .slick-list {
  padding-left: 20px;
}
.travel-carousel .slick-dots {
  position: absolute;
  bottom: 0px !important;
}
.travel-carousel .slick-dots li {
  border-bottom: none;
  background: none !important;
}
.travel-carousel .slick-dots li button {
  background: #0872bc !important;
  height: 5px !important;
}
.travel-carousel .slick-arrow.slick-prev {
  font-size: 10px;
}
.travel-carousel .ant-carousel .slick-prev::before {
  content: '<';
  z-index: 1000 !important;
  display: block;
  position: relative;
  bottom: 10px;
  right: 0px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.travel-carousel .slick-arrow.slick-next {
  font-size: 10px;
}
.travel-carousel .ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 0px;
  left: 0;
  bottom: 10px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
.marketingUserBox2 .managerHead2 {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
}
.marketingUserBox2 .managerHead2 .universityFlag {
  font-size: 11px;
  font-weight: 600;
}
.marketingUserBox2 .managerHead2 .universityFlag img {
  height: 20px;
  width: auto;
  border-radius: 3px;
}
.marketingUserBox2 .travel-avator {
  border: 2px solid;
  padding-right: 0;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.text-black {
  color: #212529;
}
.ant-input-group-wrapper {
  padding: 0 !important;
}
.ant-input-group-wrapper .ant-input {
  height: 40px;
}
tr.warning_tr td {
  background-color: #fcf8e3 !important;
}
.feedbackButton.selected {
  /* Selected button styles */
  background-color: #f0f0f0 !important;
  color: #333;
  border: 1px solid #ccc;
}
.successTag2 {
  background: #c2cdd5;
  border: 1px solid #0872bc;
  padding: 1px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
}
.managerHead2 {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.universityWiseBlock {
  margin-top: 15px !important;
}
.flagBox {
  height: 35px;
}
.liveReportingOuter {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 30px;
}
.liveReportingOuter .liveReportingInnerBox {
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  font-size: 15px;
  text-align: center;
}
.liveReportingOuter .liveReportingInnerBox .reportingInnerBox {
  height: 100px;
}
.sopModalRow {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
.successMessage {
  font-size: 18px;
  /*color: #1556bc;*/
  color: black;
  margin-bottom: 20px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.viewButton,
.closeButton {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.viewButton {
  background-color: #2196F3;
  color: white;
}
.viewButton:hover {
  background-color: #1976D2;
}
.closeButton {
  background-color: #f44336;
  color: white;
}
.closeButton:hover {
  background-color: #d32f2f;
}
.status-list-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
}
.status-list-grid .status-item-grid {
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
  /*    &::after {
            content: "|";
            display: inline-block;
          }*/
}
.status-list-grid .status-item-grid .status-box {
  display: inline-block;
}
.status-list-grid .status-item-grid .status-box .status-name {
  font-size: 15px;
  color: #666;
}
.status-list-grid .status-item-grid .status-box .status-count {
  color: #0f52ba;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
}
.liveReportTarget {
  margin-top: 10px;
}
.target-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 13px;
  font-weight: 500;
}
.target-box .value-box {
  display: flex;
  justify-content: center;
}
.target-box img {
  height: 20px;
  margin-right: 5px;
}
.target-box span {
  font-size: 17px;
}
.custom-autocomplete .ant-select-search__field__wrap {
  width: 100%;
}
/* Larger screens */
@media (min-width: 1400px) {
  .antd-pro-common-canadaDirectUniCount {
    width: 32% !important;
    padding: 0 15px !important;
  }
  .antd-pro-common-card-pane-right.antd-pro-common-listing-section {
    padding: 30px 3rem 30px 4rem;
    display: flex;
    align-items: center;
  }
  .antd-pro-common-download-pdf-flag {
    width: 25px !important;
    height: 21px !important;
  }
  .antd-pro-common-download-pdf-text {
    font-weight: bold !important;
    font-size: 19px !important;
  }
  .antd-pro-common-card-pane-right.antd-pro-common-listing-section ul li p {
    word-spacing: normal;
  }
  .antd-pro-common-card-pane-right.antd-pro-common-listing-section ul li {
    width: 23.6% !important;
    display: inline-block;
  }
}
@media print {
  body {
    font-family: Arial, sans-serif;
    font-size: 12px;
  }
  .antd-pro-common-card-body {
    padding: 8px;
  }
  .antd-pro-common-download-pdf-flag {
    height: 20px;
    width: 25px;
    margin-right: 15px;
  }
  h5 {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
}

.ant-select.ant-fullcalendar-month-select {
    width: 15%;

}

.ant-select.ant-fullcalendar-year-select {
    width: 15%;
}

.ant-fullcalendar-header .ant-radio-group {
    display: none !important;
}

.ant-fullcalendar-column-header {
    padding: 10px !important;
    background-color: white;
}

.ant-descriptions {
    border: 2px solid #000;
    padding: 20px;
    border-radius: 8px;
}

.ant-descriptions-item-label {
    font-weight: bold;
    color: #333;
}

.ant-descriptions-item-content {
    color: #666;
}

.menu-label {
    padding: 6px !important;
    font-size: 13px !important;
}

.comment-text {
    flex: 1 1;
    background-color: #f0f0f0;
    border-radius: 35px;
    padding: 6px 20px 5px 20px;
    max-width: 85%;
}

.time-span {
    color: #0f52ba;
    font-size: 8px !important;
    font-style: italic;
}
.comment-text .mb5 {
    margin-bottom: 5px;
    font-size: 12px;
}
.comment-author .name {
    text-transform: capitalize;
    font-weight: 600;
}

.avatar_text1 {
    background-color: #0f52ba;
    margin: 0 12px;
    font-size: 17px !important;
}
.comment-section .sendBtn1 {
    border-radius: 15px !important;
    background-color: #0f52ba;
    border: none;
    color: white;
    padding: 14px !important;
}

.act-container {
    padding: 20px;
    border-bottom: 1px solid #ccc;
}

.act-card {
    background-color: #efefef;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.act-card-body {
    padding: 15px;
}
.act-list {
    list-style: none;
    padding: 26px;
}


.act-list-item {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    margin-bottom: 15px;
    display: flex;
}

.act-date {
    float: left;
    width: 100px;
    text-align: center;
    font-size: 14px;
    color: #333;
    border-right: 1px solid #ccc;
    padding-right: 15px;
    font-weight: bold;
}

.act-content {
    margin-left: 15px;
    flex: 1 1;
}

.act-label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 17px;
    color: #333;
}

.act-dot {
    color: blue;
    margin-right: 5px;
}

.act-info {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
}

.act-message {
    margin-left: 0;
    padding-left: 0;
    color: #000;
}

.act-message-item {
    font-size: 15px;
    line-height: 1.5;
}

.act-subject {
    margin-bottom: 10px;
    font-size: 15px;
    color: #000;
}



/* .react-calendar__tile--active {
    background: white !important;
    color: black !important;
} */

.event-card.booked {
    color: #0f52ba;
    border: 2px solid #0f52ba;
}

.event-card.available {
    color: #00cc00;
    border: 2px solid #00cc00;
}

.event-card.pending {
    color: red;
    border: 2px solid red;

}
.card-container-slot {
    margin-top: 20px;
}

.card-slot {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #dadada;
    margin-bottom: 20px;
}
.card-slot p, .card-slot h6{
  font-weight: bold;
    line-height: 0.8rem;
}
.card-slot h6{
    color: #0f52ba;
}

.time-container {
    border: 2px solid #0f52ba;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    margin-bottom: 10px;
    color: #0f52ba;
    font-weight: bold;
}

.time-container {
    line-height: 1.6rem !important;
}

.date-container {
    text-align: center;
    color: green;
    font-size: 12px;
    font-weight: normal !important;
}

.button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.start-meeting-btn {
    background-color: indianred;
    border-color: indianred;
    color: white;
}
/*---- event calendar ----*/
.calendar-container {
    display: flex;
    align-items: flex-start;
    margin: 54px 9px;
    border: 1px solid #fff;
    border-radius: 11px;
    padding: 26px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}




.event-cards {
    display: flex;
    flex-direction: column;
    margin-left: 90px;
    /* width: 50%; */
}

.event-card-container {
    display: flex;
    flex-wrap: wrap;
}

.event-card {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    margin: 4px;
    width: calc(50% - 10px);
    box-sizing: border-box !important;
}

.event-card h6 {
    margin: 0;
    text-align: center;
    font-weight: 700;
    /*color: #464545;*/
}
.event-card p {
    margin: 5px 0;
    text-align: center;
    font-weight: 600;
}
.selected-date {
    margin: 14px;
    margin-left: 30px;
    font-weight: 600;
    font-size: 17px;
    position: relative;
}

.selected-date::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 33%;
    height: 2px;
    background-color: #0f52ba;
}


.title-calendar {
    font-weight: 600;
    margin-left: 35px;
}

.react-calendar {

    background: white;
    border: none !important;
    font-family: Arial, Helvetica, sans-serif;
}

.event-card:hover {
    transform: scale(1.05);
}

.date-text {
    padding: 10px !important;
}


.custom-calendar {
    width: 510px;
    font-size: 26px;
    border: none;
}


.rc-calendar-today-btn,
.rc-calendar-ok-btn,
.rc-calendar-time-picker-btn,
.rc-calendar-input-wrap  {

    display: none;
}

.rc-calendar {
    position: relative;
    outline: none;
    font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", "WenQuanYi Micro Hei", sans-serif;
    list-style: none;
    font-size: 18px;
    text-align: left;
    background-clip: padding-box;
    border: none !important;
    line-height: 1.5;
    box-shadow: none !important;
}

.rc-calendar-header {
    padding: 10px;
    height: 56px;
    line-height: 30px;
    text-align: center;
    border-bottom: 1px solid #ccc;
}

.rc-calendar-date {
    display: block;
    margin: 7px auto;
    color: #666;
    /* border-radius: 4px 4px; */
    width: 26px;
    height: 26px;
    padding: 0px;
    background: transparent;
    line-height: 26px;
    text-align: center;
    font-size: 16px;
}

.rc-calendar-body {
    padding: 9px 10px 10px;
    height: auto;
}

.rc-calendar-year-select, .rc-calendar-month-select, .rc-calendar-day-select {
    display: inline-block;
    font-size: 17px;
    font-weight: bold;
    color: #666;
    padding: 0 8px;
    line-height: 34px;
}

.rc-calendar-today .rc-calendar-date {
    border : none;
}

.background-image {
    background-image: url(/static/media/counsellor.85a202c2.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 32%;
    padding-top: 10px;
}

.counselling-image {

    max-width: 44%;
    float: right;
    margin-top: -37px;
    margin-right: -88px;
}


.btn-saveslot {
    padding: 6px 20px !important;
    margin-left: 12px;
}
.status-form-container {
    padding: 30px;
}

.status-form-title {
    text-align: center;
    margin-bottom: 30px;
    color: #2c3e50;
    font-family: Arial, sans-serif;
}

.status-radio-group {
    display: flex;
    justify-content: space-between;
}

.status-radio-button {
    flex: 1 1;
    border-color: #25D366;
    /* color: white; */
    /* border: none; */
    border-radius: 10px;
}

.status-radio-button:hover, .status-radio-button:focus {
    border-color: transparent;
}

.status-textarea-container {
    margin-top: 40px;
}


.radio-icon {
    margin-right: 10px;
}

.calendar-container-manager {
    /*display: flex;*/
    /*align-items: flex-start;*/
    border-radius: 11px;
}


.event-cardsNew {
    display: flex;
    flex-direction: column;
    /* margin-left: 90px; */
    /* width: 50%; */
}

.filterCounselling {
    background-color: #f1f1f1 !important;
}

.btnCounselling{
   margin-left: 12px;
}

.clearCounselling {
    padding: 5px 30px !important;
    border-radius: 17px;
}

.status-radio-group {
    width: 100%;
}


@media (max-width: 768px) {
    .event-cards, .event-cardsNew {
        margin-left: 0; 
    }
}
.corner-stone-drawer {
    margin: 0 40px 20px;
}

.cornerstone-card-body {
    padding: 20px;
}

.cornerstone-student-data {
    background-color: #f0f0f0;
    border-radius: 30px;
    padding: 20px 25px;
    font-size: 15px;
}

.cornerstone-custom-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cornerstone-custom-field {
    flex: 1 1;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff;
}

.cornerstone-custom-field:not(:last-child) {
    margin-right: 10px;
}

.cornerstone-custom-field strong {
    font-weight: bold;
}

.cornerstone-custom-field span {
    font-weight: normal;
}

.vertical-border {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
}

.corner-button {
    padding: 15px 85px !important;
    border-bottom: 2px solid #0f52ba;
    font-weight: bold;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-circle-icon {
    position: absolute;
    bottom: 28px;
    right: 46px;
    font-size: 30px;
    color: rgb(15, 82, 186);
    background: white;
    border-radius: 30px;
}

.plus-circle-icon {
    font-size: 30px;
    color: rgb(15, 82, 186);
    background: white;
    border-radius: 30px;
}

.plus-circle-disabled {
    font-size: 30px;
    color: rgb(15, 82, 186);
    background: #f1f1f1;
    border-radius: 30px;
}

.footer-submit {
    text-align: center;
    background: forestgreen;
}

.radio1 {
    /*background: #90c99c;*/
    font-weight: normal;
    padding: 6px 20px;
    border-radius: 5px;
    margin-bottom: 5px;
    color: black;
    width: 150px;
}

.radio2 {
    /*background: #f6acac;*/
    padding: 6px 20px;
    border-radius: 5px;
    color: black;
    width: 150px;
}

.table-screenshots {
    font-size: 14px;
}

/* Add this CSS to your style.css file */

/* Table */
.table-screenshots {
    border-collapse: collapse;
    width: 100%;
}

/* Table Header */
.table-screenshots th {
    background-color: #f2f2f2;
    border: 1px solid #959595;
    padding: 8px;
    text-align: left;
}

/* Table Rows */
.table-screenshots td {
    border: 1px solid #959595;
    padding: 8px;
    text-align: left;
}

/* Add vertical line */
.table-screenshots td:not(:last-child) {
    border-right: 1px solid #959595;
}

/* Add line after every row */
.table-screenshots tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Add line after every row */
.table-screenshots tr:nth-child(odd) {
    background-color: #ffffff;
}

.enroll-table {
    margin-top: 20px !important;
}

.enroll-table td {
    border: 1px solid #959595;
    padding: 8px;
    text-align: left;
}

/* Add vertical line */
.enroll-table td:not(:last-child) {
    border-right: 1px solid #959595;
}

/* Add line after every row */
.enroll-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Add line after every row */
.enroll-table tr:nth-child(odd) {
    background-color: #ffffff;
}

.card-enroll-table {
    border-radius: 27px;
    border: 2px solid #818181;
    padding: 34px;
    margin-top: 40px;
}

.reupload-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.screenshot_img {
    height: 100px;
    max-width: 100%;
    border: 2px solid #808080;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #808080;
}

.custom-radioBtn {
    position: relative;
}

.custom-radioBtn input[type='radio'] {
    position: absolute;
    margin: 0;
    height: 25px;
    top: -5px;
    left: -10px;
}


.article-icons {
    display: flex;
    margin-top: 10px;
}

.article-icons > * {
    margin-right: 10px;
    font-size: 20px;
    color: #777;
}


.newspaper {
    /* font-family: 'Times New Roman', Times, serif; */
    margin: 5px 50px;
}


.newspaper-heading {
    font-size: 25px;
    text-align: center;
    margin: 10px auto;
}

.latest-update {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.article {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 20px;
    box-shadow: 0 0px 5px rgb(208 163 163 / 40%);
    background-color: #fafafa;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    border-radius: 10px;
}

.article:hover {
    transform: translateY(5px);
}

.article-image {
    width: 220px;
    height: 170px;
    object-fit: cover;
    /*margin: 0 10px;*/
    display: flex;
    align-self: flex-start;
    border-radius: 5px;
    opacity: 0.9;
}

.article-details {
    flex: 1 1;
}

.article-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #0f52ba;
    display: flex;
    align-items: center;
}

.article-title .anticon {
    color: #5cb85c;
}

.article-content {
    font-size: 12px;
    line-height: 1.6;
    color: black;
}

.article-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    color: #777;
    font-size: 12px;
}

.article-meta span {
    display: inline-block;
    margin-right: 10px;
}

.article-datetime {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.article-date,
.article-time {
    font-size: 14px;
    color: #0f52ba;
    display: flex;
    align-items: center;
}

.article-date img {
    height: 25px;
    margin-right: 5px;
}

.article-content {
    font-size: 14px;
    line-height: 1.6;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
}

.article:hover .article-content {
    opacity: 1;
}

.load-more-button {
    background-color: #0f52ba;
    color: white;
    border: none;
    padding: 5px 13px;
    border-radius: 5px;
    font-size: 13px;
}

.load-more-button:hover {
    background-color: #0056b3;
}

.update-counts {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.line-hr {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (max-width: 768px) {
    .newspaper {
        margin: 5px 20px;
    }

    .article {
        flex-direction: column;
        align-items: flex-start;
    }

    .article-image {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }

    .article-details {
        width: 100%;
    }

    .article-title {
        font-size: 18px;
    }

    .article-content {
        font-size: 12px;
    }
}

.header1 {
  font-size: 17px;
  text-decoration: underline;
  text-align: center;
  font-weight: 600;
}
.mt50 {
  margin-top: 50px !important;
}
.contractCard {
  padding: 20px 108px 20px 105px !important;
  background-color: white;
  font-size: 15px;
  line-height: 1.7;
}
.contractCard a {
  text-decoration: underline !important;
}
.contractCard p {
  margin-bottom: 9px !important;
  text-align: justify;
}
.contractCard b,
.contractCard strong {
  font-size: 16px;
}
.contractCard ol,
.contractCard ul {
  margin-left: 10px !important;
}
.contractCard ol li,
.contractCard ul li {
  padding: 10px 30px;
}
.contractCard ol {
  list-style: none !important;
}
.contractCard ul {
  list-style: none !important;
  padding-left: 10px;
}
.contractCard ul li {
  position: relative;
}
.contractCard ul li::before {
  content: '\2022';
  position: absolute;
  left: 0;
  font-weight: bolder;
}
.themeColor {
  color: #0872BC;
  font-weight: bold !important;
}
.footer-contract {
  display: flex;
  margin: 150px 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 0.9rem;
}
.footer-contract p {
  text-align: center !important;
}
.footer-contract p b {
  color: #0872BC;
}

.gray-color {
  color: #555;
}
.mr50 {
  margin-right: 50px;
}
.currency-list {
  color: #6666;
}
.currency-list .currency-card {
  border-radius: 10px;
}
.currency-list .currency-card .currency-name {
  font-weight: 600;
}
.currency-list .currency-card .currency-value {
  color: #0f52ba;
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 600;
}
.currency-list .currency-card .your-rate .rate {
  color: #1890ff;
  font-size: 20px;
  font-weight: 600;
}
.reeudo-slider .owl-theme .owl-nav {
  display: block !important;
  position: absolute;
  width: 100%;
  top: 25%;
}
.reeudo-slider .owl-theme .owl-nav .owl-prev {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #acacac;
  line-height: 0;
  font-weight: 500;
  color: white;
  left: -25px;
  cursor: pointer;
}
.reeudo-slider .owl-theme .owl-nav .owl-prev:hover {
  background: #1890ff;
}
.reeudo-slider .owl-theme .owl-nav .owl-next {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #acacac;
  line-height: 0;
  font-weight: 500;
  right: -25px;
  color: white;
  cursor: pointer;
}
.reeudo-slider .owl-theme .owl-nav .owl-next:hover {
  background: #1890ff;
}

.custom-exchange {
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    background-color: #f9f9f9;
    overflow: hidden;
}

.custom-exchange:hover {
    transform: translateY(-10px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.custom-exchange-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.custom-exchange-body {
    padding: 13px;
    text-align: center;
    min-height: 190px;
}

.custom-exchange-img {
    max-width: 50%;
    border-radius: 50%;
    border: 2px solid #e0e0e0;
    margin-bottom: 15px;
}

.custom-exchange-btn {
    background-color: #0f52ba;
    border: none;
    border-radius: 25px;
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

.custom-exchange-btn:hover {
    background-color: #0f52ba;
}

.custom-exchange-link {
    color: #0f52ba;
    font-size: 12px;
    text-decoration: none;
}

.custom-exchange-link:hover {
    text-decoration: underline;
}


.order-summary-card {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
}

.doc-label {
    font-size: 12px;
    font-weight: bold;
}

.status-information-card {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.status-item {
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    text-align: center;
}


.header-quote .quotation-date p {
    font-weight: bold;
}

.description-section table tr td:first-child {
    font-weight: bold;
}

.description-section table {
    max-width: 65%;
    font-size: 13px;
}

.description-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-quote img {
    max-width: 22%;
}

.quotation-date {
    float: right;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-header-dropdown-index-container > * {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
@media screen and (max-width: 480px) {
  .antd-pro-components-header-dropdown-index-container {
    width: 100% !important;
  }
  .antd-pro-components-header-dropdown-index-container > * {
    border-radius: 0 !important;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-notice-list-list {
  max-height: 400px;
  overflow: auto;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item {
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-meta {
  width: 100%;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-avatar {
  background: #fff;
  margin-top: 4px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-iconElement {
  font-size: 32px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item.antd-pro-components-notice-icon-notice-list-read {
  opacity: 0.4;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:last-child {
  border-bottom: 0;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:hover {
  background: #f0fff6;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-title {
  font-weight: normal;
  margin-bottom: 5px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-description {
  font-size: 13px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-datetime {
  font-size: 12px;
  margin-top: 4px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-extra {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  margin-right: 0;
  margin-top: -1.5px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-appInfo {
  display: flex;
  justify-content: space-between;
  color: #808080;
  font-weight: 500;
}
.antd-pro-components-notice-icon-notice-list-notFound {
  text-align: center;
  padding: 73px 0 88px 0;
  color: rgba(0, 0, 0, 0.45);
}
.antd-pro-components-notice-icon-notice-list-notFound img {
  display: inline-block;
  margin-bottom: 16px;
  height: 76px;
}
.antd-pro-components-notice-icon-notice-list-clear {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e8e8e8;
  transition: all 0.3s;
  cursor: pointer;
}
.antd-pro-components-notice-icon-notice-list-clear:hover {
  color: rgba(0, 0, 0, 0.85);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-index-popover {
  width: 336px;
}
.antd-pro-components-notice-icon-index-noticeButton {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
}
.antd-pro-components-notice-icon-index-icon {
  padding: 4px;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: center;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-bar {
  margin-bottom: 4px;
}
.privateMessageBox .ant-card-body {
  padding: 0;
}
.privateMessageBox .ant-card-head {
  background: white;
  min-height: auto;
  color: #0700d9;
  border-bottom: 2px solid #53a0e4;
  height: 55px;
}
.antd-pro-components-notice-icon-index-popover {
  width: 450px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-private-message-private-message-list {
  max-height: 400px;
  overflow: auto;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item {
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  /*padding-left: 24px;
    padding-right: 24px;*/
  padding: 15px 24px;
  border-bottom: 1px solid #e8e8e87a;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item .antd-pro-components-private-message-private-message-meta {
  width: 100%;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item .antd-pro-components-private-message-private-message-avatar {
  margin-top: 4px;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item .antd-pro-components-private-message-private-message-iconElement {
  font-size: 22px;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item.antd-pro-components-private-message-private-message-read {
  opacity: 0.4;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item:last-child {
  border-bottom: 0;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item:hover {
  background: #f0fff6;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item .antd-pro-components-private-message-private-message-title {
  font-weight: normal;
  margin-bottom: 5px;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item .antd-pro-components-private-message-private-message-flexRow {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-bottom: 0;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item .antd-pro-components-private-message-private-message-description {
  font-size: 14px;
  line-height: 1.7;
  color: #333;
  text-decoration: none;
  display: block;
  position: relative;
  padding: 0 6px 0;
  text-transform: capitalize;
  font-weight: 500;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item .antd-pro-components-private-message-private-message-datetime {
  font-size: 12px;
  margin-top: 4px;
  line-height: 1.5;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item .antd-pro-components-private-message-private-message-extra {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  margin-right: 0;
  margin-top: -1.5px;
}
.antd-pro-components-private-message-private-message-list .antd-pro-components-private-message-private-message-item .antd-pro-components-private-message-private-message-appInfo {
  display: flex;
  justify-content: space-between;
  color: #808080;
  font-weight: 500;
}
.antd-pro-components-private-message-private-message-notFound {
  text-align: center;
  padding: 73px 0 88px 0;
  color: rgba(0, 0, 0, 0.45);
}
.antd-pro-components-private-message-private-message-notFound img {
  display: inline-block;
  margin-bottom: 16px;
  height: 76px;
}
.antd-pro-components-private-message-private-message-clear {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e8e8e8;
  transition: all 0.3s;
  cursor: pointer;
}
.antd-pro-components-private-message-private-message-clear:hover {
  color: rgba(0, 0, 0, 0.85);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-private-message-index-popover {
  width: 336px;
}
.antd-pro-components-private-message-index-noticeButton {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
}
.antd-pro-components-private-message-index-icon {
  padding: 4px;
}
.antd-pro-components-private-message-index-tabs .ant-tabs-nav-scroll {
  text-align: center;
}
.antd-pro-components-private-message-index-tabs .ant-tabs-bar {
  margin-bottom: 4px;
}
.privateMessageBox .ant-card-body {
  padding: 0;
}
.privateMessageBox .ant-card-head {
  background: white;
  min-height: auto;
  color: #0700d9;
  border-bottom: 2px solid #53a0e4;
  height: 55px;
}
.antd-pro-components-private-message-index-popover {
  width: 450px;
}

.antd-pro-components-promotion-icon-styles-announcementIcon {
  height: 27px;
  width: 27px;
  margin-bottom: 6px;
  cursor: pointer;
}

.antd-pro-components-latest-update-icon-styles-announcementIcon {
  height: 27px;
  width: 27px;
  margin-bottom: 6px;
  cursor: pointer;
  margin-top: 6px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-sider-menu-index-logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  transition: all 0.3s;
  background: #0872BC;
  overflow: hidden;
}
.antd-pro-components-sider-menu-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.antd-pro-components-sider-menu-index-logo h1 {
  color: black;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.antd-pro-components-sider-menu-index-sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar {
  position: fixed;
  top: 0;
  left: 0;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar .ant-menu-root {
  overflow-y: auto;
  height: calc(100vh - 64px);
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  background-color: white;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo {
  background: white;
  box-shadow: 1px 1px 0 0 #e8e8e8;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo h1 {
  color: #36c889;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .ant-menu-light {
  border-right-color: transparent;
}
.antd-pro-components-sider-menu-index-icon {
  width: 14px;
  margin-right: 10px;
}
.antd-pro-components-sider-menu-index-newLink {
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background: #0f52ba;
  font-weight: 600;
  position: relative;
  top: -3px;
  left: 0;
  margin-left: 5px;
  font-style: normal;
  animation: antd-pro-components-sider-menu-index-glow 0.5s ease-in-out infinite alternate;
  text-transform: uppercase;
}
.top-nav-menu li.ant-menu-item {
  height: 64px;
  line-height: 64px;
}
.drawer .drawer-content {
  background: red;
}
.ant-menu-inline-collapsed > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-item .sider-menu-item-img + span,
.ant-menu-submenu-title .sider-menu-item-img + span {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-top-nav-header-index-head {
  width: 100%;
  transition: background 0.3s, width 0.2s;
  height: 64px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal {
  line-height: 64px;
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title {
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head.antd-pro-components-top-nav-header-index-light {
  background-color: #fff;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main {
  display: flex;
  height: 64px;
  padding-left: 24px;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main.antd-pro-components-top-nav-header-index-wide {
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-left {
  flex: 1 1;
  display: flex;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-right {
  width: 324px;
}
.antd-pro-components-top-nav-header-index-logo {
  width: 165px;
  height: 64px;
  position: relative;
  line-height: 64px;
  transition: all 0.3s;
  overflow: hidden;
}
.antd-pro-components-top-nav-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.antd-pro-components-top-nav-header-index-logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-weight: 400;
}
.antd-pro-components-top-nav-header-index-light h1 {
  color: #0872BC;
}
.antd-pro-components-top-nav-header-index-menu {
  border: none;
  height: 64px;
  line-height: 64px;
}

.antd-pro-layouts-header-fixedHeader {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  transition: width 0.2s;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-layouts-basic-layout-content {
  margin: 24px;
  padding-top: 64px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-containers-login-login-main {
  max-width: 450px;
  margin: 0 auto;
}
@media screen and (max-width: 576px) {
  .antd-pro-containers-login-login-main {
    width: 95%;
  }
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-icon:hover {
  color: #36c889;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-other .antd-pro-containers-login-login-register {
  float: right;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-forgot {
  float: right;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-button {
  width: 100%;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-otpInfo {
  font-size: 16px;
  line-height: 1.5em;
  text-align: center;
  margin-bottom: 20px;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-antCodeBox {
  font-size: 15px;
  line-height: 1.5em;
  text-align: center;
  margin-bottom: 20px;
}
.antd-pro-containers-login-login-webInfo {
  font-size: 22px;
  color: black;
  line-height: 1.5em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.antd-pro-containers-login-login-webInfo small {
  font-size: 13px;
  color: grey;
}

.agent_form {
    background-color: white;
}

.agent_form .right_bg {
    height: auto;
    background-position: 35%;
}
.img-right{
    width: 100%;
    height: auto;
     vertical-align: middle;
}

.agent_form .form-container {
    padding: 2vw 4vw 2vw 4vw;
}

.align-center {
    text-align: center;
}

.agent-name {
    font-size: 37px;
    text-transform: none;
    line-height: 50px;
    margin-top: 10px;
    margin-bottom: 25px;
}

.form-control {
    height: 50px;
    border-radius: 0;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.section-card {
    padding: 40px 0;
    overflow: hidden;
    border: 1px solid lightgray;
    border-radius: 5px;
    
}

.info_box h3 {
    font-size: 20px;
    font-weight: 400 !important;
    line-height: 1.9;
    color: #52565e;
}
.product-teaser {
    position: relative;
    overflow: hidden;
}

.event-teaser {
    position: relative;
    display: flex;
    margin-bottom: 30px;
    padding: 30px 25px;
    background-color: #f6f6f6;
    border-radius: 10px;
}

.event-teaser .event-image .field-field-image {
    border-radius: 70%;
    position: relative;
    overflow: hidden;
}

.event-image-webinar {
    width: 100px;
    height: 100px;
    vertical-align: middle;
}
.text-webinar{
    text-align: center;
    margin-top: 10px;
}

.event-teaser .event-content-wrap {
    position: relative;
    width: 75%;
    margin-left: 50px;
}

.event-teaser .event-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    padding-bottom: 15px;
    margin-bottom: 15px;
    padding-right: 100px;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

a, a:hover, a:focus, a:visited {
    text-decoration: none;
    outline: none;
}

a {
    color: #333333;
}

.event-teaser .event-price {
    position: absolute;
    right: 0;
    top: 0;
    color: #0f52ba;
    font-size: 24px;
    font-weight: 500;
}

.event-teaser .event-meta {
    display: flex;
}

.event-teaser .event-date-wrap {
    width: 30%;
    flex: 1 1;
}

.event-teaser .event-time {
    width: 25%;
    flex: 1 1;
}

.event-teaser .event-venue-wrap {
    flex: 2 1;
}

.event-teaser .event-date span {
    display: block;
    line-height: 1.2;
}

.event-teaser .event-date-day {
    font-size: 25px;
    font-weight: 300;
    float: left;
    line-height: 1;
    margin-right: 7px;
    color: #0f52ba;
}

.event-teaser .event-date-month {
    text-transform: uppercase;
}

.event-teaser .event-venue-wrap, .event-teaser .event-date-wrap, .event-teaser .event-time {
    width: 100%;
}


.event-teaser .event-time, .event-teaser .event-venue-wrap {
    padding-top: 5px;
}

.event-teaser .event-venue, .event-teaser .event-location {
    display: inline-block;
}

.event-teaser .event-button {
    position: relative;
}

.button {
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.1;
    margin: 10px 0;
    outline: 0 none;
    padding: 10px 20px;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    position: relative;
    white-space: nowrap;
    transition: 0.3s;
    background-color: #0f52ba;
    overflow: hidden;
    margin-top: 53px;
    margin-left: 20px;
}
.info_box  p {
    font-size: 18px !important;
    line-height: 20px;
    padding-top: 16px;
    font-weight: 300 !important;
}


/* student events styles */
.gap1-62 {
    padding: 16px !important;
}
.text-webinar1 {
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
}

.event-teaser .event-date-day1 {
    font-size: 12px;
    font-weight: 400;
    float: left;
    line-height: 1;
    margin-right: 7px;
    color: #0f52ba;
}
.event-cityname {
    font-size: 11px;
    font-weight: normal;
}


.event-teaser .event-date1 span {
    display: block;
    line-height: 1.2;
    /* background: #e8f4f8; */
    border-radius: 5px;
    height: 120px;
    width: 160px;
    text-align: center;
    padding: 40px 20px;
    color: black;
    font-weight: 500;
    font-size: 15px;
}


.event-date-year1 {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 1px;
}

.event-date-time1 {
    font-weight: 700;
    font-size: 15px;
    margin-top: 4px;
}
.event-teaser1 {
    padding: 8px 8px 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 5px;
    background: white;
    max-width: 100%;
}

.event-teaser .event-title1 {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.2;
    padding-bottom: 3px;
    margin-bottom: 1px;
    padding-right: 100px;
    border-bottom: 1px solid #eaeaea;
    position: relative;
}

.event-teaser .event-title1.fontLg {
    font-size: 18px;
}

.button1 {
    padding: 6px 14px;
    font-size: 11px;
    margin: 0px !important;
}
.event-teaser .event-content-wrap1 {
    position: relative;
    width: 100%;
    margin-left: 1px;
}
.applyButton{
    font-size: 12px;
    margin-top: 30px;
    color: white;
    background: #0f52ba;
    /* margin: 20px; */
    min-height: 31px;
}
.disableApply {
    font-size: 12px;
    margin-top: 30px;
    color: #0f52ba;
    /* border: 1px solid #0f52ba; */
    padding: 5px;
    /*border-radius: 5px;*/
    background-color: #f0faff;
}

.event-teaser .event-venue-wrap1 {
    flex: 2 1;
    margin-left: 10px;
    margin-right: 20px;
}
.event-teaser .event-content-wrap1 {
    position: relative;
    width: 100%;
    margin-left: 1px;
}

.nonActive-event {
    color: red;
    font-size: 11px;
}

.active-event {
    color: #00b600;
    font-size: 11px;

}

.loadMore {
    color: white;
    background: #0f52ba;
    font-weight: 500;
    border: none;
}

.install-wrapper {
    display: flex;
    align-items: center;
    text-align: left;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
    padding: 19px 20px 20px 10px;
    transition: border 0.3s;
}

.install-wrapper.selected {
    border: 2px solid #0f52ba;
}


/* Add border-radius to the Booking summary div */
.detailsForm {
    border-radius: 20px;
    overflow: hidden; /* Ensure the border-radius is applied properly */
}

.booking-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid rgb(204, 204, 204);

    height: 700px;
}

.booking-period {
    margin: 20px 10px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid rgb(204, 204, 204);

}

.guarantor {
    display: inline-block;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin: 8px;
}

.guarantor:hover {
    background-color: #ddd;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input[type="radio"] {
    vertical-align: middle;
    margin-right: 5px; /* Adjust the margin as needed */
}

input[type='radio'], input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
    margin: -41px;
}


@media only screen and (max-width: 767px) {
    .booking-card {
        height: 800px;
        margin-bottom: 20px;
    }
    .booking-period {
        border-radius: 10px;
        border: 1px solid rgb(204, 204, 204);

    }
}

.afs {
  align-items: flex-start !important;
}
.flex-d-columns {
  flex-direction: column;
}
.hr1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

