@import "../../../baseColors";

:global {
  .gray-color {
    color: #555;
  }

  .mr50 {
    margin-right: 50px;
  }

  .currency-list {
    color: @textColor;

    .currency-card {
      border-radius: 10px;

      .currency-name {
        font-weight: 600;
      }

      .currency-value {
        color: @darkPrimary;
        font-size: 20px;
        margin-bottom: 15px;
        font-weight: 600;
      }

      .your-rate {
        .rate {
          color: @primary;
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }

  .reeudo-slider {
    .owl-theme {
      .owl-nav {
        display: block !important;
        position: absolute;
        width: 100%;
        top: 25%;

        .owl-prev {
          height: 35px;
          width: 35px;
          border-radius: 50%;
          font-size: 35px;
          position: absolute;
          top: 31%;
          background: @grey;
          line-height: 0;
          font-weight: 500;
          color: white;
          left: -25px;
          cursor: pointer;

          &:hover {
            background: @primary;
          }
        }

        .owl-next {
          height: 35px;
          width: 35px;
          border-radius: 50%;
          font-size: 35px;
          position: absolute;
          top: 31%;
          background: @grey;
          line-height: 0;
          font-weight: 500;
          right: -25px;
          color: white;
          cursor: pointer;

          &:hover {
            background: @primary;
          }
        }
      }
    }
  }

}
@primary-color: #36c889;