.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  overflow: hidden;
}

.card-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.card-content {
  text-align: center;
  font-size: 14px;
  transition: transform 0.3s ease-in-out;
}

/*.card-container:hover .card-content {
  transform: scale(1.01);
  height: 330px;
}*/

.icon-plus {
  float: right;
  font-size: 20px;
  border: 1px solid white;
  padding: 4px;
  margin-right: -71px;
  transition: font-size 0.3s ease-in-out;
}

.icon-plus:hover {
  font-size: 22px;
  color: white;
}

.custom-panel:hover,
.custom-panel:active,
.custom-panel:focus {
  background: linear-gradient(to right, #87ceeb, #0f52ba);
}

.custom-panel:hover > .ant-collapse-header,
.custom-panel:active > .ant-collapse-header,
.custom-panel:focus > .ant-collapse-header {
  color: white;
}

.collapsible {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.button-part,
.button-part1,
.button-part2,
.button-part3 {
  background-color: rgb(142, 16, 41);
  color: white;
  width: 100%;
  border: none;
  min-height: 37px;
  transition: transform 0.3s ease;
}

.button-part:hover,
.button-part1:hover,
.button-part2:hover,
.button-part3:hover {
  transform: scale(1.1);
  background-color: rgb(142, 16, 41);
  color: white;
}

.button-part1 {
  background-color: #0f52ba;
}

.button-part1:hover {
  background-color: #0f52ba;
  color: white;
}

.button-part2 {
  background-color: rgb(4, 147, 4);
}

.button-part2:hover {
  background-color: rgb(4, 147, 4);
  color: white;
}

.button-part3 {
  background-color: purple;
}

.button-part3:hover {
  background-color: purple;
  color: white;
}


.profile-area {
  padding: 10px;
}

.profile-img-container {
  text-align: center;
}

.profile-img {
  border: 2px solid #0f52ba;
  border-radius: 50%;
  font-weight: 400;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.head-text {
  color: #0f52ba;
  margin-bottom: 15px;
}

.profile-text {
  color: gray;
  font-size: 14px;
  margin-bottom: 5px;
}

.profile-text a {
  text-decoration: underline;
  font-size: 12px;
  color: black;
}

.profile-text i {
  margin-right: 5px;
  margin-left: -6px;
}

.upcoming-events-title {
  color: #0f52ba;
  margin-left: 10px;
}

/*.add-event-btn {
  margin-top: 1rem;
  border: 2px solid white;
  padding: 6px;
  margin-left: 240px;
}*/

.past-events-title {
  color: #0f52ba;
  margin-left: 8px;
}

.past-events-row {
  margin-top: 30px;
}

.card-content {
  color: white;
}

.table-container {
  overflow-x: auto;
  border: 1px solid #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 100%;
}

.custom-icon {
  font-size: medium;
  color: gray;
  transition: transform 0.3s ease-in-out;
  background: white;
  border: 1px solid grey;
  border-radius: 3px;
  padding: 5px;
  margin-right: 5px;
}

.custom-icon:hover {
  transform: scale(1.2)
}

.custom-cell {
  background: gray;
  color: white;
  border-radius: 2px;
  padding: 5px;
  text-align: center;
}

.record-type {
  float: right;
  padding: 0px 3px;
}

.record-type.agent {
  background-color: rgb(142, 16, 41);
}

.record-type.educator {
  background-color: #0f52ba;
}

.record-type.university {
  background-color: rgb(4, 147, 4);

}

.recordEmail {
  color: blue;
}

.custom-form {
  margin: 20px;
  padding: 20px;
}


/* add-followup.css */

.form-container-follow {
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.form-item-follow {
  margin-bottom: 20px;
}

.button-row-follow {
  margin-top: 20px;
}

.save-button-follow {
  margin-right: 10px;
}

.ant-select-combobox .ant-select-search__field__wrap {
  width: 200%;
  height: 100%;
}

.drawer-content {
  margin-top: 5rem;
}

.agent-details {
  background: linear-gradient(to right, #87ceeb, #0f52ba);
  color: white;
  padding: 10px;
  border-radius: 10px;
}

.agent-details h6 {
  margin: 0;
}

.event-details {
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  border-radius: 7px;
}

.all-details {
  padding: 20px;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid #fbf7f7;
}

.event-details-left {
  margin-left: 10px;
}

.event-details h6 {
  margin: 0;
}

.event-details p {
  margin: 0;
}

.agent-details h6, .history-div h6 {
  font-size: 14px;
}

.agent-name1, .history-div h6 {
  background-color: #0f52ba;
  color: white;
  padding: 4px;
  border-radius: 6px;
  text-align: center;
}

.history-div h6 {
  padding: 10px;
}

.agent-details span, .event-details-left span {
  float: right;
}

.event-details-left span {
  color: gray;
  font-size: 13px;
}

.avatar_text1 {
  background-color: #0f52ba;
  margin: 0 12px;
  font-size: 16px !important;
  /* border: 2px solid white; */
}

.sendBtn1 {
  border-radius: 15px !important;
  background-color: #0f52ba;
  border: none;
  color: white;
  padding: 14px !important;
}

.comment-author .date1 {
    /* color: #0f52ba; */
    font-size: 10px;
    font-style: italic;
    /* float: right; */
    /* font-weight: bold; */
    padding-left: 8px;
    color: #0f52ba;
    font-weight: 600;
}

.input-comment1 {
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 10px;
  height: 50px !important;
  /* border-radius: 20px; */
  width: 80%;
  border: none;
  background: #efeded;
}

.meeting-details-table {
  width: 100%;
}

.label-column {
  width: 150px;
  font-weight: bold;
}

.agent-details h6 {
  margin: 0;
  font-size: 16px;
  color: #fff;
}

.all-details {
  padding: 20px;
}

.meeting-details-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.meeting-details-table td:first-child {
  font-weight: bold;
}

.meeting-details-table tr:last-child td {
  border-bottom: none;
}


.meeting-panel {
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
}

.meeting-panel .meeting-details {
  padding: 10px;
}

.comment-panel {
  margin-top: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  overflow: hidden;
}

.comment-wrap {
  max-height: 400px;
  overflow-y: auto;
}

.comment-wrap-new {
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}

.comment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.comment-text {
  margin-left: 10px;
}

.comment-text .mb5 {
  margin-bottom: 5px;
}

.time-span {
  color: #888;
  font-size: 12px;
}


.translucent-bg {
  background-color: rgba(255, 255, 255, 0.5);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.card-event {
  border-radius: 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  overflow: hidden;
}

.card-content1 {
  /* text-align: center; */
  /* font-size: 16px; */
  color: white;
  transition: transform 0.3s ease-in-out;
  margin-top: 127px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.event-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.description {
  margin-bottom: 10px;
}

.show-more {
  color: orange;
  cursor: pointer;
}

.details-section {
  margin-top: 20px;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.detail-item .detail-label {
  margin-right: 10px;
  min-width: 80px;
  display: inline-block;
  font-weight: bold;
}

.details-header-info {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 17px;
}

.col-with-line {
  border-right: 1px solid #ccc;
  padding-right: 20px;
}

.commentText1 {
  line-height: 13px;

}
