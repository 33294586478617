
.article-icons {
    display: flex;
    margin-top: 10px;
}

.article-icons > * {
    margin-right: 10px;
    font-size: 20px;
    color: #777;
}


.newspaper {
    /* font-family: 'Times New Roman', Times, serif; */
    margin: 5px 50px;
}


.newspaper-heading {
    font-size: 25px;
    text-align: center;
    margin: 10px auto;
}

.latest-update {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.article {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 20px;
    box-shadow: 0 0px 5px rgb(208 163 163 / 40%);
    background-color: #fafafa;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    border-radius: 10px;
}

.article:hover {
    transform: translateY(5px);
}

.article-image {
    width: 220px;
    height: 170px;
    object-fit: cover;
    /*margin: 0 10px;*/
    display: flex;
    align-self: flex-start;
    border-radius: 5px;
    opacity: 0.9;
}

.article-details {
    flex: 1;
}

.article-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #0f52ba;
    display: flex;
    align-items: center;
}

.article-title .anticon {
    color: #5cb85c;
}

.article-content {
    font-size: 12px;
    line-height: 1.6;
    color: black;
}

.article-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    color: #777;
    font-size: 12px;
}

.article-meta span {
    display: inline-block;
    margin-right: 10px;
}

.article-datetime {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.article-date,
.article-time {
    font-size: 14px;
    color: #0f52ba;
    display: flex;
    align-items: center;
}

.article-date img {
    height: 25px;
    margin-right: 5px;
}

.article-content {
    font-size: 14px;
    line-height: 1.6;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
}

.article:hover .article-content {
    opacity: 1;
}

.load-more-button {
    background-color: #0f52ba;
    color: white;
    border: none;
    padding: 5px 13px;
    border-radius: 5px;
    font-size: 13px;
}

.load-more-button:hover {
    background-color: #0056b3;
}

.update-counts {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.line-hr {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media (max-width: 768px) {
    .newspaper {
        margin: 5px 20px;
    }

    .article {
        flex-direction: column;
        align-items: flex-start;
    }

    .article-image {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }

    .article-details {
        width: 100%;
    }

    .article-title {
        font-size: 18px;
    }

    .article-content {
        font-size: 12px;
    }
}
