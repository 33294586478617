.card-container-slot {
    margin-top: 20px;
}

.card-slot {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #dadada;
    margin-bottom: 20px;
}
.card-slot p, .card-slot h6{
  font-weight: bold;
    line-height: 0.8rem;
}
.card-slot h6{
    color: #0f52ba;
}

.time-container {
    border: 2px solid #0f52ba;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
    margin-bottom: 10px;
    color: #0f52ba;
    font-weight: bold;
}

.time-container {
    line-height: 1.6rem !important;
}

.date-container {
    text-align: center;
    color: green;
    font-size: 12px;
    font-weight: normal !important;
}

.button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
}

.start-meeting-btn {
    background-color: indianred;
    border-color: indianred;
    color: white;
}
/*---- event calendar ----*/
.calendar-container {
    display: flex;
    align-items: flex-start;
    margin: 54px 9px;
    border: 1px solid #fff;
    border-radius: 11px;
    padding: 26px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}




.event-cards {
    display: flex;
    flex-direction: column;
    margin-left: 90px;
    /* width: 50%; */
}

.event-card-container {
    display: flex;
    flex-wrap: wrap;
}

.event-card {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    margin: 4px;
    width: calc(50% - 10px);
    box-sizing: border-box !important;
}

.event-card h6 {
    margin: 0;
    text-align: center;
    font-weight: 700;
    /*color: #464545;*/
}
.event-card p {
    margin: 5px 0;
    text-align: center;
    font-weight: 600;
}
.selected-date {
    margin: 14px;
    margin-left: 30px;
    font-weight: 600;
    font-size: 17px;
    position: relative;
}

.selected-date::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 33%;
    height: 2px;
    background-color: #0f52ba;
}


.title-calendar {
    font-weight: 600;
    margin-left: 35px;
}

.react-calendar {

    background: white;
    border: none !important;
    font-family: Arial, Helvetica, sans-serif;
}

.event-card:hover {
    transform: scale(1.05);
}

.date-text {
    padding: 10px !important;
}


.custom-calendar {
    width: 510px;
    font-size: 26px;
    border: none;
}


.rc-calendar-today-btn,
.rc-calendar-ok-btn,
.rc-calendar-time-picker-btn,
.rc-calendar-input-wrap  {

    display: none;
}

.rc-calendar {
    position: relative;
    outline: none;
    font-family: Arial, "Hiragino Sans GB", "Microsoft Yahei", "Microsoft Sans Serif", "WenQuanYi Micro Hei", sans-serif;
    list-style: none;
    font-size: 18px;
    text-align: left;
    background-clip: padding-box;
    border: none !important;
    line-height: 1.5;
    box-shadow: none !important;
}

.rc-calendar-header {
    padding: 10px;
    height: 56px;
    line-height: 30px;
    text-align: center;
    border-bottom: 1px solid #ccc;
}

.rc-calendar-date {
    display: block;
    margin: 7px auto;
    color: #666;
    /* border-radius: 4px 4px; */
    width: 26px;
    height: 26px;
    padding: 0px;
    background: transparent;
    line-height: 26px;
    text-align: center;
    font-size: 16px;
}

.rc-calendar-body {
    padding: 9px 10px 10px;
    height: auto;
}

.rc-calendar-year-select, .rc-calendar-month-select, .rc-calendar-day-select {
    display: inline-block;
    font-size: 17px;
    font-weight: bold;
    color: #666;
    padding: 0 8px;
    line-height: 34px;
}

.rc-calendar-today .rc-calendar-date {
    border : none;
}

.background-image {
    background-image: url('../../assets/counsellor.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 32%;
    padding-top: 10px;
}

.counselling-image {

    max-width: 44%;
    float: right;
    margin-top: -37px;
    margin-right: -88px;
}


.btn-saveslot {
    padding: 6px 20px !important;
    margin-left: 12px;
}
.status-form-container {
    padding: 30px;
}

.status-form-title {
    text-align: center;
    margin-bottom: 30px;
    color: #2c3e50;
    font-family: Arial, sans-serif;
}

.status-radio-group {
    display: flex;
    justify-content: space-between;
}

.status-radio-button {
    flex: 1;
    border-color: #25D366;
    /* color: white; */
    /* border: none; */
    border-radius: 10px;
}

.status-radio-button:hover, .status-radio-button:focus {
    border-color: transparent;
}

.status-textarea-container {
    margin-top: 40px;
}


.radio-icon {
    margin-right: 10px;
}

.calendar-container-manager {
    /*display: flex;*/
    /*align-items: flex-start;*/
    border-radius: 11px;
}


.event-cardsNew {
    display: flex;
    flex-direction: column;
    /* margin-left: 90px; */
    /* width: 50%; */
}

.filterCounselling {
    background-color: #f1f1f1 !important;
}

.btnCounselling{
   margin-left: 12px;
}

.clearCounselling {
    padding: 5px 30px !important;
    border-radius: 17px;
}

.status-radio-group {
    width: 100%;
}


@media (max-width: 768px) {
    .event-cards, .event-cardsNew {
        margin-left: 0; 
    }
}