.filter_row {
  display: flex;
  justify-content: space-between;
}

.single_column {
  margin-right: 5px !important;
}

.table {
  tr {
    td {
      width: 15%;

      &:nth-child(1) {
        width: 5% !important;
        text-align: center;
      }

      &:nth-child(2) {
        width: 30%;
      }
    }

    th {
      font-weight: 600;
    }

    &.bgRow {
      background-color: #d9eafd7a;

      td {
        padding-top: 10px !important;
      }
    }
  }

}

.loader {
  //height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  //background-color: #ffffffdb;
  background-color: #ffffff;
  z-index: 1;
  height: 80vh;
  opacity: 0.7;
}

.hideHead {
  thead {
    display: none !important;
  }
}

@primary-color: #36c889;