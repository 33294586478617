:global {
  .header1 {
    font-size: 17px;
    text-decoration: underline;
    text-align: center;
    font-weight: 600;
  }

  .mt50 {
    margin-top: 50px !important;
  }

  .contractCard {
    padding: 20px 108px 20px 105px !important;
    background-color: white;
    font-size: 15px;
    line-height: 1.7;


    a {
        text-decoration: underline !important;
      }

       p {
        margin-bottom: 9px !important;
        text-align: justify;
    }
      b , strong {
        font-size: 16px;
      }

    ol, ul {
        margin-left: 10px !important;

      li {

        padding: 10px 30px;
        }
      }

    ol {
        list-style: none !important;
      }

    ul {
        list-style: none !important;
        padding-left: 10px;
      }

    ul li {
        position: relative;
      }

    ul li::before {
      content: '\2022';
      position: absolute;
      left: 0;
      font-weight: bolder;
    }

  }

  // .terms-list {
  //     ul {
  //         list-style-type: none !important;
  //         padding-left: 20px;

  //         li {
  //           margin-bottom: 10px;
  //           list-style-type: circle;
  //         }
  //       }

  // }
  .themeColor {
    color: #0872BC;
    font-weight: bold !important;
  }

  .footer-contract {
        display: flex;
        margin: 150px 0px;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 0.9rem;

        p {
          text-align: center !important;

          b {
            color:#0872BC;
          }
        }
    }
}

@primary-color: #36c889;