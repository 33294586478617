.corner-stone-drawer {
    margin: 0 40px 20px;
}

.cornerstone-card-body {
    padding: 20px;
}

.cornerstone-student-data {
    background-color: #f0f0f0;
    border-radius: 30px;
    padding: 20px 25px;
    font-size: 15px;
}

.cornerstone-custom-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cornerstone-custom-field {
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    background-color: #ffffff;
}

.cornerstone-custom-field:not(:last-child) {
    margin-right: 10px;
}

.cornerstone-custom-field strong {
    font-weight: bold;
}

.cornerstone-custom-field span {
    font-weight: normal;
}

.vertical-border {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
}

.corner-button {
    padding: 15px 85px !important;
    border-bottom: 2px solid #0f52ba;
    font-weight: bold;
    font-size: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-circle-icon {
    position: absolute;
    bottom: 28px;
    right: 46px;
    font-size: 30px;
    color: rgb(15, 82, 186);
    background: white;
    border-radius: 30px;
}

.plus-circle-icon {
    font-size: 30px;
    color: rgb(15, 82, 186);
    background: white;
    border-radius: 30px;
}

.plus-circle-disabled {
    font-size: 30px;
    color: rgb(15, 82, 186);
    background: #f1f1f1;
    border-radius: 30px;
}

.footer-submit {
    text-align: center;
    background: forestgreen;
}

.radio1 {
    /*background: #90c99c;*/
    font-weight: normal;
    padding: 6px 20px;
    border-radius: 5px;
    margin-bottom: 5px;
    color: black;
    width: 150px;
}

.radio2 {
    /*background: #f6acac;*/
    padding: 6px 20px;
    border-radius: 5px;
    color: black;
    width: 150px;
}

.table-screenshots {
    font-size: 14px;
}

/* Add this CSS to your style.css file */

/* Table */
.table-screenshots {
    border-collapse: collapse;
    width: 100%;
}

/* Table Header */
.table-screenshots th {
    background-color: #f2f2f2;
    border: 1px solid #959595;
    padding: 8px;
    text-align: left;
}

/* Table Rows */
.table-screenshots td {
    border: 1px solid #959595;
    padding: 8px;
    text-align: left;
}

/* Add vertical line */
.table-screenshots td:not(:last-child) {
    border-right: 1px solid #959595;
}

/* Add line after every row */
.table-screenshots tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Add line after every row */
.table-screenshots tr:nth-child(odd) {
    background-color: #ffffff;
}

.enroll-table {
    margin-top: 20px !important;
}

.enroll-table td {
    border: 1px solid #959595;
    padding: 8px;
    text-align: left;
}

/* Add vertical line */
.enroll-table td:not(:last-child) {
    border-right: 1px solid #959595;
}

/* Add line after every row */
.enroll-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Add line after every row */
.enroll-table tr:nth-child(odd) {
    background-color: #ffffff;
}

.card-enroll-table {
    border-radius: 27px;
    border: 2px solid #818181;
    padding: 34px;
    margin-top: 40px;
}

.reupload-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.screenshot_img {
    height: 100px;
    max-width: 100%;
    border: 2px solid #808080;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #808080;
}

.custom-radioBtn {
    position: relative;
}

.custom-radioBtn input[type='radio'] {
    position: absolute;
    margin: 0;
    height: 25px;
    top: -5px;
    left: -10px;
}
