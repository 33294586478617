.install-wrapper {
    display: flex;
    align-items: center;
    text-align: left;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
    padding: 19px 20px 20px 10px;
    transition: border 0.3s;
}

.install-wrapper.selected {
    border: 2px solid #0f52ba;
}


/* Add border-radius to the Booking summary div */
.detailsForm {
    border-radius: 20px;
    overflow: hidden; /* Ensure the border-radius is applied properly */
}

.booking-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid rgb(204, 204, 204);

    height: 700px;
}

.booking-period {
    margin: 20px 10px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid rgb(204, 204, 204);

}

.guarantor {
    display: inline-block;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin: 8px;
}

.guarantor:hover {
    background-color: #ddd;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input[type="radio"] {
    vertical-align: middle;
    margin-right: 5px; /* Adjust the margin as needed */
}

input[type='radio'], input[type='checkbox'] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: -41px;
}


@media only screen and (max-width: 767px) {
    .booking-card {
        height: 800px;
        margin-bottom: 20px;
    }
    .booking-period {
        border-radius: 10px;
        border: 1px solid rgb(204, 204, 204);

    }
}
