.date-picker {
    height: 33px !important;
}

/* TaskManager.css */

.task-manager-card {
    width: 90%;
    margin: 20px 30px;
}

.task-manager-table-head {
    display: flex;
    align-items: center;
}

.task-manager-card-body {
    display: flex;
    flex-direction: column;
}

.task-manager-input-section {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.task-manager-input {
    width: 40%;
    margin-right: 8px;
}

.task-manager-datepicker {
    width: 150px;
    height: 32px;
}

.task-manager-assignee-input {
    width: 150px;
    margin-left: 8px;
}

.task-manager-priority-input {
    width: 150px;
    margin-left: 8px;
    border-radius: 15px;
}

.task-manager-add-task-button {
    background-color: #164fb6;
    border: none;
    margin-right: 14px;
}


body {
    font-family: 'Arial', sans-serif; /* Choose a suitable font-family */
}

/* Component-specific styles */
.card {
    background-color: #f5f5f5; /* Light gray background */
    border-radius: 10px; /* Rounded corners */
    padding: 20px; /* Add padding for better spacing */
}

.taskWrapper {
    background-color: #0f52ba; /* Dark blue header background */
    color: white; /* White text color */
    padding: 10px; /* Add padding to the header */
    border-radius: 8px; /* Rounded corners for the header */
    height: 20px;
}

.card-body {
    margin-top: 20px; /* Add some space between the header and content */
}

/* Input and Button styles */
.ant-input,
.date-picker,
.ant-select-selection {
    border-radius: 5px; /* Rounded corners for input and select elements */
}

/* Button styles */
.ant-btn-primary {
    background-color: #0f52ba; /* Dark red for primary buttons */
    border-color: #0f52ba; /* Dark red border color */
    border-radius: 5px; /* Rounded corners for buttons */
}

.ant-btn-link {
    color: #000; /* Dark red for link buttons */
}

/* Modal styles */
.ant-modal-header {

    color: white; /* White text color for modal header */
    border-radius: 8px 8px 0 0; /* Rounded corners for modal header */
}

.ant-modal-body {
    padding: 20px; /* Add padding to the modal body */
}

/* List styles */
.ant-list-item {
    border-bottom: 1px solid #d9d9d9; /* Light gray border between list items */
}

/* Button inside List styles */
.ant-btn-link {
    color: #000; /* Dark red for link buttons inside the list */
}

/* Button inside Popconfirm styles */
.ant-popover-inner-content {
    background-color: #fff; /* White background for Popconfirm content */
}

.ant-btn-danger {
    color: #fff; /* White text color for danger buttons */
    background-color: #f5222d; /* Red background for danger buttons */
    border-color: #f5222d; /* Red border color for danger buttons */
}

.card {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

/* style.css or your custom CSS file */
.input-hover {
    transition: box-shadow 0.3s ease;
}

.input-hover:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.button-hover {
    transition: transform 0.3s ease;
}

.button-hover:hover {
    transform: scale(1.2);
}

.primary-button-hover {
    transition: background-color 0.3s ease;
}

.cancel-button-hover {
    transition: background-color 0.3s ease;
}

.cancel-button-hover:hover {
    color: white
}

.remove-btn:hover {
    color: white;
    border: none;
}

.datepicker-input {
    height: 34px;
}

.group-by-button-hover {
    border-bottom: 1px solid transparent !important;
    border: none;
    margin-left: 8px;
    transition: border-color 0.3s ease-in-out;
}

.group-by-button-hover:hover {
    border-color: #1890ff !important;
}

.show-complete-tasks-button-hover,
.show-future-tasks-button-hover {
    border-bottom: 1px solid transparent !important;
    border: none;
    margin-left: 8px;
    transition: border-color 0.3s ease-in-out;
}

.show-complete-tasks-button-hover:hover,
.show-future-tasks-button-hover:hover {
    border-color: #1890ff !important;
}

/* Add these styles to your CSS or SCSS file */
.group-by-button-hover,
.show-complete-tasks-button-hover,
.show-future-tasks-button-hover {
    border-bottom: 1px solid transparent !important;
    border: none;
    margin-left: 8px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add box shadow */
}

.group-by-button-hover:hover,
.show-complete-tasks-button-hover:hover,
.show-future-tasks-button-hover:hover {
    border-color: #1890ff !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust box shadow on hover */
}


/* Add these styles to your CSS or SCSS file */
.table-comp-shadow {
    box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1); /* Add box shadow */
    transition: box-shadow 0.3s ease-in-out;
}

.table-comp-shadow:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust box shadow on hover */
}


/* Recorder styling */
.recorder-container {
    margin: 20px;
}

.recorder-buttons {
    margin-bottom: 10px;
    position: relative;

}

.btn-record {
    margin-right: 10px;
}

.live-video {

    width: 100%;
    height: 100%;
}

.audio-recording-icon {
    animation: blink-animation 0.5s infinite alternate;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.audio-recording-modal .ant-modal-content {
    position: relative;
}

.audio-recording-modal .delete-audio-btn {
    position: absolute;
    top: 92px;
    padding-left: 10px;
    /* right: 324px; */
    color: red;
    font-size: 18px;
}

.mini-screen-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    background-color: black;
}

.mini-screen {
    position: relative;
    width: 300px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
    border: 1px solid #ccc;
}

.mini-screen-video {
    width: 100%;
    height: 100%;
}


/*drawer task profile */

.head-text h6 {
    font: 30px/30px var(--ui-font-family-secondary, var(--ui-font-family-open-sans));
    font-weight: var(--ui-font-weight-light, 300);
    margin: 0;
    padding: 21px 0 21px 0;
    display: block;
    word-wrap: break-word;
    margin-left: 20px;
}

.drawer-head {
    margin: 0px 5px 5px 5px;

}

.contents {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.left-side {
    background-color: white;
    border: 1px solid white;
    border-radius: 8px;
    width: 63%;
    margin: 0px 22px 10px 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    height: 320px;
}

.progress-wrap {
    background-color: white;
    border: 1px solid white;
    border-radius: 8px;
    width: 64%;
    margin: 0px 10px 10px 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.right-side {
    background-color: white;
    border: 1px solid white;
    border-radius: 8px;
    width: 35%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.text-area {
    overflow: auto;
    resize: vertical;
    border-radius: 7px;
    border: none;
    width: 100%;
    height: 109px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
}


.tabs-container1 {
    background: #f0f0f0;
}

.custom-tabs1 {
    display: flex;
    padding: 1px;
}

.custom-tabs1 button {
    font-size: 15px;
    margin: 5px;
    margin-right: 0px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease;
    padding: 8px 12px;
    border-radius: 5px;
}

.custom-tabs1 button:hover {
    background-color: #56D1E0;
    color: white;
}

.custom-tabs1 button.active {
    background-color: #56D1E0;
    color: white;
}

.custom-tabs1 button:active {
    background-color: #56D1E0;
    color: white;
}

.custom-tabs1 button:focus {
    outline: none;
}

.progress-color {
    position: relative;
    display: inline-block;
    width: 90%;
    overflow: hidden;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 100px;
    margin: 16px;
}

.right-text {
    margin: 14px;
    color: gray;
}

.info-text {
    display: flex;
    align-items: center;
    padding: 0px;
    background: rgba(47, 198, 246, .1);
    border-radius: 48px;
    margin: 0px 63px;
    width: 80%;
}

.comments-box, .history-box {
    width: 62%;
    background-color: white;
    /* border-radius: 0px 8px 0px 8px; */
    padding: 15px;
    height: 300px;
    overflow-y: auto;
    margin-left: 19px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}


.comment {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    /*margin-right: 30px;*/
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.comment-text {
    flex: 1 1;
    background-color: #f0f0f0;
    border-radius: 35px;
    padding: 12px 20px 12px 20px;
    max-width: 85%;
}
.comment-author {
    margin-bottom: 5px;
}

.comment-author .name {
    text-transform: capitalize;
}

.comment-author .date {
    color: #0f52ba;
    font-size: 10px;
    font-style: italic;
    float: right;
    font-weight: bold;
}

.time-span {
    color: #0f52ba;
    font-size: 10px !important;
    font-style: italic;
}

.tab-menu {
    padding: 11px;
    border: none;
    border-radius: 12px 12px 0 0;
    margin-right: 5px;
    margin-left: 19px;
    background: #e5e0e9;
}

.tab-menu:hover {
    background-color: white;
}

.tab-menu.active {
    background-color: white;
}

.input-comment {
    text-overflow: ellipsis;
    flex-grow: 1;
    margin-right: 10px;
    height: 50px !important;
    border-radius: 20px;
    width: 80%;
}

.activityTab {
    margin-left: 19px;
    background-color: white;
    width: 62%;
    border-radius: 10px;
    padding: 10px;
    flex-direction: column;
}

.left-row {

    margin-bottom: 10px;
    background: #0f52ba;
    padding: 10px;
    margin-top: -6px;
    border-radius: 5px;
}


/* Add this CSS in your stylesheet */
.priority-select {
    position: relative;
    display: inline-block;
}

.priority-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    font-size: 14px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
    width: 120px;
}

.priority-select::after {
    content: '\25BC';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
}

.priority-select select:hover,
.priority-select select:focus {
    border-color: #007bff;
}


.task-text {
    font-size: 14px;
    color: #555;

}

.even-row {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;
}

.odd-row {
    display: flex;
    justify-content: space-between;
    background-color: #f2f2f2;
    padding: 10px;
}

.filter-select {
    border: none;
    height: 34px !important;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.filter-select .ant-calendar-picker-input.ant-input, .ant-time-picker-input {
    height: 34px !important;
    border: none;
    border-radius: 5px;
}