:global {
  /*.inputBox {
    margin-top: 10px;
    margin-bottom: 10px;

    .labelRow {
      line-height: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 2px 0;

      .extra {
        a {
          margin-left: 10px;
        }
      }
    }

    .labelNew {
      //line-height: 25px;
      line-height: 34px;
      color: rgba(0, 0, 0, 0.65);
      float: left;
      margin: 0 15px 0;
    }

    .innerBox {
      //margin-top: 14px;

      &.logoCls {
        //margin-top: 10px;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }

    .uniTypeCheckbox {
      .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        label {
          width: 45%;
          margin-bottom: 5px;
        }
      }
    }
  }*/
  /*.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
    color: rgba(255, 255, 255, 0.9);

    &:hover {
      text-decoration: underline;
    }
  }*/
  /* .ant-menu-inline-collapsed > .ant-menu-item .anticon + span {
     max-width: 100%;
   }*/
  /*  .PhoneInputInput {
      border-width: 1px !important;
      height: 42px !important;
      border-color: #666;
      color: #666;
      border-radius: 6px;
    }*/
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  /* Dropdown Button */
  /* The container <div> - needed to position the dropdown content */
  /* Dropdown Content (Hidden by Default) */
  /* Links inside the dropdown */
  /* .btn {
     width: 150px;
     background: #1556bc;
     font-size: 14px;
     text-transform: uppercase;
     color: #fff;
     margin: 25px 0 0;
     border-radius: 50px;
     font-weight: 600;
     height: 42px;
   }*/
}
:global body {
  color: #212529 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px;
}
:global .logo-link {
  display: none;
}
:global .ant-layout {
  background: white;
}
:global .ant-card-head .ant-drawer-title,
:global .ant-drawer-header .ant-drawer-title {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin: 0;
}
:global input[type='text'],
:global input[type='password'],
:global input[type='number'],
:global .ant-select-selection {
  height: 33px;
}
:global .ant-input,
:global .ant-input-number,
:global .ant-select-selection--single {
  height: 33px;
}
:global .ant-input.ant-select-search__field {
  height: 43px !important;
  border: 1px solid #666 !important;
}
:global .ant-select {
  width: 100%;
  font-size: 12px;
}
:global .mt0 {
  margin-top: 0 !important;
}
:global .mt1 {
  margin-top: 1px !important;
}
:global .ck.ck-reset.ck-editor.ck-rounded-corners {
  width: 100%;
}
:global .mt3 {
  margin-top: 3px !important;
}
:global .mt5 {
  margin-top: 7px !important;
}
:global .mt10 {
  margin-top: 10px !important;
}
:global .mt15 {
  margin-top: 15px !important;
}
:global .mt18 {
  margin-top: 18px !important;
}
:global .mt20 {
  margin-top: 20px !important;
}
:global .mt30 {
  margin-top: 30px !important;
}
:global .mt40 {
  margin-top: 40px !important;
}
:global .p10 {
  padding: 10px;
}
:global .p20 {
  padding: 20px;
}
:global .mb0 {
  margin-bottom: 0 !important;
}
:global .mb10 {
  margin-bottom: 10px !important;
}
:global .mb20 {
  margin-bottom: 20px !important;
}
:global .mb5 {
  margin-bottom: 5px;
}
:global .ml5 {
  margin-left: 5px !important;
}
:global .mr5 {
  margin-right: 5px !important;
}
:global .mr0 {
  margin-right: 0 !important;
}
:global .ml10 {
  margin-left: 10px;
}
:global .ml15 {
  margin-left: 15px;
}
:global .ml20 {
  margin-left: 20px;
}
:global .mr10 {
  margin-right: 10px;
}
:global .mr20 {
  margin-right: 20px;
}
:global .mb100 {
  margin-bottom: 100px;
}
:global .customForm .ant-select {
  width: 100%;
}
:global .customForm .ant-form-item-label {
  line-height: 28.9999px;
}
:global .customForm .ant-form-item {
  margin-bottom: 2px;
}
:global .ant-layout-sider {
  background: white;
}
:global .ant-form-item {
  margin-bottom: 2px;
}
:global .antd-pro-components-sider-menu-index-logo {
  text-align: center;
  height: 60px;
  background: white !important;
  padding: 5px 5px 5px 0;
  display: flex;
}
:global .antd-pro-components-sider-menu-index-logo img {
  height: 100%;
}
:global .ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo {
  text-align: left;
  height: 60px;
  background: white;
  padding: 5px;
  display: flex;
}
:global .ant-layout-sider-collapsed {
  width: 120px !important;
  min-width: 120px !important;
  max-width: 120px !important;
}
:global .ant-layout-sider-collapsed .antd-pro-components-sider-menu-index-logo a {
  text-align: center;
}
:global .ant-menu-submenu-popup {
  left: 120px !important;
}
:global .ant-menu-inline-collapsed {
  padding-top: 0 !important;
  /*.ant-menu-item-selected {
      background-color: #22ade2 !important;
    }*/
  /* li {
       padding: 0 10px !important;
       display: flex;
       width: 100% !important;
       justify-content: center;
       align-items: center;
       height: 60px !important;

       .ant-menu-submenu > .ant-menu-submenu-title {
         padding: 0 10px !important;
       }

       span, a {
         line-height: 15px;
         color: #fff;
         display: flex !important;
         flex-direction: column !important;
         width: 100% !important;
         justify-content: center;
         align-items: center;

         span {
           opacity: 1 !important;
         }
       }
     }*/
}
:global .englishTypeBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
:global .englishTypeBox div {
  width: 80px;
}
:global .inputBox {
  /*margin-top: 10px;
    margin-bottom: 10px;*/
  margin-bottom: 12px;
}
:global .inputBox .labelRow {
  padding: 0 10px;
  display: inline-block;
  overflow: hidden;
  line-height: 30px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
:global .inputBox .labelRow .extra a {
  color: #000000d9;
  margin-left: 10px;
  text-decoration: underline;
}
:global .inputBox .labelNew {
  float: left;
  color: #666;
  margin-bottom: 0;
}
:global .inputBox .innerBox {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
:global .inputBox .innerBox .ant-calendar-picker {
  width: 100%;
}
:global .inputBox .uniTypeCheckbox .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
:global .inputBox .uniTypeCheckbox .ant-checkbox-group label {
  width: 45%;
  margin-bottom: 5px;
}
:global .ant-select-selection--multiple {
  height: auto;
}
:global .ant-input-number {
  width: 100%;
}
:global .courseBox {
  line-height: 22px;
}
:global .courseBox .ant-card-body {
  padding-bottom: 0;
}
:global .courseBox a {
  color: #0064e1;
}
:global .courseBox .ant-card-bordered {
  border: none;
  border-bottom: 1px solid #e8e8e8;
}
:global .courseBox .uniInfo {
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 8px 24px;
}
:global .courseBox .uniInfo img {
  height: 48px !important;
  width: 48px !important;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
:global .courseBox .uniInfo .uniName {
  font-size: 20px;
}
:global .courseBox .uniInfo .uniName .uniAdd {
  font-size: 15px;
}
:global .courseBox .courseRow {
  line-height: 22px;
  margin-bottom: 17px;
  margin-top: 9px;
  padding: 12px 24px;
}
:global .courseBox .courseRow:last-child {
  border: none;
}
:global .courseBox .courseRow .courseName {
  font-size: 13px;
  margin-right: 20px;
}
:global .courseBox .courseRow .courseInfo {
  font-weight: normal;
  font-size: 15px;
  margin-top: 8px;
}
:global .courseBox .courseRow .courseInfo .ant-col {
  line-height: 1;
}
:global .courseBox .courseRow .courseInfo .ant-col span {
  font-size: 12px;
}
:global .courseBox .courseRow .courseInfo .ant-col small {
  font-size: 12px;
}
:global .courseBox .courseRow .courseInfo .ant-col button {
  color: #2f8ac9;
}
:global .courseBox .loadAllBtn {
  display: flex;
  justify-content: space-between;
  padding: 10px 100px;
  position: absolute;
  z-index: 999;
  top: 100px;
  width: 100%;
  background: #1890ff54;
  font-size: 17px;
  font-weight: 500;
  align-items: center;
  border-left: 10px solid #1890ff;
}
:global .universityBox a {
  color: #0064e1;
}
:global .universityBox .ant-col {
  margin-bottom: 10px;
}
:global .universityBox .uniInfo {
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 8px 5px;
  height: 50px;
}
:global .universityBox .uniInfo img {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
}
:global .universityBox .uniInfo .uniName {
  font-size: 15px;
}
:global .universityBox .uniInfo .uniName .uniAdd {
  font-size: 13px;
}
:global .linkBtn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  height: 32px;
  padding: 3px 40px;
  font-size: 13px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
}
:global .linkBtn i {
  margin-right: 5px;
}
:global .linkBtn.small {
  height: 28px;
  padding: 2px 10px;
}
:global .alignCenter {
  text-align: center;
  cursor: pointer;
}
:global .alignRight {
  text-align: right;
}
:global .alignLeft {
  text-align: left !important;
}
:global .loadMoreBox {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 15px;
}
:global .totalCourseCount {
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  position: relative;
}
:global .vertical-form .header {
  font-size: 25px !important;
  color: #606a84;
  margin-top: 10px;
}
:global .vertical-form .ant-card {
  margin-bottom: 20px;
}
:global .vertical-form .ant-row {
  margin-bottom: 0;
}
:global .vertical-form .ant-row .ant-form-item-label {
  text-align: left;
  line-height: 30px;
}
:global .vertical-form .ant-calendar-picker {
  width: 100%;
}
:global .sticky {
  /*position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    left: 0;*/
  position: fixed;
  top: 0;
  width: calc(100% - 168px);
  z-index: 10;
  opacity: 0.9;
}
:global .outerLoader {
  height: 100%;
  width: 100%;
  background: #ffffffc7;
  z-index: 9999;
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1890ff;
  font-size: 25px;
}
:global .outerLoader .ant-spin-dot {
  font-size: 40px;
}
:global .outerLoader .mainLoader {
  margin-top: 20px;
  font-size: 50px;
}
:global .outerLoader .mainLoader i {
  margin-right: 20px;
}
:global .imgSm {
  height: 50px;
  width: 50px;
}
:global input[type='file']::-webkit-file-upload-button {
  color: rgba(0, 0, 0, 0.85);
  display: inline-block;
  font-weight: normal;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  height: 100%;
  border: none;
  border-right: 1px solid #ddd;
  width: 150px;
  margin-right: 10px;
}
:global .ant-form input[type='file']:focus {
  outline: 0 auto -webkit-focus-ring-color !important;
  border-color: white;
}
:global .rowFlex {
  display: flex;
}
:global .rowFlex > div {
  width: 100%;
}
:global .rowFlex button {
  margin-left: 10px;
}
:global .rowFlex .d1 {
  width: 100%;
}
:global .rowFlex .d1.mr10 {
  margin-right: 10px;
}
:global .rowFlex .d2 {
  width: 100%;
  margin-left: 10px;
}
:global .linkBtn {
  line-height: 1.499;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  height: 40px;
  padding: 0 10px;
  font-size: 18px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #666;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 5px;
  background-color: white;
  margin-top: 1px;
}
:global .linkBtn.sm {
  height: 25px;
  padding: 0 10px;
  font-size: 13px;
}
:global ul.list {
  padding-left: 0;
}
:global ul.list li {
  justify-content: space-between;
  border: 1px solid #ddd;
  align-items: center;
  padding: 5px 10px;
  border-bottom: none;
}
:global ul.list li:last-child {
  border-bottom: 1px solid #ddd;
}
:global .padd-5-bb-1 {
  border-bottom: 1px solid #d3d3d34a;
  padding: 7px 5px;
  font-size: 13px;
  color: #2a2a2a;
}
:global .padd-5-bb-1 .ant-row .ant-col:first-child {
  color: #333 !important;
}
:global .ant-success {
  color: #0872bc;
}
:global .ant-info {
  color: #5bc0de;
}
:global .ant-warning {
  color: rgba(240, 173, 78, 0.87);
}
:global .card.unizportal button.btn[type='submit'] {
  width: 150px;
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  height: 42px !important;
}
:global .ant-btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
:global .ant-btn-basic {
  color: #333;
  background-color: #f1f1f1;
  border-color: #f7ebeb;
}
:global .successTag {
  background: #0872bc;
  border: 1px solid #0872bc;
  padding: 1px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
}
:global .statusDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
:global .statusDiv .statusBox {
  height: 30px;
  background: #fff;
  border-radius: 0;
  text-align: center;
  border: 1px solid #ddd;
  margin: 3px 0;
  color: #333;
  padding: 3px;
  font-size: 12px;
  width: 350px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
:global .statusDiv .statusBox .icon {
  position: absolute;
  right: 10px;
  color: rgba(240, 173, 78, 0.87);
  top: 25%;
}
:global .bgTheme {
  background-color: #0872bc !important;
  border: 1px solid #0872bc !important;
  color: white !important;
}
:global .bgTheme .icon {
  color: #0872bc !important;
}
:global table tbody tr:nth-child(odd) {
  background: white;
}
:global table tbody tr:nth-child(even) {
  background: #f9f9f9;
}
:global .applicationProfile {
  overflow: hidden;
  height: calc(100vh - 200);
}
:global .applicationProfile .imgDiv {
  display: inline-flex;
}
:global .applicationProfile aside {
  display: none;
}
:global .applicationProfile .ant-layout-content {
  padding: 0;
  margin: 0;
}
:global .applicationProfile .ant-drawer-header {
  background-color: #fafafa;
  border-radius: 0;
  padding: 10px 20px;
}
:global .applicationProfile .ant-drawer-header .ant-drawer-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 15px;
}
:global .applicationProfile .ant-drawer-header .ant-drawer-close {
  color: rgba(0, 0, 0, 0.85);
}
:global .applicationProfile .input-group-box {
  width: 40%;
}
:global .applicationProfile .logo-link {
  display: block;
}
:global .applicationProfile .displayName {
  display: none;
}
:global .bgWhite {
  background: white !important;
}
:global .appProfile {
  overflow: hidden !important;
  background: #f5f8fa;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
:global .appProfile ::-webkit-scrollbar {
  width: 5px;
}
:global .appProfile ::-webkit-scrollbar-thumb {
  background: #a9a9a9;
}
:global .appProfile .align-self-stretch {
  display: flex;
  align-self: stretch !important;
  word-break: break-word;
  overflow: visible;
  flex-grow: 1;
  width: 100%;
}
:global .appProfile .align-self-stretch .profile-3-column {
  overflow: scroll;
  height: calc(100vh - 1px);
  padding-bottom: 45px;
}
:global .appProfile .align-self-stretch .profile-3-column.profile-3-col-sidebar.profile-3-col-sidebar--left {
  border-right: 1px solid #dfe3eb;
  padding-bottom: 52px;
  flex-basis: 350px;
  max-width: 500px;
  min-width: 280px;
}
:global .appProfile .align-self-stretch .profile-3-column.profile-3-col-sidebar--right-wrapper {
  background-color: #fff;
  display: block;
  flex-basis: 25%;
  max-width: 500px;
  transition: flex 0.15s cubic-bezier(0.16, 0.84, 0.44, 1);
  will-change: flex;
  min-width: 280px;
}
:global .appProfile .align-self-stretch .profile-6-column {
  padding-bottom: 45px;
}
:global .appProfile .align-self-stretch .profile-6-column.noteBlock {
  flex-basis: 50%;
  height: 100vh;
  padding-left: 10px;
}
:global .appProfile .align-self-stretch .profile-12-column {
  padding-bottom: 45px;
}
:global .appProfile .align-self-stretch .profile-12-column.noteBlock {
  /* flex-basis: 100%;
           //padding-top: 10px;
           height: 100vh;
           //border-left: 1px solid #ddd;
           //border-right: 1px solid #ddd;
           padding-left: 10px;
           overflow: hidden;
           border-bottom: 3px solid #ededed;
           padding-top: 15px;*/
  flex-basis: 100%;
  height: 100vh;
  /* padding-left: 10px; */
  overflow: hidden;
  border-bottom: 3px solid #ededed;
  /* padding-top: 15px; */
  border-top: 1px solid #ededed;
}
:global .appProfile .align-self-stretch .profile-12-column.noteBlock.noteBlockAgent {
  height: auto !important;
  max-height: 100vh !important;
}
:global .appProfile .align-self-stretch .profile-12-column.noteBlock.noteBlockAgent .scrollView {
  height: auto !important;
  max-height: 100vh !important;
}
:global .appProfile .infoBox {
  line-height: 1.5;
  margin-bottom: 10px;
}
:global .appProfile .infoBox label {
  font-size: 12px;
  color: #1890ff;
}
:global .appProfile .bgWhite {
  background: white !important;
}
:global .appProfile .bgWhite .ant-card-body {
  padding: 0;
}
:global .appProfile .actionBlock {
  display: flex;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background: white;
  margin-top: 10px;
}
:global .appProfile .stuInfo {
  padding: 18px 45px;
}
:global .appProfile .leftBlock {
  text-align: center;
}
:global .appProfile .bgImg {
  height: 80px;
  width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
}
:global .appProfile .logo {
  height: 80px;
  width: 80px;
}
:global .appProfile .font15 {
  font-size: 15px;
}
:global .appProfile .appInfo {
  margin-top: 10px;
  font-size: 13px;
  line-height: 2.3em;
}
:global .appProfile .appInfo .moveBtn {
  display: flex;
  justify-content: space-between;
}
:global .appProfile .appInfo .moveBtn .ant-btn {
  margin-left: 10px;
}
:global .appProfile .ant-collapse-header {
  background: white;
}
:global .appProfile .ant-collapse-content {
  border: none;
}
:global .appProfile .noteBlock {
  height: 100vh;
  border-left: 3px solid #ededed;
  border-right: 3px solid #ededed;
  overflow: scroll;
  background: white;
  /* &::-webkit-scrollbar-track {
         background: #f1f1f1;
       }

       &::-webkit-scrollbar-thumb {
         background: @primary;
       }

       &::-webkit-scrollbar-thumb:hover {
         background: @success;
       }*/
}
:global .appProfile .noteBlock::-webkit-scrollbar {
  width: 3px;
}
:global .appProfile .noteBlock .activityBlock {
  padding: 30px 10px 10px 50px;
}
:global .appProfile .noteBlock .noteCardOuter {
  margin: 10px;
}
:global .appProfile .noteBlock .noteCardOuter .dateLabel {
  font-size: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard {
  background: white;
  line-height: 2.5;
  font-size: 15px;
  box-shadow: 2px 2px 10px #ddd;
  margin-bottom: 20px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .content {
  display: flex;
  padding: 5px 20px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .content i {
  margin-top: 10px;
  margin-right: 20px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent {
  width: 100%;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent .title {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .content .noteContent .title .date {
  font-size: 12px;
  color: #9d8e8e;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .contentComm {
  padding: 0;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent {
  width: 100%;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title {
  background: #dfe6ed;
  color: black;
  padding: 0 10px;
  border: 1px solid #80808014;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  font-size: 12px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit {
  display: flex;
  padding: 5px 0;
  align-items: flex-start;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit i {
  font-size: 12px;
  margin-right: 10px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .title .leftTit .subject {
  line-height: 1;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .commContent {
  padding: 10px 20px 10px 50px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .commContent strong {
  margin-right: 10px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .contentComm .noteContent .date {
  display: flex;
  font-size: 10px;
  line-height: 1;
  padding: 5px 0;
  opacity: 0.7;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard img {
  height: 30px;
  margin-right: 10px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .commentBlock {
  border-top: 1px solid #ddd;
  padding: 5px 20px;
  color: #1890ff;
  display: flex;
  justify-items: center;
  align-items: center;
  font-weight: 600;
  margin-top: 10px;
}
:global .appProfile .noteBlock .noteCardOuter .noteCard .commentBlock a {
  color: #1890ff;
  margin-left: 10px;
}
:global .customTag .ant-tag {
  border: none;
  background: #ddffff !important;
  padding: 2px 8px;
}
:global .customTag .ant-tag.noneCls {
  border: none;
  background: none !important;
  font-size: 13px;
  color: #2a2a2a;
}
:global .documentTitle {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 15px;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
  background: white;
  margin-top: 10px;
}
:global .documentTitle span {
  display: flex;
  align-items: center;
}
:global .oldDoc {
  background: #ddffff !important;
}
:global .oldDoc span {
  text-decoration: line-through !important;
}
:global .documentBlock .ant-collapse-header {
  font-weight: bold;
}
:global .documentBlock .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
:global .documentBlock .documentDownBlock {
  margin-top: 10px;
  background: #dddddd4a;
  padding: 5px;
}
:global .documentBlock .documentText {
  font-size: 13px;
  padding-right: 15px;
}
:global .btnGroup {
  display: flex;
  justify-content: space-between;
}
:global .btnGroup .eBtn img {
  height: 40px;
  margin-bottom: 5px;
}
:global .btnGroup .eBtn button {
  margin-bottom: 10px;
}
:global .dashboardCard .ant-card-body .ant-card-meta {
  border-bottom: 1px solid #e8e8e8 !important;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
:global .dashboardCard .ant-col .ant-card {
  text-align: center;
  text-transform: uppercase;
  padding: 0px 12px 0px 0px;
  font-weight: 600;
  border: none;
}
:global .dashboardCard .ant-col .ant-card .title {
  height: 25px;
  font-size: 12px;
}
:global .dashboardCard .ant-col .ant-card .count {
  margin-top: 10px;
}
:global .dashboardCard .ant-col .ant-card .count p {
  font-size: 28px;
  width: 80px;
  margin: 0 auto;
  padding: 6px;
  border-radius: 20px;
  color: #1890ff;
  cursor: pointer;
}
:global .dashboardCard .universityCountBox {
  padding: 10px;
  height: 400px;
  overflow-y: scroll;
}
:global .dashboardCard .universityCountBox::-webkit-scrollbar {
  width: 3px;
}
:global .dashboardCard .universityCountBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}
:global .dashboardCard .universityCountBox::-webkit-scrollbar-thumb {
  background: #1890ff;
}
:global .dashboardCard .universityCountBox::-webkit-scrollbar-thumb:hover {
  background: #0872bc;
}
:global .ant-menu-submenu-title {
  font-size: 13px;
}
:global .ant-menu-submenu-title span {
  font-size: 13px !important;
}
:global .drawerFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 15px;
  background: #fff;
  text-align: left;
}
:global .drawerFooter.alignRight {
  text-align: right;
}
:global .drawerFooter .ml-auto i {
  margin-left: 5px !important;
}
:global .drawerFooter .ant-btn {
  margin-left: 10px;
  display: flex;
  justify-content: center;
}
:global .ql-container {
  height: 400px;
}
:global .paddingHz {
  padding: 10px 0;
}
:global .attachment-box {
  background-color: white;
}
:global .attachment-box a {
  color: rgba(0, 0, 0, 0.65);
}
:global .noteTitle {
  display: flex;
  justify-content: space-between;
}
:global .commentBox {
  margin-top: 10px;
  line-height: 1.5;
  font-size: 13px;
  background-color: white;
  padding: 10px;
}
:global .commentBox .avatar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
}
:global .commentBox .avatar i {
  margin: 0 !important;
}
:global .commentBox .userInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1890ff;
}
:global .commentBox .userInfo .date {
  margin-left: 10px;
  font-size: 12px;
  color: #808080;
}
:global .commentBox .userInfo .date i {
  margin: 0 !important;
}
:global .commentBox .commentText {
  margin-top: 5px;
  font-size: 12px;
  margin-left: 15px;
}
:global .jcsb {
  display: flex;
  justify-content: space-between;
}
:global .jcc {
  display: flex;
  justify-content: center;
}
:global .jcic {
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .customTimeLine {
  margin-left: 15%;
}
:global .customTimeLine .ant-timeline-item {
  min-height: 50px;
}
:global .customTimeLine .ant-timeline-item-head-custom {
  width: 180px;
}
:global .customTimeLine .ant-timeline-item-head-custom .date {
  position: absolute;
  top: 4px;
  text-align: right;
  color: #0872bc;
  font-weight: 500;
}
:global .customTimeLine .ant-timeline-item-head-custom .date span {
  font-size: 10px;
  color: #9d8e8e;
}
:global .ant-timeline-item-head-primary {
  color: #1890ff;
}
:global .customPanel {
  border: 1px solid #e8e8e8;
}
:global .customPanel .ant-collapse-header {
  background: white;
  font-size: 15px;
}
:global .pendencyList {
  border-bottom: 1px solid #dddddd54;
  padding: 7px 0;
}
:global .label {
  display: inline;
  padding: 5px;
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
:global .label.labelNew {
  display: block;
  cursor: pointer;
}
:global .label.round {
  border-radius: 50px;
  padding: 0.1em 0.6em 0.2em;
}
:global .label.sm {
  padding: 0.1em 0.6em 0.2em;
  font-weight: normal !important;
}
:global .label.xs {
  padding: 0.5px 8px;
  font-weight: normal !important;
  margin: 2px 0;
}
:global .label.xs1 {
  padding: 0 6px;
  font-weight: normal !important;
  margin: 2px 0;
  font-size: 11px;
}
:global .label-info {
  background-color: #5bc0de;
}
:global .label-default {
  background-color: #0f52ba;
}
:global .label-primary {
  background-color: #337ab7;
}
:global .label-success {
  background-color: #0872bc;
}
:global .label-success-green {
  background-color: #5cb85c;
}
:global .label-danger {
  background-color: #d9534f;
}
:global .label-warning {
  background-color: rgba(240, 173, 78, 0.87);
}
:global .label-black {
  background-color: black;
}
:global .label-tag {
  border: none;
  font-size: 12px !important;
  color: black;
  text-transform: capitalize;
}
:global .label-sm {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
:global .label-xs {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}
:global .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}
:global .pointerCls-pdf {
  width: 24.6% !important;
  display: inline-block;
}
:global .pointerCls {
  cursor: pointer;
}
:global .linkPointer {
  cursor: pointer;
  color: #1890ff;
  font-size: 13px;
}
:global .antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: left;
}
:global .dotsReplace {
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  text-overflow: ellipsis;
  height: 30px;
}
:global .ant-btn-circle {
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 35px !important;
  min-width: 35px !important;
  background: none;
  border: none;
}
:global .ant-btn-circle.border {
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
:global .bs_btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #efefef;
  color: #333;
  padding: 0.2rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  height: 35px;
  min-width: 35px;
}
:global .bs_btn.bs-sm {
  padding: 5px 10px;
}
:global .bs_btn.bs-xs {
  padding: 2px 5px;
  font-size: 11px;
  height: 25px;
}
:global .bs_btn.bs-xxs {
  padding: 1px 5px;
  font-size: 10px;
  height: 20px;
}
:global .bs_btn.bs-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
:global .bs_btn.bs-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
:global .bs_btn.bs-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
:global .bs_btn.bs-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
:global .bs_btn.bs-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
:global .bs_btn.bs-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
:global .bs_btn.bs-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}
:global .bs_btn.bs-link:hover {
  text-decoration: underline !important;
}
:global .bs-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
  font-size: 11px;
}
:global .bs-link:hover {
  text-decoration: underline !important;
}
:global .tableAction {
  width: 125px;
  display: flex;
  flex-wrap: wrap;
}
:global .tableAction button,
:global .tableAction a {
  margin-right: 2px;
  margin-bottom: 5px;
  min-height: 35px !important;
  min-width: 35px !important;
  background: none;
  border: none;
}
:global .appCommentDotsReplace {
  display: block;
  width: 150px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 35px;
}
:global .appTextReplace {
  display: block;
  white-space: pre-wrap;
  width: 100%;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 23px;
}
:global .newsContentDotsReplace {
  display: block;
  width: 350px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}
:global .replaceAgentDot {
  display: block;
  width: 100px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  height: 17px;
  overflow-x: hidden;
}
:global .colorPrimary {
  color: #1890ff;
}
:global .colorPrimaryDark {
  color: #0f52ba;
}
:global .colorSuccess {
  color: #5cb85c;
}
:global .label1 {
  display: block !important;
  white-space: normal !important;
  font-size: 12px;
}
:global .linkAA {
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
}
:global .student-tab {
  border-right: none;
}
@media only screen and (max-width: 768px) {
  :global .applicationProfile {
    overflow: scroll;
  }
  :global .applicationProfile .appProfile .align-self-stretch {
    flex-direction: column;
  }
  :global .applicationProfile .profile-3-column.profile-3-col-sidebar.profile-3-col-sidebar--left {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  :global .applicationProfile .profile-3-column.profile-3-col-sidebar--right-wrapper {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  :global .applicationProfile .profile-6-column.noteBlock {
    flex-basis: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
}
:global .tuitionBox {
  font-size: 15px;
  color: black;
}
:global .ant-calendar-picker,
:global .ant-time-picker {
  width: 100%;
}
:global .ant-btn {
  line-height: 1.5;
  padding: 0.225rem 0.75rem !important;
  height: auto !important;
}
:global .ant-btn[type='submit'] {
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  width: auto;
  height: auto !important;
}
:global table {
  border: none;
}
:global table tbody th {
  font-size: 13px;
  border-top: 2px solid #dee2e6 !important;
  background: #fafafa;
  border-bottom: 1px solid #dee2e6 !important;
}
:global table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
:global table tbody tr:nth-of-type(even) {
  background: #fafafa;
}
:global table tbody tr td {
  padding: 8px 10px !important;
  font-size: 12px;
  font-weight: 500;
  color: #212529 !important;
}
:global table td.borderNone {
  border: none;
}
:global .ant-table-thead {
  background: #fafafa;
}
:global .ant-table-thead tr th {
  padding: 8px 10px !important;
  border: none !important;
  color: #212529 !important;
}
:global .ant-table-small {
  border: none;
}
:global .ant-table-content {
  border-right: none !important;
}
:global .antd-pro-components-global-header-index-header {
  padding: 15px 25px !important;
  height: auto;
}
:global .antd-pro-components-sider-menu-index-logo {
  text-align: center;
  height: 81px;
  background: white;
  padding: 0;
  border-bottom: 3px solid #ededed !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .antd-pro-components-sider-menu-index-logo img {
  width: 65%;
  height: auto;
}
:global .antd-pro-components-sider-menu-index-sider {
  box-shadow: none;
  border-right: 3px solid #ededed !important;
}
:global .ant-layout-header {
  height: auto;
}
:global .ant-layout-header .antd-pro-components-global-header-index-header {
  padding: 13px 25px !important;
  height: 83px;
  box-shadow: none;
  border-bottom: 3px solid #ededed !important;
}
:global .sidebar {
  height: auto;
  overflow-y: initial;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  overflow-x: hidden;
}
:global .ant-layout.ant-layout-has-sider > .ant-layout,
:global .ant-layout.ant-layout-has-sider > .ant-layout-content {
  height: 100%;
}
:global .antd-pro-components-global-header-index-right {
  display: flex;
  flex-direction: row;
}
:global .antd-pro-components-global-header-index-action {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
:global .antd-pro-components-global-header-index-action .antd-pro-components-global-header-index-name {
  line-height: 1;
  margin-top: 10px;
}
:global .admin-box .content h5 {
  font-size: 15px;
  white-space: nowrap;
  font-weight: 600;
  color: #1a5abd;
  margin: 0;
}
:global form.input-group-box {
  width: 46%;
  float: left;
  margin-top: 10px;
}
:global .pd-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
:global .appAction {
  float: right;
  margin-bottom: 10px;
}
:global .top_P10 {
  padding-top: 10px !important;
}
:global .sort-box-table {
  padding-right: 20px;
}
:global .btnBorder {
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .search-box-table-width {
  width: 34%;
  margin-right: auto;
}
:global .round-input .ant-select-selection--single {
  font-size: 14px;
  border-radius: 50px;
  padding-left: 10px;
}
:global .card-body table td span.badge {
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  background: #1053ba;
  border-radius: 4px;
  color: #fff;
}
:global .ant-form-item label {
  color: rgba(0, 0, 0, 0.65);
  float: left;
  margin: 0 15px 0;
}
:global .form-control {
  height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
:global .PhoneInputCountrySelect {
  padding: 10px !important;
}
:global .PhoneInput {
  border: 1px solid #666 !important;
  height: 42px !important;
  color: #666;
  border-radius: 6px;
  padding-right: 3px;
}
:global .PhoneInput .PhoneInputCountry {
  padding: 10px;
  border-right: 1px solid #666 !important;
}
:global .PhoneInput .PhoneInputInput {
  height: 40px !important;
  color: #666;
  padding-left: 10px;
  border: none;
}
:global .PhoneInput .PhoneInputInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  opacity: 1;
  /* Firefox */
}
:global .PhoneInput .PhoneInputInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #999 !important;
}
:global .PhoneInput .PhoneInputInput::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999 !important;
}
:global .ant-select-selection--single {
  height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
:global .ant-select-selection--multiple {
  min-height: 42px !important;
  border-color: #666;
  color: #666;
  border-radius: 6px;
}
:global .ant-select-selection--multiple .ant-select-selection__rendered {
  min-height: 40px !important;
}
:global .ant-select-selection--multiple .ant-select ul,
:global .ant-select-selection--multiple .ant-select ol {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 5px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
}
:global .ant-form-item-control {
  line-height: 30px;
}
:global .ant-select-selection__rendered {
  line-height: 40px !important;
}
:global .ant-select-arrow {
  user-select: none;
  background: #666666;
  padding: 5px;
  color: white;
  font-weight: 600;
  margin-top: -12px;
  border-radius: 5px;
}
:global .ant-select-focused .ant-select-arrow {
  background: #1254bb !important;
}
:global .ant-input-number {
  height: 42px;
}
:global .heading-form.stuHeader span.img img {
  height: 18px;
  margin-right: 3px;
  position: relative;
  top: -1px;
}
:global .heading-form a.btn {
  box-shadow: 0 0 12px 0px #00000038 !important;
  border-radius: 0;
  margin: 0 40px;
  font-size: 13px;
  text-transform: capitalize;
  min-width: 120px;
}
:global .pa-0 {
  padding: 0 !important;
}
:global .antd-pro-layouts-basic-layout-content {
  padding: 20px 15px;
  margin: 0 !important;
}
:global .ant-select-selection-selected-value {
  margin-right: 20px;
}
:global .ant-calendar-picker-input.ant-input,
:global .ant-time-picker-input {
  height: 40px !important;
}
:global .ant-calendar {
  width: auto;
}
:global .stuIcon {
  font-size: 20px;
  color: #1890ff;
}
:global ul.pointerUl li {
  cursor: pointer;
}
:global .ant-menu-dark {
  background: white;
}
:global .ant-menu-dark svg {
  display: none;
}
:global .ant-menu-dark .ant-menu-item {
  font-size: 13px !important;
}
:global .ant-menu-dark .ant-menu-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: black !important;
}
:global .ant-menu-dark .ant-menu-item:hover div > span {
  color: black !important;
}
:global .ant-menu-dark .ant-menu-sub {
  background: white !important;
}
:global .ant-menu-dark li:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #343a40 !important;
}
:global .ant-menu-dark li:hover span,
:global .ant-menu-dark li:hover a {
  color: #343a40 !important;
}
:global .ant-menu-dark li a {
  color: #343a40 !important;
}
:global .ant-menu-dark li a :hover {
  color: #343a40 !important;
}
:global .ant-menu-dark li span {
  color: #343a40 !important;
}
:global .ant-menu-dark li span :hover {
  color: #343a40 !important;
}
:global .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: none;
}
:global .ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item {
  height: 40px;
  line-height: 40px;
}
:global .ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover {
  background: rgba(0, 0, 0, 0.1);
  color: #343a40 !important;
}
:global .ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover a:hover {
  color: #343a40 !important;
}
:global .ant-menu-dark .ant-menu-inline.ant-menu-sub .ant-menu-item:hover span:hover {
  color: #343a40 !important;
}
:global li.ant-menu-item.ant-menu-item-selected {
  background: #fff !important;
  color: #343a40 !important;
  box-shadow: 0 0 12px 0px #00000038 !important;
  border-right: 5px solid #1254bb !important;
}
:global li.ant-menu-item.ant-menu-item-selected a {
  color: #343a40 !important;
}
:global li.ant-menu-item.ant-menu-item-selected a span {
  color: #343a40 !important;
}
:global i.ant-menu-submenu-arrow {
  color: #343a40 !important;
}
:global i.ant-menu-submenu-arrow::before {
  background: #343a40 !important;
}
:global i.ant-menu-submenu-arrow::after {
  background: #343a40 !important;
}
:global .nav-item {
  color: #343a40 !important;
}
:global .nav-item a {
  color: #343a40 !important;
}
:global .imgDiv {
  display: inherit;
  width: 75%;
}
:global .imgDiv .logo-link {
  width: 350px;
  box-shadow: 3px 0 0 #ededed;
}
:global .imgDiv .logoImg {
  line-height: 0.8;
  margin-left: 0.8rem;
  margin-right: 0.5rem;
  margin-top: -3px;
  width: auto;
  box-shadow: none !important;
  opacity: 1 !important;
  border-radius: 0;
  max-height: 45px !important;
}
:global .student-dashboard {
  width: calc(100% - 352px);
}
:global .profile-main {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-bottom: 50px;
}
:global .profile-main::-webkit-scrollbar {
  display: none;
}
:global .search-profile-main {
  height: 84vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
:global .search-profile-main::-webkit-scrollbar {
  display: none;
}
:global .attachment-box {
  height: 100vh;
  overflow: scroll;
  /*  &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: @primary;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: @success;
      }
  */
  padding-bottom: 50px;
}
:global .scrollView {
  height: 100vh;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding-bottom: 50px;
}
:global .scrollView::-webkit-scrollbar {
  display: none;
}
:global .mobileMenu {
  display: none;
}
:global .infoBox {
  width: 350px;
}
:global .courseBox {
  min-height: 80px;
}
:global .courseFeeInfo {
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 5px;
}
:global .selectStudentBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}
:global .selectStudentBox .filter-box button.apply {
  font-size: 11px;
}
:global .selectStudentBox .selectStudentBtn {
  display: flex;
  align-items: center;
  background: #0f52ba;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 50px;
  min-width: 100px;
  font-size: 12px;
}
:global .selectStudentBox .selectStudentBtn i {
  margin-right: 5px;
}
:global .detail-list li {
  color: #212529;
}
:global .ant-pagination-options-size-changer .ant-select-selection--single {
  height: 33px !important;
  border: 1px solid #ddd;
}
:global .ant-pagination-options-size-changer .ant-select-selection--single::after {
  content: '' !important;
}
:global .ant-pagination-options-size-changer .ant-select-selection--single::before {
  content: '' !important;
}
:global .ant-btn[type='submit'] {
  width: auto;
  height: auto !important;
}
:global .custom-sort-box-table {
  width: 34% !important;
}
:global .sort-box-table-right {
  text-align: right;
}
:global .btn img {
  height: 17px;
}
:global .btn img.plus {
  height: 15px;
  margin-right: 5px;
}
:global .btn.round {
  margin-right: auto;
  display: flex;
  border-radius: 50px;
  padding-left: 50px;
  position: relative;
}
:global .btn.roundNew {
  margin-right: auto;
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 15px;
  text-decoration: none !important;
  vertical-align: middle;
}
:global .btn.roundNew img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
:global .btn .anticon {
  margin-right: 5px;
}
:global .btn.ac {
  display: flex;
  align-items: center;
}
:global .btn.roundNew2 {
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 10px;
  float: right;
  margin-right: 10px;
}
:global .btn.roundNew2 img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
:global .btn.round-bt {
  margin-right: 5px;
  border-radius: 50px;
  position: relative;
  margin-bottom: 5px;
  font-size: 15px;
}
:global .btn.round-bt img {
  height: 10px !important;
  margin-right: 5px;
  max-height: 13px;
}
:global .btn.lg {
  padding: 8px 30px !important;
}
:global .btn.md {
  padding: 5px 17px !important;
  font-size: 0.87rem;
}
:global .btn.sm {
  padding: 3px 15px !important;
  font-size: 0.8rem;
}
:global .btn.xs {
  padding: 2px 10px !important;
  font-size: 0.7rem;
}
:global .btn.xss {
  padding: 2px 5px !important;
  font-size: 0.65rem;
}
:global .btn.p0 {
  padding: 0 !important;
}
:global .search-box-table.round {
  margin-right: auto;
  display: flex;
  border-radius: 50px;
  padding-left: 50px;
  position: relative;
  background: #fff;
}
:global .search-box-table.round.m0 {
  margin: 0 !important;
}
:global .search-box-table.round img {
  position: absolute;
  left: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
:global .search-box-table.round input {
  border: none !important;
  border-radius: 50px !important;
  height: auto;
  padding: 8px 15px;
  padding-left: 0;
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
:global .search-box-table.ml0 {
  margin-left: 0 !important;
}
:global td span.badge {
  padding: 5px 7px !important;
  cursor: pointer;
}
:global select {
  background: white;
}
:global .custom-sort-box-new {
  padding-right: 0 !important;
  margin-bottom: 10px;
}
:global .custom-sort-box-new select {
  margin-right: auto;
  display: flex;
  position: relative;
  background: #fff;
  border: none !important;
  border-radius: 50px !important;
  padding: 8px 15px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 42px;
}
:global .custom-sort-box-new select ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057 !important;
  opacity: 1;
  /* Firefox */
}
:global .custom-sort-box-new select :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495057 !important;
}
:global .custom-sort-box-new select ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #495057 !important;
}
:global .custom-sort-box-new select:visited,
:global .custom-sort-box-new select:active,
:global .custom-sort-box-new select:focus {
  border: none !important;
  outline: none !important;
}
:global table td button.btn,
:global table td a.btn {
  padding: 0 4px !important;
}
:global ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #495057 !important;
  opacity: 1;
  /* Firefox */
}
:global :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #495057 !important;
}
:global ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #495057 !important;
}
:global .courseList-box {
  margin-top: 43px;
}
:global .unizportal .ant-col-8 .inputBox {
  margin-top: 0;
  position: relative;
}
:global ::-webkit-scrollbar {
  width: 7px;
  height: 4px;
  border-radius: 10px !important;
}
:global ::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px !important;
  box-shadow: inset 0 0 5px #acacac;
}
:global ::-webkit-scrollbar-thumb {
  background: #acacac;
  border-radius: 10px !important;
}
:global ::-webkit-scrollbar-thumb:hover {
  background: #9b9b9b;
  border-radius: 10px !important;
}
:global .chooseCountryBox {
  position: absolute;
  z-index: 9;
  top: 35px;
  background: #fff;
}
:global .chooseCountryBox label {
  margin: 0 !important;
  border-bottom: 1px solid #ddd;
}
:global .selectedCountryLabel .icon {
  position: absolute;
  right: 10px;
  top: 12px;
}
:global .profile-activity.application-activity ul li .date {
  width: 15%;
}
:global .ant-menu-dark .ant-menu-sub {
  background: #dddddd42 !important;
}
:global .font11 {
  font-size: 11px;
}
:global .font12 {
  font-size: 12px !important;
}
:global .font14 {
  font-size: 13px;
}
:global .font14_1 {
  font-size: 14px;
}
:global .font15 {
  font-size: 15px;
}
:global .actionBtnGroup .viewBtn {
  height: 20px;
}
:global .actionBtnGroup .crossBtn {
  height: 10px;
}
:global .antd-pro-components-sider-menu-index-sider {
  box-shadow: none;
  border-right: 3px solid #ededed !important;
}
:global .ant-select-selection__rendered {
  content: '' !important;
}
:global .ant-select-selection__clear {
  top: 42%;
  right: 15px;
}
:global .ant-select-selection__clear .ant-select-clear-icon {
  height: 20px;
  width: 20px;
}
:global .ant-select-selection__clear .ant-select-clear-icon svg {
  height: 20px;
  width: 20px;
}
:global .ant-select-enabled.ant-select-allow-clear .ant-select-selection--single:hover .ant-select-arrow {
  display: none;
}
:global .PrivacyPolicyPage {
  font-size: 12px;
  padding: 50px;
}
:global .PrivacyPolicyPage h4 {
  text-align: left;
  font-size: 18px;
  text-decoration: underline;
}
:global .PrivacyPolicyPage p {
  text-align: left;
  line-height: 1.8;
  color: #6c4a4a;
  margin-bottom: 20px;
}
:global .linkUl {
  float: right;
  color: #6c4a4a;
}
:global .linkUl a {
  text-decoration: underline !important;
}
:global .ant-drawer-close {
  width: 30px;
  height: 30px;
  right: 20px !important;
  line-height: 2;
  top: 1px;
}
:global .ant-drawer-close:focus {
  outline: none;
}
:global .roundBtn {
  line-height: 1.5;
  padding: 10px 15px !important;
  border-radius: 50px !important;
  height: 42px !important;
  margin-right: 5px;
  border-color: #efe8e8;
}
:global .roundBtn.icon {
  height: 40px !important;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
:global .status.status1 p {
  min-height: 22px;
  height: auto !important;
}
:global .ant-btn-success {
  width: 150px;
  background: #1556bc !important;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff !important;
  margin: 25px 0 0;
  border-radius: 50px !important;
  font-weight: 600;
  height: 42px !important;
}
:global .ant-btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
:global .ant-btn-warning {
  color: #fff !important;
  background-color: rgba(240, 173, 78, 0.87) !important;
  border-color: #eea236 !important;
}
:global .ant-btn-cancel {
  background: #999;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 50px;
  min-width: 100px;
  margin-right: 15px;
  height: 42px !important;
}
:global .ant-table-scroll .ant-table-body::-webkit-scrollbar {
  height: 4px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
:global .btn-success-green {
  border: 1px solid #5cb85c !important;
  background-color: #5cb85c !important;
  color: white !important;
}
:global .moreBtn {
  text-decoration: underline;
  color: #5f5f5f;
  margin-left: 20px;
  font-size: 10px;
}
:global .moreBtn:hover {
  color: #5f5f5f;
}
:global .guestEmailBox {
  background: white !important;
  padding: 5px 5px;
  margin: 2px;
  display: flex;
  border: 1px solid #ddddff;
  border-radius: 5px;
  min-height: 40px;
}
:global .guestEmailBox ul {
  padding-left: 0;
  margin-bottom: 0;
}
:global .guestEmailBox li.emailTag {
  position: relative;
  float: left;
  max-width: 99%;
  margin-right: 4px;
  padding: 2px 5px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  margin-bottom: 2px;
}
:global .guestEmailBox .emailIcon {
  font-size: 12px;
  position: relative;
  top: -2px;
  margin-left: 5px;
}
:global #loginForm.login-main {
  height: 100vh !important;
  display: flex !important;
  align-items: center !important;
}
@media screen and (max-width: 560px) {
  :global .mobileMenu {
    display: block;
    position: absolute;
    top: 0;
  }
  :global .antd-pro-layouts-basic-layout-content {
    margin: 0;
  }
  :global .infoBox {
    width: 100%;
  }
  :global .applicationProfile aside {
    display: block;
  }
  :global .imgDiv .logo-link {
    display: none;
  }
  :global .imgDiv .logoImg {
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: -3px;
    width: auto;
    box-shadow: none !important;
    opacity: 1 !important;
    border-radius: 0;
    max-height: 45px !important;
  }
  :global .student-dashboard {
    width: 100% !important;
  }
  :global .profile-main,
  :global .search-profile-main,
  :global .attachment-box,
  :global .scrollView {
    height: 100%;
  }
}
@media screen and (min-width: 769px) {
  :global .applicationProfile .ant-layout-header .antd-pro-components-global-header-index-header {
    padding: 0 !important;
    height: 90px;
  }
  :global .applicationProfile .imgDiv {
    height: 90px;
  }
  :global .applicationProfile .imgDiv .logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :global .applicationProfile form.input-group-box {
    margin-top: 0;
    display: flex;
    align-items: center;
    padding-left: 70px;
  }
}
:global .login-box .d-flex button.btn {
  height: 42px !important;
}
:global .card-pane-right.listing-section ul.count {
  justify-content: start !important;
}
:global .card-pane-right.card-pane-new .pointerCls .linkText {
  font-size: 18px;
}
:global .card-pane-right.card-pane-new .pointerCls .statusLabel {
  color: black;
  font-size: 15px;
}
:global .mark-btn.mark-btn30 {
  width: 35% !important;
}
:global .mark-btn.mark-btn30 div {
  display: flex;
}
:global .mark-btn.mark-btn30 div a {
  margin: 0 5px;
}
:global .mark-btn.mark-btn40 {
  width: 50% !important;
  position: absolute;
  right: 0;
}
:global .mark-btn.mark-btn40 div {
  display: flex;
}
:global .mark-btn.mark-btn40 div a {
  margin: 0 5px;
}
:global .mark-btn.mark-btn70 {
  width: 70% !important;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
}
:global .mark-btn.mark-btn70 div {
  display: flex;
}
:global .mark-btn.mark-btn70 div a {
  margin: 0 5px;
}
:global .selectedUni {
  margin-right: 10px;
  background: #666666;
  padding: 2px 10px;
  border-radius: 5px;
  color: white !important;
  font-size: 12px;
}
:global .ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 450px !important;
  overflow-x: hidden;
  border: 0;
  border-radius: 4px 4px 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
:global .uniBox .col-lg-4 {
  margin-bottom: 20px;
}
:global .uniBox .col-lg-4 .logo img {
  height: 67px;
}
:global .featureCourseDiv .heading-form {
  position: fixed !important;
  top: 0;
  z-index: 9;
  background: white;
  width: 100%;
  padding: 10px 0;
}
:global .featureCourseDiv .cardRelative {
  position: relative;
  top: 70px;
  background: white !important;
  padding-bottom: 0 !important;
}
:global .featureCourseDiv .courseBox .col-lg-4 {
  background: #f1f1f1 !important;
  padding: 20px;
}
:global .featureCourseDiv .courseBox .newRow {
  margin-bottom: 20px;
}
:global .featureCourseDiv .courseBox .infoRow {
  padding-left: 20px;
}
:global .featureCourseDiv .courseBox .infoRow p {
  margin-bottom: 0;
}
:global .featureCourseDiv .logo {
  padding: 10px;
}
:global .featureCourseDiv .logo img {
  height: 60px;
}
:global .featureCourseDiv .nameBox {
  height: 70px;
  font-weight: bold;
  color: #0f52ba;
}
:global .featureCourseDiv .infoBox {
  /* width: 500px;
       display: flex;
       justify-content: space-between;
       align-items: center;*/
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 44%;
}
:global .featureCourseDiv .infoBox .totalInfo {
  display: flex;
  padding-left: 27px;
}
:global .featureCourseDiv .infoBox .totalInfo .totalCourseCount {
  margin-right: 50px;
  top: 4px;
}
:global .featureCourseDiv .infoBox .featureCourseHeader {
  padding: 0 25px;
}
:global .featureCourseDiv .infoBox .featureCourseHeader h5 {
  padding: 0;
}
:global .featureUniversityDiv .heading-form {
  padding: 20px 0 0 !important;
}
:global .featureUniversityDiv .ml-auto {
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .featureUniversityDiv .ml-auto i {
  margin-left: 5px !important;
}
:global .featureUniversityDiv .cardRelative {
  position: relative;
  background: white !important;
}
:global .featureUniversityDiv .cardRelative.pb-0 {
  padding-bottom: 0 !important;
}
:global .featureUniversityDiv .courseBox .col-lg-4 {
  background: #f1f1f1 !important;
  padding: 20px;
}
:global .featureUniversityDiv .courseBox .newRow {
  margin-bottom: 20px;
}
:global .featureUniversityDiv .courseBox .infoRow {
  padding-left: 20px;
}
:global .featureUniversityDiv .courseBox .infoRow p {
  margin-bottom: 0;
}
:global .featureUniversityDiv .logo img {
  height: 80px;
}
:global .featureUniversityDiv .nameBox {
  height: 80px;
  font-weight: bold;
  color: #1890ff;
}
:global .featureUniversityDiv .infoBox {
  align-self: flex-end !important;
  margin-bottom: 10px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}
:global .loadMoreDiv {
  text-align: center;
  text-transform: uppercase;
}
:global .loadMoreDiv a {
  background: #f1f1f1;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 15px;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 5px;
}
:global .loadMoreDiv a i {
  position: relative;
  bottom: 2px;
  margin-left: 5px !important;
}
:global .social.social1 {
  padding: 0 !important;
}
:global .social.social1 h6 {
  color: white;
}
:global .navbar-dark {
  background-color: transparent;
  border-color: #4b545c;
}
:global .searchAppDiv .loadMoreBox {
  text-align: right;
  text-transform: uppercase;
}
:global .searchNewBtn {
  background: #0f52ba !important;
  border-radius: 50px !important;
  color: #fff !important;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex !important;
}
:global .searchNewBtn:hover {
  opacity: 0.7;
  color: white;
}
:global .clearNewBtn {
  background: #efefef !important;
  border-radius: 50px !important;
  color: black !important;
  font-size: 14px;
  text-transform: capitalize;
  min-width: 100px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex !important;
}
:global .clearNewBtn:hover {
  opacity: 0.7;
  color: white;
}
:global .btn.apply:hover {
  opacity: 0.7;
}
:global .invalidValueField .form-control {
  border: 2px solid #ff0000;
}
:global .requirementNote {
  font-size: 12px;
  color: black;
  padding-left: 10px;
}
:global .frontFooter .social {
  padding: 0 !important;
}
:global .frontFooter .social h6 {
  color: white !important;
}
:global .frontFooter .white {
  color: white !important;
}
:global .all-student-search h5 {
  width: 20% !important;
}
:global .all-student-search h5.allAppCountry {
  width: 70% !important;
}
:global .all-student-search .search-box-table {
  width: 30%;
}
:global .all-student-search .search-box-table.round {
  margin-left: 10px !important;
  position: relative;
}
:global .all-student-search .search-box-table.round a.searchBtn1 {
  position: absolute;
  right: 0;
  top: 1px;
  background: #0872bc;
  color: white;
  padding: 7px 5px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 13px;
}
:global .all-student-search .search-box-table.ml0 {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
:global .all-student-search .form-control {
  height: 35px !important;
}
:global .search-box-table.round {
  margin-left: 10px !important;
  position: relative;
}
:global .search-box-table.round a.searchBtn1 {
  position: absolute;
  right: 0;
  top: 0px;
  background: #0872bc;
  color: white;
  padding: 11.5px 8px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 13px;
}
:global .search-box-table.ml0 {
  margin-left: 0 !important;
  margin-bottom: 10px;
}
:global header.navbar-fix nav.newNav a:first-child {
  color: black !important;
  background: none !important;
  border: none;
  margin-right: 15px;
  box-shadow: none;
  text-decoration: underline;
  font-size: 15px !important;
}
:global header.navbar-fix nav.newNav a:last-child {
  color: #fff !important;
  background: #0f52ba;
  border: none;
  margin-right: 15px;
  box-shadow: 2px 2px 4px #00000057;
}
@media screen and (max-width: 560px) {
  :global .table-head .search-box-table {
    display: none !important;
  }
  :global .all-student-search h5 {
    width: 60%;
  }
  :global .containerStu {
    margin-top: 20% !important;
  }
}
:global .recharts-legend-wrapper {
  position: relative !important;
  bottom: 35px !important;
}
:global .amt {
  font-style: normal;
  color: black;
  font-size: 13px;
  font-weight: 600;
}
:global .ant-scroll-number {
  position: absolute;
  top: 11px;
  right: 6px;
}
:global .rowWrap {
  display: flex;
  flex-wrap: wrap;
}
:global .custom-tooltip {
  background: #ffffff6e;
  padding: 10px 10px 2px 10px;
  line-height: 1.3;
  font-size: 13px;
}
:global .custom-tooltip .label {
  color: black;
  font-size: 13px;
  padding: 0;
}
:global .recharts-legend-wrapper {
  margin-top: 20px;
}
:global .barChart .recharts-legend-wrapper {
  display: none;
}
:global .barLabel_ul {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
:global .barLabel_ul.btn_option {
  display: flex;
  justify-content: space-between;
}
:global .barLabel_ul li {
  display: inline-flex;
  margin-right: 10px;
  align-items: center;
  font-size: 14px;
}
:global .barLabel_ul li .colorBox {
  height: 12px;
  width: 12px;
  margin-right: 4px;
}
:global .barLabel_ul li.lg .colorBox {
  height: 25px;
  width: 40px;
  margin-right: 4px;
}
:global .newsRowBox {
  display: flex;
  cursor: pointer;
  margin-right: 20px !important;
}
:global .newsRowBox i {
  margin-right: 5px;
  font-weight: bold;
  margin-top: 2px;
  font-size: 15px;
  color: #1890ff;
}
:global .sideMenuSoical li {
  margin-right: 10px !important;
}
:global .sideMenuSoical img {
  height: 32px;
}
:global .tableBox {
  margin-bottom: 20px;
}
:global .tableBox.striped .tableRow:nth-child(odd) {
  background-color: #f9f9f9;
}
:global .tableBox .tableRow {
  padding: 4px 10px;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
:global .tableBox .tableRow .title {
  font-weight: bold;
  width: 35%;
  height: 18px;
}
:global .tableBox .tableRow .title1 {
  font-weight: bold;
  width: 40%;
}
:global .tableBox .tableRow p {
  margin-bottom: 0;
}
:global .tableBox .tableRow:first-child {
  border-top: 1px solid #ddd;
}
:global .circleBtn {
  background: #0872bc;
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .justifyContent {
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
}
:global .meetingInfo {
  line-height: 1.8;
}
:global .login-form label {
  display: none !important;
}
:global .login-form .form-control {
  border: none !important;
}
:global .login-form input[type='text'].form-control {
  background: #e6e6e6 !important;
}
:global .login-form input[type='file'].form-control {
  background: #e6e6e6 !important;
  padding: 0;
}
:global .login-form .documents .inputBox {
  margin-bottom: 0;
}
:global .login-form .documents .inputBox .labelNew {
  height: 20px;
}
:global .login-form .documents .inputBox input[type='file'].form-control {
  height: 35px !important;
}
:global .login-form input.ant-calendar-picker-input.ant-input {
  height: 55px !important;
}
:global .login-form .form-control {
  height: 55px !important;
}
:global .login-form .ant-select-selection__placeholder,
:global .login-form .ant-select-search__field__placeholder {
  color: #666;
  font-size: 14px;
}
:global .login-form .ant-select-selection {
  background: #e6e6e6;
  border: none;
  width: 100%;
  height: 55px !important;
  padding: 0 23px;
  outline: none;
  border-radius: 6px;
  padding-right: 75px;
  font-size: 14px;
}
:global .login-form .btnSub {
  background: #0f52ba;
  color: #fff;
  height: 42px !important;
  min-width: 120px;
  border-radius: 50px;
}
:global .btn-secondary.dropdown-toggle {
  background: #0f52ba !important;
  border-radius: 50px;
  width: 150px;
  margin-right: 10px;
}
:global header.navbar-fix nav a:first-child.regLink {
  color: #666 !important;
}
:global header.navbar-fix nav a.regLink {
  color: #666 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  text-align: left;
  justify-content: flex-start;
}
:global .dropbtn {
  background: #0f52ba !important;
  border-radius: 50px;
  width: 150px;
  margin-right: 10px;
  color: #fff;
  height: 37px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem !important;
}
:global .dropbtn i {
  margin-left: 5px;
}
:global .dropbtn.lite {
  background: transparent !important;
  border: 1px solid #c1c1c1;
  color: black;
}
:global .dropbtn:focus {
  outline: none !important;
}
:global .dropdown {
  position: relative;
  display: inline-block;
}
:global .dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 10px;
  border-radius: 10px;
}
:global .dropdown-content a.regLink {
  margin-right: 0 !important;
  border-radius: 0;
}
:global .dropdown-content a.regLink:hover {
  background: #e5dddd !important;
}
:global .dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
}
:global .dropdown:hover .dropdown-content {
  display: block;
}
:global .studentRegForm {
  padding: 20px 20px;
  margin-top: 50px;
  border-radius: 5px;
  background: #fffdfd;
}
:global .studentRegForm label {
  display: block !important;
  padding: 0 !important;
  margin: 0;
}
:global .studentRegForm .ant-form-item-label {
  line-height: 28.9999px;
}
:global .studentRegForm .labelNew {
  display: block !important;
  color: #666;
  margin: 0 0 10px 0 !important;
}
:global .studentRegForm input[type='text'],
:global .studentRegForm input[type='text'].form-control,
:global .studentRegForm input[type='password'],
:global .studentRegForm input[type='email'],
:global .studentRegForm input[type='number'],
:global .studentRegForm input[type='textarea'],
:global .studentRegForm .ant-select-selection {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
:global .studentRegForm input[type='text'].ant-select-focused,
:global .studentRegForm input[type='text'].form-control.ant-select-focused,
:global .studentRegForm input[type='password'].ant-select-focused,
:global .studentRegForm input[type='email'].ant-select-focused,
:global .studentRegForm input[type='number'].ant-select-focused,
:global .studentRegForm input[type='textarea'].ant-select-focused,
:global .studentRegForm .ant-select-selection.ant-select-focused {
  background: #ffffff !important;
}
:global .studentRegForm .ant-select-search__field {
  background: transparent !important;
  padding: 0 !important;
}
:global .studentRegForm .ant-calendar-picker {
  padding: 0 !important;
}
:global .studentRegForm input.ant-calendar-picker-input.ant-input {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
:global .studentRegForm input[type='file'] {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
:global .studentRegForm input[type='file']::-webkit-file-upload-button {
  width: 120px;
}
:global .studentRegForm input[type='file'].form-control {
  background: #ffffff !important;
  border: 1px solid #e5dddd !important;
}
:global .containerStu {
  max-width: 100%;
  padding: 0 calc((100% - 1140px) / 2);
  overflow-y: scroll;
  margin-top: 5%;
  z-index: 99999;
  overflow-x: hidden;
}
:global .containerStu::-webkit-scrollbar {
  display: none;
}
:global .containerStu .inner-container {
  height: 80vh;
}
:global .login-form label.authCheckbox {
  display: block !important;
}
:global .hyperLink {
  color: #00e !important;
}
:global .hyperLinkUnderLine {
  color: #00e !important;
  text-decoration: underline;
}
:global .disbaledField {
  display: flex;
  width: 100%;
  padding: 0 11px;
  text-align: left;
  border: 1px solid #666;
  border-radius: 5px;
  outline: 0;
  background: #ededed;
}
:global .sliderBox {
  margin: 0 15px;
}
:global .sliderBox .sliderImg {
  height: 65px;
  width: 100%;
}
:global .dotNone {
  background: none !important;
}
:global .owl-theme .owl-dots {
  margin-top: 5px;
}
:global .owl-theme .owl-dots .owl-dot span {
  background-color: #666 !important;
  height: 7px !important;
  width: 7px !important;
}
:global .owl-theme .owl-dots .owl-dot.active span {
  background-color: #0872bc !important;
}
:global .owl-theme .owl-nav {
  display: none !important;
}
:global .owl-theme.owl-new .owl-nav {
  display: block !important;
}
:global .owl-theme.owl-new .owl-nav .owl-prev {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #f1f1f1;
  line-height: 0;
  font-weight: 500;
  left: -14px;
}
:global .owl-theme.owl-new .owl-nav .owl-next {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 35px;
  position: absolute;
  top: 31%;
  background: #f1f1f1;
  line-height: 0;
  font-weight: 500;
  left: 97%;
}
:global .main-footer {
  border-bottom: none !important;
}
:global .ant-time-picker,
:global .ant-calendar-picker {
  padding: 0 !important;
  border-color: #666 !important;
  box-shadow: none !important;
}
:global .form-control {
  border-color: #666 !important;
}
:global .ant-slider-mark {
  width: 82%;
  left: 9%;
}
:global .custom-slider .ant-slider-mark {
  width: 102%;
  left: 0%;
}
:global .statusRow .statusColumn {
  margin-bottom: 10px;
  text-align: center;
}
:global .statusRow .statusColumn .ant-card-body {
  text-align: center;
  height: 120px;
}
:global .statusRow .box {
  text-align: center;
}
:global .statusRow .box.active {
  color: white;
  background-color: #0872bc;
}
:global .statusRow .box img {
  height: 20px;
  margin-bottom: 5px;
}
:global .statusRow .status {
  font-size: 12px;
}
:global .statusRow .title {
  font-size: 18px;
  font-weight: bold;
}
:global .statusRow .iconDiv {
  font-size: 28px;
  padding: 1px;
}
:global .newsImg {
  margin-top: 20px;
}
:global .newsImg img {
  width: 80%;
  margin-left: 10%;
}
:global .roundGoBackBtn {
  line-height: 1.5;
  padding: 10px 15px !important;
  border-radius: 50px !important;
  border-color: #efe8e8;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px !important;
  background-color: white;
}
:global .roundGoBackBtn.icon {
  width: 36px;
}
:global .thinLabel {
  font-weight: normal !important;
}
:global .flairUniversityBlockOuter .flairUniversityBlock .innerBox {
  padding: 15px 40px;
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
  min-height: 250px !important;
}
:global .flairUniversityBlockOuter .flairUniversityBlock .imgBlock {
  background-color: white;
  padding: 10px 20px;
  border-radius: 3px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
:global .flairUniversityBlockOuter .flairUniversityBlock .imgBlock img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
:global .flairUniversityBlockOuter .flairUniversityBlock .applyBtn {
  background-color: white;
  padding: 6px 15px;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  border: none;
  border-radius: 3px;
}
:global .flairUniversityBlockOuter .flairUniversityBlock .applyBtn.dark {
  background-color: #0872bc;
  color: white;
}
:global .flairUniversityBlockOuter .slick-dots {
  position: absolute;
  bottom: 0px !important;
}
:global .flairUniversityBlockOuter .slick-dots li {
  border-bottom: none;
  background: none !important;
}
:global .flairUniversityBlockOuter .slick-dots li button {
  background: #0872bc !important;
  height: 5px !important;
}
:global .flairUniversityBlockOuter .slick-arrow.slick-prev {
  font-size: 10px;
}
:global .flairUniversityBlockOuter .ant-carousel .slick-prev::before {
  content: '<';
  z-index: 1000 !important;
  display: block;
  position: relative;
  bottom: 10px;
  right: -38px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
:global .flairUniversityBlockOuter .slick-arrow.slick-next {
  font-size: 10px;
}
:global .flairUniversityBlockOuter .ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 0px;
  left: -39px;
  bottom: 10px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
:global .countList {
  list-style: decimal;
  padding-left: 12px;
}
:global .labelDoc {
  margin-top: -2px;
  margin-bottom: 0;
}
:global .addStuInfo {
  width: 100%;
}
:global .addStuInfo .ant-row.row1 {
  width: 100%;
  display: flex;
  align-items: center;
}
:global .ant-drawer-body {
  overflow: hidden;
}
:global .ant-input-number-disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}
:global .label_sm {
  padding-left: 10px;
  font-size: 13px;
  font-weight: normal !important;
  margin-bottom: 0 !important;
}
:global .antSelect.ant-select .ant-select-selection-selected-value {
  margin-right: 10px !important;
}
:global .antSelect.ant-select .ant-select-selection {
  border: none !important;
  border-radius: 50px !important;
  font-size: 13px !important;
}
:global .antSelect.ant-select .ant-select-selection .ant-select-selection__placeholder {
  display: block;
  color: #495057 !important;
}
:global .antSelect.ant-select .ant-select-selection .ant-select-arrow {
  background: none !important;
  color: #495057 !important;
}
:global .smSelect .ant-select-selection {
  height: 25px !important;
  border-radius: 0;
}
:global .smSelect .ant-select-selection .ant-select-selection__rendered {
  line-height: 1 !important;
  margin-left: 5px !important;
}
:global .smSelect .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
  margin-right: 5px !important;
}
:global .smSelect .ant-select-selection .ant-select-arrow {
  background: none !important;
  color: #495057 !important;
  border: none;
  position: absolute;
  right: 0;
  top: 8px;
}
:global .fcmtDiv {
  text-align: center;
}
:global .fcmtDiv img {
  height: 40px;
}
:global .fcmtDiv img.sm {
  height: 32px;
}
:global .infoUni {
  background-color: white;
  font-size: 15px;
  border-radius: 5px;
  line-height: 2;
  color: black;
}
:global .infoUni .title {
  width: 18% !important;
  font-size: 13px;
  font-weight: normal !important;
  border-right: 1px solid #ddd;
  margin-right: 10px;
}
:global .infoUni span {
  color: #0872bc;
  font-size: 13px;
}
:global .darkBtn {
  background: transparent !important;
  border: 1px solid #c1c1c1 !important;
  display: inline-block;
  border-radius: 50px;
  min-width: 110px;
  color: #000 !important;
  height: 38px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
}
:global .darkBtn span {
  color: #343a40 !important;
}
:global .sprBox {
  margin-top: 7px;
  padding-top: 7px;
}
:global .customAutoComplete ul {
  width: 100%;
}
:global .customAutoComplete .ant-select-search__field {
  height: 43px !important;
  border-color: #666 !important;
}
:global .pointerCls {
  height: 40px;
  padding-top: 3px;
}
:global .pCursor {
  cursor: pointer;
}
:global .CountCircle {
  font-size: 14px !important;
  height: 25px;
  width: 25px;
  border-radius: 5%;
  line-height: 2.5;
  background: white !important;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0 0 2px #1890ff !important;
}
:global .CountCircle a {
  align-self: center;
  font-size: 12px !important;
  color: black !important;
}
:global .pointerCls1 {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding-top: 0 !important;
}
:global .pointerCls1 h5 {
  font-size: 14px !important;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
}
:global input[type='text']::placeholder,
:global input[type='password']::placeholder,
:global input[type='search']::placeholder,
:global input[type='number']::placeholder,
:global input[type='email']::placeholder,
:global input[type='file']::placeholder,
:global input[type='date']::placeholder,
:global textarea::placeholder,
:global .ant-select-selection::placeholder,
:global .ant-calendar-picker-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999 !important;
  font-size: 14px !important;
  opacity: 0.8 !important;
  /* Firefox */
}
:global .ant-select-selection__placeholder {
  color: #999 !important;
  font-size: 13px !important;
  opacity: 0.8 !important;
  /* Firefox */
}
:global .ant-select-dropdown-menu-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  /* overflow: hidden; */
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid #edededbf;
}
:global .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
  opacity: 0.5;
}
:global .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled:hover {
  opacity: 0.5 !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
:global .mailerOption input[type='radio'] {
  position: relative;
  top: 2px;
  margin-left: 10px;
}
:global .mailerOption label {
  font-weight: normal !important;
  margin-left: 5px !important;
  margin-bottom: 0 !important;
  margin-right: 5px;
}
:global .mailerOption i {
  font-style: inherit;
  margin-left: 3px;
}
:global .countLink {
  color: #1254bb;
}
:global .ck-editor__editable {
  min-height: 400px;
}
:global .quillEditor {
  background-color: white;
}
:global .quillEditor .ql-editor {
  line-height: 2;
  font-size: 15px;
}
:global .customExtra {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #00e;
  z-index: 9;
}
:global .defaultEligBtn {
  display: flex;
  border: 1px solid #ddd !important;
  justify-content: center;
  align-items: center;
  padding: 1px 10px !important;
  background: white !important;
  margin-top: 8px;
  font-size: 13px !important;
  border-radius: 50px !important;
}
:global .defaultEligBtn i {
  margin-right: 5px !important;
}
:global .eligiBtn {
  border: 1px solid #ddd !important;
  background: white !important;
  height: 27px;
  margin-top: 8px;
  font-size: 11px !important;
  border-radius: 50px !important;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px !important;
}
:global .eligiBtn i {
  margin-right: 5px !important;
}
:global .flexEndCustom {
  margin-top: 10px;
  justify-content: flex-end;
}
:global .wrapBox {
  display: flex;
  flex-wrap: wrap;
}
:global .wrapBox .widthLabel {
  font-weight: bold;
  text-decoration: underline;
  color: black;
}
:global .wrapBox .widthLabel.w100 {
  width: 100%;
}
:global .wrapBox .widthLabel.w200 {
  width: 200%;
}
:global .wrapBox label {
  font-weight: 600 !important;
}
:global .status p {
  min-height: 22px !important;
  height: auto !important;
}
:global .status p {
  min-height: 22px !important;
  height: auto !important;
}
:global .courseNameDotsReplace {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  height: 40px;
}
:global input[type='number']::-webkit-outer-spin-button,
:global input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
:global input[type='number'] {
  -moz-appearance: textfield;
}
:global .smallBtn {
  padding: 10px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .lgBtn {
  padding: 15px 25px !important;
  height: auto !important;
}
:global .image-upload > input {
  display: none;
}
:global .image-upload label {
  margin-right: 10px;
}
:global .image-upload img {
  height: 30px;
  cursor: pointer;
}
:global .pendencyTab td {
  padding: 5px !important;
}
:global .documentBox {
  padding: 0 5px;
  /* margin: 2px; */
  border-radius: 5px;
  min-height: auto !important;
}
:global .documentBox ul {
  padding-left: 0;
  margin-bottom: 0;
}
:global .documentBox .emailTag {
  position: relative;
  float: left;
  margin-right: 4px;
  padding: 2px 5px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fafafa;
  border-radius: 2px;
  white-space: nowrap;
  width: 180px;
  text-overflow: ellipsis;
}
:global .documentBox .emailIcon {
  font-size: 15px;
  position: relative;
  top: 3px;
  margin-left: 5px;
}
:global .cardSm {
  padding: 10px;
  background: white;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
:global .cardSm .pb0 {
  padding-bottom: 0;
}
:global .cardSm .smImg {
  height: 10px;
  margin-left: 5px;
}
:global .cardSm .smFont {
  margin-top: 5px;
  font-size: 11px;
}
:global .cardSm .smFont .dLab {
  color: #0872bc;
  font-weight: 600 !important;
}
:global .cardSm .dLab {
  font-size: 11px;
  margin-block: 0;
  font-weight: 600 !important;
}
:global .customTextArea {
  height: 100px !important;
}
:global .ratioBox label {
  margin-right: 20px;
}
:global .ratioBox input {
  position: relative;
  top: 2px;
  left: 3px;
}
:global .feedbackLabel {
  background: #0872bc;
  padding: 10px 50px;
  color: white;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
:global .banner11 {
  padding-bottom: 40px !important;
}
:global .feedbackInfo {
  font-size: 25px;
  text-align: center;
  padding: 50px 0;
}
:global .antd-pro-components-header-dropdown-index-container {
  z-index: 99 !important;
}
:global .float-right {
  float: right !important;
}
:global .ant-avatar-sm {
  width: 40px;
  height: 40px;
  line-height: 24px;
  border-radius: 50%;
}
:global .avatarBig .ant-avatar-sm {
  width: 50px;
  height: 50px;
  line-height: 24px;
  border-radius: 50%;
}
:global .card-body.table-responsive {
  padding-top: 0px !important;
}
:global .ant-table-pagination.ant-pagination {
  margin-top: 0px !important;
  margin-bottom: 7px !important;
}
:global .slimBtn .fee-box .box .btn-group button {
  display: flex;
  width: 100%;
  border: 2px solid #ededed;
  background: #e6e7e8;
  font-size: 13px;
  text-transform: capitalize;
  margin: 0 0 10px;
  height: 30px;
  align-items: center;
  padding: 0 10px;
}
:global section.gic-revenue {
  border-radius: 15px;
}
:global section.gic-revenue a.main-btn {
  background: #11148a;
  font-size: 15px;
  color: #fff;
  border-radius: 12px;
  padding: 12px 25px;
  text-transform: capitalize;
  height: auto;
}
:global section.gic-revenue .list .box {
  box-shadow: inset 0 0 0 2px #e6e6e6;
  border-radius: 18px;
  padding: 10px 20px;
  margin: 0 0 15px;
  display: flex;
  padding-left: 80px;
  position: relative;
  flex-direction: column;
  text-align: left;
}
:global section.gic-revenue .list .box span {
  min-width: 50px;
  height: 50px;
  display: inline-flex;
  background: #e6e6e6;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  position: absolute;
  left: 20px;
  top: 0;
  bottom: 0;
  margin: auto;
}
:global section.gic-revenue .list .box h4 {
  font-size: 23px;
  font-weight: 600;
  color: #4d4d4d;
  line-height: normal;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  margin: 0;
}
:global section.gic-revenue .list .box p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  color: #666666;
}
:global section.gic-revenue .list .box span img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
:global section.gic-revenue .list button.btn {
  background: #5bb85d;
  border-radius: 18px;
  width: 100%;
  color: #fff;
}
:global section.gic-revenue .list button.btn span {
  display: block;
  font-size: 18px;
  font-weight: 600;
}
:global .flexRow {
  display: flex;
  flex-wrap: wrap;
}
:global .flexRow span {
  padding: 3px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
}
:global .flexRow .cross {
  margin-left: 10px;
  position: relative;
  top: -1px;
}
:global .actBy {
  font-weight: normal;
  color: #745e5e;
  margin-left: 27px;
  padding: 0;
  height: 15px;
  margin-bottom: 5px;
  position: relative;
  top: -5px;
}
:global table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
:global table tbody tr:nth-child(odd) {
  background: white;
}
:global .input-group-inner.field1 {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-right: 1px solid #00000017;
}
:global .input-group-inner.field1 .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
:global .input-group-inner.field2 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  padding-left: 20px !important;
}
:global .input-group-inner.field3 {
  border-radius: 50px !important;
  border-right: none;
}
:global .input-group-inner.field3 .form-control {
  border-radius: 50px !important;
}
:global .icefBlock {
  display: flex;
  flex-direction: row;
  background: #ffffff7a;
  font-weight: 700;
  line-height: 36px;
  align-items: center;
  border-left: 3px solid #1890ff;
  padding: 10px 30px 10px 10px;
  box-shadow: 2px 5px 10px #e9c4c4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
  width: 80%;
}
:global .icefBlock h1 {
  font-size: 20px !important;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 !important;
}
:global .icefBlock img {
  max-width: 60px;
}
:global .outMotto {
  font-size: 16px !important;
}
:global .card.unizportal.regStu {
  padding: 20px;
}
:global .student-application-detail.student-application-new .inputBox {
  margin-bottom: 0 !important;
}
:global .student-application-detail.student-application-new .card .form-group label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 !important;
}
:global .student-application-detail.student-application-new.addCourseForm .card .form-group label {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
  margin: -10px 6px -5px !important;
}
:global .student-application-detail.student-application-new h5 {
  font-size: 1rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
:global .student-application-detail.student-application-new .detail-list ul {
  padding-left: 0;
}
:global .student-application-detail.student-application-new .detail-list ul li {
  padding: 5px 0 !important;
}
:global .student-application-detail.student-application-new .detail-list ul li strong {
  width: 30%;
}
:global .student-application-detail.student-application-new .manageBox {
  background-color: white;
}
:global .student-application-detail.student-application-new .manageBox .managerHeader {
  background-color: gray;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  position: absolute;
  right: 10px;
  border-bottom-left-radius: 10px;
}
:global .student-application-detail.student-application-new .manageBox .managerBoxOuter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
:global .student-application-detail.student-application-new .manageBox .managerBoxOuter .manageBoxInfo {
  text-align: left;
  font-size: 12px;
  color: #585858;
  padding: 20px 0 20px 20px;
}
:global .student-application-detail.student-application-new .add-application-btn {
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 0px 0 0 !important;
  border-radius: 50px;
  font-weight: 400;
  width: auto;
  height: auto !important;
}
:global .fee-box .box .btn-group button.profileBtn {
  border: 2px solid #ededed;
  background: #e6e7e8 !important;
  border-radius: 5px;
  text-align: center;
  height: 30px;
  align-items: center;
  justify-content: center;
}
:global .login-box .form-group .countryCode img {
  position: relative;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin-right: 5px;
}
:global .referBtn {
  background-color: #0f52ba;
  color: white;
  text-align: center;
  margin: 10px;
  border-radius: 3px;
  padding: 0 20px 20px 10px;
}
:global .referBtn .ant-badge {
  position: relative;
  top: -17px;
}
:global .referBtn .ant-badge img {
  margin-right: 10px !important;
  height: 20px;
}
:global .referBtn a:hover {
  color: white !important;
}
:global .referCodeBox {
  border-color: #0f52ba;
  border-style: dashed;
  padding-top: 5px;
  padding-bottom: 5px;
}
:global .referHeader {
  background-color: #0f52ba;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-top: 10px;
}
:global .marketingHeader {
  background-color: white;
  border: 1px solid #ddd;
  padding: 15px 20px;
  margin-top: 10px;
  color: black;
  font-size: 18px;
}
:global .marketingHeader .fa {
  margin-right: 5px;
}
:global .referInfoBox {
  margin-top: 30px;
  text-align: center;
}
:global .conditionList {
  flex: 1;
  padding-left: 15px;
  padding-right: 30px;
}
:global .conditionListText {
  font-size: 12px;
}
:global .reportingBox {
  padding: 15px;
  background-color: white;
  margin: 5px;
  text-align: center;
}
:global .reportContainer .reportBox {
  margin: 20px 0;
}
:global .reportContainer .reportingBox {
  padding: 0 !important;
  box-shadow: 1px 1px 10px #b3b3b36b !important;
  background-color: #1890ff;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
:global .reportContainer .reportingBox .arrowIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
:global .reportContainer .reportingBox .reportingInnerBox {
  border-radius: 5px;
  border-bottom-right-radius: 80px !important;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
}
:global .reportContainer .reportingBox img {
  margin-bottom: 10px;
  height: 30px;
}
:global .reportContainer .reportingBox .anticon {
  margin-left: 10px;
}
:global .reportContainer .reportingBox .reportInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
:global .reportContainer .reportingBoxLiveReporting {
  padding: 0 !important;
  box-shadow: 1px 1px 10px #b3b3b36b !important;
  background-color: white;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
:global .reportContainer .reportingBoxLiveReporting .arrowIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;
}
:global .reportContainer .reportingBoxLiveReporting .reportingInnerBox {
  border-radius: 5px;
  border-bottom-right-radius: 80px !important;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
}
:global .reportContainer .reportingBoxLiveReporting img {
  margin-bottom: 10px;
  height: 30px;
}
:global .reportContainer .reportingBoxLiveReporting .anticon {
  margin-left: 10px;
}
:global .reportContainer .reportingBoxLiveReporting .reportInfoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}
:global .linkText {
  color: #0066ff;
  cursor: pointer;
}
:global .customGrpBtn .ant-input-group-addon {
  padding: 0 !important;
}
:global .customGrpBtn .ant-input-group-addon a {
  padding: 5px 15px !important;
  border-radius: 3px;
}
:global .customGrpBtn .ant-input-group-addon a:first-child {
  border-right: 1px solid #ddd !important;
}
:global .customGrpBtn .selected {
  background-color: #1890ff;
  color: white;
}
:global .othAppContainer {
  margin-top: 10px;
  margin-bottom: 50px;
}
:global .othAppContainer .flexColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
:global .othAppContainer .flexColumn .otherAppList {
  width: 32%;
}
:global .othAppContainer .otherAppList {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px #ddd;
  border-radius: 5px;
  padding: 10px 10px 5px;
  flex-direction: column;
  margin-top: 10px;
}
:global .othAppContainer .otherAppList .leftBox {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
:global .othAppContainer .otherAppList .leftBox img {
  width: 120px !important;
  height: auto !important;
  margin-bottom: 10px;
}
:global .othAppContainer .otherAppList .rightBox {
  text-align: left;
  width: 100%;
}
:global .othAppContainer .otherAppList .rightBox .label1 {
  font-size: 10px !important;
}
:global .othAppContainer .otherAppList .rightBox a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  width: 100%;
}
:global .othAppContainer .otherAppList .rightBox a p {
  color: #495057;
  font-size: 15px;
}
:global .photoShortName {
  max-height: 35px;
  margin-bottom: 5px;
  padding: 5px 10px 0;
}
:global .photoShortName a {
  color: black !important;
  display: block;
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
:global .followUp {
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: #ddd !important;
}
:global .studentRegForm .form-group input {
  padding-right: 10px !important;
}
:global .forgetEmailText {
  font-size: 16px !important;
  margin-top: 10px;
  text-transform: capitalize;
}
:global .forgetEmailText strong {
  color: #1890ff;
  font-weight: 500;
}
:global .gcKey {
  height: 250px !important;
}
:global .oshcBox {
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 3px 5px;
  border-radius: 5px;
  background: #1690ff;
  color: white;
  font-weight: bold;
}
:global .oshcBox img {
  height: 18px;
  margin-right: 5px;
}
:global .noteMain {
  overflow: hidden !important;
}
:global .student-tab {
  overflow: hidden !important;
}
:global .student-tab .loadMoreBoxDiv {
  text-align: center;
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1 !important;
}
:global .student-tab .noteTitle {
  color: #111b21;
}
:global .student-tab .noteRow {
  background-color: white !important;
  position: relative;
}
:global .student-tab .noteRow .noteContainer {
  height: 80vh;
  overflow-y: auto;
}
:global .student-tab .noteRow .noteColumn .card {
  background: white !important;
  border: 1px solid #1890ff57;
  line-height: 1.5;
}
:global .student-tab .noteRow .noteColumn .card .dateBox {
  text-align: left;
  font-weight: bold;
  color: #0872bc;
}
:global .student-tab .noteRow .noteColumn .card .userBox {
  text-align: right;
  color: #667781;
  padding: 0;
  line-height: 1;
  font-size: 15px;
}
:global .student-tab .noteRow .noteColumn.noteRight {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
:global .student-tab .noteRow .noteColumn.noteRight .card {
  border: 1px solid #5cb85c57;
}
:global .student-tab .noteRow .noteColumn.noteRight .card .dateBox {
  text-align: right;
}
:global .student-tab .noteRow .noteColumn.noteRight .card .userBox {
  text-align: right;
}
:global .student-tab .noteRow .noteBox {
  width: 75% !important;
}
:global .student-tab .noteRow .noteBox .card {
  background: #fff;
}
:global .student-tab .noteRow .noteBox .inner.card {
  padding: 10px 15px !important;
}
:global .textAreaCls {
  height: 200px;
}
:global .textAreaCls textarea.form-control {
  height: 200px !important;
}
:global .loginCredentialsBox {
  border: 0.3px solid #e6e7e8;
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #e6e7e8;
}
:global .loginCredentialsBox .header {
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
}
:global .localLoginCredentialsBox {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #0f52ba2e;
  border-radius: 10px;
  margin-bottom: 30px;
}
:global .localLoginCredentialsBox .header {
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
}
:global .localLoginCredentialsBox .header .loginBanner {
  margin-bottom: 10px;
}
:global .localLoginCredentialsBox .header .loginIcon {
  margin-bottom: 10px;
  height: 70px;
}
:global .localLoginCredentialsBox .verticalPadding10 {
  padding-left: 10px;
  padding-right: 10px;
}
:global .localLoginCredentialsBox .verticalPadding10 .label1 {
  position: relative;
  padding: 4px;
  font-size: 13px;
  color: #555;
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
:global .localLoginCredentialsBox .verticalPadding10 .label2 {
  position: relative;
  padding: 4px;
  font-size: 13px;
  color: #555;
  display: flex !important;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
:global .localLoginCredentialsBox .verticalPadding10 .label2 .btn-info {
  background-color: #0f52ba;
  border-color: #0f52ba;
}
:global .localLoginCredentialsBox .verticalPadding10 .rowC {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #f1f1f1;
  width: 100%;
}
:global .localLoginCredentialsBox .verticalPadding10 .rowC:last-child {
  border-bottom: none;
}
:global .localLoginCredentialsBox .verticalPadding10 .rowC:last-child span {
  margin-top: 5px;
}
:global .localLoginCredentialsBox .verticalPadding10 .rowC img {
  height: 20px;
  margin-right: 8px;
}
:global .localLoginCredentialsBox .verticalPadding10 .rowC span {
  border: none;
  padding: 0;
  flex: 1;
  text-transform: capitalize;
  display: flex;
}
:global .commissionBox .commissionParentBox {
  display: flex;
  flex-direction: column;
}
:global .commissionBox .commissionParentBox.row {
  flex-direction: row;
}
:global .commissionBox .commissionParentBox.row .commissionUniInfo {
  flex: 1;
  margin: 0 10px 20px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo {
  border: none;
  box-shadow: 1px 1px 10px #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .ant-card-body {
  padding: 10px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo img {
  width: max-content;
  height: 50px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo div[class*='ant-col'] {
  display: flex;
  justify-content: center;
  height: 70px;
  flex-direction: column;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .logoBox {
  border-right: 1px solid #ddd;
  padding-right: 30px;
  align-items: center;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .contantBox {
  padding-left: 50px !important;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox {
  display: flex;
  min-height: 60px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentProfile {
  width: 20%;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentProfile img {
  height: 60px;
  max-width: 100%;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox .studentInfo {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 5px 5px 5px 40px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart {
  border-right: 1px solid #f1f1f1;
  padding-right: 30px;
  width: 30%;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:first-child {
  width: 25%;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:last-child {
  border: none;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .studentInfo {
  justify-content: flex-start;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .uniLogo {
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart .uniLogo img {
  height: 50px;
  width: 50px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew {
  border-right: 1px solid #f1f1f1;
  padding-right: 30px;
  width: 22.5%;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew:first-child {
  width: 25%;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew:last-child {
  border: none;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .studentInfo {
  justify-content: flex-start;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .uniLogo {
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStartNew .uniLogo img {
  height: 50px;
  width: 50px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex {
  width: 15%;
  padding-left: 20px;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .uniName {
  font-size: 18px;
  color: #0f52ba;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .courseInfo {
  font-size: 15px;
  color: black;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .courseInfo.sm {
  font-size: 14px;
  color: #555;
}
:global .commissionBox .commissionParentBox .commissionUniInfo .commissionInfo {
  font-size: 14px;
  color: #0872bc;
  font-weight: 600;
}
:global .commissionBox .inputGroup {
  margin-top: 10px;
}
:global .commissionBox .inputGroup .input-group-text {
  background-color: #e9ecef;
  border-color: #666;
}
:global .commissionBox textarea.form-control {
  height: 50px !important;
}
:global .comBtnGroup {
  display: flex;
  flex-wrap: wrap;
}
:global .comBtnGroup .btn {
  margin-bottom: 3px;
  font-size: 13px !important;
  padding: 0 4px !important;
  width: 180px;
}
:global .comBtnGroup .btn img {
  margin-right: 5px;
  height: 13px;
}
:global .comBtnGroup .btn-view {
  border: 1px solid #d9d9d9 !important;
  color: #212529 !important;
  background-color: white;
}
:global .darkFont {
  color: black;
}
:global .dangerPos {
  position: absolute;
  left: 10px;
}
:global .commissionListBox {
  margin-top: 20px;
  position: relative;
}
:global .commissionListBox .addMoreCommissionBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}
:global .student-group {
  margin-top: 10px;
  margin-right: 10px;
}
:global .student-group label {
  margin: 0 10px 4px 3px !important;
  color: black;
  font-weight: 500 !important;
}
:global .student-group .form-control {
  border-color: #e5dcdc !important;
  background-image: none !important;
  height: 35px !important;
  border-radius: 5px !important;
}
:global .student-group .input-group-text {
  border-color: #e5dcdc !important;
  height: 35px !important;
}
:global .uniInfoLogo {
  border-right: 1px solid #f1f1f1;
  padding-right: 20px;
  margin-right: 20px;
}
:global .uniInfoLogo img {
  height: 70px !important;
}
:global .textCap {
  text-transform: capitalize;
}
:global .textUpp {
  text-transform: uppercase;
}
:global .customCheckbox {
  cursor: pointer;
  text-align: center;
}
:global .customCheckbox img {
  height: 25px;
}
:global .universityParentBox {
  display: flex;
  flex-direction: column;
  height: 100%;
}
:global .universityParentBox .commissionUniInfo {
  border: none;
  box-shadow: 1px 1px 10px #ddd;
  border-radius: 5px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
:global .universityParentBox .commissionUniInfo .ant-card-body {
  justify-content: center;
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
}
:global .universityParentBox .commissionUniInfo .uniLogo {
  border-right: none;
}
:global .universityParentBox .commissionUniInfo .uniLogo img {
  height: 70px !important;
  width: 100%;
}
:global .universityParentBox .commissionUniInfo .studentInfoBox {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
:global .universityParentBox .commissionUniInfo .ant-card-body {
  padding: 10px;
}
:global .universityParentBox .commissionUniInfo img {
  width: max-content;
  height: 50px;
}
:global .universityParentBox .commissionUniInfo .uniName {
  font-size: 18px;
  color: #0f52ba;
}
:global .filterCard .ant-select .ant-select-selection--single {
  border: 1px solid #e9d5d5 !important;
}
:global .filterCard .search-box-table.round {
  border: 1px solid #e9d5d5 !important;
}
:global .filterCard .roundBtn {
  border: 1px solid #e9d5d5 !important;
}
:global .statusDate {
  margin-top: 5px;
  font-size: 13px;
  color: #0f52ba;
  font-weight: 500;
}
:global .btn-warning {
  background-color: rgba(240, 173, 78, 0.87) !important;
  color: white !important;
}
:global .btn-success {
  border-color: #0872bc !important;
  background-color: #0872bc !important;
  color: white !important;
}
:global .btn-view {
  border: 1px solid #d9d9d9 !important;
  color: #212529 !important;
  background-color: white !important;
  margin-top: 0 !important;
}
:global .btn-view.sm {
  padding: 0.25rem 0.8rem;
  font-size: 0.8rem;
  line-height: 1.5;
}
:global .label-info-new {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  box-shadow: none;
}
:global .btn-paid {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  color: white !important;
}
:global .label-paid {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
  color: white !important;
}
:global .view-btn-box {
  margin-top: 5px;
  /* .eyeBtn {
       height: 20px;
     }*/
}
:global .view-btn-box .btn-view {
  font-size: 12px !important;
  border: 1px solid #d9d9d9;
  height: 32px;
  padding: 2px 5px;
  border-radius: 5px;
  color: #212529 !important;
  margin-right: 5px;
}
:global .view-btn-box .btn-view img {
  height: 17px;
}
:global .contract {
  text-transform: uppercase;
  margin-top: 5px;
  color: black;
}
:global .contract a {
  text-decoration: underline;
  font-weight: 500;
}
:global .customInput {
  margin-top: 8px;
}
:global .customInput label {
  margin-bottom: auto;
}
:global .table-head .sort-box-table.sort-box-table30 {
  width: 30% !important;
}
:global .flexRowBox {
  display: flex;
}
:global .flexRowBox .flexBox1 {
  flex: 1;
  width: 30%;
  margin-right: 10px;
  background-color: #f1f1f1 !important;
  margin-bottom: 18px;
}
:global .flexRowBox .flexBox2 {
  flex: 2;
}
:global .imgFlex {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
}
:global .imgFlex .img-column {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  margin-right: 10px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .imgFlex .img-column img {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  :global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart {
    width: 33%;
  }
  :global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:first-child {
    width: 33%;
  }
  :global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:nth-child(3) {
    border: none;
  }
  :global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.flexStart:last-child {
    border: none;
  }
  :global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex {
    width: 50%;
    padding-left: 20px;
  }
  :global .commissionBox .commissionParentBox .commissionUniInfo .studentBox.inputFlex .student-group {
    margin-top: 0;
  }
  :global .commissionBox .commissionParentBox .commissionUniInfo .uniName {
    font-size: 15px;
    color: #0f52ba;
  }
  :global .commissionBox .commissionParentBox .commissionUniInfo .courseInfo {
    font-size: 12px;
    color: black;
  }
  :global .commissionBox .commissionParentBox .commissionUniInfo .courseInfo.sm {
    font-size: 12px;
    color: #555;
  }
  :global .commissionBox .commissionParentBox .commissionUniInfo .commissionInfo {
    font-size: 12px;
    color: #0872bc;
    font-weight: 600;
  }
  :global .universityParentBox {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  :global .universityParentBox .commissionUniInfo {
    border: none;
    box-shadow: 1px 1px 10px #ddd;
    border-radius: 5px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  :global .universityParentBox .commissionUniInfo .ant-card-body {
    justify-content: center;
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  :global .universityParentBox .commissionUniInfo .uniLogo {
    border-right: none;
  }
  :global .universityParentBox .commissionUniInfo .uniLogo img {
    height: 70px !important;
    width: 100%;
  }
  :global .universityParentBox .commissionUniInfo .uniName {
    font-size: 15px;
    color: #0f52ba;
  }
  :global .customInput {
    margin-top: 8px;
  }
  :global .customInput label {
    margin-bottom: 0;
  }
}
:global .round-date-picker .ant-calendar-picker-input {
  height: 42px !important;
  border-radius: 50px;
  border: none;
}
:global .verifyOfferBox {
  position: relative;
  overflow: hidden;
  height: auto;
  padding-top: 7%;
  padding-bottom: 90px;
  min-height: 80vh;
}
:global .verifyOfferBox h3 {
  font-size: 32px;
  text-transform: capitalize;
  margin: 0 0 10px;
  color: #0f52ba;
}
:global .verifyOfferBox .store {
  padding-top: 7%;
}
:global .verifyOfferBox .store img {
  width: 100%;
}
:global .verifyOfferBox .iframeBox {
  margin: 5% 15% 100px;
  border: 1px solid #ddd;
  padding: 10px;
}
:global .announcement_banner {
  background-color: white;
  max-height: 250px;
  width: 100%;
  display: flex;
  justify-content: center;
}
:global .announcement_banner img {
  width: auto;
  height: 250px;
}
:global .announcement_banner .applyBtn {
  position: absolute;
  bottom: 15px;
  width: 350px !important;
}
:global .certificate_box .btn-default {
  margin-bottom: 10px;
  width: 220px;
}
:global .certificate_box .ant-tag {
  margin-bottom: 5px;
  border-color: #007bff33;
}
:global .certificate_box .ant-tag .btn-link {
  font-weight: 400;
  color: #007bff !important;
  text-decoration: none;
}
:global .duplicateRecordLink {
  font-size: 15px;
  margin-bottom: 10px;
}
:global .broadcast_info {
  font-size: 25px;
  text-align: center;
  padding: 50px 0;
  min-height: 500px;
}
:global .broadcast_info img {
  height: 150px;
  margin-bottom: 20px;
}
:global .addInfo {
  padding-left: 3px !important;
}
:global .addInfo .box {
  position: relative;
  margin-bottom: 5px;
  display: flex !important;
}
:global .addInfo .box img {
  height: 22px !important;
  width: auto !important;
}
:global .addInfo .box span {
  margin-left: 27px;
}
@media (max-width: 600px) {
  :global .verifyOfferBox {
    position: relative;
    overflow: hidden;
    height: auto;
    padding-top: 100px;
    padding-bottom: 90px;
    min-height: 80vh;
  }
  :global .verifyOfferBox h3 {
    font-size: 32px;
    text-transform: capitalize;
    margin: 0 0 10px;
    color: #0f52ba;
  }
  :global .verifyOfferBox .store {
    padding-top: 7%;
  }
  :global .verifyOfferBox .store img {
    width: 100%;
  }
  :global .verifyOfferBox .iframeBox {
    margin: 5% 2% 100px;
    border: 1px solid #ddd;
    padding: 10px;
    overflow: auto;
  }
}
:global .counsellerDashboard {
  width: auto;
}
:global .counsellerDashboard .main-logo {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
:global .counsellerDashboard .counseller-card {
  width: 100%;
  height: 50%;
  border: 1px solid lightgrey;
}
:global .counsellerDashboard .counseller-card .countryInfo {
  text-align: center;
}
:global .counsellerDashboard .counseller-card .countryInfo .map {
  width: auto;
  height: 100px;
}
:global .counsellerDashboard .counseller-card .countryInfo h5 {
  color: #000;
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 20px;
}
:global .counsellerDashboard .counseller-card .counselling-container {
  position: relative;
  min-height: 200px;
  border-bottom: 1px solid #ddd;
}
:global .counsellerDashboard .counseller-card .counselling-container .waitingBox {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #00000033;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
:global .counsellerDashboard .counseller-card .counselling-container .waitingBox .waitingLabel {
  font-size: 25px;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  font-size: 15px;
  flex-direction: column;
  padding: 20px 20px;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card:nth-child(0) {
  border-bottom: 1px solid lightgrey;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title {
  width: 100%;
  padding: 10px;
  padding-bottom: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  border-radius: 10px;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title img {
  border-radius: 50px;
  margin-right: 15px;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title .box-title {
  font-size: 12px;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo-card-title .tarundiv {
  display: flex;
  flex-direction: column;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .waitingInfo {
  display: flex;
  margin-bottom: 20px;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .waitingInfo .counsellerInfo-num {
  background-color: #ddd;
  border-radius: 40px;
  padding: 0 10px;
  margin-left: 10px;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo {
  width: 100%;
  height: 50%;
  font-size: 15px;
  display: flex;
  justify-content: left;
  align-items: center;
}
:global .counsellerDashboard .counseller-card .counselling-container .counsellerInfo-card .counsellerInfo.active {
  color: #0872bc;
}
:global .comment-boxx {
  border-bottom: lightgrey;
}
:global .student-informationn {
  display: flex;
  padding: 9px 14px;
}
:global .studentprofile {
  display: flex;
  flex-direction: row;
}
:global .studentprofile .image {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
}
:global .studentprofile .image img {
  border-radius: 100%;
}
:global .studentprofile .descrip {
  width: 65%;
}
:global .studentprofile .descrip .border {
  width: 60%;
  border: 2px solid gray;
  padding: 10px;
}
:global .imagee {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
:global .imagee img {
  border-radius: 100%;
}
:global .studentinformationboxx {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
:global .nameandnumberboxx {
  height: 40%;
}
:global .localLoginCredentialsBoxx {
  margin-top: 10px;
  padding: 10px;
  background-color: white;
  box-shadow: 1px 1px 10px #0f52ba2e;
  border-radius: 10px;
  margin-bottom: 10px;
}
:global .centerpage {
  display: flex;
  align-items: center;
  justify-content: center;
}
:global .centerpage .borderr {
  width: 50%;
}
:global .dateandhistory {
  height: 50px;
}
:global .studentListContainer {
  margin-bottom: 5px;
}
:global .studentListContainer.active .otherAppList {
  background-color: #fff8e1;
}
:global .studentListContainer .otherAppList {
  display: flex;
  padding: 10px 10px 5px;
  background-color: white;
  box-shadow: 0 0 10px #f1f1;
  border-radius: 5px;
  margin-bottom: 10px;
}
:global .studentListContainer .otherAppList .leftBox {
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
:global .studentListContainer .otherAppList .leftBox .logo1 {
  width: 200px;
  height: 200px;
}
:global .studentListContainer .otherAppList .leftBox img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
:global .studentListContainer .otherAppList .leftBox .studentDetails {
  line-height: 1.5;
}
:global .studentListContainer .otherAppList .leftBox .leftBox1 {
  background-color: gray !important;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
:global .studentListContainer .otherAppList .leftBox .leftBox1 img {
  width: 50px !important;
  height: 50px !important;
  margin-right: 20px;
}
:global .studentListContainer .otherAppList .leftBox .leftBox1 .studentDetails {
  line-height: 1.5;
}
:global .studentListContainer .otherAppList .rightBox {
  margin-top: 10px;
  text-align: left;
}
:global .studentListContainer .otherAppList .rightBox a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}
:global .studentListContainer .otherAppList .rightBox a p {
  color: #495057;
  font-size: 15px;
}
:global .closeconvert {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}
:global .flaguk {
  margin-left: 466px;
  width: 25px;
}
:global .nameanddate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:global .backanddot {
  display: flex;
  align-items: center;
  justify-content: center;
}
:global .onoffdot {
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: 10px;
}
:global .onoffdot .innerdot {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
}
:global .picandmore {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
:global .caseclose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}
:global .caseclose .caseclosebtn {
  width: 100px;
  background: #0872bc;
  font-weight: 500 !important;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  border: none;
  outline: none;
}
:global .myTextarea {
  padding: 10px;
}
:global .directUniCount {
  width: 16.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
:global .directUniCount .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
:global .directUniCount .uniCountBox img {
  height: 30px;
  width: auto;
}
:global .directUniCount .uniCountBox h5 {
  font-size: 14px !important;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
:global .canadaDirectUniCount.canadaDirectUniCount2 {
  width: 25% !important;
}
:global .canadaDirectUniCount2 .innerBox {
  border: 1px solid #ccc;
}
:global .card.card-pdf {
  background: white !important;
}
:global .canadaDirectUniCount {
  width: 19.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
:global .canadaDirectUniCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
:global .canadaDirectUniCount .innerBox .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
:global .canadaDirectUniCount .innerBox .uniCountBox img {
  height: 30px;
  width: auto;
}
:global .canadaDirectUniCount .innerBox .uniCountBox img.sm {
  height: 25px;
}
:global .canadaDirectUniCount .innerBox .uniCountBox img.md {
  height: 35px !important;
}
:global .canadaDirectUniCount .innerBox .uniCountBox h5 {
  font-size: 14px;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
:global .canadaDirectUniCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
}
:global .canadaDirectUniCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .canadaDirectUniCount .innerBox .btnBox a i {
  margin-right: 5px;
}
:global .canadaDirectUniCount .innerBox .dirUniAgent {
  padding: 10px 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 27px;
}
:global .filter-row.ant-row {
  display: flex;
  flex-wrap: wrap;
}
:global .commissionBlock {
  margin: 20px 0;
}
:global .commissionBlock .countryCommission {
  padding: 20px;
  text-align: center;
}
:global .commissionBlock .countryCommission .countryName {
  font-size: 16px !important;
  height: 50px;
}
:global .commissionBlock .countryCommission .countryFlag {
  margin: 25px 0;
}
:global .commissionBlock .countryCommission .countryFlag img {
  height: 30px;
}
:global .commissionBlock .countryCommission .currencyName {
  font-size: 16px !important;
}
:global .commissionBlock .countryCommission .countryAmount {
  font-size: 25px;
  cursor: pointer;
}
:global .commissionBlock .countryCommission .canada {
  color: #ad2125;
}
:global .commissionBlock .countryCommission .uk {
  color: #ff0066;
}
:global .commissionBlock .countryCommission .australia {
  color: #003399;
}
:global .card.bgWhite {
  background: white !important;
}
:global .ant-col-md-0 {
  display: block;
}
:global .squareContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
:global .squareContainer .squareStyle {
  width: 45px;
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
}
:global .squareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
:global .squareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
:global .squareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
:global .squareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
:global .squareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
:global .ukSquareContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 15px;
  grid-column-gap: 5px;
}
:global .ukSquareContainer .squareStyle {
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  text-align: center;
}
:global .ukSquareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
:global .ukSquareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
:global .ukSquareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
:global .ukSquareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
:global .ukSquareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
:global .dirUniLabelBox {
  display: flex;
  padding: 30px 2.4rem 0 !important;
}
:global .dirUniLabelBox div {
  margin: 0 20px;
  padding: 0 10px;
}
:global .dirUniLabelBox div:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
:global .dirUniLabelBox div:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
:global .dirUniLabelBox div:nth-child(3) {
  border-bottom: 2px solid #5bc0de;
}
:global .ledgerBox {
  padding: 20px 3%;
}
:global .ledgerBox .header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}
:global .ledgerBox .header .ant-col {
  color: black;
  font-weight: 500;
  padding-bottom: 10px;
}
:global .ledgerBox .ant-col:nth-child(3) {
  text-align: right;
}
:global .ledgerBox .ant-col:last-child {
  text-align: right;
}
:global .ledgerBox .ant-row {
  padding: 10px 10px 0;
}
:global .ledgerBox .ant-row:nth-child(even) {
  background-color: #f7f7f7;
}
:global .ledgerBox .ant-row:nth-child(odd) {
  background-color: #ff11ff08;
}
:global .ledgerBox .ant-row.header {
  background-color: white;
}
:global .ledgerBox .debit {
  color: #d9534f;
  font-weight: 600;
}
:global .ledgerBox .credit {
  color: #5cb85c;
  font-weight: 600;
}
:global .ledgerBox .dateBox {
  font-size: 15px;
  font-weight: 500;
  color: #0872bc;
  padding-bottom: 10px;
}
:global .filterBox .ant-calendar-picker-input {
  border-radius: 50px;
  border: none;
}
:global .filterBox .search-box-table.round {
  margin-left: 0 !important;
}
:global .agentName {
  display: flex;
}
:global .agentName .agentOnline {
  height: 7px;
  width: 7px;
  background-color: #5cb85c;
  border-radius: 50%;
  margin-left: 5px;
}
:global .display-linebreak {
  white-space: pre-line;
}
:global .hrFooter {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
:global .selectable-box {
  border: 1px solid #e5d9d9;
  margin: 5px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 5px 10px;
  border-radius: 5px;
}
:global .box-text {
  font-size: 14px;
}
:global .countCircle {
  font-size: 12px !important;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  line-height: 0.9;
  background: #0872bc;
  color: white !important;
  margin-left: 7px;
  margin-top: 2px;
  padding: 5px;
  display: inline-block;
}
:global .selectable-box.selected {
  background-color: #007bff;
  color: white;
}
:global .selectable-box.selected .countCircle {
  background: #5cb85c;
}
:global .intakeRow {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  margin-top: 10px;
}
:global .intakeRow .singleIntakeBox {
  border: 1px solid #d9d9d9;
  padding: 0 5px;
  background: #fafafa;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
}
:global .intakeRow .singleIntakeBox .singleIntakeBox-check {
  height: 12px;
  width: 12px;
  margin-right: 5px;
  bottom: 3px;
}
:global .intakeRow .singleIntakeBox.selected {
  background-color: #1890ff;
}
:global .intakeRow .singleIntakeBox.selected i {
  color: white !important;
}
:global .intakeRow .singleIntakeBox i {
  font-style: normal;
  color: rgba(0, 0, 0, 0.65);
}
:global .trainingBox {
  text-align: center;
  margin-top: 50px;
}
:global .trainingBox img {
  height: 150px;
}
:global .trainingBox .textBox {
  margin-top: 20px;
}
:global .trainingBox .textBox h3 {
  font-weight: 600;
  text-transform: uppercase;
  font-family: ui-monospace;
  font-size: 30px;
  margin-bottom: 3%;
}
:global .trainingBox .textBox.normal h3 {
  text-transform: capitalize;
}
:global .trainingBox .textBox .confirmLink {
  font-size: 17px;
  background-color: #0f52ba;
  color: white;
  padding: 6px 30px;
  text-transform: capitalize;
  border-radius: 5px;
}
:global .marketingUserBox {
  margin-bottom: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 1px 10px 10px #ddd;
}
:global .marketingUserBox.managerBoxCanada {
  width: 100%;
  margin-bottom: 0;
}
:global .marketingUserBox.managerBoxCanada .ant-avatar img {
  left: 0 !important;
}
:global .marketingUserBox.managerBoxCanada .managerHead {
  margin-bottom: 5px;
  background-color: #ad2125;
}
:global .marketingUserBox .managerHead {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 80%;
}
:global .marketingUserBox .managerBody {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 10px;
  padding-left: 10px;
}
:global .marketingUserBox .managerBody.ais {
  align-items: flex-start;
}
:global .whatsNewCard .whatsNewBox {
  border-bottom: 1px solid #fde6e6;
  padding: 20px;
}
:global .whatsNewCard .whatsNewBox .flex-row {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
}
:global .whatsNewCard .whatsNewBox .flex-row .icon {
  width: 50px;
  height: 50px;
  margin-right: 30px;
  margin-left: 20px;
}
:global .whatsNewCard .whatsNewBox .flex-row .title {
  color: #0f52ba;
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 600;
}
:global .whatsNewCard .whatsNewBox .flex-row .dateRow {
  color: #555;
  font-size: 14px;
}
:global .whatsNewCard .whatsNewBox .flex-row .dateRow i {
  margin-right: 5px;
  color: #888;
}
:global .whatsNewCard .whatsNewBox .content-box {
  margin-top: 20px;
}
:global .whatsNewCard .whatsNewBox .img-box img {
  width: 80%;
}
:global .studentInfoTableRow {
  padding: 4px 10px;
  display: flex;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
:global .studentInfoTableRow .title {
  font-weight: bold;
  width: 40%;
  padding: 5px 10px;
}
:global .studentInfoTableRow .details {
  padding: 5px 10px;
}
:global .studentInfoTableRow p {
  margin-bottom: 0;
}
:global .studentInfoTableRow:first-child {
  border-top: 1px solid #ddd;
}
:global .studentInfoTableRow:nth-child(odd) {
  background-color: white;
}
:global .studentInfoTableRow:nth-child(even) {
  background-color: #effbf8;
}
:global .textAreaHeight {
  min-height: 100px !important;
}
:global .agent_form {
  background-color: #f7f7ff;
}
:global .agent_form .ant-form-item {
  margin-bottom: 0.8rem;
}
:global .agent_form .form-control,
:global .agent_form .ant-select-selection {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 50px !important;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-image: none !important;
}
:global .agent_form .form-control::-webkit-input-placeholder,
:global .agent_form .ant-select-selection::-webkit-input-placeholder {
  font-size: 1rem !important;
  font-weight: 400;
}
:global .agent_form .ant-select-selection__placeholder {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
}
:global .agent_form .ant-select-selection__rendered {
  margin-left: 0;
}
:global .agent_form .ant-select-arrow {
  background-color: white;
  color: #777 !important;
}
:global .agent_form .ant-select-focused .ant-select-arrow {
  background-color: white !important;
  color: #777 !important;
}
:global .agent_form label {
  font-size: 18px !important;
  margin: 0 !important;
  margin-bottom: 8px !important;
}
:global .agent_form .right_bg {
  height: 100%;
  background-position: 35%;
  background-repeat: no-repeat;
}
:global .agent_form .btn_sub {
  padding: 8px 16px !important;
  font-size: 1.25rem;
  border-radius: 0 !important;
  background-color: #1890ff;
  border: none;
}
:global .registrationClose {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .default_btn {
  border: 1px solid #ddd;
  padding: 3px 10px;
  color: #555;
  text-decoration: none;
  font-size: 0.8rem !important;
  border-radius: 3px;
  margin-right: 5px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 5px;
}
:global .default_btn:last-child {
  margin-right: 0;
}
:global .default_btn img {
  height: 15px !important;
  margin-right: 4px;
  margin-bottom: 2px;
}
:global .btn_group {
  display: flex;
  flex-wrap: wrap;
}
:global .btn_group .default_btn {
  margin-right: 5px;
}
:global .btn_group .btn {
  margin-right: 5px;
}
:global .select_btn_group {
  display: flex;
  flex-wrap: wrap;
}
:global .select_btn_group .default_btn {
  margin-right: 5px;
  background-color: white;
  line-height: 1.5;
  padding: 0.4rem 1.75rem !important;
  height: auto !important;
  border: none;
  margin-left: 5px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 15px;
}
:global .select_btn_group .default_btn.selected {
  background-color: #5cb85c;
  color: white;
}
:global .select_btn_group .default_btn .anticon {
  font-size: 16px;
  margin-right: 7px;
}
:global .select_btn_group .default_btn_lg {
  margin-right: 10px;
  background-color: white;
  line-height: 1.5;
  padding: 0.4rem 3rem !important;
  height: auto !important;
  border: none;
  margin-left: 5px;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  border-radius: 3px;
  color: #555;
  display: flex;
  align-items: center;
}
:global .select_btn_group .default_btn_lg.selected {
  color: white;
}
:global .select_btn_group .default_btn_lg .anticon {
  font-size: 16px;
  margin-right: 7px;
}
:global .btn_link {
  text-decoration: underline;
}
:global .custom-checkbox {
  width: 100%;
}
:global .custom-checkbox .ant-checkbox-group-item {
  width: 40% !important;
}
:global .custom-checkbox span {
  font-weight: normal;
}
:global .marketing_country_box {
  border: 1px solid #f1f1f1;
  padding: 5px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}
:global .marketing_country_box .uni_row {
  border: 1px solid #f1f1f1;
  padding: 5px 0 0 0;
  margin-bottom: 5px;
  border-radius: 5px;
}
:global .marketing_country_box .uni_row .form-control.sm {
  height: 35px !important;
  border-color: #ddd !important;
  background-image: none !important;
  font-size: 14px !important;
}
:global .filter_box {
  margin-top: 15px;
  background-color: white;
  border-radius: 5px;
  padding: 15px 10px 10px;
}
:global .filter_box.bg {
  border: 1px solid #dee2e6 !important;
}
:global .filter_box .labelNew {
  font-size: 14px;
  font-weight: normal !important;
}
:global .filter_box input:not([class]) {
  width: 100%;
  border: 1px solid #ddd;
  padding: 0 5px 0 20px;
  border-radius: 50px;
  font-size: 14px;
  color: #555;
  height: 40px;
}
:global .filter_box .ant-calendar-picker-input {
  height: 42px !important;
  border-radius: 50px;
  border: 1px solid #ddd;
}
:global .filter_box .ant-select.ant-select-focused .ant-select-arrow {
  background: transparent !important;
  color: #555;
}
:global .filter_box .ant-select .ant-select-selection {
  border-radius: 50px;
  border-color: #ddd;
}
:global .filter_box .ant-select .ant-select-selection .ant-select-arrow {
  background: transparent;
  color: #555;
}
:global .filter_box .ant-select .ant-select-selection:hover .ant-select-arrow {
  background-color: white;
  display: block;
}
:global .filter_box .ant-select .ant-select-selection:hover .ant-select-arrow .anticon {
  color: #555;
}
:global .filter_box .ant-select-selection__placeholder {
  font-size: 14px !important;
  opacity: 0.8 !important;
  color: #333 !important;
}
:global .filter_box .search-box-table.round {
  border: 1px solid #ddd;
}
:global .filter_box .btn_group {
  display: flex;
  justify-content: flex-end;
}
:global .filter_box .btn_group .default_btn {
  margin-right: 5px;
  padding: 5px 20px;
  border-radius: 50px;
}
:global .filter_box .btn_group .default_btn:last-child {
  margin-right: 0;
}
:global .mismatchNote {
  padding: 5px 10px;
  background-color: #d9534f;
  color: white;
  font-weight: 600;
  margin-top: 10px;
}
:global .expenseAmtBox .successAmt {
  color: #5cb85c;
}
:global .expenseAmtBox .pendingAmt {
  color: rgba(240, 173, 78, 0.87);
}
:global .expenseAmtBox .totalAmt {
  color: #0872bc;
}
:global .joinDate {
  margin-top: 5px;
  color: #1890ff;
  font-size: 11px;
  border-radius: 3px;
  font-weight: 600;
}
:global .joinDate::before {
  content: '' !important;
  border-left: 2px solid #1890ff;
  padding-left: 3px;
}
:global .table-bordered {
  border: 1px solid #dee2e6 !important;
}
:global .table-bordered th,
:global .table-bordered td {
  border: 1px solid #dee2e6 !important;
}
:global .table-bordered thead th,
:global .table-bordered thead td {
  border-bottom-width: 2px;
}
:global .table-bordered th,
:global .table-bordered td,
:global .table-bordered thead th,
:global .table-bordered tbody + tbody {
  border: 0;
}
:global .marketingDirectUniCount {
  width: 16.6% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
}
:global .marketingDirectUniCount .uni_box {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
:global .marketingDirectUniCount .uni_box img {
  height: 40px;
  width: auto;
}
:global .marketingDirectUniCount .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 15px;
}
:global .marketingDirectUniCount .uniCountBox .countBox {
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 40%;
  cursor: pointer;
}
:global .marketingDirectUniCount .uniCountBox .countBox .label {
  font-size: 12px !important;
  color: #555;
  padding: 0;
}
:global .marketingDirectUniCount .uniCountBox .countBox .count {
  padding: 0 5px;
  color: #1890ff;
}
:global .dropbtn {
  border: none;
}
:global input[type='date'].js_datepicker {
  background-color: white !important;
  border: 1px solid #e5dddd !important;
}
:global .eye_btn {
  padding: 0 3px !important;
  border-radius: 3px;
  margin-left: 5px;
  font-size: 10px;
  color: #0f52ba !important;
}
:global .eye_btn img {
  height: 14px;
}
:global .structureDotsReplace {
  display: block;
  overflow-y: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}
:global .event_gallery_list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
:global .event_gallery_list .single_img {
  border: 1px solid #ebcfcf;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
:global .event_gallery_list .single_img:hover {
  border: 2px solid #0872bc;
}
:global .event_gallery_list .single_img .cross_btn {
  position: absolute;
  right: -8px;
  top: -8px;
  background: #0f52ba;
  border-radius: 50px;
  width: 22px;
  height: 22px;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
:global .event_gallery_list .single_img img {
  height: 100px;
}
:global .btn_options {
  display: flex;
}
:global .btn_options a {
  font-size: 14px;
  width: 100%;
  height: 40px !important;
  padding: 0.475rem 0.75rem;
}
:global .btn_options .date_box {
  width: 220px;
  margin-right: 10px !important;
}
:global .btn_options .date_box.selected {
  background-color: #f1f1f1 !important;
}
:global .btn_options .date_box.selected .btn {
  background-color: #f1f1f1 !important;
}
:global .btn_options .date_box .form-control {
  border-color: #ddd !important;
  font-size: 15px;
}
:global .ant-calendar-month-calendar {
  width: 300px;
}
:global div#studentChart {
  padding-top: 30px !important;
}
:global .agent-rank-label {
  font-size: 10px !important;
  padding: 4px !important;
}
:global .priorityBox {
  margin-top: 10px;
}
:global .priorityBox .priority {
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background: rgba(240, 173, 78, 0.87);
  font-weight: 600;
  position: relative;
  top: -3px;
  left: 0;
  margin-left: 5px;
  font-style: normal;
  -webkit-animation: glowS 0.5s ease-in-out infinite alternate;
  -moz-animation: glowS 0.5s ease-in-out infinite alternate;
  animation: glowS 0.5s ease-in-out infinite alternate;
  text-transform: uppercase;
}
@-webkit-keyframes glowS {
  from {
    box-shadow: 0 0 6px rgba(240, 173, 78, 0.87);
  }
  to {
    box-shadow: 0 0 13px rgba(240, 173, 78, 0.87);
  }
}
:global .priorityBox .superPriority {
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 50px;
  color: white;
  background: red;
  font-weight: 600;
  position: relative;
  top: -3px;
  left: 0;
  margin-left: 5px;
  font-style: normal;
  -webkit-animation: glow 0.5s ease-in-out infinite alternate !important;
  -moz-animation: glow 0.5s ease-in-out infinite alternate !important;
  animation: glow 0.5s ease-in-out infinite alternate !important;
  text-transform: uppercase;
}
@-webkit-keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
:global .eventDirectCount {
  width: 20% !important;
  display: inline-block;
  padding: 0 20px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
:global .eventDirectCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
:global .eventDirectCount .innerBox .uniCountBox {
  display: flex !important;
  align-items: center;
  padding: 10px 5px;
}
:global .eventDirectCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
}
:global .eventDirectCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .eventDirectCount .innerBox .btnBox a i {
  margin-right: 5px;
}
:global .eventDirectCount .innerBox .dirUniAgent {
  padding: 10px 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 27px;
}
:global .eventDirectCount .innerBox .squareContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  margin-bottom: 10px;
}
:global .eventDirectCount .innerBox .squareContainer > div {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}
:global .eventDirectCount .innerBox .squareContainer > div span {
  font-size: 12px;
  margin-bottom: 5px;
}
:global .eventDirectCount .innerBox .squareContainer .squareStyle {
  width: 60px;
  height: 35px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  text-align: center;
  align-self: center;
}
:global .eventDirectCount .innerBox .squareContainer .squareStyle.totalCount {
  border-bottom: 2px solid #5cb85c;
}
:global .eventDirectCount .innerBox .squareContainer .squareStyle.approvedCount {
  border-bottom: 2px solid #1890ff;
}
:global .eventDirectCount .innerBox .squareContainer .squareStyle.pendingCount {
  border-bottom: 2px solid #0872bc;
}
:global .eventDirectCount .innerBox .squareContainer .squareStyle.deferredCount {
  border-bottom: 2px solid #5bc0de;
}
:global .eventDirectCount .innerBox .squareContainer .squareStyle.deniedCount {
  border-bottom: 2px solid #d9534f;
}
:global .icefMeetingCount .singleLi {
  display: inline-block;
  padding: 10px;
}
:global .icefMeetingCount .singleLi .li_box {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
}
:global .icefMeetingCount .singleLi .li_box .squareStyle {
  margin-left: 20px;
  border-bottom: 2px solid;
  min-width: 30px;
  text-align: center;
  font-weight: bold;
}
:global .main-btn {
  width: auto !important;
  min-width: 150px;
  background: #1556bc;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  height: 42px !important;
}
:global .white-btn {
  background: white;
  border: 1px solid #ddd;
  color: #555;
}
:global .white-btn .ant-calendar-picker-input.ant-input {
  height: 35px !important;
}
:global .small-select .ant-select-selection {
  height: 30px !important;
  border-color: #ddd;
  border-radius: 3px;
}
:global .small-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
:global .md-select .ant-select-selection {
  height: 33px !important;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
:global .md-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
:global .room_slider .mobile_view {
  display: none;
}
:global .room_slider .web_view {
  display: block;
}
@media (max-width: 768px) {
  :global .room_slider .mobile_view {
    display: block;
  }
  :global .room_slider .web_view {
    display: none;
  }
}
:global .shadow-select {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
}
:global .shadow-select .ant-select-selection {
  height: 35px !important;
  border-radius: 7px;
  padding: 15px 10px;
  border: none;
}
:global .shadow-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
:global .comment-section .sendBtn {
  border-radius: 15px !important;
  background-color: #87d068;
  border: none;
  color: white;
  padding: 14px !important;
}
:global .comment-section .avatar_text {
  background-color: #87d068;
  margin: 0 12px;
  font-size: 20px !important;
}
:global .top_city {
  height: 170px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
:global .top_city img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
:global .top_city .city_name {
  position: absolute;
  bottom: 0;
  left: 7.5px;
  padding: 15px;
  z-index: 1;
  color: white;
  font-size: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: calc(100% - 15px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 500;
}
:global .shadow-select {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
}
:global .shadow-select .ant-select-selection {
  height: 35px !important;
  border-radius: 7px;
  padding: 15px 10px;
  border: none;
}
:global .shadow-select .ant-select-selection .ant-select-arrow {
  background-color: transparent !important;
  color: #555;
}
:global .comment-section .sendBtn {
  border-radius: 15px !important;
  background-color: #87d068;
  border: none;
  color: white;
  padding: 14px !important;
}
:global .comment-section .avatar_text {
  background-color: #87d068;
  margin: 0 12px;
  font-size: 20px !important;
}
:global .top_city {
  height: 170px;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}
:global .top_city img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 15px;
}
:global .top_city .city_name {
  position: absolute;
  bottom: 0;
  left: 7.5px;
  padding: 15px;
  z-index: 1;
  color: white;
  font-size: 16px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  width: calc(100% - 15px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 500;
}
:global .icefManager {
  background: #A1A8EA;
  height: 140px;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .icefManager .avatar_box {
  position: absolute;
  bottom: 0;
  left: 5%;
}
:global .icefManager .avatar_box img {
  height: 170px;
}
:global .icefManager .manager_info_box {
  position: absolute;
  left: 25%;
  font-size: 13px;
}
:global .icefManager .manager_info_box .head-title {
  font-size: 22px;
  color: white;
}
:global .icefManager .manager_info_box .manager_details .inline_a {
  display: flex;
  align-items: center;
}
:global .icefManager .manager_info_box .manager_details .inline_a:first-child {
  font-style: italic;
}
:global .event-card .card-container {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 400px !important;
  justify-content: flex-start;
}
:global .event-card .event-card-box h2 {
  font-size: 22px;
  margin: 15px 0 0 0;
  text-shadow: 1px 1px 5px #000;
  color: #fff;
  font-weight: bold;
}
:global .event-card .event-card-box h3 {
  font-size: 16px;
  margin: 5px 0 0 0;
  text-shadow: 1px 1px 5px #000;
  color: #fff;
  font-weight: bold;
}
:global .event-card .event-card-box p {
  font-size: 16px;
  position: absolute;
  bottom: 45px;
  left: 50%;
  right: 25%;
  width: 250px;
  margin-left: -145px;
  text-transform: uppercase;
  text-shadow: 1px 1px 5px #000;
  font-weight: bold;
}
:global .event-card .event-card-box .event-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  margin-bottom: 20px;
  font-size: 16px;
}
:global .event-card .event-card-box .event-info .add-event-btn {
  margin-top: 1rem;
  border: 2px solid white;
  padding: 6px;
  position: absolute;
  right: 20px;
  bottom: 5px;
  cursor: pointer;
}
:global .task_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:global .task_box .label-sm {
  padding: 0.5em 0.6em 0.3em;
}
:global .completeTask {
  text-decoration: line-through;
}
:global .event_list_card {
  height: 82vh;
  overflow-y: auto;
  padding: 5px;
}
:global .event_list_card .single_event_card {
  margin-bottom: 15px;
  padding: 20px;
  box-shadow: 0 0 10px #ddd;
  border-radius: 10px;
}
:global .event_list_card .single_event_card .ant-collapse {
  border: 1px solid #f4efef !important;
  margin-top: 10px;
}
:global .event_list_card .single_event_card .ant-collapse .ant-collapse-header {
  padding: 8px 40px;
}
:global .align_center {
  align-items: center;
  display: flex;
  color: #0f52ba;
}
:global .aic {
  align-items: center !important;
  display: flex !important;
}
:global .aic label {
  margin-bottom: 0;
}
:global .ant-select-disabled .ant-select-selection {
  background-color: #ededed !important;
}
:global .calendar-container.bgWhite {
  background: white !important;
}
:global .calendar-container.bgWhite .ant-card-body {
  padding: 0;
}
:global .calendar-container .table-event td {
  width: calc(100% / 7);
}
:global .small-select .ant-select-selection--single {
  height: 30px !important;
  border-color: #666;
  border-radius: 5px;
}
:global .small-select .ant-select-selection--single .ant-select-arrow {
  padding: 3px;
  margin-top: -10px;
}
:global .custom_row {
  display: flex;
  margin-left: -9px;
  margin-right: -9px;
}
:global .custom_row .custom_col {
  flex: 1;
  padding-left: 9px;
  padding-right: 9px;
}
:global .w-65 {
  width: 65% !important;
}
:global .login--wCard {
  width: 65% !important;
}
:global .commission-listing-section {
  padding: 30px 2rem !important;
}
:global .custom-calendar-1 {
  width: 100% !important;
  font-size: 26px;
  border: none !important;
}
:global .custom-calendar-1 .rc-calendar-header {
  border-bottom: none;
}
:global .custom-calendar-1 .rc-calendar-body {
  padding: 0;
}
:global .custom-calendar-1 .rc-calendar-body .rc-calendar-cell > div {
  margin-left: auto;
  margin-right: auto;
}
:global .ant-menu-inline .ant-menu-submenu-title {
  padding-right: 20px !important;
}
:global .autoCompleted ul {
  width: 100%;
}
:global .autoCompleted .ant-select-search__field__wrap {
  width: 100%;
}
:global .help_btn {
  position: absolute !important;
  font-size: 14px;
  right: 10px;
  margin-top: 2px;
  border: 1px solid #d5c4c4;
  padding: 0px 20px;
  border-radius: 5px;
  color: #555;
  bottom: 10px;
  display: flex;
  align-items: center;
}
:global .help_btn i {
  margin-right: 5px;
}
:global .help_btn:hover {
  color: black;
}
:global .req_btn {
  font-size: 14px;
  right: 10px;
  border: 1px solid #d5c4c4;
  padding: 0px 5px;
  border-radius: 5px;
  color: #555;
  bottom: 40px;
  display: flex;
  align-items: center;
}
:global .req_btn i {
  margin-right: 5px;
}
:global .req_btn:hover {
  color: black;
}
:global .custom-container .custom_card .ant-card-head {
  height: 30px;
  background: #f1f1f1;
  font-size: 15px;
  color: #555;
}
:global .custom-container .custom_card .ant-card-head span {
  font-size: 14px;
  /* margin-left: 5px; */
}
:global .custom-container .custom_card .ant-card-body {
  padding: 14px 14px 14px 24px;
  color: black;
}
:global .table-sm th,
:global .table-sm td {
  padding: 0.3rem !important;
  font-size: 12px !important;
}
:global .table-striped tr:nth-of-type(odd) {
  background-color: #dff0d8;
}
:global .flexWrap {
  display: flex;
  flex-wrap: wrap;
}
:global .commissionRow {
  display: flex;
}
:global .commissionRow .commBox {
  flex: 1;
}
:global .direct-uni-box .count {
  display: flex !important;
}
:global .direct-uni-box .canadaDirectUniCount {
  width: 20% !important;
  display: inline-block;
  padding: 0 10px 0 10px !important;
  margin: 0 !important;
  cursor: pointer;
}
:global .direct-uni-box .canadaDirectUniCount:last-child {
  padding-right: 0;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox {
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 6px #1890ff !important;
  padding: 10px !important;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox .record-count {
  display: flex;
  justify-content: center;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox img {
  height: 30px;
  width: auto;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox img.sm {
  height: 25px;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox .uniCountBox h5 {
  font-size: 14px;
  height: 35px;
  width: auto;
  border-radius: 50%;
  line-height: 2.5;
  background: #0872bc;
  color: white !important;
  min-width: 35px;
  padding: 0 5px;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox .btnBox {
  border-top: 1px solid #f1f1f1;
  margin-top: 15px;
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox .btnBox a {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox .btnBox a i {
  margin-right: 5px;
}
:global .direct-uni-box .canadaDirectUniCount .innerBox .dirUniAgent {
  padding: 0;
  font-size: 12px;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 1px;
  background: #f1f1f1;
  margin: 10px 0;
}
:global .direct-uni-box .squareContainer {
  margin-top: 15px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(4, 1fr);
}
:global .direct-uni-box .squareContainer .squareStyle {
  width: auto;
  height: 32px;
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  color: #0f52ba;
  font-size: 15px;
  margin-bottom: 0;
  text-align: center;
}
:global .direct-uni-box .squareContainer .squareStyle:nth-child(1) {
  border-bottom: 2px solid #1890ff;
}
:global .direct-uni-box .squareContainer .squareStyle:nth-child(2) {
  border-bottom: 2px solid #5cb85c;
}
:global .direct-uni-box .squareContainer .squareStyle:nth-child(3) {
  border-bottom: 2px solid #0872bc;
}
:global .direct-uni-box .squareContainer .squareStyle:nth-child(4) {
  border-bottom: 2px solid #5bc0de;
}
:global .direct-uni-box .squareContainer .squareStyle:nth-child(5) {
  border-bottom: 2px solid rgba(240, 173, 78, 0.87);
}
:global .direct-uni-box .squareContainer .squareStyle:nth-child(6) {
  border-bottom: 2px solid #1890ff;
}
:global .direct-uni-box .squareContainer .squareStyle:nth-child(7) {
  border-bottom: 2px solid #5cb85c;
}
:global .direct-uni-box .squareContainer .squareStyle:nth-child(8) {
  border-bottom: 2px solid #5cb85c;
}
:global .commissionBlock .countryCommission {
  width: 20%;
}
@media only screen and (max-width: 1441px) {
  :global .canadaDirectUniCount .innerBox .uniCountBox {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
  }
  :global .canadaDirectUniCount .innerBox .uniCountBox img {
    max-height: 20px;
    width: auto;
  }
  :global .canadaDirectUniCount .innerBox .uniCountBox h5 {
    font-size: 9px !important;
    height: 25px;
    width: auto;
    min-width: 25px;
    line-height: 3;
  }
  :global .canadaDirectUniCount .innerBox .btnBox a {
    font-size: 11px;
  }
}
@media only screen and (max-width: 560px) {
  :global section.banner1 {
    height: auto !important;
  }
  :global section.banner1 .img {
    display: none;
  }
  :global .login--wCard {
    width: 80% !important;
  }
  :global .direct-uni-box .canadaDirectUniCount {
    width: 100% !important;
    margin-bottom: 10px !important;
  }
  :global .commissionBlock .countryCommission {
    width: 50%;
  }
}
@media only screen and (max-width: 991px) {
  :global .institute--event .table-head h5 {
    width: 65% !important;
  }
  :global .institute--event .event-teaser .event-meta {
    flex-direction: column;
  }
  :global .institute--event .event-teaser .event-title {
    padding-right: 0;
  }
  :global .institute--event .event-teaser .event-date-day1 {
    width: 100%;
  }
  :global .institute--event .event-teaser .event-venue-wrap1 {
    padding: 20px 0;
    margin-left: 0;
  }
  :global .institute--event .event-teaser .reg_btn {
    margin-left: 0 !important;
  }
  :global .institute--event .event-teaser .regTime {
    margin: 10px 0;
  }
}
:global .WcStyle {
  padding: 3px !important;
}
:global .penSpan {
  background: #fff;
  white-space: nowrap;
  text-transform: capitalize;
  padding: 3px 12px;
  border-radius: 50px;
  font-weight: 500 !important;
  font-size: 12px;
  border: 2px solid;
}
:global .gic-list .active {
  color: #50C37E;
  border-color: #50C37E;
}
:global .gic-list .pending {
  color: #bccf5b;
  border-color: #bccf5b;
}
:global .rc-table table td span.inactive {
  color: #ff3f5b;
  border-color: #ff3f5b;
}
:global .rc-table table td:first-child img {
  width: 35px;
}
:global .rc-table table td a {
  font-weight: 500;
  color: #3E6AB4;
}
:global .rc-table table td:last-child {
  white-space: nowrap;
}
:global .rc-table table td:last-child button {
  padding: 0;
  width: 30px;
  height: 30px;
}
:global .rc-table table td:last-child button img {
  width: 100%;
}
:global .debit2 {
  color: #ff0000;
  font-weight: 600;
}
:global .credit2 {
  color: #008000;
  font-weight: 600;
}
:global .nav-sidebar .nav-item .nav-link1 {
  font-size: 13px !important;
}
:global .table-head.table-head-new h6 {
  font-size: 15px !important;
  margin: 0 !important;
  width: auto !important;
  padding-right: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:global .travel-plan-table .ant-table-bordered .ant-table-thead > tr > th,
:global .travel-plan-table .ant-table-bordered .ant-table-tbody > tr > td {
  vertical-align: top;
}
:global .gic-manager .header-box {
  padding: 10px 20px 5px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: #0f52ba;
}
:global .round-selector .ant-select-selection__rendered ul {
  padding: 0 10px;
}
:global .round-selector .ant-select-selection__rendered ul li {
  border-radius: 50px;
}
:global .feedback-button.selected {
  border: 2px solid #1890ff !important;
}
:global .tag-gic {
  color: rgba(0, 0, 0, 0.65);
  border-radius: 10px;
  width: 200px;
  display: flex;
  align-items: center;
}
:global .travel-carousel .slick-list {
  padding-left: 20px;
}
:global .travel-carousel .slick-dots {
  position: absolute;
  bottom: 0px !important;
}
:global .travel-carousel .slick-dots li {
  border-bottom: none;
  background: none !important;
}
:global .travel-carousel .slick-dots li button {
  background: #0872bc !important;
  height: 5px !important;
}
:global .travel-carousel .slick-arrow.slick-prev {
  font-size: 10px;
}
:global .travel-carousel .ant-carousel .slick-prev::before {
  content: '<';
  z-index: 1000 !important;
  display: block;
  position: relative;
  bottom: 10px;
  right: 0px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
:global .travel-carousel .slick-arrow.slick-next {
  font-size: 10px;
}
:global .travel-carousel .ant-carousel .slick-next::before {
  content: '>';
  display: block;
  position: relative;
  right: 0px;
  left: 0;
  bottom: 10px;
  /* width: 100px; */
  font-size: 25px;
  color: gray;
  background-color: white;
  border: 2px solid gray;
  justify-content: center;
  align-items: center;
  padding: 9px 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}
:global .marketingUserBox2 .managerHead2 {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid #f1f1f1;
}
:global .marketingUserBox2 .managerHead2 .universityFlag {
  font-size: 11px;
  font-weight: 600;
}
:global .marketingUserBox2 .managerHead2 .universityFlag img {
  height: 20px;
  width: auto;
  border-radius: 3px;
}
:global .marketingUserBox2 .travel-avator {
  border: 2px solid;
  padding-right: 0;
  margin-top: 5px;
  margin-left: 15px;
  margin-right: 15px;
}
:global .font-weight-500 {
  font-weight: 500;
}
:global .font-weight-600 {
  font-weight: 600;
}
:global .text-black {
  color: #212529;
}
:global .ant-input-group-wrapper {
  padding: 0 !important;
}
:global .ant-input-group-wrapper .ant-input {
  height: 40px;
}
:global tr.warning_tr td {
  background-color: #fcf8e3 !important;
}
:global .feedbackButton.selected {
  /* Selected button styles */
  background-color: #f0f0f0 !important;
  color: #333;
  border: 1px solid #ccc;
}
:global .successTag2 {
  background: #c2cdd5;
  border: 1px solid #0872bc;
  padding: 1px 20px;
  border-radius: 20px;
  font-size: 13px;
  color: white;
}
:global .managerHead2 {
  margin-right: auto;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 100%;
}
:global .universityWiseBlock {
  margin-top: 15px !important;
}
:global .flagBox {
  height: 35px;
}
:global .liveReportingOuter {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 30px;
}
:global .liveReportingOuter .liveReportingInnerBox {
  border: 1px solid #efefef;
  line-height: 2;
  border-radius: 3px;
  font-size: 15px;
  text-align: center;
}
:global .liveReportingOuter .liveReportingInnerBox .reportingInnerBox {
  height: 100px;
}
:global .sopModalRow {
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}
:global .successMessage {
  font-size: 18px;
  /*color: #1556bc;*/
  color: black;
  margin-bottom: 20px;
}
:global .buttonContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
}
:global .viewButton,
:global .closeButton {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
:global .viewButton {
  background-color: #2196F3;
  color: white;
}
:global .viewButton:hover {
  background-color: #1976D2;
}
:global .closeButton {
  background-color: #f44336;
  color: white;
}
:global .closeButton:hover {
  background-color: #d32f2f;
}
:global .status-list-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
}
:global .status-list-grid .status-item-grid {
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
  /*    &::after {
            content: "|";
            display: inline-block;
          }*/
}
:global .status-list-grid .status-item-grid .status-box {
  display: inline-block;
}
:global .status-list-grid .status-item-grid .status-box .status-name {
  font-size: 15px;
  color: #666;
}
:global .status-list-grid .status-item-grid .status-box .status-count {
  color: #0f52ba;
  font-size: 18px;
  font-weight: normal;
  cursor: pointer;
}
:global .liveReportTarget {
  margin-top: 10px;
}
:global .target-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 13px;
  font-weight: 500;
}
:global .target-box .value-box {
  display: flex;
  justify-content: center;
}
:global .target-box img {
  height: 20px;
  margin-right: 5px;
}
:global .target-box span {
  font-size: 17px;
}
:global .custom-autocomplete .ant-select-search__field__wrap {
  width: 100%;
}
/* Larger screens */
@media (min-width: 1400px) {
  .canadaDirectUniCount {
    width: 32% !important;
    padding: 0 15px !important;
  }
  .card-pane-right.listing-section {
    padding: 30px 3rem 30px 4rem;
    display: flex;
    align-items: center;
  }
  .download-pdf-flag {
    width: 25px !important;
    height: 21px !important;
  }
  .download-pdf-text {
    font-weight: bold !important;
    font-size: 19px !important;
  }
  .card-pane-right.listing-section ul li p {
    word-spacing: normal;
  }
  .card-pane-right.listing-section ul li {
    width: 23.6% !important;
    display: inline-block;
  }
}
@media print {
  body {
    font-family: Arial, sans-serif;
    font-size: 12px;
  }
  .card-body {
    padding: 8px;
  }
  .download-pdf-flag {
    height: 20px;
    width: 25px;
    margin-right: 15px;
  }
  h5 {
    margin: 0;
    padding: 0;
    font-size: 14px;
  }
}
.flexUl {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}
.flexUl li {
  width: 32%;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 5px;
  height: 38px;
  border-radius: 5px;
}
.flexUl li:nth-child(2) {
  margin: 0 2%;
}
.singleCountry {
  padding-left: 5px;
}
.singleCountry img {
  height: 15px;
  margin-right: 5px;
  opacity: 0.8;
}
.singleCountry div {
  font-size: 12px;
  margin-top: 0;
  transform: rotate(335deg);
  color: #555;
  font-weight: bold;
}
.count {
  font-size: 17px;
  color: #0f52ba;
  padding-right: 10px;
}
